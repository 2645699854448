import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { loadUserRoles, resetRoles, selectUserRoles, updateRoleRecord } from '../../../../../state/store/reducers/entities/userRoleSlice';
import useToast from '../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
import userRolesService from '../../../../../services/api/user-accounts/userRolesService';
import { Button, ErrorLoading, ListNoData, Pagination } from '../../../../../components/common';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import { paginationInfo } from '../../../../../utils/pagination';
import { ListLoader } from '../../../../../components/loaders';
import { format } from 'date-fns';
import ModalUserRoleForm from '../modals/ModalUserRoleForm';
import ModalUserRoleDetails from '../modals/ModalUserRoleDetails';
import useAlert from '../../../../../state/hooks/useAlert';


function UserRolesListPage() {
    const dispatch = useDispatch();
    const userRoles = useSelector(selectUserRoles);
    const [searchParams, setSearchParams] = useSearchParams();

    const [userRoleForm, setUserRoleForm] = useState({ open: false, role: null });
    const [userRoleDetails, setUserRoleDetails] = useState({ open: false, role: null });

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    // get query params
    const page = Number(searchParams.get('page')) || userRoles.pager.page;
    const searchFilters = {
        //name: searchParams.get('name') || '',
    };

    useEffect(() => {
        dispatch(changePageInfo({ title: "User Roles" }));

        return () => {
            dispatch(resetRoles({}));
        }
    }, []);


    useEffect(() => {
        getUserRoles();
    }, [searchParams, dispatch]);



    const getUserRoles = async () => {
        dispatch(resetRoles({}));
        await dispatch<any>(loadUserRoles({
            page: page,
            filters: searchFilters,
        }));
    };


    const handleDelete = async (role: any) => {
        openAlertConfirm({
            title: 'Delete Role',
            message: `Are you sure you want to remove ${role.label} role?`,
            onConfirmed: () => deleteRole(role),
            positiveButtonText: "Delete",
            negativeButtonText: 'Cancel',
            positiveButtonClass: 'bg-red-500 hover:bg-red-600',
        });
    }


    const deleteRole = async (role: any) => {
        const result = await userRolesService.deleteRole(role.id);
        if (result.success) {
            getUserRoles();
            addToast({
                text: "Role deleted successfully",
                type: "success"
            });
        } else {
            addToast({
                title: "Error",
                text: "Role could not be deleted",
                type: "error"
            });
        }
    }


    return (
        <div className=''>
            <div className='flex flex-row-reverse flex-wrap justify-between mb-1'>
                <Button type='button' onClick={() => setUserRoleForm({ open: true, role: null })} className='mb-4'>Add Role</Button>
                <div>

                </div>
            </div>

            {
                // if there're roles
                !userRoles.loadingError && !userRoles.loading && userRoles.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>Role</th>
                                    <th className='py-6 px-6'>Description</th>
                                    <th className='py-6 px-6'>Permissions</th>
                                    <th className='hidden 2xl:table-cell py-6 px-6'>Date Created</th>
                                    <th className='py-6 px-6 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    userRoles.list.map((roleItem: any) =>
                                        <tr key={roleItem.id} className='group'>
                                            <td className='py-4 px-6'>{roleItem.label}</td>
                                            <td className='py-4 px-6'>{roleItem.description}</td>
                                            <td className='py-4 px-6'>
                                                <div className='flex gap-2.5'>
                                                    {
                                                        roleItem.permissions
                                                            .slice(0, 2)
                                                            .map((permissionItem: any) =>
                                                                <span
                                                                    key={permissionItem.id}
                                                                    className='text-sm bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'
                                                                >
                                                                    {permissionItem.name}
                                                                </span>
                                                            )
                                                    }
                                                    {
                                                        roleItem.permissions.length > 2 &&
                                                        <span className='text-sm bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>
                                                            +{roleItem.permissions.length - 2}
                                                        </span>
                                                    }
                                                    {
                                                        roleItem.permissions.length > 0 &&
                                                        <button type='button' onClick={() => setUserRoleDetails({ open: true, role: roleItem })} className='opacity-0 group-hover:opacity-90 font-medium whitespace-nowrap text-sm transition-all duration-300 hover:text-blue p-2 mx-1'>
                                                            {roleItem.permissions.length > 2 ? 'View All' : 'View'}
                                                        </button>
                                                    }
                                                </div>
                                            </td>
                                            <td className='hidden 2xl:table-cell py-4 px-6 whitespace-nowrap'>{format(new Date(roleItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 px-6 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-64 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setUserRoleForm({ open: true, role: roleItem, })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => handleDelete(roleItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                Delete
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(userRoles.pager.pageSize, userRoles.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={userRoles.pager.pageSize}
                            totalCount={userRoles.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if roles are loading
                !userRoles.loadingError && userRoles.loading &&
                <ListLoader loadingText='Loading User Roles' className='px-8 py-24' />
            }

            {
                // if there're no role in the app
                !userRoles.loadingError && !userRoles.loading && userRoles.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Role Found' description='There are no user role added in the app' className='px-8 py-24'>
                    <Button type='button' onClick={() => setUserRoleForm({ open: true, role: null })}>Add a User Role Here</Button>
                </ListNoData>
            }

            {
                // if there're no role in the app
                !userRoles.loadingError && !userRoles.loading && userRoles.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Role Found' description='No user role was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                userRoles.loadingError &&
                <ErrorLoading title='Error Loading Roles' message='There was a problem loading user roles' className='px-8 py-24' onTryAgain={getUserRoles} />
            }


            <ModalUserRoleForm
                isOpen={userRoleForm.open}
                role={userRoleForm.role}
                onRoleAdded={(role) => {
                    addToast({
                        text: `Role created successfully`,
                        type: "success"
                    });

                    dispatch(resetRoles({}));
                    setSearchParams({});

                    if (Array.from(searchParams.entries()).length === 0) {
                        getUserRoles();
                    }
                }}
                onRoleUpdated={(role) => {
                    dispatch(updateRoleRecord({ role, page }));
                    addToast({
                        text: `Role updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setUserRoleForm((prevState) => ({ ...prevState, open: false }))}
            />


            {
                userRoleDetails.role &&
                <ModalUserRoleDetails
                    isOpen={userRoleDetails.open}
                    role={userRoleDetails.role}
                    onPermissionsChanged={(role: any) => {
                        dispatch(updateRoleRecord({ role, page }));
                    }}
                    onClose={() => setUserRoleDetails((prevState) => ({ ...prevState, open: false }))}
                />
            }
        </div>
    );
}


export default UserRolesListPage;