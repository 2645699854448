import React, { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { format } from 'date-fns';

import Modal from '../../../../../../../../components/modals/Modal';
import { ErrorLoading, ListNoData } from '../../../../../../../../components/common';
import { ListLoader } from '../../../../../../../../components/loaders';
import invoicesService from '../../../../../../../../services/api/payments/invoicesService';


function ModalInvoiceDetails({ isOpen, invoice, onClose }: Props) {
    const [invoiceStatus, setInvoiceStatus] = useState({ loading: false, error: false, notFound: false });
    const [invoiceDetails, setInvoiceDetails] = useState<any>(null);


    useEffect(() => {
        if (invoice?.id) {
            getInvoice();
        }
    }, [invoice?.id]);


    const getInvoice = async () => {
        setInvoiceStatus({ loading: true, error: false, notFound: false });
        const result = await invoicesService.getInvoice(invoice.id);
        if (result?.success) {
            setInvoiceDetails(result.data);
            setInvoiceStatus({ loading: false, error: false, notFound: false });
        } else {
            if (result.notFound) {
                setInvoiceStatus({ loading: false, error: true, notFound: true });
            } else {
                setInvoiceStatus({ loading: false, error: true, notFound: false });
            }
        }
    };



    return (
        <Modal open={isOpen} onClose={onClose} >
            <div className="w-screen max-w-3xl">
                <div className="flex justify-between mb-5 px-8 pt-8">
                    <h2 className="font-medium text-xl">
                        Invoice Details
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-500 focus:outline-none">
                        <i className="ri-close-line"></i>
                    </button>
                </div>


                {
                    !invoiceStatus.loading && !invoiceStatus.error && invoiceDetails &&
                    <div className='max-h-[80vh] overflow-y-auto px-8 pb-6'>
                        <div className='flex justify-between gap-y-2 gap-x-8 items-end mb-2'>
                            <div className='pb-1.5'>
                                <p className='font-medium text-lg mb-0.5'>{invoiceDetails?.name}</p>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400'>{invoiceDetails?.invoiceNumber}</p>
                            </div>


                            <span className={`inline-block font-medium text-sm uppercase ${invoiceDetails?.approved ? "bg-green-500/10 text-green-500" : "bg-yellow-500/10 text-yellow-500"} rounded-lg py-2 px-3`}>
                                {invoiceDetails?.approved ? "Approved" : "Not Approved"}
                            </span>
                        </div>

                        <Tab.Group>
                            <Tab.List as='div' className="border-b dark:border-gray-700">
                                <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Details</Tab>
                                <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Invoice Items</Tab>
                                <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Payment Plan</Tab>
                            </Tab.List>
                            <Tab.Panels as='div' className="">
                                <Tab.Panel unmount={false} as='div' className="divide-y dark:divide-gray-800 pt-2">
                                    <div className='divide-y dark:divide-gray-800'>
                                        <div className='grid grid-cols-2'>
                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Billed For</p>
                                                <p>{invoiceDetails?.tag}</p>
                                            </div>

                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Invoice by (School)</p>
                                                <p>{invoiceDetails?.client?.name}</p>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-2'>
                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Collecting Account</p>
                                                <p className='flex items-center flex-wrap gap-2'>
                                                    <span>{invoiceDetails?.collectingAccount?.accountName}</span>
                                                    <span className={`inline-block font-medium text-sm uppercase ${invoiceDetails?.collectingAccount?.status ? "bg-green-500/10 text-green-500" : "bg-yellow-500/10 text-yellow-500"} rounded-lg py-0.5 px-2`}>
                                                        {invoiceDetails?.collectingAccount?.status ? "Active" : "In Active"}
                                                    </span>
                                                </p>
                                            </div>

                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Account No.</p>
                                                <p>{invoiceDetails?.collectingAccount?.accountNumber}</p>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-2'>
                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Account Provider</p>
                                                <p>{invoiceDetails?.collectingAccount?.accountProvider}</p>
                                            </div>

                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Account Type</p>
                                                <p>{invoiceDetails?.collectingAccount?.accountType}</p>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-2'>
                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Due Date</p>
                                                <p>{invoiceDetails?.dueDate ? format(new Date(invoiceDetails?.dueDate), 'd LLL yyyy h:mm a') : ''}</p>
                                            </div>

                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Generated On</p>
                                                <p>{invoiceDetails?.generatedDate ? format(new Date(invoiceDetails?.generatedDate), 'd LLL yyyy h:mm a') : ''}</p>
                                            </div>
                                        </div>

                                        <div className=''>
                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Description</p>
                                                <p>{invoiceDetails?.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>
                                <Tab.Panel unmount={false} as='div' className="pt-5">
                                    {
                                        invoiceDetails.invoiceItems.length > 0 &&
                                        <React.Fragment>
                                            <div className='max-w-full overflow-x-auto mb-6'>
                                                <table className="border-collapse table-auto w-full">
                                                    <thead>
                                                        <tr className='text-left border-b dark:border-gray-700'>
                                                            <th className='py-3 pl-2 pr-4'>No.</th>
                                                            <th className='w-full py-3 px-4'>Item</th>
                                                            <th className='whitespace-nowrap py-3 px-4'>Unit Price</th>
                                                            <th className='py-3 px-4'>Qty.</th>
                                                            <th className='py-3 px-4'>Dis.</th>
                                                            <th className='py-3 pl-4 pr-2'>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y dark:divide-gray-700/60">
                                                        {
                                                            invoiceDetails.invoiceItems.map((item: any, itemIndex: number) =>
                                                                <tr key={item.id}>
                                                                    <td className='pl-2 pr-4'>{itemIndex + 1}</td>
                                                                    <td className='py-3 px-4'>
                                                                        <div className='flex gap-1.5' title={`${item.name} (${item.compulsory ? 'Required' : ''})`}>
                                                                            <span>{item.name}</span>
                                                                            {
                                                                                item.compulsory &&
                                                                                <span className='text-gray-500 dark:text-gray-400'>
                                                                                    <i className="ri-checkbox-circle-line"></i>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-right py-3 px-4'>{item.unitPrice.toFixed(2)}</td>
                                                                    <td className='text-right py-3 px-4'>{item.quantity}</td>
                                                                    <td className={`text-right py-3 px-4 ${item.discount ? '' : 'text-gray-500 dark:text-gray-400'}`}>{item.discount || 'N/A'}</td>
                                                                    <td className='text-right py-3 pl-4 pr-2'>{item.total.toFixed(2)}</td>
                                                                </tr>
                                                            )
                                                        }
                                                        <tr className=''>
                                                            <td colSpan={5} className='py-3 px-4 text-right'>Total ({invoiceDetails.defaultCurrency}):</td>
                                                            <td className='font-semibold text-right py-3 pl-4 pr-2'>{invoiceDetails.totalAmount.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className='flex justify-between gap-3 bg-gray-50 dark:bg-white/5 rounded-xl py-3.5 px-6 mb-1'>
                                                <div className=''>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Minimum Amount Required</p>
                                                    <p>{invoiceDetails.defaultCurrency} {invoiceDetails?.totalRequiredAmount.toFixed(2)}</p>
                                                </div>

                                                <div className=''>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Gross Total</p>
                                                    <p className='text-lg font-semibold text-blue'>{invoiceDetails.defaultCurrency} {invoiceDetails?.invoiceTotalAmount.toFixed(2)}</p>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }

                                    {
                                        invoiceDetails.invoiceItems.length === 0 &&
                                        <ListNoData title='No Item Added' description="Invoice has no items to it" className='!pt-10 !pb-0'>
                                        </ListNoData>
                                    }
                                </Tab.Panel>
                                <Tab.Panel unmount={false} as='div' className="pt-4">
                                    <p className='text-gray-500 dark:text-gray-400 mb-3'>
                                        Minimum Down Payment: <span className='font-medium'>{invoiceDetails?.paymentPlan?.downPaymentPercentageValue || 0}%</span>
                                    </p>
                                    {
                                        invoiceDetails?.paymentPlan && invoiceDetails?.paymentPlan?.cycles.length > 0 &&
                                        <React.Fragment>
                                            <div className='max-w-full overflow-x-auto mb-6'>
                                                <table className="border-collapse table-auto w-full">
                                                    <thead>
                                                        <tr className='text-left border-b dark:border-gray-700'>
                                                            <th className='py-3 pl-2 pr-4'>&nbsp;</th>
                                                            <th className='py-3 px-4'>Amount</th>
                                                            <th className='whitespace-nowrap py-3 px-4'>Due Date</th>
                                                            {/*  <th className='whitespace-nowrap py-3 px-4'>Grace Period</th> */}
                                                            <th className='whitespace-nowrap py-3 px-4'>Late Fee</th>
                                                            <th className='py-3 pl-4 pr-2'>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y dark:divide-gray-700/60">
                                                        {
                                                            invoiceDetails.paymentPlan.cycles.map((cycleItem: any, cycleItemIndex: number) =>
                                                                <tr key={cycleItem.id}>
                                                                    <td className='pl-2 pr-4'>{cycleItemIndex + 1}</td>
                                                                    <td className='whitespace-nowrap py-3 px-4'>{invoiceDetails.defaultCurrency} {cycleItem.amount.toFixed(2)}</td>
                                                                    <td className='whitespace-nowrap py-3 px-4'>{cycleItem.dueDate ? format(new Date(cycleItem.dueDate), 'd LLL yyyy h:mm a') : 'N/A'}</td>
                                                                    <td className='whitespace-nowrap py-3 px-4'>{invoiceDetails.defaultCurrency} {cycleItem.lateFeeCharge.toFixed(2)}</td>
                                                                    <td className='py-3 pl-4 pr-2'>{cycleItem.status}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </React.Fragment>
                                    }

                                    {
                                        (!invoiceDetails?.paymentPlan || invoiceDetails?.paymentPlan?.cycles.length === 0) &&
                                        <ListNoData title='' description="No payment plan provided for this invoice" className='!pt-10 !pb-0'>
                                        </ListNoData>
                                    }
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                }

                {
                    (invoiceStatus.loading || invoiceStatus.error) &&
                    <div className='pb-10'>
                        {
                            invoiceStatus.loading &&
                            <ListLoader loadingText='Loading Invoice' className='py-10' loaderSize={2} />
                        }
                        {
                            invoiceStatus.error && !invoiceStatus.notFound &&
                            <ErrorLoading title='Error Loading Invoice' message='An unexpected error occurred while loading invoice' className='px-8 py-16' onTryAgain={getInvoice} />
                        }
                        {
                            invoiceStatus.error && invoiceStatus.notFound &&
                            <ErrorLoading title='Invoice Not Found' message='Invoice may have been removed or never existed' className='px-8 py-16' />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


interface Props {
    isOpen: boolean;
    onClose: () => void;
    invoice: any;
}


export default ModalInvoiceDetails;