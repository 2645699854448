import React from 'react';
import { Form, Formik } from 'formik';

import { FormInput } from '../../../../../../components/form';


function FilterSection({ initialData, onSearch, searching }: Props) {
    const handleSubmit = (values: any, { reset }: any) => {
        onSearch(values);
    }

    return (
        <Formik
            initialValues={{
                name: initialData['name'] || '',
            }}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, setErrors, submitCount, isValid }) => (
                <Form className="flex-grow flex items-center mr-8 mb-4">
                    <FormInput type="text" id="input-name" name="name" className=" sm:w-full sm:max-w-xs md:max-w-sm mr-5" placeholder="Search by name" />
                    <button type='submit' className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}>Search</button>
                </Form>
            )}
        </Formik>
    );
}


interface Props {
    initialData: any;
    onSearch: (values: any) => void;
    searching: boolean;
}


export default FilterSection;