import React, { useEffect, useState } from 'react';
import { PasswordInput } from '../../../../components/form';
import useMediaViewer from '../../../../state/hooks/useMediaViewer';


function Dashboard() {
    const { openMediaViewer } = useMediaViewer();

    return (
        <div className='text-center rounded-2xl px-10 py-20'>
            {/* <div className='max-w-md mb-10'>
                <MultiSelectInput
                    items={data.list}
                    valueKey='id'
                    labelKey='title'
                    searchable
                    onSearch={(query) => getData(query)}
                    searching={data.loading}
                />
            </div> */}

            {/* <div className='max-w-xs'>
                <PasswordInput />
            </div> */}


            <div className='max-w-sm mx-auto'>
                <img src="/img/svgs/dashboard-nothing.svg" className='inline-block w-36' alt="No data for dashboard" />
                <h2 className='text-3xl pt-8 mb-3'>Nothing For Dashboard</h2>
                <p>Suggest something to the team to put here. Your suggestions are very much welcomed.</p>
            </div>

            {/*  <button onClick={() => openMediaViewer({
                mediaType: "image",
                link: "https://picsum.photos/id/237/200/300",

            })}>view</button> */}
        </div>
    );
}


export default Dashboard;