import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { changePageInfo } from '../../state/store/reducers/ui/headerSlice';


function NotFoundPage({ pageTitle = '' }: Props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageInfo({ title: pageTitle }));
    }, []);


    return (
        <div className='text-center py-20'>
            <img src='/img/svgs/404.svg' className='inline-block w-32 mb-5' alt='' />
            <p className='font-medium text-2xl mb-2'>Page Not Found</p>
            <p className='max-w-sm text-gray-500 dark:text-gray-400 mx-auto mb-4'>The page you're looking for might have been renamed, removed or never existed</p>
        </div>
    );
}


interface Props {
    pageTitle?: string;
}


export default NotFoundPage;