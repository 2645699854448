import { unifiedApi } from '../httpService';


const login = async (payload: any) => {
    try {
        const result = await unifiedApi.post("/login", payload);

        return {
            success: true,
            data: result.data.data
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: { message: "Your user name or password may be incorrect" }
            };
        }


        return {
            success: false,
            data: { message: "We could not log you in at this time, please try again later" }
        };
    }
}



export default {
    login
}

