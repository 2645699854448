import React, { useEffect, useRef, useState } from 'react';
import { Input } from '../form';
import { Button } from './Button';


function Pagination({ currentPage, totalCount, pageSize, className = '', onPageChange }: Props) {
    const [page, setPage] = useState<number>(currentPage || 1);
    const pageInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const firstRenderRef = useRef(true);

    const totalPages = Math.ceil(totalCount / pageSize);


    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        } else {
            onPageChange(page);
        }

        pageInputRef.current.value = page.toString();
    }, [page]);


    return (
        <div className={`flex items-center ${className}`}>
            <span className='mr-4'>Page</span>


            <Input
                type="number"
                ref={pageInputRef}
                //value={pageNumberInput}
                //onChange={(e) => setPageNumberInput(Number(e.target.value))}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        event.stopPropagation();

                        const value = Number(pageInputRef.current.value);
                        if (value > 0 && value <= totalPages) {
                            setPage(value);
                        }
                    }
                }}
                className='w-24 !px-3 !py-2 mr-4'
                min={1}
                max={totalPages || 0}
                disabled={totalPages === 1}
            />

            <span className='whitespace-nowrap mr-4'>of {totalPages}</span>

            <Button type='button' title='Go to Previous Page' onClick={() => setPage((currentPage) => currentPage - 1)} className='flex justify-center items-center rounded-r-none text-3xl !px-1 !py-0.5 mr-2' disabled={page === 1}>
                <i className="ri-arrow-left-s-line"></i>
            </Button>

            <Button type='button' title='Go to Next Page' onClick={() => setPage((currentPage) => currentPage + 1)} className='flex justify-center items-center rounded-l-none text-3xl !px-1 !py-0.5' disabled={page === totalPages}>
                <i className="ri-arrow-right-s-line"></i>
            </Button>
        </div>
    );
}


interface Props {
    currentPage: number;
    totalCount: number;
    pageSize: number;
    className?: string;
    onPageChange: (page: number) => void
}


export default Pagination;