import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { format } from 'date-fns';

import { changePageInfo } from '../../../../state/store/reducers/ui/headerSlice';
import { loadScanners, resetScanners, selectScanners, updateScannerRecord } from '../../../../state/store/reducers/entities/scannersSlice';
import useToast from '../../../../state/hooks/useToast';
import { ListLoader } from '../../../../components/loaders';
import { Button, ErrorLoading, ListNoData, Pagination } from '../../../../components/common';
import { paginationInfo } from '../../../../utils/pagination';
import PopoverWithFloat from '../../../../components/hoc/PopoverWithFloat';
import ModalScannerDetails from './modals/ModalScannerDetails';
import scannerService from '../../../../services/api/unified-api/scannerService';
import ModalScannerForm from './modals/ModalScannerForm';
import useAlert from '../../../../state/hooks/useAlert';


function ScannerListPage() {
    const dispatch = useDispatch();
    const scanners = useSelector(selectScanners);
    const [searchParams, setSearchParams] = useSearchParams();

    const [scannerForm, setScannerForm] = useState({ open: false, scanner: null });
    const [scannerDetails, setScannerDetails] = useState({ open: false, scanner: null });

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    // get query params
    const page = Number(searchParams.get('page')) || scanners.pager.page;
    const searchFilters = {
        //name: searchParams.get('name') || '',
    };

    useEffect(() => {
        dispatch(changePageInfo({ title: "Scanners" }));

        return () => {
            dispatch(resetScanners({}));
        }
    }, []);


    useEffect(() => {
        getScanners();
    }, [searchParams, dispatch]);



    const getScanners = async () => {
        await dispatch<any>(loadScanners({
            page: page,
            filters: searchFilters,
        }));
    };


    const handleDelete = async (scanner: any) => {
        openAlertConfirm({
            title: 'Delete Scanner',
            message: `Are you sure you want to delete scanner with serial number ${scanner.serialNumber}`,
            onConfirmed: () => deleteScanner(scanner),
            positiveButtonText: "Delete",
            negativeButtonText: 'Cancel',
            positiveButtonClass: 'bg-red-500 hover:bg-red-600',
        });
    }


    const deleteScanner = async (scanner: any) => {
        const result = await scannerService.deleteScanner(scanner.serialNumber);
        if (result.success) {
            dispatch(resetScanners({}));
            getScanners();
            addToast({
                text: "Scanner deleted successfully",
                type: "success"
            });
        } else {
            addToast({
                title: "Error",
                text: "Scanner could not be deleted",
                type: "error"
            });
        }
    }


    return (
        <div className=''>
            <div className='flex flex-row-reverse flex-wrap justify-between mb-1'>
                <Button type='button' onClick={() => setScannerForm({ open: true, scanner: null })} className='mb-4'>Add Scanner</Button>
                <div>

                </div>
            </div>

            {
                // if there're scanners
                !scanners.loadingError && !scanners.loading && scanners.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>Serial Number</th>
                                    <th className='py-6 px-6'>Batch Number</th>
                                    <th className='py-6 px-6'>Mobile Number</th>
                                    <th className='py-6 px-6'>Version</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Last Used</th>
                                    <th className='py-6 px-6 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    scanners.list.map((scannerItem: any) =>
                                        <tr key={scannerItem.id}>
                                            <td className='py-4 px-6'>
                                                <button type='button' onClick={() => setScannerDetails({ open: true, scanner: scannerItem })} className='transition-colors duration-300 hocus:text-blue'>
                                                    {scannerItem.serialNumber}
                                                </button>
                                            </td>
                                            <td className='py-4 px-6'>{scannerItem.batchNumber}</td>
                                            <td className='py-4 px-6'>{scannerItem.mobileNumber}</td>
                                            <td className='py-4 px-6'>{scannerItem.version}</td>
                                            <td className='py-4 px-6'>
                                                <span className={`inline-block font-medium text-sm uppercase ${scannerItem.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{scannerItem.status || "Inactive"}</span>

                                                {/*                                                 {scannerItem.isActive && <span className='inline-block font-medium bg-green-500/10 text-green-500 text-sm rounded-lg py-1 px-3'>Active</span>}
                                                {!scannerItem.isActive && <span className='inline-block font-medium bg-red-500/10 text-red-500 text-sm rounded-lg py-1 px-3'>Inactive</span>} */}
                                            </td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(scannerItem.lastUsed), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 px-6 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-64 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-white/5 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setScannerDetails({ open: true, scanner: scannerItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                View Details
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => setScannerForm({ open: true, scanner: scannerItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => handleDelete(scannerItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                Delete
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(scanners.pager.pageSize, scanners.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={scanners.pager.pageSize}
                            totalCount={scanners.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if scanners are loading
                !scanners.loadingError && scanners.loading &&
                <ListLoader loadingText='Loading Scanners' className='px-8 py-24' />
            }

            {
                // if there're no scanner in the app
                !scanners.loadingError && !scanners.loading && scanners.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Scanner Found' description='There are no Scanner Added' className='px-8 py-24'>
                    <Button type='button' onClick={() => setScannerForm({ open: true, scanner: null })}>Add a Scanner Here</Button>
                </ListNoData>
            }

            {
                // if there're no scanners in the app
                !scanners.loadingError && !scanners.loading && scanners.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Scanner Found' description='No scanner was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                scanners.loadingError &&
                <ErrorLoading title='Error Loading Scanners' message='There was a problem loading scanners' className='px-8 py-24' onTryAgain={getScanners} />
            }


            <ModalScannerDetails
                isOpen={scannerDetails.open}
                scanner={scannerDetails.scanner}
                onClose={() => setScannerDetails((prevState) => ({ ...prevState, open: false }))}
            />


            <ModalScannerForm
                isOpen={scannerForm.open}
                scanner={scannerForm.scanner}
                onScannerAdded={(scanner) => {
                    addToast({
                        text: `Scanner added successfully`,
                        type: "success"
                    });

                    dispatch(resetScanners({}));
                    setSearchParams({});

                    if (Array.from(searchParams.entries()).length === 0) {
                        getScanners();
                    }
                }}
                onScannerUpdated={(scanner) => {
                    dispatch(updateScannerRecord({ scanner, page }));
                    addToast({
                        text: `Scanner updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setScannerForm((prevState) => ({ ...prevState, open: false }))}
            />
        </div>
    );
}


export default ScannerListPage;