import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';


function ToastPosition({ show, position = "top-right", children, onDone }: Props) {
    if (position === "top-center") {
        return (
            <Transition
                as={Fragment}
                show={show}
                enter="transition duration-150 transform"
                enterFrom="-translate-y-6 opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition duration-150 transform"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="-translate-y-6 opacity-0"
                afterLeave={onDone}
            >
                <div className='fixed top-20 left-1/2 -translate-x-1/2 z-[1000]'>
                    {children}
                </div>
            </Transition>
        )
    }


    if (position === "bottom-center") {
        return (
            <Transition
                as={Fragment}
                show={show}
                enter="transition duration-150 transform"
                enterFrom="translate-y-6 opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition duration-150 transform"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-6 opacity-0"
                afterLeave={onDone}
            >
                <div className='fixed bottom-12 left-1/2 -translate-x-1/2 z-[1000]'>
                    {children}
                </div>
            </Transition>
        )
    }


    if (position === "top-right") {
        return (
            <Transition
                as={Fragment}
                show={show}
                enter="transition duration-150 transform"
                enterFrom="translate-x-full opacity-0"
                enterTo="translate-x-0 opacity-100"
                leave="transition duration-150 transform"
                leaveFrom="translate-x-0 opacity-100"
                leaveTo="translate-x-full opacity-0"
                afterLeave={onDone}
            >
                <div className='fixed top-16 right-6 z-[1000]'>
                    {children}
                </div>
            </Transition>
        )
    }


    if (position === "bottom-right") {
        return (
            <Transition
                as={Fragment}
                show={show}
                enter="transition duration-150 transform"
                enterFrom="translate-x-full opacity-0"
                enterTo="translate-x-0 opacity-100"
                leave="transition duration-150 transform"
                leaveFrom="translate-x-0 opacity-100"
                leaveTo="translate-x-full opacity-0"
                afterLeave={onDone}
            >
                <div className='fixed bottom-12 right-6 z-[1000] '>
                    {children}
                </div>
            </Transition>
        )
    }


    return null;
}


export type ToastPositionType = "top-left" | "top-center" | "top-right" | "bottom-left" | "bottom-center" | "bottom-right";


interface Props {
    show: boolean;
    position: ToastPositionType;
    children: JSX.Element | JSX.Element[];
    onDone: () => void;
}


export default ToastPosition;