import { RouteLinksType } from "./appNavLinkList";


export const studentRouteLinks: RouteLinksType = {
    profile: {
        groupLabel: "Profile",
        visible: true,
        url: "/students/:schoolId/:studentId",
        items: {
            details: {
                label: "Details",
                icon: "ri-article-line",
                url: "/students/:schoolId/:studentId",
                visible: true,
                new: false,
                children: {},
            },
            parents: {
                label: "Parents",
                icon: "ri-group-line",
                url: "/students/:schoolId/:studentId/parents",
                visible: true,
                new: false,
                children: {},
            },
            pickupPersons: {
                label: "Pickup Persons",
                icon: "ri-team-line",
                url: "/students/:schoolId/:studentId/pickup-persons",
                visible: true,
                new: false,
                children: {},
            },
            activities: {
                label: "Scan Activities",
                icon: "ri-history-line",
                url: "/students/:schoolId/:studentId/activities",
                visible: true,
                new: false,
                children: {},
            },
        }
    },
    attendance: {
        groupLabel: "Attendance",
        visible: false,
        url: "/schools/:schoolId/manage/attendance",
        items: {
            general: {
                label: "General",
                icon: "ri-ai-generate",
                url: "/schools/:schoolId/manage/attendance",
                visible: true,
                new: false,
                children: {},
            },
            configurations: {
                label: "Configurations",
                icon: "ri-equalizer-fill",
                url: "/schools/:schoolId/manage/canteen/configurations",
                visible: false,
                new: false,
                children: {},
            },
        }
    },
    canteen: {
        groupLabel: "Canteen",
        visible: false,
        url: "/schools/:schoolId/manage/canteen",
        items: {
            general: {
                label: "General",
                icon: "ri-ai-generate",
                url: "/schools/:schoolId/manage/canteen",
                visible: false,
                new: false,
                children: {},
            },
            serviceProviders: {
                label: "Canteen Vendors",
                icon: "ri-restaurant-2-line",
                //url: "/schools/:schoolId/manage/canteen/service-providers",
                url: "/schools/:schoolId/manage/canteen",
                visible: true,
                new: false,
                children: {},
            },
            cspManagers: {
                label: "Canteen Owners",
                icon: "ri-user-settings-line",
                url: "/schools/:schoolId/manage/canteen/csp-managers",
                visible: true,
                new: false,
                children: {},
            },
            configurations: {
                label: "Configurations",
                icon: "ri-equalizer-fill",
                url: "/schools/:schoolId/manage/canteen/configurations",
                visible: false,
                new: false,
                children: {},
            },
        }
    },
    payments: {
        groupLabel: "Payments",
        visible: true,
        url: "/students/:schoolId/:studentId/payments",
        items: {
            sikaID: {
                label: "SikaID",
                icon: "ri-bank-card-line",
                url: "/students/:schoolId/:studentId/payments",
                visible: true,
                new: false,
                children: {},
            },
            invoices: {
                label: "Invoices",
                icon: "ri-bill-line",
                url: "/students/:schoolId/:studentId/payments/invoices",
                visible: true,
                new: false,
                children: {},
            },
            transactions: {
                label: "Transactions",
                icon: "ri-arrow-left-right-line",
                url: "/students/:schoolId/:studentId/payments/transactions",
                visible: true,
                new: false,
                children: {},
            },
        }
    },
};
