
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
//const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];


export function getNumberOfDaysInMonth(month: number, year: number) {
    return 32 - new Date(year, month - 1, 32).getDate();
}


export function generateMonth(month: number, year: number, outSide = false) {
    const numberOfDaysInMonth = getNumberOfDaysInMonth(month, year);
    return Array.from({ length: numberOfDaysInMonth }, (_, index) => ({ year, month, day: index + 1, outSide }));
}


export function generateMonthForCalendar(month: number, year: number, outsideDays = false) {
    const startingDayInWeek = (new Date(year, month - 1)).getDay();
    const endingDayInWeek = (new Date(year, month, 0)).getDay();

    let prevMonthDays = [];
    const prevMonthYear = month === 1 ? year - 1 : year;
    const prevMonth = month === 1 ? 12 : month - 1;

    let nextMonthDays: { year: number; month: number; day: number; outSide: boolean; }[] = [];
    const nextMonthYear = month === 12 ? year + 1 : year;
    const nextMonth = month === 12 ? 1 : month + 1;

    if (outsideDays) {
        prevMonthDays = generateMonth(prevMonth, prevMonthYear, true);
        nextMonthDays = generateMonth(nextMonth, nextMonthYear, true);
    } else {
        prevMonthDays = Array(startingDayInWeek).fill(null);
    }

    const currentMonthDays = generateMonth(month, year);
    let daysInMonth;
    if (startingDayInWeek === 0) {
        daysInMonth = [...currentMonthDays, ...nextMonthDays.slice(0, 6 - endingDayInWeek)];
    } else {
        daysInMonth = [...prevMonthDays.slice(startingDayInWeek * -1), ...currentMonthDays, ...nextMonthDays.slice(0, 6 - endingDayInWeek)];
    }


    const monthWeeks = [];
    const chunkSize = 7;
    for (let i = 0; i < daysInMonth.length; i += chunkSize) {
        const chunk = daysInMonth.slice(i, i + chunkSize);
        // do whatever
        monthWeeks.push(chunk);
    }

    return {
        month: {
            name: months[month - 1],
            number: month,
        },
        year: year,
        weekHeading: weekDays,
        calendarDays: monthWeeks
    };
}


export function generateCalendarForYear(year: number) {
    const calendarYear = [];

    for (let i = 1; i <= months.length; i++) {
        const monthCalendar = generateMonthForCalendar(i, year);
        calendarYear.push(monthCalendar);
    }

    return calendarYear;
}