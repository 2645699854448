import { createSlice } from "@reduxjs/toolkit";

import { RootState } from './../../index';
import { DispatchType } from "../..";
import appRatingService from "../../../../services/api/unified-api/appRatingService";


const initialState = {
    list: {} as { [index: number]: any[] },
    loading: true,
    filters: null,
    pager: {
        pageSize: 0,
        page: 1,
        totalCount: 0
    },
    loadingError: false
};


const slice = createSlice({
    name: "appRatings",
    initialState: initialState,
    reducers: {
        appRatingsRequested: (state, action) => {
            state.loading = true;
            state.filters = action.payload.filters;
            state.pager.page = action.payload.page;
            state.loadingError = false;
        },
        appRatingsReceived: (state, action) => {
            state.list[action.payload.pager.page] = action.payload.data;
            state.pager = action.payload.pager;
            state.loading = false;
        },
        appRatingsRequestFailed: (state, action) => {
            state.loadingError = true;
            state.loading = false;
        },
        updateAppRatingsRecord: (state, action) => {
            const { appRatings, page } = action.payload;

            const list = state.list[page];
            if (list) {
                const index = list.findIndex(appRatingsItem => appRatingsItem.id === appRatings.id);
                list[index] = { ...appRatings };

                state.list[page] = list;
            }
        },
        getCachedAppRatings: (state, action) => {
            state.pager.page = action.payload.page;
        },
        clearCachedRatings: (state, action) => {
            state.list = {};
        },
        resetAppRatings: (state, action) => initialState,
    }
});


const { appRatingsRequested, getCachedAppRatings, appRatingsRequestFailed, appRatingsReceived, clearCachedRatings } = slice.actions;
export const { resetAppRatings, updateAppRatingsRecord } = slice.actions;




// Actions
export const loadAppRatings = (payload: any) => async (dispatch: DispatchType, getState: () => RootState) => {
    const appRatings = getState().entities.appRatings;
    const sameFilters = JSON.stringify(payload.filters) === JSON.stringify(appRatings.filters);

    // load from cache when page is already loaded
    if (appRatings.list[payload.page] && sameFilters) {
        dispatch(getCachedAppRatings({
            page: payload.page
        }));

        return;
    }


    if (!sameFilters) {
        dispatch(clearCachedRatings({}));
    }


    dispatch(appRatingsRequested(payload));
    const result = await appRatingService.getRatings({
        ...payload.filters,
        page: payload.page,
        pageSize: 10,
        direction: 'desc',
        properties: 'id',
    });

    if (result.success) {
        dispatch(appRatingsReceived(result));
    } else {
        dispatch(appRatingsRequestFailed({}));
    }
};




// Selectors
export const selectAppRatings = (state: RootState): ListSelectorType => {
    const appRatings = { ...state.entities.appRatings };
    appRatings.list = appRatings.list[state.entities.appRatings.pager.page] || [];

    return appRatings as ListSelectorType;
};


type ListSelectorType = Omit<typeof initialState, 'list'> & {
    list: any[];
}


export default slice.reducer;