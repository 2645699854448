import { combineReducers } from "redux";

import authReducer from "./authSlice";
import schoolsReducer from "./schoolsSlice";
import scannersReducer from "./scannersSlice";
import settlementAccountsReducer from "./settlementAccountSlice";
import userPermissionReducer from "./userPermissionSlice";
import userRoleReducer from "./userRoleSlice";
import userAccountReducer from "./userAccountSlice";
import appRatingsReducer from "./appRatingsSlice";
import appFeedsReducer from "./appFeedsSlice";
import listPageReducer from "./listPageSlice";


export default combineReducers({
    auth: authReducer,
    schools: schoolsReducer,
    scanners: scannersReducer,
    settlementAccounts: settlementAccountsReducer,
    userPermissions: userPermissionReducer,
    userRoles: userRoleReducer,
    userAccounts: userAccountReducer,
    appRatings: appRatingsReducer,
    appFeeds: appFeedsReducer,
    listPage: listPageReducer,
});
