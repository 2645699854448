import { unifiedApi } from '../httpService';


const syncStudents = async (schoolId: any) => {
    try {
        const result = await unifiedApi.get('/api/v1/jobs/students/sync', {
            params: { schoolId }
        });

        return {
            success: true,
            data: result.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const syncEmployees = async (schoolId: any) => {
    try {
        const result = await unifiedApi.get('/api/v1/jobs/employees/sync', {
            params: { schoolId }
        });

        return {
            success: true,
            data: result.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



const syncFCMToken = async (schoolId: any) => {
    try {
        const result = await unifiedApi.get('/api/v1/jobs/phones/sync', {
            params: { schoolId }
        });

        return {
            success: true,
            data: result.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



export default {
    syncStudents,
    syncEmployees,
    syncFCMToken
}