import React from 'react';
import { format } from 'date-fns';

import Modal from '../../../../../components/modals/Modal';
import { Button } from '../../../../../components/common';


function ModalScannerDetails({ isOpen, scanner, onClose }: Props) {
    return (
        <Modal open={isOpen} onClose={onClose} >
            <div className="inline-block w-[36rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        Scanner Details
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-500 focus:outline-none">
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    {
                        scanner &&
                        <div className='divide-y dark:divide-gray-800 mb-8'>
                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Serial Number</p>
                                    <p>{scanner.serialNumber}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Batch Number</p>
                                    <p>{scanner.batchNumber}</p>
                                </div>
                            </div>

                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Model</p>
                                    <p>{scanner.model || "N/A"}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>version</p>
                                    <p>{scanner.version}</p>
                                </div>
                            </div>

                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Mobile Number</p>
                                    <p>{scanner.mobileNumber || "N/A"}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Status</p>
                                    <span className={`inline-block font-medium text-sm uppercase ${scanner.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{scanner.status || "Inactive"}</span>
                                </div>
                            </div>

                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Campus</p>
                                    <p>{scanner.campus || "N/A"}</p>
                                </div>


                            </div>

                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Used By</p>
                                    <p>{scanner.lastUsedBy || "N/A"}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Used Date</p>
                                    <p>{format(new Date(scanner.lastUsed), 'd LLL yyyy h:mm a')}</p>
                                </div>
                            </div>

                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Updated</p>
                                    <p>{format(new Date(scanner.updatedDate), 'd LLL yyyy h:mm a')}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date Created</p>
                                    <p>{format(new Date(scanner.createdDate), 'd LLL yyyy h:mm a')}</p>
                                </div>
                            </div>
                        </div>
                    }

                    <div className='flex justify-end items-center'>
                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


interface Props {
    isOpen: boolean;
    onClose: () => void;
    scanner: any;
}


export default ModalScannerDetails;