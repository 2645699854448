import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';

import { generateMonthForCalendar } from '../../utils/calendar-generator';


function CalendarInput({ value, outsideDays = true, minDate, maxDate, onChange }: CalendarInputProps) {
    const todayDateObj = new Date();
    const minDateObj = minDate ? new Date(minDate) : false;
    const maxDateObj = maxDate ? new Date(maxDate) : false;

    const [selectedDate, setSelectedDate] = useState<DateConstruct>();
    const [calendarToShow, setCalendarToShow] = useState<{ year: number, month: number }>({ year: todayDateObj.getFullYear(), month: todayDateObj.getMonth() + 1 });


    useEffect(() => {
        if (value) {
            setSelectedDate(dateStringToConstruct(value));
        }
    }, [value]);


    useEffect(() => {
        const valueDateObj = value ? new Date(value) : null;
        const minDateObj = minDate ? new Date(minDate) : false;
        const maxDateObj = maxDate ? new Date(maxDate) : false;

        if (valueDateObj) {
            setCalendarToShow({ month: valueDateObj.getMonth() + 1, year: valueDateObj.getFullYear() });
        } else {
            if (minDateObj) {
                setCalendarToShow({ month: minDateObj.getMonth() + 1, year: minDateObj.getFullYear() });
            } else if (maxDateObj) {
                setCalendarToShow({ month: maxDateObj.getMonth() + 1, year: maxDateObj.getFullYear() - 5 });
            } else {
                setCalendarToShow({ month: todayDateObj.getMonth() + 1, year: todayDateObj.getFullYear() });
            }

            /*  if (!minDateObj && !maxDateObj) {
                 setCalendarToShow({ month: todayDateObj.getMonth() + 1, year: todayDateObj.getFullYear() });
             } */
        }
    }, [value, outsideDays, minDate, maxDate]);


    const monthCalendar = useMemo(() => {
        if (calendarToShow) {
            return generateMonthForCalendar(calendarToShow.month, calendarToShow.year, outsideDays);
        }

        return generateMonthForCalendar(todayDateObj.getMonth() + 1, todayDateObj.getFullYear());
    }, [calendarToShow, outsideDays]);



    const yearList = useMemo(() => {
        const min = minDate ? new Date(minDate).getFullYear() : monthCalendar.year - 5;
        const max = maxDate ? new Date(maxDate).getFullYear() : monthCalendar.year + 5;
        return Array.from({ length: max - min + 1 }, (_, i) => i + min);
    }, [minDate, maxDate, monthCalendar]);


    const monthList = useMemo(() => {
        let minMonth = 1;
        let maxMonth = 12;

        const minDateObj = minDate ? new Date(minDate) : false;
        const maxDateObj = maxDate ? new Date(maxDate) : false;

        if (minDateObj && minDateObj.getFullYear() === monthCalendar.year) {
            minMonth = minDateObj.getMonth() + 1;
        }

        if (maxDateObj && maxDateObj.getFullYear() === monthCalendar.year) {
            maxMonth = maxDateObj.getMonth() + 1;
        }

        if (minDateObj && maxDateObj && minDateObj.getTime() > maxDateObj.getTime()) {
            minMonth = 1;
            maxMonth = 12;
        }

        return monthValues.slice(minMonth - 1, maxMonth);
    }, [minDate, maxDate, monthCalendar]);






    return (
        <div className='inline-block'>
            <div className='flex justify-between items-center px-2 mb-6'>
                <div className='flex items-center mr-4'>
                    <Listbox
                        as="div"
                        value={calendarToShow && calendarToShow.month}
                        onChange={(month) => setCalendarToShow((prevState) => ({ ...prevState, month }))}
                        className="relative"
                    >
                        {
                            ({ open }) => (
                                <React.Fragment>
                                    {/*  <PopoverWithFloat placement='bottom-start' > */}
                                    <Listbox.Button className={`text-xl text-left transition-colors duration-300 ${open ? 'bg-black/5 dark:bg-white/5' : ' bg-transparent'} hocus:bg-black/5 dark:hover:bg-white/5 focus:outline-none rounded-md py-1 px-1.5`}>
                                        {monthLabels[calendarToShow.month - 1]}
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-300"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-200"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Listbox.Options className="absolute z-20 max-h-72 origin-top-left text-left left-0 mt-2 rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60 overflow-y-scroll px-2 py-3 ">
                                            {
                                                monthList.map((item, itemIndex) => (
                                                    <Listbox.Option
                                                        key={item}
                                                        value={item}
                                                        className="cursor-pointer text-left ui-selected:text-blue hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg w-full pl-5 pr-10 py-2.5"
                                                    >
                                                        {monthLabels[item - 1]}
                                                    </Listbox.Option>
                                                ))
                                            }
                                        </Listbox.Options>
                                    </Transition>
                                    {/* </PopoverWithFloat> */}
                                </React.Fragment>
                            )
                        }
                    </Listbox>

                    <Listbox
                        as="div"
                        value={calendarToShow.year}
                        onChange={(year) => setCalendarToShow((prevState) => ({ ...prevState, year }))}
                        className="relative"
                    //placeholder=''
                    >
                        {
                            ({ open }) => (
                                <React.Fragment>
                                    <Listbox.Button className={`text-xl text-left transition-colors duration-300 ${open ? 'bg-black/5 dark:bg-white/5' : ' bg-transparent'} hocus:bg-black/5 dark:hover:bg-white/5 focus:outline-none rounded-md py-1 px-1.5`}>
                                        {calendarToShow.year}
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-300"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-200"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Listbox.Options className="absolute z-20 max-h-72 origin-top-left text-left left-0 mt-2 rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60 overflow-y-scroll px-2 py-3 ">
                                            {
                                                yearList.map((item, itemIndex) => (
                                                    <Listbox.Option
                                                        key={item}
                                                        value={item}
                                                        className="cursor-pointer text-left ui-selected:text-blue hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg w-full pl-5 pr-10 py-2.5"
                                                    >
                                                        {item}
                                                    </Listbox.Option>
                                                ))
                                            }
                                        </Listbox.Options>
                                    </Transition>
                                </React.Fragment>
                            )
                        }
                    </Listbox>
                </div>


                <div className='flex items-center'>
                    <button
                        type='button'
                        className='text-2xl transition-all duration-300 opacity-60 [&:not(:disabled)]:hocus:opacity-100 [&:not(:disabled)]:hocus:text-blue disabled:opacity-40 mr-2'
                        onClick={() => {
                            setCalendarToShow((prevState) => ({
                                year: prevState.month === 1 ? prevState.year - 1 : prevState.year,
                                month: prevState.month === 1 ? 12 : prevState.month - 1
                            }));
                        }}
                        disabled={minDateObj && minDateObj.getMonth() + 1 === calendarToShow.month}
                    >
                        <i className="ri-arrow-left-s-line"></i>
                    </button>

                    <button
                        type='button'
                        className='text-2xl transition-all duration-300 opacity-60 [&:not(:disabled)]:hocus:opacity-100 [&:not(:disabled)]:hocus:text-blue disabled:opacity-40'
                        onClick={() => {
                            setCalendarToShow((prevState) => ({
                                year: prevState.month === 12 ? prevState.year + 1 : prevState.year,
                                month: prevState.month === 12 ? 1 : prevState.month + 1
                            }));
                        }}
                        disabled={maxDateObj && maxDateObj.getMonth() + 1 === calendarToShow.month}
                    >
                        <i className="ri-arrow-right-s-line"></i>
                    </button>
                </div>
            </div>

            <div className='grid grid-cols-7 gap-x-px mb-1'>
                {
                    monthCalendar.weekHeading.map((headItem) =>
                        <span key={headItem} className='flex justify-center'>
                            {headItem}
                        </span>
                    )
                }
            </div>

            <div className='grid gap-y-px'>
                {
                    monthCalendar.calendarDays.map((weekItem, weekItemIndex) =>
                        <div key={`${monthCalendar.month.name}${weekItemIndex}`} className='grid grid-cols-7 min-w-max gap-x-px'>
                            {
                                weekItem.map((dayItem, dayItemIndex) => {
                                    const isToday = dayItem.year === todayDateObj.getFullYear() && dayItem.month === todayDateObj.getMonth() + 1 && dayItem.day === todayDateObj.getDate();
                                    const isSelected = selectedDate && dayItem.year === selectedDate.year && dayItem.month === selectedDate.month && dayItem.day === selectedDate.day;

                                    const fullDate = `${dayItem.year}-${zeroPadded(dayItem.month)}-${zeroPadded(dayItem.day)}`;
                                    const dayItemObj = new Date(fullDate);


                                    let disabled = false;
                                    if (minDateObj && minDateObj.getTime() > dayItemObj.getTime()) {
                                        disabled = true;
                                    }

                                    if (maxDateObj && maxDateObj.getTime() < dayItemObj.getTime()) {
                                        disabled = true;
                                    }


                                    return (
                                        <React.Fragment key={`${dayItem.month}${weekItemIndex}${dayItemIndex}`}>
                                            {
                                                dayItem &&
                                                <button
                                                    type='button'
                                                    className={`w-10 h-9 border border-dashed rounded-md transition-colors duration-150 hover:bg-gray-50 dark:hover:bg-gray-800 focus:bg-blue ${dayItem.outSide ? 'opacity-40' : ''} ${isToday ? 'dark:border-gray-700' : 'border-transparent'} ${isSelected ? 'bg-blue hover:!bg-blue-600 !border-transparent' : ''} disabled:opacity-50`}
                                                    title={isToday ? 'Today' : ''}
                                                    disabled={disabled}
                                                    onClick={() => {
                                                        const selected: DateConstruct = {
                                                            year: dayItem.year,
                                                            month: dayItem.month,
                                                            day: Number(dayItem.day),
                                                            fullDate: fullDate
                                                        }
                                                        setSelectedDate(selected);
                                                        setCalendarToShow({ year: selected.year, month: selected.month });
                                                        if (onChange) {
                                                            onChange(selected);
                                                        }
                                                    }}
                                                >
                                                    {dayItem.day}
                                                </button>
                                            }
                                            {!dayItem && <span className=''></span>}
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}



const monthLabels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const monthValues = Array.from({ length: 12 }, (_, i) => i + 1);


const zeroPadded = (val: number | string): string => {
    return val.toString().padStart(2, '0');
}


const dateStringToConstruct = (date: string = ''): DateConstruct => {
    const dateObj = date ? new Date(date) : new Date();
    return {
        year: dateObj.getFullYear(),
        month: dateObj.getMonth() + 1,
        day: dateObj.getDate(),
        fullDate: `${dateObj.getFullYear()}-${zeroPadded(dateObj.getMonth() + 1)}-${zeroPadded(dateObj.getDate())}`
    }
}


export interface CalendarInputProps {
    value?: string;
    onChange?: (value: DateConstruct) => void;
    outsideDays?: boolean;
    minDate?: string;
    maxDate?: string;
}

interface DateConstruct {
    year: number;
    month: number;
    day: number;
    fullDate: string;
}


export default CalendarInput;