import { unifiedApi } from '../httpService';


const getPasswordPinComplexity = async () => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/pwd-pin-complexity`, {});
        return {
            success: true,
            data: result.data.data,
            message: ""
        };
    } catch (error: any) {
        /* if (error.response && error.response.status === 404) {
            return {
                success: false,
                data: null,
                message: "An unexpected Error"
            };
        } */

        return {
            success: false,
            data: null,
            message: "An unexpected Error"
        };
    }
}


const updatePasswordPinComplexity = async (data: any) => {
    try {
        const result = await unifiedApi.patch(`/api/v1/schools/pwd-pin-complexity`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating password and pin complexity. Please try again later."
        };
    }
}


export default {
    getPasswordPinComplexity,
    updatePasswordPinComplexity
}