import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeViewer, selectMediaViewer } from '../../../state/store/reducers/ui/mediaViewerSlice';
import ModalMediaViewer from './ModalMediaViewer';


function MediaViewerContainer() {
    const dispatch = useDispatch();

    const mediaViewer: any = useSelector(selectMediaViewer);

    return (
        <ModalMediaViewer
            isOpen={mediaViewer.isOpen}
            onClose={() => dispatch(closeViewer({}))}
            {...mediaViewer.data}
        />
    );
}

export default MediaViewerContainer;