import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { FormInput, FormTextArea, PhoneNumberInput } from '../../../../../components/form';
import { Button } from '../../../../../components/common';
import campusService from '../../../../../services/api/unified-api/campusService';


function ModalCampusForm({ isOpen, onClose, schoolId, campus, onCampusCreated, onCampusUpdated }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });


    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        if (campus) {
            // update
            const result = await campusService.updateCampus(schoolId, campus.id, formData);
            if (result.success) {
                if (onCampusUpdated) {
                    onCampusUpdated({ ...campus, ...result.data });
                }
                //resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        } else {
            // create
            const result = await campusService.createCampus(schoolId, formData);
            if (result.success) {
                if (onCampusCreated) {
                    onCampusCreated(result.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    };



    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[36rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {campus ? "Edit" : "Create"} Campus
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            name: campus ? campus.name : '',
                            alias: campus ? campus.alias : '',
                            contact: campus ? campus.contact : '',
                            address: campus ? campus.address : '',
                            location: campus ? campus.location : '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }

                                <div className="mb-6">
                                    <label htmlFor="input-name" className='block text-sm mb-2'>Campus Name</label>
                                    <FormInput type="text" name="name" id="input-name" className="w-full" placeholder="Enter campus name" />
                                    {touched['name'] && errors['name'] && <p className='text-sm text-red-500 mt-2'>Campus Name is Required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-alias" className='block text-sm mb-2'>Campus Alias</label>
                                    <FormInput type="text" name="alias" id="input-alias" className="w-full" placeholder="Enter campus alias" />
                                    {touched['alias'] && errors['alias'] && <p className='text-sm text-red-500 mt-2'>Campus alias is Required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-contact-number" className='block text-sm mb-2'>Contact Number</label>
                                    <PhoneNumberInput
                                        id="input-contact-number"
                                        name="contact"
                                        className="w-full"
                                        //placeholder="Enter campus contact number"
                                        value={values["contact"]}
                                        onChange={value => setFieldValue('contact', value)}
                                        onBlur={() => setFieldTouched('contact', true)}
                                    />
                                    {touched['contact'] && errors['contact'] && <p className='text-sm text-red-500 mt-2'>Contact number is Required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-address" className='block text-sm mb-2'>Address</label>
                                    <FormTextArea name='address' id="input-address" className='w-full' placeholder="Enter campus address" />
                                    {/* <FormInput type="text" id="input-address" name="address" className="w-full" placeholder="Enter campus address" /> */}
                                    {touched['address'] && errors['address'] && <p className='text-sm text-red-500 mt-2'>Campus address is Required</p>}

                                </div>

                                <div className="mb-10">
                                    <label htmlFor="input-location" className='block text-sm mb-2'>Campus Location</label>
                                    <FormInput type="text" name="location" id="input-location" className="w-full" placeholder="Enter campus location" />
                                    {touched['location'] && errors['location'] && <p className='text-sm text-red-500 mt-2'>Campus location is Required</p>}
                                </div>

                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        {campus ? "Save" : "Create Campus"}
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required().label(""),
    alias: Yup.string().trim().required().label(""),
    contact: Yup.string().trim().required().label(""),
    address: Yup.string().trim().required().label(""),
    location: Yup.string().trim().required().label(""),
});



interface Props {
    isOpen: boolean;
    onClose: () => void;
    campus: any;
    schoolId: number;
    onCampusCreated?: (campus: any) => void;
    onCampusUpdated?: (campus: any) => void;
}


export default ModalCampusForm;