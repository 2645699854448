
const storeToken = (token: any): void => {
    localStorage.setItem('user', JSON.stringify(token));
}


const getToken = (): any => {
    try {
        return JSON.parse(localStorage.getItem('user') as string);
    } catch (error) {
        return null;
    }
}


const removeToken = (): void => {
    localStorage.removeItem('user');
}


const setExpiry = (time: number): void => {
    localStorage.setItem('_expiry', time.toString());
}


const getExpiry = (): any => {
    try {
        return localStorage.getItem('_expiry') || 0;
    } catch (error) {
        return null;
    }
}



export default {
    storeToken,
    getToken,
    removeToken,
    setExpiry,
    getExpiry
}
