import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Menu, Switch } from '@headlessui/react';

import useToast from '../../../../../../../../../state/hooks/useToast';
import moduleSyncingService from '../../../../../../../../../services/api/unified-api/moduleSyncingService';
import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import Loader from '../../../../../../../../../components/svgs/Loader';
import schoolService from '../../../../../../../../../services/api/unified-api/schoolService';
import useAlert from '../../../../../../../../../state/hooks/useAlert';
import schoolConfigurationService from '../../../../../../../../../services/api/attendance/schoolConfigurationService';
import { Button } from '../../../../../../../../../components/common';
import { Form, Formik } from 'formik';


function SchoolManagementGeneralPage() {
    const { profile }: any = useOutletContext();

    const [syncingData, setSyncingData] = useState<any[]>([]);

    const [dropOffDenialFeature, setDropOffDenialFeature] = useState(false);
    const [staffAttendanceFeature, setStaffAttendanceFeature] = useState(false);
    const [activationStatus, setActivationStatus] = useState({
        dropOffDenial: { loading: false, error: false },
        staffAttendance: { loading: false, error: false }
    });

    const [configuration, setConfiguration] = useState<any>(null);
    const [dropOffDenialCount, setDropOffDenialCount] = useState(0);
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });


    const { addToast } = useToast();
    const { openAlertOk, openAlertConfirm } = useAlert();


    useEffect(() => {
        /* if (profile.configuration.hasEnabledDropOffDenial) {
            setDropOffDenialFeature(profile.configuration.hasEnabledDropOffDenial as boolean);
        } */
        getSchoolAttendanceConfigurations();
        getDropOffDenialCount();
    }, [profile]);



    const getSchoolAttendanceConfigurations = async () => {
        const result = await schoolConfigurationService.getSchoolConfigurations(profile.id);
        if (result.success) {
            //setStaffAttendanceFeature(result.data?.hasEnabledStaffAttendance);
            setConfiguration(result?.data);
        } else {
        }
    }


    const getDropOffDenialCount = async () => {
        const result = await schoolService.getDropOffDeniedCount(profile.id);
        if (result.success) {
            setDropOffDenialCount(result?.data);
        } else {
        }






        /*  const countResult = await schoolService.getDropOffDeniedCount(profile.id);
         if (countResult.success) {
             if (countResult.data > 0) {
                 openAlertOk({
                     title:
                         <div className='flex items-center'>
                             <span className='mr-2.5'>Turn Off Drop off Denial</span>
                             <i className="ri-error-warning-line text-2xl text-yellow-500"></i>
                         </div>,
                     message:
                         <React.Fragment>
                             <p className='mb-3.5'>There are ${countResult.data} students in active denial list for this school.</p>
                             <p>Remove all students from active denial list before turning OFF this feature, else students will be denied unknowingly</p>
                         </React.Fragment>,
                 });
                 setActivationStatus((prevState) => ({ ...prevState, dropOffDenial: { loading: false, error: true } }));
                 setDropOffDenialFeature(!activate);
                 return;
             }
         } else {
             setActivationStatus((prevState) => ({ ...prevState, dropOffDenial: { loading: false, error: true } }));
             setDropOffDenialFeature(!activate);
             return;
         } */


    }



    const handleSyncAllData = async () => {
        await handleSyncStudents();
        await handleSyncEmployees();
        await handleFCMToken();
    }


    const handleSyncStudents = async () => {
        setSyncingData(prevState => [...prevState, true]);

        const result = await moduleSyncingService.syncStudents(profile.id);

        if (result?.success) {
            setSyncingData(prevState => prevState.slice(0, -1));
            addToast({
                text: `Students synced successfully`,
                type: "success",
            });

        } else {
            setSyncingData(prevState => prevState.slice(0, -1));
            addToast({
                text: `Could not sync students`,
                type: "error",
            });
        }
    }


    const handleSyncEmployees = async () => {
        setSyncingData(prevState => [...prevState, true]);

        const result = await moduleSyncingService.syncEmployees(profile.id);

        if (result?.success) {
            setSyncingData(prevState => prevState.slice(0, -1));
            addToast({
                text: `Employees synced successfully`,
                type: "success",
            });
        } else {
            setSyncingData(prevState => prevState.slice(0, -1));
            addToast({
                text: `Could not sync employees`,
                type: "error",
            });
        }
    }


    const handleFCMToken = async () => {
        setSyncingData(prevState => [...prevState, true]);

        const result = await moduleSyncingService.syncFCMToken(profile.id);

        if (result?.success) {
            setSyncingData(prevState => prevState.slice(0, -1));
            addToast({
                text: `FCM token synced successfully`,
                type: "success",
            });

        } else {
            setSyncingData(prevState => prevState.slice(0, -1));
            addToast({
                text: `Could not sync FCM token`,
                type: "error",
            });
        }
    }


    const handleDropOffDenialFeatureActivation = async (activate: boolean) => {
        setDropOffDenialFeature(activate);

        setActivationStatus((prevState) => ({ ...prevState, dropOffDenial: { loading: true, error: true } }));

        if (!activate) {
            const countResult = await schoolService.getDropOffDeniedCount(profile.id);
            if (countResult.success) {
                if (countResult.data > 0) {
                    openAlertOk({
                        title:
                            <div className='flex items-center'>
                                <span className='mr-2.5'>Turn Off Drop off Denial</span>
                                <i className="ri-error-warning-line text-2xl text-yellow-500"></i>
                            </div>,
                        message:
                            <React.Fragment>
                                <p className='mb-3.5'>There are ${countResult.data} students in active denial list for this school.</p>
                                <p>Remove all students from active denial list before turning OFF this feature, else students will be denied unknowingly</p>
                            </React.Fragment>,
                    });
                    setActivationStatus((prevState) => ({ ...prevState, dropOffDenial: { loading: false, error: true } }));
                    setDropOffDenialFeature(!activate);
                    return;
                }
            } else {
                setActivationStatus((prevState) => ({ ...prevState, dropOffDenial: { loading: false, error: true } }));
                setDropOffDenialFeature(!activate);
                return;
            }
        }


        // activate or deactivate
        const result = await schoolService.updateSchoolConfigurations(profile.id, {
            ...profile.configuration,
            hasEnabledDropOffDenial: activate
        });

        if (result.success) {
            addToast({
                text: `Drop Off Denial ${activate ? 'activated' : 'deactivated'}`,
                type: "success"
            });
            setActivationStatus((prevState) => ({ ...prevState, dropOffDenial: { loading: false, error: false } }));
        } else {
            setActivationStatus((prevState) => ({ ...prevState, dropOffDenial: { loading: false, error: true } }));
            setDropOffDenialFeature(!activate);
        }
    }



    const handleStaffAttendanceActivation = async (activate: boolean) => {
        setStaffAttendanceFeature(activate);
        setActivationStatus((prevState) => ({ ...prevState, staffAttendance: { loading: true, error: true } }));

        // activate or deactivate
        const result = await schoolConfigurationService.updateSchoolConfigurations(profile.id, {
            hasEnabledStaffAttendance: activate
        });

        if (result.success) {
            addToast({
                text: `Staff attendance ${activate ? 'activated' : 'deactivated'}`,
                type: "success"
            });
            setActivationStatus((prevState) => ({ ...prevState, staffAttendance: { loading: false, error: false } }));
        } else {
            setActivationStatus((prevState) => ({ ...prevState, staffAttendance: { loading: false, error: true } }));
            setStaffAttendanceFeature(!activate);
        }
    }



    const handleConfigurationsFormSubmit = async (formData: object, { resetForm }: any) => {
        openAlertConfirm({
            title: 'Change Attendance Configurations',
            message: "Are you sure you want to make changes to this school's attendance configurations?",
            onConfirmed: async () => {
                const result = await schoolService.updateSchoolConfigurations(profile.id, {
                    ...profile.configuration,
                    ...formData
                });
                if (result.success) {
                    addToast({
                        text: `Attendance Configurations Updated`,
                        type: "success"
                    });
                } else {
                    setSubmitStatus({ loading: false, failedMessage: result.message });
                }
            },
            positiveButtonText: "Update Configurations",
            negativeButtonText: 'Cancel',
        });
    };


    const handleOtherConfigurationsFormSubmit = async (formData: object, { resetForm }: any) => {
        openAlertConfirm({
            title: 'Change Attendance Configurations',
            message: "Are you sure you want to make changes to this school's attendance configurations?",
            onConfirmed: async () => {
                const result = await schoolConfigurationService.updateSchoolConfigurations(profile.id, { ...formData });
                if (result.success) {
                    addToast({
                        text: `Attendance Configurations Updated`,
                        type: "success"
                    });
                } else {
                    setSubmitStatus({ loading: false, failedMessage: result.message });
                }
            },
            positiveButtonText: "Update Configurations",
            negativeButtonText: 'Cancel',
        });
    };



    return (
        <React.Fragment>
            <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
                <div className='flex justify-between items-start flex-wrap gap-2 mb-4'>
                    <div>
                        <h2 className='font-medium text-xl mr-4'>School Management</h2>
                        <p className='text-lg text-gray-500 dark:text-gray-400'>Manage Features</p>
                    </div>

                    <div className='flex'>
                        {
                            syncingData.length > 0 &&
                            <div className='flex items-center mr-5'>
                                <span className='inline-block w-4 h-4 mr-2'>
                                    <Loader />
                                </span>
                                <span className='text-gray-500 dark:text-gray-400'>Syncing Data</span>
                            </div>
                        }

                        <Menu as="div" className="relative inline-block text-left">
                            {
                                ({ open }) => (
                                    <PopoverWithFloat placement='bottom-end'>
                                        <Menu.Button type='button' className="text-blue-500 hocus:underline ui-open:underline">
                                            Sync Data
                                        </Menu.Button>

                                        <Menu.Items as='div' unmount={false} className="w-52 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                            <Menu.Item as="button" type='button' onClick={handleSyncAllData} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                Sync All Data
                                            </Menu.Item>
                                            <Menu.Item as="button" type='button' onClick={handleSyncStudents} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                Sync Students
                                            </Menu.Item>
                                            <Menu.Item as="button" type='button' onClick={handleSyncEmployees} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                Sync Employees
                                            </Menu.Item>
                                            <Menu.Item as="button" type='button' onClick={handleFCMToken} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                Sync FCM Token
                                            </Menu.Item>
                                        </Menu.Items>
                                    </PopoverWithFloat>
                                )
                            }
                        </Menu>
                    </div>
                </div>


                <div className='max-w-lg mb-16'>
                    <Formik
                        initialValues={{
                            hasEnabledDropOffDenial: profile?.configuration?.hasEnabledDropOffDenial || false,
                        }}
                        //validationSchema={validationSchema}
                        onSubmit={handleConfigurationsFormSubmit}
                        enableReinitialize
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }


                                <div className=' divide-y divide-gray-200 dark:divide-gray-700 rounded-xl mb-2'>
                                    <div className='py-5'>
                                        <div className='flex justify-between items-center gap-x-6'>
                                            <div className=''>
                                                <p>Drop Off Denial</p>
                                                <p className='text-gray-500 dark:text-gray-400'>Feature allows schools to denial students during Drop Offs</p>
                                            </div>
                                            <Switch
                                                checked={values["hasEnabledDropOffDenial"]}
                                                disabled={dropOffDenialCount > 0}
                                                onChange={(on: boolean) => setFieldValue("hasEnabledDropOffDenial", on)}
                                                className={`flex-shrink-0 ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 ui-disabled:opacity-50 relative inline-flex items-center h-6 w-11 rounded-full`}
                                            >
                                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                            </Switch>
                                        </div>

                                        {
                                            dropOffDenialCount > 0 &&
                                            <div className='bg-yellow-500/10 text-yellow-500 text-sm rounded-lg py-3.5 px-4 mt-3'>
                                                <p className='mb-1'>There are <b>{dropOffDenialCount} students</b>  in active denial list for this school.</p>
                                                <p>Remove all students from active denial list before turning OFF this feature, else students will be denied unknowingly</p>
                                            </div>
                                        }
                                    </div>
                                </div>


                                <div className=''>
                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        Save Configuration
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>


                <div className='max-w-lg'>
                    <Formik
                        initialValues={{
                            //hasEnabledDropOffDenial: configuration?.hasEnabledDropOffDenial || false,
                            hasEnabledStaffAttendance: configuration?.hasEnabledStaffAttendance || false,
                            hasEnabledManualCapture: configuration?.hasEnabledManualCapture || false,
                        }}
                        //validationSchema={validationSchema}
                        onSubmit={handleOtherConfigurationsFormSubmit}
                        enableReinitialize
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }


                                <div className=' divide-y divide-gray-200 dark:divide-gray-700 rounded-xl mb-2'>
                                    {/*  <div className='py-5'>
                                        <div className='flex justify-between items-center gap-x-6'>
                                            <div className=''>
                                                <p>Drop Off Denial</p>
                                                <p className='text-gray-500 dark:text-gray-400'>Feature allows schools to denial students during Drop Offs</p>
                                            </div>
                                            <Switch
                                                checked={values["hasEnabledDropOffDenial"]}
                                                disabled={dropOffDenialCount > 0}
                                                onChange={(on: boolean) => setFieldValue("hasEnabledDropOffDenial", on)}
                                                className={`flex-shrink-0 ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 ui-disabled:opacity-50 relative inline-flex items-center h-6 w-11 rounded-full`}
                                            >
                                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                            </Switch>
                                        </div>

                                        {
                                            dropOffDenialCount > 0 &&
                                            <div className='bg-yellow-500/10 text-yellow-500 text-sm rounded-lg py-3.5 px-4 mt-3'>
                                                <p className='mb-1'>There are <b>{dropOffDenialCount} students</b>  in active denial list for this school.</p>
                                                <p>Remove all students from active denial list before turning OFF this feature, else students will be denied unknowingly</p>
                                            </div>
                                        }
                                    </div> */}


                                    <div className='flex justify-between items-center gap-x-6 py-5'>
                                        <div className=''>
                                            <p>Staff Attendance</p>
                                            <p className='text-gray-500 dark:text-gray-400'>Feature allows schools to take attendance of staff</p>
                                        </div>
                                        <Switch
                                            checked={values["hasEnabledStaffAttendance"]}
                                            onChange={(on: boolean) => setFieldValue("hasEnabledStaffAttendance", on)}
                                            className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                        >
                                            <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                        </Switch>
                                    </div>


                                    <div className='flex justify-between items-center gap-x-6 py-5'>
                                        <div className=''>
                                            <p>Enable Manual Picture Capture</p>
                                            <p className='text-gray-500 dark:text-gray-400'>Allows users to manually take pictures of individuals after scanning</p>
                                        </div>
                                        <Switch
                                            checked={values["hasEnabledManualCapture"]}
                                            onChange={(on: boolean) => setFieldValue("hasEnabledManualCapture", on)}
                                            className={`flex-shrink-0 ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                        >
                                            <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                        </Switch>
                                    </div>
                                </div>


                                <div className=''>
                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        Save Configuration
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>


                {/*<div className='py-4'>
                    <h3 className='text-lg text-gray-500 dark:text-gray-400'>Feature Activation</h3>
                     <div className='divide-y divide-gray-200 dark:divide-gray-700'>
                        <div className='sm:flex justify-between items-center max-w-lg py-5'>
                            <div className='mr-5 mb-5 sm:mb-0'>
                                <div className='flex justify-between items-center gap-6 mb-1'>
                                    <p className=''>Drop Off Denial</p>
                                    {
                                        activationStatus.dropOffDenial.loading &&
                                        <div className='flex items-center'>
                                            <span className='inline-block w-4 h-4 mr-2'>
                                                <Loader />
                                            </span>
                                            <span className='text-sm text-gray-500 dark:text-gray-400'>
                                                {dropOffDenialFeature ? "Activating" : "Deactivating"} feature
                                            </span>
                                        </div>
                                    }
                                </div>
                                <p className='text-gray-500 dark:text-gray-400'>Feature allows schools to denial students during Drop Offs</p>
                            </div>
                            <div className='flex sm:flex-row-reverse justify-between items-center'>
                                <Switch
                                    checked={dropOffDenialFeature}
                                    onClick={() => {
                                        if (dropOffDenialFeature) {
                                            // disable
                                            openAlertConfirm({
                                                title: 'Deactivate Drop Off Denial',
                                                message:
                                                    <React.Fragment>
                                                        <p className='mb-3.5'>You are about to turn off drop-off denial feature for ${profile.name}?</p>
                                                        <p>Once turned off, the school will not see Drop-off denial on the dashboard or in management</p>
                                                    </React.Fragment>
                                                ,
                                                onConfirmed: () => handleDropOffDenialFeatureActivation(false),
                                                positiveButtonText: "Turn off Drop Off Denial",
                                                negativeButtonText: 'Cancel',
                                            });
                                        } else {
                                            // enable
                                            openAlertConfirm({
                                                title: 'Activate Drop Off Denial',
                                                message:
                                                    <React.Fragment>
                                                        <p className='mb-3.5'>You are about to turn on drop-off denial feature for ${profile.name}?</p>
                                                        <p>Drop-off denial will show on their attendance dashboard and in management</p>
                                                    </React.Fragment>
                                                ,
                                                onConfirmed: () => handleDropOffDenialFeatureActivation(true),
                                                positiveButtonText: "Turn on Drop Off Denial",
                                                negativeButtonText: 'Cancel',
                                            });
                                        }
                                    }}
                                    onChange={(on: boolean) => { }}
                                    className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                >
                                    <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                </Switch>


                            </div>
                        </div>



                        <div className='sm:flex justify-between items-center max-w-lg py-5'>
                            <div className='mr-5 mb-5 sm:mb-0'>
                                <div className='flex justify-between items-center gap-6 mb-1'>
                                    <p className=''>Staff Attendance</p>
                                    {
                                        activationStatus.staffAttendance.loading &&
                                        <div className='flex items-center'>
                                            <span className='inline-block w-4 h-4 mr-2'>
                                                <Loader />
                                            </span>
                                            <span className='text-sm text-gray-500 dark:text-gray-400'>
                                                {staffAttendanceFeature ? "Activating" : "Deactivating"} feature
                                            </span>
                                        </div>
                                    }
                                </div>
                                <p className='text-gray-500 dark:text-gray-400'>Feature allows schools to take attendance of staff</p>
                            </div>
                            <div className='flex sm:flex-row-reverse justify-between items-center'>
                                <Switch
                                    checked={staffAttendanceFeature}
                                    onClick={() => {
                                        if (staffAttendanceFeature) {
                                            // disable
                                            openAlertConfirm({
                                                title: 'Deactivate Staff Attendance',
                                                message:
                                                    <React.Fragment>
                                                        <p className='mb-3.5'>You are about to turn off staff Attendance feature for ${profile.name}?</p>
                                                        <p>Once turned off, the school will not be able to take attendance for staff members.</p>
                                                    </React.Fragment>
                                                ,
                                                onConfirmed: () => handleStaffAttendanceActivation(false),
                                                positiveButtonText: "Turn off Staff Attendance",
                                                negativeButtonText: 'Cancel',
                                            });
                                        } else {
                                            // enable
                                            openAlertConfirm({
                                                title: 'Activate Staff Attendance',
                                                message:
                                                    <React.Fragment>
                                                        <p className='mb-3.5'>You are about to turn on staff Attendance feature for ${profile.name}?</p>
                                                        <p>The school will be able to take the attendance for staff members.</p>
                                                    </React.Fragment>
                                                ,
                                                onConfirmed: () => handleStaffAttendanceActivation(true),
                                                positiveButtonText: "Turn on Staff Attendance",
                                                negativeButtonText: 'Cancel',
                                            });
                                        }
                                    }}
                                    onChange={(on: boolean) => { }}
                                    className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                >
                                    <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                </Switch>
                            </div>
                        </div>

                        <div className='sm:flex justify-between items-center max-w-lg py-5'>
                            <div className='mr-5 mb-5 sm:mb-0'>
                                <div className='flex justify-between items-center gap-6 mb-1'>
                                    <p className=''>Staff Attendance</p>
                                    {
                                        activationStatus.staffAttendance.loading &&
                                        <div className='flex items-center'>
                                            <span className='inline-block w-4 h-4 mr-2'>
                                                <Loader />
                                            </span>
                                            <span className='text-sm text-gray-500 dark:text-gray-400'>
                                                {staffAttendanceFeature ? "Activating" : "Deactivating"} feature
                                            </span>
                                        </div>
                                    }
                                </div>
                                <p className='text-gray-500 dark:text-gray-400'>Feature allows schools to take attendance of staff</p>
                            </div>
                            <div className='flex sm:flex-row-reverse justify-between items-center'>
                                <Switch
                                    checked={staffAttendanceFeature}
                                    onClick={() => {
                                        if (staffAttendanceFeature) {
                                            // disable
                                            openAlertConfirm({
                                                title: 'Deactivate Staff Attendance',
                                                message:
                                                    <React.Fragment>
                                                        <p className='mb-3.5'>You are about to turn off staff Attendance feature for ${profile.name}?</p>
                                                        <p>Once turned off, the school will not be able to take attendance for staff members.</p>
                                                    </React.Fragment>
                                                ,
                                                onConfirmed: () => handleStaffAttendanceActivation(false),
                                                positiveButtonText: "Turn off Staff Attendance",
                                                negativeButtonText: 'Cancel',
                                            });
                                        } else {
                                            // enable
                                            openAlertConfirm({
                                                title: 'Activate Staff Attendance',
                                                message:
                                                    <React.Fragment>
                                                        <p className='mb-3.5'>You are about to turn on staff Attendance feature for ${profile.name}?</p>
                                                        <p>The school will be able to take the attendance for staff members.</p>
                                                    </React.Fragment>
                                                ,
                                                onConfirmed: () => handleStaffAttendanceActivation(true),
                                                positiveButtonText: "Turn on Staff Attendance",
                                                negativeButtonText: 'Cancel',
                                            });
                                        }
                                    }}
                                    onChange={(on: boolean) => { }}
                                    className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                >
                                    <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                </Switch>
                            </div>
                        </div>
                    </div> 
                </div>*/}
            </div>
        </React.Fragment>
    );
}



export default SchoolManagementGeneralPage;