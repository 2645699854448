import { Menu, Switch, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPageInfo } from '../../state/store/reducers/ui/headerSlice';
import { toggleSidebar } from '../../state/store/reducers/ui/sidebarSlice';
import { getTheme, setTheme } from '../../state/store/reducers/ui/themeSlice';
import { Avatar } from '../common';



function AppHeader({ user, onLogout }: Props) {
    const dispatch = useDispatch();
    const pageInfo = useSelector(getPageInfo);
    const themeMode: "dark" | "light" = useSelector(getTheme);


    return (
        <header className='sticky top-0 z-40 flex justify-between items-center backdrop-blur bg-white/80 dark:bg-gray-900/80 py-4 px-5 md:px-7'>
            <div className='flex items-center mr-4'>
                <button type='button' onClick={() => dispatch(toggleSidebar({}))} className={`lg:hidden text-xl w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 mr-2`}>
                    <i className="ri-menu-3-line"></i>
                </button>

                <div>
                    <h1 className='line-clamp-1 font-medium text-xl md:text-2xl'>{pageInfo.title}</h1>
                </div>
            </div>


            <div className='flex'>
                <Menu as="div" className="relative inline-block text-left">
                    {
                        ({ open }) => (
                            <React.Fragment>
                                <Menu.Button type='button' className={`flex items-center border dark:border-gray-800 transition-colors duration-300 bg-transparent hover:bg-gray-200 hover:dark:bg-gray-800 focus:bg-gray-200 focus:dark:bg-gray-900 ${open ? 'bg-gray-200 dark:bg-gray-800' : ''} rounded-full py-1 pl-4 pr-1`}>
                                    <span className='whitespace-nowrap mr-3 parent'>Hi, {user.name.split(" ")[0]}</span>
                                    <Avatar name={user.name} imageUrl={user.avatar} />
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-300"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-200"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                    unmount={false}
                                >
                                    <Menu.Items unmount={false} className="absolute right-0 w-80 origin-top-right rounded-xl bg-white dark:bg-gray-950 shadow-xl dark:shadow-gray-800/40 overflow-hidden mt-2 py-1.5 px-5">
                                        <div className='flex items-center py-4'>
                                            <Avatar name={user.name} imageUrl={user.avatar} className='mr-3' />
                                            <div>
                                                <p className='font-medium'>{user.name}</p>
                                                <p className='text-sm text-gray-500 dark:text-gray-400'>{user.email}</p>
                                            </div>
                                        </div>

                                        <div className='border-y dark:border-y-gray-900 py-1 px-2'>
                                            <div className='flex justify-between py-4'>
                                                <span>Dark theme</span>

                                                <Switch
                                                    checked={themeMode === "dark"}
                                                    onChange={(on: boolean) => {
                                                        if (on) {
                                                            dispatch(setTheme({ mode: 'dark' }));
                                                        } else {
                                                            dispatch(setTheme({ mode: 'light' }));
                                                        }
                                                    }}
                                                    className={`${themeMode === "dark" ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex items-center h-6 w-11 rounded-full`}
                                                >
                                                    <span className="sr-only">Enable dark theme</span>
                                                    <span className={`${themeMode === "dark" ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                                </Switch>
                                            </div>

                                        </div>

                                        <div className='py-2'>
                                            <Menu.Item as="button" onClick={onLogout} type='button' className="w-full text-left font-medium transition-colors duration-300 hover:text-blue px-2 py-3">
                                                Logout
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </React.Fragment>
                        )
                    }
                </Menu>
            </div>
        </header>
    );
}


interface Props {
    user: {
        id: number;
        name: string;
        email: string;
        avatar: string;
    },
    onLogout: () => void
}


export default AppHeader;