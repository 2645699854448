import { AxiosResponse, AxiosError } from "axios";

import userSessionService from "../../local/userSessionService";

export const onResponse = (response: AxiosResponse): AxiosResponse => {
    //console.info(`[response] [${JSON.stringify(response)}]`);
    return response;
}


export const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    // Any HTTP Code which is not 2xx will be considered as error
    const statusCode = error.response?.status;
    if (statusCode === 401 /* || statusCode === 403 */) {
        userSessionService.removeToken();
        window.location.reload();
    }


    //console.error(`[response error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}