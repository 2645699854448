import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
import { doSidebarOpen } from '../../../../../state/store/reducers/ui/sidebarSlice';
import { ListLoader } from '../../../../../components/loaders';
import { Avatar, ErrorLoading } from '../../../../../components/common';
import { studentRouteLinks } from '../../../../../config/routes-list/studentsNavLinkList';
import { RouteLinksGroupType } from '../../../../../config/routes-list/appNavLinkList';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import studentService from '../../../../../services/api/unified-api/studentService';
import delegateService from '../../../../../services/api/unified-api/delegateService';
import usePagedListApi from '../../../../../state/hooks/usePagedListApi';


function ManageStudentPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { schoolId, studentId } = useParams();

    const [pageStatus, setPageStatus] = useState({ loading: false, error: false, notFound: false });
    const [profile, setProfile] = useState<any>(null);

    const scanHistoryList = usePagedListApi((query: any) => studentService.getStudentScanHistory(profile.id, query));


    const [qrCodeStatus, setQrCodeStatus] = useState({ loading: false, error: false, notFound: false });
    const [qrCode, setQrCode] = useState('');

    const [selectedModule, setSelectedModule] = useState<RouteLinksGroupType>();



    useEffect(() => {
        dispatch(doSidebarOpen({ open: false, keepClosed: true }));
        dispatch(changePageInfo({ title: 'Student Profile' }));

        if (location.state && location.state.profile) {
            setProfile(location.state.profile);
        } else {
            loadStudentProfile();
        }

        return () => {
            dispatch(doSidebarOpen({ open: true, keepClosed: false }));
        }
    }, []);


    const determineSelectedModule = async () => {
        const moduleBase = location.pathname.split('/')[4];
        switch (moduleBase) {
            case 'canteen':
                setSelectedModule(studentRouteLinks['canteen']);
                break;
            case 'payments':
                setSelectedModule(studentRouteLinks['payments']);
                break;
            default:
                setSelectedModule(studentRouteLinks['profile']);
        }
    };


    const loadStudentProfile = async () => {
        await getStudent();
    }


    const getStudent = async () => {
        setPageStatus({ loading: true, error: false, notFound: false });
        const result = await studentService.getStudentProfile(schoolId, studentId);
        if (result?.success) {
            setProfile(result.data);
            setPageStatus({ loading: false, error: false, notFound: false });
        } else {
            if (result.notFound) {
                setPageStatus({ loading: false, error: true, notFound: true });
            } else {
                setPageStatus({ loading: false, error: true, notFound: false });
            }
        }
    }


    const getQRCode = async (delegates: any[] = []) => {
        const delegateList: any[] = profile?.delegates || delegates;

        const selfDelegate = delegateList.find((delegate: any) => delegate.relation === 'SELF');

        if (selfDelegate) {
            setQrCodeStatus({ loading: true, error: false, notFound: false });
            const result = await delegateService.generateQrCode({
                delegateId: selfDelegate.id,
                parentId: -1
            }, { forSchool: false });

            if (result?.success) {
                setQrCode(result.data);
                setQrCodeStatus({ loading: false, error: false, notFound: false });
            } else {
                if (result.notFound) {
                    setQrCodeStatus({ loading: false, error: true, notFound: true });
                } else {
                    setQrCodeStatus({ loading: false, error: true, notFound: false });
                }
            }
        } else {
            setQrCodeStatus({ loading: false, error: true, notFound: true });
        }
    };


    return (
        <React.Fragment>
            {
                !pageStatus.loading && !pageStatus.error && profile &&
                <div className='xl:flex items-start gap-8'>
                    <div className='xl:sticky top-20 flex flex-wrap xl:block w-[18rem] shrink-0 xl:bg-white xl:dark:bg-gray-800 xl:shadow dark:shadow-none rounded-xl py-6 px-5 gap-x-6 gap-y-2.5'>
                        <div className='flex items-center xl:mb-5'>
                            <Avatar name={`${profile?.firstName} ${profile?.otherNames} ${profile?.lastName}`} imageUrl={profile?.avatar} size={3.2} className="shrink-0 mr-3" />

                            <div>
                                <h2 className='font-medium'>{profile?.firstName} {profile?.otherNames} {profile?.lastName}</h2>
                                <p className='text-gray-500 dark:text-gray-400 text-sm'>{profile?.school?.alias} - {profile?.grade?.name}</p>
                            </div>
                        </div>


                        <div className='xl:hidden'>
                            <Menu as="div" className="relative">
                                {
                                    ({ open }) => (
                                        <PopoverWithFloat placement='bottom-start' adaptiveWidth>
                                            <Menu.Button
                                                className={`w-full cursor-pointer flex justify-between text-left bg-transparent autofill:bg-white border  ${open ? 'border-blue-500/60 dark:border-blue-500/60' : 'border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600'} rounded-lg py-2.5 px-3.5`}
                                            >
                                                <span className='mr-4'>Go to...</span>
                                                <i className="ri-arrow-down-s-line opacity-70"></i>
                                            </Menu.Button>
                                            <Menu.Items className="max-h-64 w-full min-w-fit text-left -left-2.5 origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60 overflow-y-scroll px-2 py-3">
                                                {
                                                    Object.values(studentRouteLinks)
                                                        .filter(item => item.visible)
                                                        .map(groupItem =>
                                                            <div key={groupItem.url + groupItem.groupLabel} className='mb-1.5'>
                                                                <p className=' text-sm font-medium text-gray-500 dark:text-gray-400 mb-1 pl-2' >{groupItem.groupLabel}</p>
                                                                {
                                                                    Object.values(groupItem.items)
                                                                        .filter(item => item.visible)
                                                                        .map(item =>
                                                                            <Menu.Item
                                                                                key={item.url}
                                                                            >
                                                                                <NavLink
                                                                                    to={item.url.replace(':studentId', profile.id).replace(':schoolId', profile?.school?.id)}
                                                                                    className={({ isActive }) => `block cursor-pointer text-left whitespace-nowrap ${isActive ? 'text-blue' : ''} hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg w-full pl-5 pr-10 py-3`}
                                                                                    end
                                                                                >
                                                                                    <i className={`${item.icon} mr-2.5`}></i>
                                                                                    <span>{item.label}</span>
                                                                                </NavLink>
                                                                            </Menu.Item>
                                                                        )
                                                                }
                                                            </div>
                                                        )
                                                }
                                            </Menu.Items>
                                        </PopoverWithFloat>
                                    )
                                }
                            </Menu>
                        </div>


                        <div className='hidden xl:block'>
                            {
                                Object.values(studentRouteLinks)
                                    .filter(item => item.visible)
                                    .map(groupItem =>
                                        <div key={groupItem.url + groupItem.groupLabel} className='mb-3.5'>
                                            <p className=' text-sm font-medium text-gray-500 dark:text-gray-400 mb-1' >{groupItem.groupLabel}</p>
                                            <ul className='hidden xl:block'>
                                                {
                                                    Object.values(groupItem.items)
                                                        .filter(item => item.visible)
                                                        .map(item =>
                                                            <li
                                                                key={item.url}
                                                                className=''
                                                            >
                                                                <NavLink
                                                                    to={item.url.replace(':studentId', profile.id).replace(':schoolId', profile?.school?.id)}
                                                                    className={({ isActive }) => `flex items-center w-full rounded-lg hover:bg-black/5 dark:hover:bg-white/5 px-3 py-2.5 mb-1.5 ${isActive ? 'bg-blue-500/10 text-blue-500' : ''}`}
                                                                    end
                                                                >
                                                                    <i className={`${item.icon} mr-2.5`}></i>
                                                                    <span>{item.label}</span>
                                                                </NavLink>
                                                            </li>
                                                        )
                                                }
                                            </ul>
                                        </div>
                                    )
                            }
                        </div>
                    </div>



                    {/* content area */}
                    <div className='flex-grow'>
                        <Outlet context={{ profile, setProfile, qrCode, qrCodeStatus, getQRCode, scanHistoryList }} />
                    </div>
                </div>
            }

            {
                // if it's loading
                pageStatus.loading &&
                <ListLoader loadingText='Loading Profile' className='px-8 py-24' />
            }

            {
                // if student is not found
                !pageStatus.loading && pageStatus.error && pageStatus.notFound &&
                <ErrorLoading title='Student Not Found' message='Student may have been removed or never existed' className='px-8 py-24' />
            }

            {
                // if there's an error loading
                !pageStatus.loading && pageStatus.error && !pageStatus.notFound &&
                <ErrorLoading title='Error Loading Student' message='An unexpected error occurred while loading student profile' className='px-8 py-24' onTryAgain={loadStudentProfile} />
            }
        </React.Fragment>
    );
}


export default ManageStudentPage;