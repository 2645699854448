import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../../../../../../components/modals/Modal';
import { Button } from '../../../../../../../../components/common';
import { FormInput, Input, OTPInput } from '../../../../../../../../components/form';
import accountingService from '../../../../../../../../services/api/payments/accountingService';


function ModalSikaIDPinForm({ isOpen, account, onClose, onPinChanged }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });

    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);



    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });

        const payload = {
            pin: formData.pin,
            accountId: account?.id || null,
        }
        const pinResult = await accountingService.createPin(payload);
        if (pinResult.success) {
            if (onPinChanged) {
                onPinChanged();
            }
            onClose();
        } else {
            setSubmitStatus({ loading: false, failedMessage: pinResult.message });
        }
    }



    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-96 max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        Create SikaID Pin
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pt-2 pb-6'>
                    <Formik
                        initialValues={{
                            pin: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setFieldError, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="" autoComplete="off" >
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }

                                <div className="mb-10">
                                    <label htmlFor="input-first-name" className='block text-sm mb-2'>Enter Pin</label>
                                    <Input
                                        id='input-first-name'
                                        value={values['pin']}
                                        type='number'
                                        className='w-full tracking-[1.6rem] appearance-none text-4xl px-8 py-4'
                                        maxLength={4}
                                        onChange={(event) => {
                                            const val = event.target.value;
                                            if (!val || (val.length < 5 && /^\d+$/.test(val))) {
                                                setFieldValue('pin', val);
                                            }
                                        }}
                                    />
                                    {touched['pin'] && errors['pin'] && <p className='text-sm text-red-500 mt-2'>{errors['pin'] as string}</p>}
                                </div>




                                <div className='flex justify-end items-center mt-10'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}



const validationSchema = Yup.object().shape({
    pin: Yup.string().required("Pin is required").length(4, 'Pin must be 4 numbers long').matches(/^(?!^(.)\1*$).+/, "Cannot use repeating digits").label(""),
});


interface Props {
    isOpen: boolean;
    account: any;
    onClose: () => void;
    onPinChanged?: () => void;
}


export default ModalSikaIDPinForm;