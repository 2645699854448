import { RefObject, useEffect } from 'react';


function useClickOutside(ref: RefObject<HTMLDivElement>, callback: CallableFunction) {

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });


    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            callback(event);
        }
    };
}


export default useClickOutside;