import React, { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import StudentAccountSummarySection from './components/StudentAccountSummarySection';
import StudentInvoicesListSection from './components/StudentInvoicesListSection';



function StudentInvoiceListPage() {
    const { invoiceList }: any = useOutletContext();


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl overflow-x-auto py-8 px-8'>
            <StudentAccountSummarySection />
            <StudentInvoicesListSection />
        </div>
    );
}

export default StudentInvoiceListPage;