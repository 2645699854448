import { paymentsApi } from '../httpService';


const getCustomerTransactionsByReference = async (referenceId: any, query: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/customers/references/${referenceId}/transactions`, {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const getTransaction = async (transactionId: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/transactions/${transactionId}`, {
            timeout: 8000
        });
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


const reverseTransaction = async (transactionId: any, data: any) => {
    try {
        const result = await paymentsApi.post(`/api/v1/transactions/${transactionId}/reversals`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && (error.response.status === 400 || error.response.status === 404)) {
            return {
                success: false,
                data: null,
                message: error.response.data.errors[0].message
            }
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem reversing transaction. Please try again later."
        };
    }
}


export default {
    getCustomerTransactionsByReference,
    getTransaction,
    reverseTransaction
}