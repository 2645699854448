import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../..";
import { ModalAlertConfirmProps } from "../../../../components/modals/alert/ModalAlertConfirm";
import { ModalAlertOkProps } from "../../../../components/modals/alert/ModalAlertOk";


const slice = createSlice({
    name: "alert",
    initialState: {
        ok: {
            isOpen: false,
            data: {},
        },
        confirm: {
            isOpen: false,
            data: {},
        }
    },
    reducers: {
        alertOk: (state, action: PayloadAction<AlertOkActionProps>) => {
            state.ok.isOpen = true;
            state.ok.data = action.payload;
        },
        alertOkClose: (state, action) => {
            state.ok.isOpen = false;
        },
        alertConfirm: (state, action: PayloadAction<AlertConfirmActionProps>) => {
            state.confirm.isOpen = true;
            state.confirm.data = action.payload;
        },
        alertConfirmClose: (state, action) => {
            state.confirm.isOpen = false;
        }
    }
});


// Actions
export const { alertOk, alertOkClose, alertConfirm, alertConfirmClose } = slice.actions;


// Getters
export const selectAlertOk = (state: RootState) => state.ui.alert.ok;
export const selectAlertConfirm = (state: RootState) => state.ui.alert.confirm;


export default slice.reducer;


export type AlertOkActionProps = Omit<ModalAlertOkProps, 'isOpen' | 'onOk'> & { onOk?: () => void; };
export type AlertConfirmActionProps = Omit<ModalAlertConfirmProps, 'isOpen' | 'onClose'>;