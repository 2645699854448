import { useRef, useState } from 'react';


function usePagedListApi(apiFunc: CallableFunction) {
    const [list, setList] = useState<{ [index: number]: any[] }>({});
    const [loadingStatus, setLoadingStatus] = useState({ loading: true, loadingError: false });
    const [pager, setPager] = useState<PagerType>({ pageSize: 0, page: 1, totalCount: 0 });
    const [filters, setFilters] = useState<FiltersType>({});
    const reset = useRef(false);


    const requestData = async (payload: payloadType) => {
        const sameFilters = JSON.stringify(payload.filters) === JSON.stringify(filters);

        // load from cache when page is already loaded
        if (list[payload.page] && sameFilters && !reset.current) {
            setPager((prevState) => ({ ...prevState, page: payload.page }));
            return;
        }

        if (!sameFilters) {
            setList({});
        }

        setFilters(payload.filters);
        setPager((prevState) => ({ ...prevState, page: payload.page }));
        setLoadingStatus({ loading: true, loadingError: false });
        const result = await apiFunc({
            ...payload.filters,
            ...payload.otherProperties,
            page: payload.page,
        });

        if (result.success) {
            setList((prevState) => {
                const current = { ...prevState };
                current[payload.page] = result.data;
                return current;
            });
            setPager(result.pager);
            setLoadingStatus({ loading: false, loadingError: false });
        } else {
            setLoadingStatus({ loading: false, loadingError: true });
        }
        reset.current = false;
    }



    const updateRecord = ({ item, page }: { item: any; page: number }) => {
        const pageList = list[page];

        if (pageList) {
            setList((prevState) => {
                const current = { ...prevState };
                const index = pageList.findIndex(record => record.id === item.id);
                current[page][index] = { ...item };

                return current;
            });
        }
    }


    const resetRecords = () => {
        reset.current = true;
        setList({});
        setPager({ pageSize: 0, page: 1, totalCount: 0 });
        setFilters({});
    }


    return {
        data: {
            list: list[pager.page] || [],
            filters,
            pager,
            ...loadingStatus,
        },
        requestData,
        updateRecord,
        resetRecords,
    }
}



interface PagerType {
    pageSize: number,
    page: number,
    totalCount: number
}


interface FiltersType {
    [key: string]: any;
}


interface payloadType {
    filters: {
        [key: string]: any;
    };
    page: number,
    otherProperties: {
        [key: string]: any
    }
}



export default usePagedListApi;