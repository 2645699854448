import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { Button, ErrorLoading } from '../../../../../../../../../components/common';
import bulkSmsService from '../../../../../../../../../services/api/notifications/bulkSmsService';
import { ListLoader } from '../../../../../../../../../components/loaders';
import BulkSmsTopUpForm from '../../modals/BulkSmsTopUpForm';
import useToast from '../../../../../../../../../state/hooks/useToast';
import useAuth from '../../../../../../../../../state/hooks/useAuth';



function BulkSmsDetailsPage() {
    const { profile }: any = useOutletContext();

    const [topUpForm, setTopUpForm] = useState({ open: false });
    const { addToast } = useToast();
    const { hasPermission } = useAuth();


    const queryClient = useQueryClient();
    const clientKey = ['bulk-sms', 'client', profile.id];
    const clientQuery = useQuery({
        queryKey: clientKey,
        queryFn: () => bulkSmsService.getClient({
            schoolId: profile.id,
        }),
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    const summaryQuery = useQuery({
        queryKey: ['bulk-sms', 'summary', profile.id],
        queryFn: () => bulkSmsService.getDeliverySummary({
            reportType: 'delivery-summary',
            isPaid: true,
            channel: 'SMS',
            schoolId: profile.id,
        }),
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });
    const summaryTotal = summaryQuery.data?.SUCCESSFUL + summaryQuery.data?.SENT + summaryQuery.data?.FAILED;
    const deliveredPercent = Math.round(summaryQuery.data?.SUCCESSFUL / summaryTotal * 100) || 0;
    const sentPercent = Math.round(summaryQuery.data?.SENT / summaryTotal * 100) || 0;
    const failedPercent = Math.round(summaryQuery.data?.FAILED / summaryTotal * 100) || 0;


    const statusClass = classNames({
        "min-w-56 rounded-2xl p-8": true,
        "bg-green-500/5 text-green-500": clientQuery.data?.smsBalance > 0,
        "bg-red-500/5 text-red-500": clientQuery.data?.smsBalance === 0,
    });


    return (
        <React.Fragment>
            <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
                <div className='flex justify-between items-start flex-wrap gap-2 mb-4'>
                    <h2 className='font-medium text-xl mr-4'>Bulk SMS</h2>

                    {
                        hasPermission("SMS_TOP_UP") &&
                        <Button type='button' onClick={() => setTopUpForm({ open: true })} className=''>Top Up</Button>
                    }
                </div>

                {
                    clientQuery.data &&
                    <div className=' flex flex-wrap gap-x-10'>
                        <div className={statusClass}>
                            <div className=''>
                                <p className='font-semibold text-4xl mb-1'>{clientQuery.data?.smsBalance}</p>
                                <p className='font-medium'>SMS Left</p>
                            </div>
                        </div>


                        {
                            summaryQuery.data &&
                            <div className='flex-1'>
                                <p className='text-lg font-medium mb-3'>Delivery Summary</p>

                                <div className='h-8 flex items-stretch bg-black/10 dark:bg-white/10 rounded-lg overflow-hidden mb-6'>
                                    <div className='bg-green-500' style={{ width: `${deliveredPercent}%` }}></div>
                                    <div className='bg-yellow-500' style={{ width: `${sentPercent}%` }}></div>
                                    <div className='bg-red-500' style={{ width: `${failedPercent}%` }}></div>
                                </div>


                                <div className='flex gap-10'>
                                    <div>
                                        <div className='flex items-center gap-x-2'>
                                            <span className='w-4 h-4 bg-green-500 rounded'></span>
                                            <span>Delivered</span>
                                        </div>
                                        <span className='text-gray-500 dark:text-gray-400'>
                                            {summaryQuery.data?.SUCCESSFUL} ({deliveredPercent}%)
                                        </span>
                                    </div>


                                    <div>
                                        <div className='flex items-center gap-x-2'>
                                            <span className='w-4 h-4 bg-yellow-500 rounded'></span>
                                            <span>Sent</span>
                                        </div>
                                        <span className='text-gray-500 dark:text-gray-400'>
                                            {summaryQuery.data?.SENT} ({sentPercent}%)
                                        </span>
                                    </div>

                                    <div>
                                        <div className='flex items-center gap-x-2'>
                                            <span className='w-4 h-4 bg-red-500 rounded'></span>
                                            <span>Failed</span>
                                        </div>
                                        <span className='text-gray-500 dark:text-gray-400'>
                                            {summaryQuery.data?.FAILED} ({failedPercent}%)
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

                {
                    // if it's loading
                    clientQuery.isLoading &&
                    <ListLoader loadingText='Loading Details' loaderSize={2} className='px-8 py-16' />
                }

                {
                    // if there's an error loading
                    clientQuery.isError &&
                    <ErrorLoading title='Error Loading' message='An unexpected error occurred while loading details' className='px-8 py-16' onTryAgain={() => clientQuery.refetch()} />
                }
            </div>


            <BulkSmsTopUpForm
                isOpen={topUpForm.open}
                onClose={() => setTopUpForm({ open: false })}
                schoolId={profile.id}
                onTopedUp={(data) => {
                    queryClient.setQueryData(clientKey, (oldData: any) => {
                        if (oldData) {
                            const newData = JSON.parse(JSON.stringify(oldData));
                            newData['smsBalance'] = data?.smsBalance;
                            return newData;
                        }
                    });

                    addToast({
                        text: 'Top up was successful',
                        type: 'success',
                    });
                }}
            />
        </React.Fragment>
    );
}

export default BulkSmsDetailsPage;