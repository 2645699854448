import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';

import { FormInput, SelectInput } from '../../../../../../components/form';
import feedCategoriesService from '../../../../../../services/api/paro/feedCategoriesService';



function AppFeedListFilters({ initialData, searching, onSearch }: Props) {
    const [categoryList, setCategoryList] = useState<any[]>(["All categories"]);

    useEffect(() => {
        getCategories();
    }, []);


    const handleSubmit = (values: any, { reset }: any) => {
        const prePayload = { ...values };
        if (prePayload.category === categoryList[0]) {
            prePayload.category = '';
        }

        const payload: any = {};

        if (prePayload.title || initialData['title']) { payload['title'] = prePayload.title }
        if (prePayload.category || initialData['category']) { payload['category'] = prePayload.category }

        onSearch(payload);
    }


    const getCategories = async () => {
        const result = await feedCategoriesService.getCategories({});
        if (result.success) {
            setCategoryList((prevState) => [...prevState, ...result.data]);
        }
    }


    return (
        <Formik
            initialValues={{
                category: initialData['category'] || categoryList[0],
                title: initialData['title'] || '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ errors, touched, setErrors, submitCount, values, isValid, setFieldValue, resetForm }) => (
                <Form className="flex-grow flex items-center flex-wrap mr-8 gap-y-4">
                    <div className='hidden xl:block mr-4'>
                        <SelectInput
                            items={categoryList}
                            placeholder="Select Category"
                            value={values["category"]}
                            onChange={(value) => setFieldValue("category", value)}
                        />
                    </div>

                    <FormInput type="text" id="input-search-title" name="title" className="lg:w-full lg:max-w-xs mr-4" placeholder="Search title" autoComplete='off' />

                    <button type='submit' className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}>Search</button>
                </Form>
            )}
        </Formik>
    );
}

interface Props {
    initialData: any;
    onSearch: (values: any) => void;
    searching: boolean;
}


export default AppFeedListFilters;