
export function paginateList(list: any[], pageSize: number, pageNumber: number): any[] {
    return list.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}


export function deduceOffset(pageSize: number, pageNumber: number): number {
    return (pageSize * (pageNumber - 1)) + 1;
}


export function deduceLimit(pageSize: number, totalCount: number, pageNumber: number): number {
    const limit = (pageSize * (pageNumber - 1)) + pageSize;

    if (limit > totalCount) {
        return totalCount;
    }

    return limit;
}


export function paginationInfo(pageSize: number, totalCount: number, pageNumber: number): string {
    return `Showing ${deduceOffset(pageSize, pageNumber)} - ${deduceLimit(pageSize, totalCount, pageNumber)} of ${totalCount}`;
}