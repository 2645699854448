import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { DispatchType, RootState } from "../..";


const breakPoint = 1280;

const screenIsSmaller = (): boolean => {
    return window.matchMedia(`(max-width: ${breakPoint}px)`).matches;
}


const slice = createSlice({
    name: "sidebar",
    initialState: {
        isOpen: (window.innerWidth < breakPoint) ? false : true,
        keepClosed: false,
    },
    reducers: {
        toggleSidebar: (state, action) => {
            state.isOpen = !state.isOpen
        },
        doSidebarOpen: (state, action: PayloadAction<DoSideBarOpenOptions>) => {
            state.isOpen = action.payload.open;
            if (action.payload.keepClosed !== undefined) {
                state.keepClosed = action.payload.keepClosed;
            }
        },
    }
});


export const { toggleSidebar, doSidebarOpen } = slice.actions;

export const getSidebar = (state: any) => state.ui.sidebar;



export function getInitialSidebarStatus(): boolean {
    return (window.innerWidth < breakPoint) ? false : true;
}


export function setBodyBehaviorToSidebar(): void {

}


export const closeSidebarOnSmallScreensAction = () => (dispatch: DispatchType, getState: () => RootState) => {
    const { isOpen } = getState().ui.sidebar;

    if (isOpen && screenIsSmaller()) {
        dispatch(doSidebarOpen({ open: false }));
    }

    return;
}



export const toggleSidebarOnWindowResizeAction = () => (dispatch: DispatchType, getState: () => RootState) => {
    const isSmallerScreen = screenIsSmaller();
    const { isOpen, keepClosed } = getState().ui.sidebar;


    if (isOpen && isSmallerScreen) {
        dispatch(doSidebarOpen({ open: false }));
    }

    if (!keepClosed && !isOpen && !isSmallerScreen) {
        dispatch(doSidebarOpen({ open: true }));
    }

    return;
}

interface DoSideBarOpenOptions {
    open: boolean;
    keepClosed?: boolean;
}

export default slice.reducer;
