import { unifiedApi } from '../httpService';


const getParentAppVersion = async (platform: "ios" | "android") => {
    try {
        const result = await unifiedApi.get(`/api/v1/utils/smartsapp_${platform}/version`, {

        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



const createNewParentAppVersion = async (platform: "ios" | "android", data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/utils/smartsapp_${platform}/version`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: null,
                message: error.response.data.errors[0].error
            }
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating version. Please try again later."
        };
    }
}


export default {
    getParentAppVersion,
    createNewParentAppVersion
}