import React, { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { format } from 'date-fns';

import Modal from '../../../../../components/modals/Modal';
import { ListLoader } from '../../../../../components/loaders';
import { Avatar, Button, ErrorLoading, ListNoData } from '../../../../../components/common';
import employeeService from '../../../../../services/api/unified-api/employeeService';


function ModalEmployeeDetails({ isOpen, employee, idKey = "id", schoolId, onClose }: Props) {
    const [loadingStatus, setLoadingStatus] = useState({ loading: false, error: false, notFound: false });
    const [qrCodeStatus, setQrCodeStatus] = useState({ loading: false, error: false, notFound: false });
    const [employeeDetails, setEmployeeDetails] = useState<any>(null);
    const [qrCode, setQrCode] = useState('');


    useEffect(() => {
        if (isOpen && employee[idKey]) {
            getEmployee();
        }
    }, [employee]);


    const getEmployee = async () => {
        setLoadingStatus({ loading: true, error: false, notFound: false });

        const employeeSchoolId = employee.schoolId || schoolId;

        const result = await employeeService.getEmployeeProfile(employee[idKey], employeeSchoolId);
        if (result?.success) {
            setEmployeeDetails(result.data);
            setLoadingStatus({ loading: false, error: false, notFound: false });
        } else {
            if (result.notFound) {
                setLoadingStatus({ loading: false, error: true, notFound: true });
            } else {
                setLoadingStatus({ loading: false, error: true, notFound: false });
            }
        }
    };


    const getQRCode = async () => {
        setQrCodeStatus({ loading: true, error: false, notFound: false });
        const employeeSchoolId = employee.schoolId || schoolId;

        const result = await employeeService.getEmployeeQRCode(employee[idKey], employeeSchoolId);
        if (result?.success) {
            setQrCode(result.data);
            setQrCodeStatus({ loading: false, error: false, notFound: false });
        } else {
            if (result.notFound) {
                setQrCodeStatus({ loading: false, error: true, notFound: true });
            } else {
                setQrCodeStatus({ loading: false, error: true, notFound: false });
            }
        }
    };



    return (
        <Modal open={isOpen} onClose={onClose} >
            <div className="inline-block w-screen max-w-3xl">
                <div className="flex justify-between mb-8 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        Employee Profile
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none">
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                {
                    !loadingStatus.loading && !loadingStatus.error && employeeDetails &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <div className='flex justify-between items-center flex-wrap gap-4 mb-4'>
                            <div className='flex items-center'>
                                <Avatar imageUrl={employeeDetails?.avatar} name={`${employeeDetails?.firstName} ${employeeDetails?.otherNames} ${employeeDetails?.lastName}`} size={4} className='mr-4' />
                                <div>
                                    <p className='font-medium text-lg '>{employeeDetails?.firstName} {employeeDetails?.otherNames} {employeeDetails?.lastName}</p>
                                    <p className='text-gray-500 dark:text-gray-400'>{employeeDetails?.email}</p>
                                </div>
                            </div>

                            {
                                employeeDetails?.status === "ACTIVE" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-3 mr-3`}>{employeeDetails?.status}</span>
                            }
                            {
                                employeeDetails?.status !== "ACTIVE" && employeeDetails?.status !== "SUSPENDED" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-3 mr-3`}>{employeeDetails?.status}</span>
                            }
                            {
                                employeeDetails?.status === "SUSPENDED" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-1 px-3 mr-3`}>{employeeDetails?.status}</span>
                            }
                        </div>


                        <div className=''>
                            <Tab.Group>
                                <Tab.List as='div' className="border-b dark:border-gray-700">
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Details</Tab>
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Assigned Classrooms</Tab>
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">QR Code</Tab>
                                </Tab.List>
                                <Tab.Panels as='div' className="pb-8 px-4">
                                    <Tab.Panel unmount={false} as='div' className="divide-y dark:divide-gray-800">
                                        <div className='pt-4'>
                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>School</p>
                                                <p>{employeeDetails?.school?.name} <span className='text-gray-500 dark:text-gray-400'>({employeeDetails?.school?.alias})</span></p>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-4'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Primary Contact</p>
                                                    <a href={`tel:${employeeDetails?.primaryContact}`} className='hover:underline'>{employeeDetails?.primaryContact}</a>
                                                </div>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Gender</p>
                                                    <p>{employeeDetails?.gender || 'N/A'}</p>
                                                </div>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-4'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Role</p>
                                                    <p>{employeeDetails?.role?.name}</p>
                                                </div>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Verification</p>
                                                    <span className={`inline-block font-medium text-sm uppercase ${employeeDetails?.portalUser?.verified ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{employeeDetails?.portalUser?.verified ? "Verified" : "Not Verified"}</span>
                                                </div>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-4'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date Created</p>
                                                    <p>{format(new Date(employeeDetails?.createdDate), 'do LLLL yyyy h:mm a')}</p>
                                                </div>
                                                {
                                                    employeeDetails?.updatedDate &&
                                                    <div className='py-4'>
                                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Updated</p>
                                                        <p>{format(new Date(employeeDetails?.updatedDate), 'do LLLL yyyy h:mm a')}</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                    <Tab.Panel unmount={false} as='div' className="pt-8">
                                        {
                                            employeeDetails.grades && employeeDetails.grades.length > 0 &&
                                            <div className='max-w-full overflow-x-auto mb-6'>
                                                <table className="border-collapse table-auto text-sm w-full">
                                                    <thead>
                                                        <tr className='text-left border-b dark:border-gray-700'>
                                                            <th className='py-3 px-4'>No.</th>
                                                            <th className='py-3 px-4'>Class</th>
                                                            <th className='py-3 px-4'>Campus</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y dark:divide-gray-700/60">
                                                        {
                                                            employeeDetails.grades.map((classroomItem: any, classroomItemIndex: number) =>
                                                                <tr key={classroomItem.id}>
                                                                    <td className='whitespace-nowrap py-3 px-4'>
                                                                        {classroomItemIndex + 1}
                                                                    </td>
                                                                    <td className='py-3 px-4'>
                                                                        {classroomItem?.name} <span className='text-gray-500 dark:text-gray-400'>({classroomItem?.code})</span>
                                                                    </td>
                                                                    <td className='py-3 px-4'>
                                                                        {classroomItem.campus?.name} <span className='text-gray-500 dark:text-gray-400'>({classroomItem?.campus?.alias})</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                        {
                                            !employeeDetails.grades &&
                                            <ListNoData title='No Class Assigned' description={`No classes assigned to ${employeeDetails.firstName} ${employeeDetails.otherNames} ${employeeDetails.lastName}`} className='!pt-10 !pb-0'>
                                            </ListNoData>
                                        }
                                    </Tab.Panel>
                                    <Tab.Panel unmount={false} as='div' className="pt-8">
                                        {
                                            !qrCodeStatus.loading && !qrCode &&
                                            <ListNoData title='' showIcon={false} description={`Click the button below to generate QR COde`} className='!pt-10 pb-20'>
                                                <Button type='button' onClick={() => getQRCode()} className='py-2'>Generate QR Code</Button>
                                            </ListNoData>
                                        }
                                        {
                                            !qrCodeStatus.loading && qrCode &&
                                            <div className='text-center'>
                                                <img src={qrCode} className='inline-block max-w-xs mb-4' alt="Employee QR Code" />
                                                <div>
                                                    <a href={qrCode} download={`qrcode-for-employee-${employeeDetails.firstName}${employeeDetails.otherNames ? `-${employeeDetails.otherNames}` : ''}-${employeeDetails.lastName}.png`} className='transition-colors text-blue-500 hover:underline'>Download QR Code</a>
                                                </div>
                                            </div>
                                        }
                                        {
                                            qrCodeStatus.loading &&
                                            <ListLoader loadingText='Loading QR Code' className='py-10' loaderSize={2} />
                                        }
                                        {
                                            qrCodeStatus.error && qrCodeStatus.notFound &&
                                            <ListNoData title='No QR Code Found' showIcon={false} description={`No QR Code found for ${employeeDetails.firstName} ${employeeDetails.otherNames} ${employeeDetails.lastName}`} className='!pt-10 !pb-0'>
                                            </ListNoData>
                                        }
                                        {
                                            qrCodeStatus.error && !qrCodeStatus.notFound &&
                                            <ErrorLoading title='Error Loading QR Code' showIcon={false} message='An unexpected error occurred while loading QR Code' className='px-8 py-16' onTryAgain={getQRCode} />
                                        }
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>

                        <div className='flex justify-end items-center'>
                            <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                                Close
                            </Button>
                        </div>
                    </div>
                }

                {
                    (loadingStatus.loading || loadingStatus.error) &&
                    <div className='pb-10'>
                        {
                            loadingStatus.loading &&
                            <ListLoader loadingText='Loading Profile' className='py-10' loaderSize={2} />
                        }
                        {
                            loadingStatus.error && !loadingStatus.notFound &&
                            <ErrorLoading title='Error Loading Profile' message='An unexpected error occurred while loading employee profile' className='px-8 py-16' onTryAgain={getEmployee} />
                        }
                        {
                            loadingStatus.error && loadingStatus.notFound &&
                            <ErrorLoading title='Employee Not Found' message='Employee may have been removed or never existed' className='px-8 py-16' />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}

interface Props {
    isOpen: boolean;
    employee: any;
    idKey?: string;
    schoolId?: string | number;
    onClose: () => void;
}


export default ModalEmployeeDetails;