import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import useToast from '../../../../../../../../../state/hooks/useToast';
import { Button } from '../../../../../../../../../components/common';
import ModalSchoolConfigurationForm from '../../../../../modals/ModalSchoolConfigurationForm';


function SchoolConfigurationsPage() {
    const { profile, setProfile }: any = useOutletContext();

    const [configurationsFormOpen, setConfigurationsFormOpen] = useState(false);
    const { addToast } = useToast();


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            <div className='flex justify-between items-center flex-wrap gap-2 mb-2'>
                <h2 className='font-medium text-xl mr-4'>School Configurations</h2>
                {
                    profile.configuration &&
                    <Button type='button' onClick={() => setConfigurationsFormOpen(true)} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-700 dark:hocus:bg-gray-600 py-2.5'>
                        Change
                    </Button>
                }
            </div>

            {
                profile.configuration &&
                <div className='divide-y dark:divide-gray-700'>
                    <div className='pt-4 pb-8'>
                        <h3 className='text-lg text-gray-500 dark:text-gray-400 mb-5'>General</h3>
                        <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Language</p>
                                <p>{profile?.configuration.language}</p>
                            </div>
                            {
                                profile?.configuration.alternativeContacts &&
                                <div className=''>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Location</p>
                                    <p>{profile?.configuration.alternativeContacts}</p>
                                </div>
                            }
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Metric Unit</p>
                                <p>{profile?.configuration.metricUnit}</p>
                            </div>
                        </div>
                    </div>


                    <div className='py-8'>
                        <h3 className='text-lg text-gray-500 dark:text-gray-400 mb-5'>Drop Off Notification Settings</h3>
                        <div className='grid sm:grid-cols-2 md:grid-cols-4 gap-8'>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Drop Off Start Time</p>
                                <p>{profile?.configuration.dropOffStartTime}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Drop Off Cut Off Time</p>
                                <p>{profile?.configuration.dropOffCutOffTime}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Drop Off Notification Start Time</p>
                                <p>{profile?.configuration.dropOffNotificationStartTime}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Drop Off Grace Period In Minutes</p>
                                <p>{profile?.configuration.dropOffGracePeriodInMinutes}</p>
                            </div>
                        </div>
                    </div>


                    <div className='py-8'>
                        <h3 className='text-lg text-gray-500 dark:text-gray-400 mb-5'>Pickup Notification Settings</h3>
                        <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Pick Up Start Time</p>
                                <p>{profile?.configuration.pickupStartTime}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Pick Up Cut Off Time</p>
                                <p>{profile?.configuration.pickupCutOffTime}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Pick Up Notification Start Time</p>
                                <p>{profile?.configuration.pickupNotificationStartTime}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Pick Up Grace Period In Minutes</p>
                                <p>{profile?.configuration.pickUpGracePeriodInMinutes}</p>
                            </div>
                        </div>
                    </div>


                    <div className='py-8'>
                        <h3 className='text-lg text-gray-500 dark:text-gray-400 mb-5'>Staff Attendance Times</h3>
                        <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Staff Drop Off Start Time</p>
                                <p>{profile?.configuration.employeeDropOffStartTime || 'N/A'}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Staff Drop Off Cut Off Time</p>
                                <p>{profile?.configuration.employeeDropOffCutOffTime || 'N/A'}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Staff Pickup Start Time</p>
                                <p>{profile?.configuration.employeePickupStartTime || 'N/A'}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Staff Pickup Cut Off Time</p>
                                <p>{profile?.configuration.employeePickupCutOffTime || 'N/A'}</p>
                            </div>
                        </div>
                    </div>


                    <div className='py-8'>
                        <h3 className='text-lg text-gray-500 dark:text-gray-400 mb-5'>Time & Location Settings</h3>
                        <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Timezone</p>
                                <p>{profile?.configuration.timezone}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Time Format</p>
                                <p>{profile?.configuration?.amPm ? '12 Hour' : '24 Hour'}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Longitude</p>
                                <p>{profile?.configuration?.longitude}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Latitude</p>
                                <p>{profile?.configuration?.latitude}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Geofence Radius</p>
                                <p>{profile?.configuration?.geoFenceRadius}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }


            {
                !profile.configuration &&
                <div className='pt-3 pb-6'>
                    <p className='text-gray-500 dark:text-gray-400 mb-4'>No configurations found for school</p>
                    <Button type='button' onClick={() => setConfigurationsFormOpen(true)}>Add Configurations Here</Button>
                </div>
            }



            <ModalSchoolConfigurationForm
                isOpen={configurationsFormOpen}
                onClose={() => setConfigurationsFormOpen(false)}
                school={profile}
                configuration={profile.configuration}
                onConfigurationAdded={(configuration: any) => {
                    setProfile((prevState: any) => ({ ...prevState, configuration }))
                    addToast({
                        text: `Configuration added successfully`,
                        type: "success"
                    });
                }}
                onConfigurationUpdated={(configuration: any) => {
                    setProfile((prevState: any) => ({ ...prevState, configuration }))
                    addToast({
                        text: `Configuration updated successfully`,
                        type: "success"
                    });
                }}
            />
        </div>
    );
}

export default SchoolConfigurationsPage;