import React, { useEffect, useState } from 'react';
import { useOutletContext, Outlet } from 'react-router-dom';
import clientsService from '../../../../../../../services/api/payments/clientsService';


function SchoolPaymentPage() {
    const { profile }: any = useOutletContext();
    const [paymentClient, setPaymentClient] = useState({ loading: false, error: false, notFound: false, client: null, });
    const [directoryList, setDirectoryList] = useState<any[]>([]);

    useEffect(() => {
        getPaymentClient();
    }, []);


    const getPaymentClient = async () => {
        setPaymentClient({ loading: true, error: false, notFound: false, client: null });
        const result = await clientsService.getClientByReference(profile?.id);
        if (result?.success) {
            setPaymentClient({ loading: false, error: false, notFound: false, client: result.data });
        } else {
            if (result.notFound) {
                setPaymentClient({ loading: false, error: true, notFound: true, client: null });
            } else {
                setPaymentClient({ loading: false, error: true, notFound: false, client: null });
            }
        }
    }


    return (
        <Outlet
            context={{
                profile,
                paymentClient,
                setPaymentClient,
                directoryList,
                setDirectoryList
            }}
        />
    );
}

export default SchoolPaymentPage;