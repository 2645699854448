import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';

import { loadAppRatings, resetAppRatings, selectAppRatings } from '../../../../state/store/reducers/entities/appRatingsSlice';
import { changePageInfo } from '../../../../state/store/reducers/ui/headerSlice';
import { ErrorLoading, ListNoData, Pagination, RatingStarsComponent } from '../../../../components/common';
import { ListLoader } from '../../../../components/loaders';
import { paginationInfo } from '../../../../utils/pagination';
import appRatingService from '../../../../services/api/unified-api/appRatingService';
import AppRatingsListFilters from './components/AppRatingsListFilters';


function AppRatingsListPage() {
    const dispatch = useDispatch();
    const appRatings = useSelector(selectAppRatings);
    const [ratingInsights, setRatingInsights] = useState<any>();
    const [searchParams, setSearchParams] = useSearchParams();

    // get query params
    const page = Number(searchParams.get('page')) || appRatings.pager.page;
    const searchFilters: any = {
        //name: searchParams.get('name') || '',
    };
    if (searchParams.get('score')) { searchFilters['score'] = searchParams.get('score') || '' }
    if (searchParams.get('fromDate')) { searchFilters['fromDate'] = searchParams.get('fromDate') || '' }
    if (searchParams.get('toDate')) { searchFilters['toDate'] = searchParams.get('toDate') || '' }
    if (searchParams.get('os')) { searchFilters['os'] = searchParams.get('os') || '' }
    if (searchParams.get('isOnAppStore')) { searchFilters['isOnAppStore'] = searchParams.get('isOnAppStore') || '' }
    if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "App Ratings" }));

        return () => {
            dispatch(resetAppRatings({}));
        }
    }, []);


    useEffect(() => {
        getRatings();
    }, [searchParams, dispatch]);



    const getRatings = async () => {
        await dispatch<any>(loadAppRatings({
            page: page,
            filters: searchFilters,
        }));

        const result = await appRatingService.getInsights();
        if (result.success) {
            setRatingInsights(result.data);
        }
    };


    return (
        <div className=''>
            <div className='flex sm:flex-row-reverse flex-wrap justify-between gap-y-4 mb-1'>
                {
                    ratingInsights &&
                    <div className=''>
                        <div>
                            <span className='mr-4'>Average: {Math.round(ratingInsights.averageRating * 10) / 10}</span>
                            <span>Total Ratings: {ratingInsights.count}</span>
                        </div>
                        <RatingStarsComponent rating={ratingInsights.averageRating} className='flex sm:justify-end gap-0.5 text-xl text-yellow-500' />
                    </div>
                }

                <AppRatingsListFilters
                    initialData={searchFilters}
                    onSearch={(values) => {
                        //setSearchParams({ ...values });
                        setSearchParams(current => {
                            const entries = { ...Object.fromEntries(current.entries()), ...values };
                            if (entries.page) {
                                entries.page = '1';
                            }
                            return entries;
                        });
                    }}
                    searching={appRatings.loading}
                />
            </div>

            {
                // if there're ratings
                !appRatings.loadingError && !appRatings.loading && appRatings.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>User</th>
                                    <th className='py-6 px-6'>Phone Number</th>
                                    <th className='py-6 px-6'>School</th>
                                    <th className='py-6 px-6'>Rating</th>
                                    <th className='py-6 px-6'>Comment</th>
                                    <th className='py-6 px-6'>OS</th>
                                    <th className='py-6 px-6'>From Store</th>
                                    <th className='py-6 px-6'>Date</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    appRatings.list.map((ratingItem: any) =>
                                        <tr key={ratingItem.id}>
                                            <td className='py-5 px-6'>
                                                {ratingItem?.employee?.firstName} {ratingItem?.employee?.lastName}
                                                {ratingItem?.parent?.firstName} {ratingItem?.parent?.lastName}
                                            </td>
                                            <td className='py-5 px-6'>
                                                {ratingItem?.employee?.primaryContact || ''}
                                                {ratingItem?.parent?.primaryContact || ''}
                                            </td>
                                            <td className='py-5 px-6'>{ratingItem.school.name}</td>
                                            <td className='py-5 px-6'>
                                                <div className='flex items-center'>
                                                    <span className='w-[2ch] text-right mr-2'>{ratingItem.score}</span>
                                                    <RatingStarsComponent rating={ratingItem.score} className='flex gap-0.5 text-yellow-500' />
                                                </div>
                                            </td>
                                            <td className='py-5 px-6'>{ratingItem.review}</td>
                                            <td className='py-5 px-6'>{ratingItem.os}</td>
                                            <td className='py-5 px-6 uppercase'>{ratingItem.isOnAppStore ? 'yes' : 'no'}</td>
                                            <td className='py-5 px-6 whitespace-nowrap'>{format(new Date(ratingItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(appRatings.pager.pageSize, appRatings.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={appRatings.pager.pageSize}
                            totalCount={appRatings.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if ratings are loading
                !appRatings.loadingError && appRatings.loading &&
                <ListLoader loadingText='Loading Ratings' className='px-8 py-24' />
            }

            {
                // if there're no ratings in the app
                !appRatings.loadingError && !appRatings.loading && appRatings.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Rating Found' description='App is yet to be rated' className='px-8 py-24'>
                </ListNoData>
            }

            {
                // if there're no ratings in the app
                !appRatings.loadingError && !appRatings.loading && appRatings.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Rating Found' description='No rating was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                appRatings.loadingError &&
                <ErrorLoading title='Error Loading Ratings' message='There was a problem loading app ratings' className='px-8 py-24' onTryAgain={getRatings} />
            }
        </div>
    );
}

export default AppRatingsListPage;