import { createSlice } from "@reduxjs/toolkit";

import { RootState } from './../../index';
import { DispatchType } from "../..";
import usersPermissionsService from "../../../../services/api/user-accounts/usersPermissionsService";


const initialState = {
    list: {} as { [index: number]: any[] },
    loading: true,
    filters: null,
    pager: {
        pageSize: 0,
        page: 1,
        totalCount: 0
    },
    loadingError: false
};


const slice = createSlice({
    name: "userPermissions",
    initialState: initialState,
    reducers: {
        userPermissionsRequested: (state, action) => {
            state.loading = true;
            state.filters = action.payload.filters;
            state.pager.page = action.payload.page;
            state.loadingError = false;
        },
        userPermissionsReceived: (state, action) => {
            state.list[action.payload.pager.page] = action.payload.data;
            state.pager = action.payload.pager;
            state.loading = false;
        },
        userPermissionsRequestFailed: (state, action) => {
            state.loadingError = true;
            state.loading = false;
        },
        updatePermissionRecord: (state, action) => {
            const { permission, page } = action.payload;

            const list = state.list[page];
            if (list) {
                const index = list.findIndex(permissionItem => permissionItem.id === permission.id);
                list[index] = { ...permission };

                state.list[page] = list;
            }
        },
        getCachedPermissions: (state, action) => {
            state.pager.page = action.payload.page;
        },
        clearCachedPermissions: (state, action) => {
            state.list = {};
        },
        resetPermissions: (state, action) => initialState,
    }
});


const { userPermissionsRequested, getCachedPermissions, userPermissionsRequestFailed, userPermissionsReceived, clearCachedPermissions } = slice.actions;
export const { resetPermissions, updatePermissionRecord } = slice.actions;




// Actions
export const loadUserPermissions = (payload: any) => async (dispatch: DispatchType, getState: () => RootState) => {
    const permissions = getState().entities.userPermissions;
    const sameFilters = JSON.stringify(payload.filters) === JSON.stringify(permissions.filters);

    // load from cache when page is already loaded
    if (permissions.list[payload.page] && sameFilters) {
        dispatch(getCachedPermissions({
            page: payload.page
        }));

        return;
    }


    if (!sameFilters) {
        dispatch(clearCachedPermissions({}));
    }


    dispatch(userPermissionsRequested(payload));
    const result = await usersPermissionsService.getPermissions({
        ...payload.filters,
        page: payload.page,
        pageSize: 10,
        direction: 'asc',
        properties: 'name',
    });

    if (result.success) {
        dispatch(userPermissionsReceived(result));
    } else {
        dispatch(userPermissionsRequestFailed({}));
    }
};




// Selectors
export const selectUserPermissions = (state: RootState): ListSelectorType => {
    const permissions = { ...state.entities.userPermissions };
    permissions.list = permissions.list[state.entities.userPermissions.pager.page] || [];

    return permissions as ListSelectorType;
};


type ListSelectorType = Omit<typeof initialState, 'list'> & {
    list: any[];
}


export default slice.reducer;