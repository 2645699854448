import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../../../../components/modals/Modal';
import { FormTextArea } from '../../../../../../../../components/form';
import { Button } from '../../../../../../../../components/common';
import transactionsService from '../../../../../../../../services/api/payments/transactionsService';
import useAlert from '../../../../../../../../state/hooks/useAlert';


function ModalReversePaymentTransaction({ isOpen, transaction, onClose, onTransactionReversed }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    const { openAlertConfirm } = useAlert();


    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        await openAlertConfirm({
            title: "Reverse Transaction?",
            message: `Are you sure you want to reverse GHS ${transaction?.amountProcessed.toFixed(2)} ${transaction?.paymentChannel} transaction for ${transaction?.customer?.name} `,
            positiveButtonText: 'Yes, Continue',
            negativeButtonText: 'No, Cancel',
            onConfirmed: async () => {
                setSubmitStatus({ loading: true, failedMessage: '' });
                const result = await transactionsService.reverseTransaction(transaction.transactionId, formData);
                if (result.success) {
                    if (onTransactionReversed) {
                        onTransactionReversed({ ...transaction, status: "REVERSED", amountProcessed: 0 });
                    }
                    //resetForm();
                    onClose();
                    setSubmitStatus({ loading: false, failedMessage: '' });
                } else {
                    setSubmitStatus({ loading: false, failedMessage: result.message });
                }
            },
        });
    };


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[34rem] max-w-full">
                <div className="flex justify-between mb-4 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        Reverse Transaction
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>
                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    {
                        submitStatus.failedMessage && !submitStatus.loading &&
                        <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                    }
                    {
                        transaction &&
                        <div className='divide-y dark:divide-gray-800 mb-4'>
                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Amount</p>
                                    <p>GHS {transaction?.amountProcessed?.toFixed(2)}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Transaction ID</p>
                                    <p>{transaction?.transactionId}</p>
                                </div>
                            </div>


                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium'>{transaction?.customer?.name}</p>
                                    <p className='text-gray-500 dark:text-gray-400 text-sm'>{transaction?.customer?.group?.name}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Customer Number</p>
                                    <p>{transaction?.customer?.customerNumber}</p>
                                </div>
                            </div>


                            <div className='py-4'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Invoice</p>
                                <p>{transaction?.invoice?.name}</p>
                                <small className='text-sm text-gray-500 dark:text-gray-400'>{transaction?.invoice?.invoiceNumber}</small>
                            </div>
                        </div>
                    }


                    <Formik
                        initialValues={{
                            reason: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                <div className="mb-10">
                                    <label htmlFor="input-reversal-reason" className='block mb-2'>Provide Reason for Reversal</label>
                                    <FormTextArea name="reason" id="input-reversal-reason" className="w-full" placeholder="Enter reason" rows={3} />
                                    {touched['reason'] && errors['reason'] && <p className='text-sm text-red-500 mt-2'>Reason is required</p>}
                                </div>


                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        Reverse Transaction
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    reason: Yup.string().trim().required().label(""),
});


interface Props {
    isOpen: boolean;
    transaction: any;
    onClose: () => void;
    onTransactionReversed?: (transaction: any) => void;
}


export default ModalReversePaymentTransaction;