import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Modal from '../../../../../../../../components/modals/Modal';
import { Button } from '../../../../../../../../components/common';
import { FormInput } from '../../../../../../../../components/form';
import { useMutation } from '@tanstack/react-query';
import bulkSmsService from '../../../../../../../../services/api/notifications/bulkSmsService';


function BulkSmsTopUpForm({ isOpen, schoolId, onClose, onTopedUp }: Props) {
    const saveMutation = useMutation({
        mutationFn: (data: any) => bulkSmsService.topUp(data, { schoolId }),
    });


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        saveMutation.mutate(formData, {
            onSuccess: (data) => {
                onClose();
                if (onTopedUp) {
                    onTopedUp(data);
                }
            },
            onError: () => {
            }
        });
    }


    return (
        <Modal open={isOpen} onClose={false ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-96 max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        Top Up SMS
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={saveMutation.isPending}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            smsCount: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    saveMutation.error &&
                                    <p className='text-red-500 mb-4' >{saveMutation.error as unknown as string}</p>
                                }

                                <div className="mb-10">
                                    <label htmlFor="input-top-up" className='block text-sm mb-2'>Top Up Number</label>
                                    <FormInput type="number" name="smsCount" id="input-top-up" className="w-full" placeholder="Enter SMS Count" />
                                    {touched['smsCount'] && errors['smsCount'] && <p className='text-sm text-red-500 mt-2'>Top up number is Required</p>}
                                </div>

                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={saveMutation.isPending} className='' disabled={saveMutation.isPending}>
                                        Top Up
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = yup.object().shape({
    smsCount: yup.number().integer().positive().required(''),
});



interface Props {
    isOpen: boolean;
    schoolId: number;
    onClose: () => void;
    onTopedUp?: (data: any) => void;
}

export default BulkSmsTopUpForm;