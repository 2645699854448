import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Switch } from '@headlessui/react';

import Modal from '../../../../../components/modals/Modal';
import { FormInput, PhoneNumberInput } from '../../../../../components/form';
import { Button } from '../../../../../components/common';
import employeeService from '../../../../../services/api/unified-api/employeeService';
import userAccountsService from '../../../../../services/api/user-accounts/userAccountsService';



function ModalAdminAccountForm({ isOpen, adminAccount, schoolId, onClose, onAdminAccountCreated, onAdminAccountUpdated, onAdminCreatedNotAccount }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });

    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {

        setSubmitStatus({ loading: true, failedMessage: '' });
        if (adminAccount) {
            // update
            /* const result = await campusService.updateCampus(schoolId, campus.id, formData);
            if (result.success) {
                if (onCampusUpdated) {
                    onCampusUpdated({ ...campus, ...result.data });
                }
                //resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            } */
        } else {
            // create
            const payload = {
                ...formData,
                employeeId: null,
                environment: "Live",
                role: "ROLE_ADMINISTRATOR",
                schoolId: schoolId
            }

            const employeeResult = await employeeService.createEmployee(schoolId, payload);

            if (employeeResult.success) {
                if (formData.makeActive) {
                    const accountPayload = {
                        employeeId: employeeResult.data.id,
                        schoolId: schoolId,
                        userType: "PORTAL_USER",
                        username: payload.email,
                        password: formData.password,
                        roleId: employeeResult.data.role.id,
                    };

                    const accountResult = await userAccountsService.createAccount(accountPayload);
                    if (accountResult) {
                        if (onAdminAccountCreated) {
                            onAdminAccountCreated(employeeResult.data);
                        }
                    } else {
                        if (onAdminCreatedNotAccount) {
                            onAdminCreatedNotAccount(employeeResult.data);
                        }
                    }
                } else {
                    if (onAdminAccountCreated) {
                        onAdminAccountCreated(employeeResult.data);
                    }
                }

                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: employeeResult.message });
            }



            /* if (accountResult.success) {
                const employeeId = accountResult.data.id;

                const credentialsResult = await employeeService.createAdminAccountCredentials(schoolId, employeeId, { ...payload, employeeId });
                if (credentialsResult.success && formData.makeActive) {
                    const activateResult = await employeeService.activateAdminAccount(schoolId, {
                        accountId: credentialsResult.data.id,
                        action: "ACTIVATE",
                        email: formData.email,
                        environment: payload.environment,
                        role: payload.role
                    });

                    if (activateResult) { }
                }


                if (onAdminAccountCreated) {
                    onAdminAccountCreated(accountResult.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: accountResult.message });
            } */
        }
    };



    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[34rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {adminAccount ? "Edit" : "Create"} Admin Account
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            firstName: adminAccount ? adminAccount.firstName : '',
                            lastName: adminAccount ? adminAccount.lastName : '',
                            email: adminAccount ? adminAccount.email : '',
                            primaryContact: adminAccount ? adminAccount.primaryContact : '',
                            password: adminAccount ? adminAccount.password : '',
                            repeatPassword: adminAccount ? adminAccount.repeatPassword : '',
                            makeActive: adminAccount ? adminAccount.status : false,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setFieldError, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="" autoComplete="off" >
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }

                                <div className="mb-6">
                                    <label htmlFor="input-first-name" className='block text-sm mb-2'>First Name</label>
                                    <FormInput type="text" name="firstName" id="input-first-name" className="w-full" placeholder="Enter first name" />
                                    {touched['firstName'] && errors['firstName'] && <p className='text-sm text-red-500 mt-2'>First name is required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-last-name" className='block text-sm mb-2'>Last Name</label>
                                    <FormInput type="text" name="lastName" id="input-last-name" className="w-full" placeholder="Enter last name" />
                                    {touched['lastName'] && errors['lastName'] && <p className='text-sm text-red-500 mt-2'>Last name is required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="modal-input-email" className='block text-sm mb-2'>Email</label>
                                    <input autoComplete="false" name="hidden" type="email" style={{ display: 'none' }} />
                                    <FormInput type="email" name="email" id="modal-input-email" className="w-full" placeholder="Enter email address" autoComplete='off' role="presentation" />
                                    {touched['email'] && errors['email'] && <p className='text-sm text-red-500 mt-2'>Email is required</p>}
                                </div>

                                <div className="mb-7">
                                    <label htmlFor="input-contact-number" className='block text-sm mb-2'>Primary Contact</label>
                                    <PhoneNumberInput
                                        id="input-contact-number"
                                        name="contact"
                                        className="w-full"
                                        //placeholder="Enter campus contact number"
                                        value={values["primaryContact"]}
                                        onChange={value => setFieldValue('primaryContact', value)}
                                        onBlur={() => setFieldTouched('primaryContact', true)}
                                    />
                                    {/* {touched['primaryContact'] && errors['primaryContact'] && <p className='text-sm text-red-500 mt-2'>Contact number is required</p>} */}
                                    {touched['primaryContact'] && errors['primaryContact'] && <p className='text-sm text-red-500 mt-2'>{errors['primaryContact'] as string}</p>}
                                </div>

                                <div className='mb-3'>
                                    <div className='flex justify-between items-center'>
                                        <div className='mr-5'>
                                            <p>Activate Account</p>
                                            <p className='text-gray-500 dark:text-gray-400 mb-4'>Account should be activated upon creation</p>
                                        </div>
                                        <Switch
                                            checked={values["makeActive"]}
                                            onChange={(on: boolean) => setFieldValue("makeActive", on)}
                                            className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                        >
                                            <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                        </Switch>
                                    </div>
                                </div>


                                {
                                    values["makeActive"] &&
                                    <React.Fragment>
                                        <div className="mb-6">
                                            <label htmlFor="input-password" className='block text-sm mb-2'>Password</label>
                                            <FormInput type="password" name="password" id="input-password" className="w-full" placeholder="Enter password" autoComplete='off' />
                                            {touched['password'] && errors['password'] && <p className='text-sm text-red-500 mt-2'>Password is required</p>}
                                        </div>

                                        <div className="mb-6">
                                            <label htmlFor="input-repeat-password" className='block text-sm mb-2'>Repeat Password</label>
                                            <FormInput type="password" name="repeatPassword" id="input-repeat-password" className="w-full" placeholder="Repeat password" />
                                            {touched['repeatPassword'] && errors['repeatPassword'] && <p className='text-sm text-red-500 mt-2'>Passwords do not match</p>}
                                        </div>
                                    </React.Fragment>
                                }


                                <div className='flex justify-end items-center mt-10'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        {adminAccount ? "Save" : "Create Account"}
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}



const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required().label(""),
    lastName: Yup.string().trim().required().label(""),
    email: Yup.string().email().trim().required().label(""),
    //primaryContact: Yup.string().trim().required().label(""),
    primaryContact: Yup.string().trim().required("Contact number is Required").test("invalid-number", "Invalid number provided", value => value ? isValidPhoneNumber(value) : true).label(""),
    //password: Yup.string().trim().required().label(""),
    password: Yup.string().trim()
        .when('makeActive', {
            is: (val: boolean) => val,
            then: (schema) => schema.required(),
        }),
    //repeatPassword: Yup.string().trim().required().label(""),
    repeatPassword: Yup.string().trim()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
});


interface Props {
    isOpen: boolean;
    adminAccount?: any;
    schoolId: number;
    onClose: () => void;
    onAdminAccountCreated?: (adminAccount: any) => void;
    onAdminAccountUpdated?: (adminAccount: any) => void;
    onAdminCreatedNotAccount?: (adminAccount: any) => void;
}


export default ModalAdminAccountForm;