import { createSlice } from "@reduxjs/toolkit";

import { RootState } from './../../index';
import { DispatchType } from "../..";
import settlementAccountsService from "../../../../services/api/payments/settlementAccountsService";


const initialState = {
    list: {} as { [index: number]: any[] },
    loading: true,
    filters: null,
    pager: {
        pageSize: 0,
        page: 1,
        totalCount: 0
    },
    loadingError: false
};


const slice = createSlice({
    name: "settlementAccounts",
    initialState: initialState,
    reducers: {
        settlementAccountsRequested: (state, action) => {
            state.loading = true;
            state.filters = action.payload.filters;
            state.pager.page = action.payload.page;
            state.loadingError = false;
        },
        settlementAccountsReceived: (state, action) => {
            state.list[action.payload.pager.page] = action.payload.data;
            state.pager = action.payload.pager;
            state.loading = false;
        },
        settlementAccountsRequestFailed: (state, action) => {
            state.loadingError = true;
            state.loading = false;
        },
        updateSettlementAccountRecord: (state, action) => {
            const { settlementAccount, page } = action.payload;

            const list = state.list[page];
            if (list) {
                const index = list.findIndex(accountItem => accountItem.id === settlementAccount.id);
                list[index] = { ...settlementAccount };

                state.list[page] = list;
            }
        },
        getCachedSettlementAccounts: (state, action) => {
            state.pager.page = action.payload.page;
        },
        clearCachedSettlementAccounts: (state, action) => {
            state.list = {};
        },
        resetSettlementAccounts: (state, action) => initialState,
    }
});


const { settlementAccountsRequested, getCachedSettlementAccounts, settlementAccountsRequestFailed, settlementAccountsReceived, clearCachedSettlementAccounts } = slice.actions;
export const { resetSettlementAccounts, updateSettlementAccountRecord } = slice.actions;




// Actions
export const loadSettlementAccounts = (payload: any) => async (dispatch: DispatchType, getState: () => RootState) => {
    const settlementAccounts = getState().entities.settlementAccounts;
    const sameFilters = JSON.stringify(payload.filters) === JSON.stringify(settlementAccounts.filters);

    // load from cache when page is already loaded
    if (settlementAccounts.list[payload.page] && sameFilters) {
        dispatch(getCachedSettlementAccounts({
            page: payload.page,
        }));

        return;
    }


    if (!sameFilters) {
        dispatch(clearCachedSettlementAccounts({}));
    }


    dispatch(settlementAccountsRequested(payload));
    const result = await settlementAccountsService.getSchoolSettlementAccounts({
        ...payload.filters,
        page: payload.page,
        pageSize: 10,
        includeArchives: true,
        //accountType: "MOMO"
        //status: "ACTIVE"
        //direction: 'desc',
        //properties: 'id',
    });

    if (result.success) {
        dispatch(settlementAccountsReceived(result));
    } else {
        dispatch(settlementAccountsRequestFailed({}));
    }
};




// Selectors
export const selectSettlementAccounts = (state: RootState): AccountSelectorType => {
    const settlementAccounts = { ...state.entities.settlementAccounts };
    settlementAccounts.list = settlementAccounts.list[state.entities.settlementAccounts.pager.page] || [];

    return settlementAccounts as AccountSelectorType;
};


type AccountSelectorType = Omit<typeof initialState, 'list'> & {
    list: any[];
}


export default slice.reducer;