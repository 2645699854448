import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';


import Modal from '../../../../../components/modals/Modal';
import { FormInput, FormTextArea, PhoneNumberInput, SelectInput } from '../../../../../components/form';
import { Button } from '../../../../../components/common';
import schoolService from '../../../../../services/api/unified-api/schoolService';


function ModalSchoolForm({ isOpen, onClose, school, onSchoolCreated, onSchoolUpdated }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });

    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        if (school) {
            // update
            const result = await schoolService.updateSchool(school.id, formData);
            if (result.success) {
                if (onSchoolUpdated) {
                    onSchoolUpdated({ ...school, ...result.data });
                }
                //resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        } else {
            // create
            const result = await schoolService.createSchool({ ...formData, environment: 'Staging' });
            if (result.success) {
                if (onSchoolCreated) {
                    onSchoolCreated(result.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    };



    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[36rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {school ? "Edit" : "Create"} School
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            name: school ? school.name : '',
                            alias: school ? school.alias : '',
                            country: school ? school.country : '',
                            contact: school ? school.contact : '',
                            address: school && school.address ? school.address : '',
                            location: school ? school.location : '',
                            dataSource: school ? school.dataSource : '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }

                                <div className="mb-6">
                                    <label htmlFor="input-name" className='block text-sm mb-2'>School Name</label>
                                    <FormInput type="text" name="name" id="input-name" className="w-full" placeholder="Enter school name" />
                                    {touched['name'] && errors['name'] && <p className='text-sm text-red-500 mt-2'>School name is Required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-alias" className='block text-sm mb-2'>School Alias</label>
                                    <FormInput type="text" name="alias" id="input-alias" className="w-full" placeholder="Enter school alias" />
                                    {touched['alias'] && errors['alias'] && <p className='text-sm text-red-500 mt-2'>{errors['alias'] as string}</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="modal-select-country" className='block text-sm mb-2'>Country</label>
                                    <SelectInput
                                        items={["Ghana", "Nigeria"]}
                                        placeholder="Select country"
                                        value={values["country"]}
                                        onChange={(value) => setFieldValue("country", value)}
                                        onBlur={() => setFieldTouched('country', true)}
                                    />
                                    {touched['country'] && errors['country'] && <p className='text-sm text-red-500 mt-2'>Country is required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-contact-number" className='block text-sm mb-2'>Contact Number</label>
                                    <PhoneNumberInput
                                        id="input-contact-number"
                                        name="contact"
                                        className="w-full"
                                        //placeholder="Enter school contact number"
                                        value={values["contact"]}
                                        onChange={value => setFieldValue('contact', value)}
                                        onBlur={() => setFieldTouched('contact', true)}
                                    />
                                    {/* {touched['contact'] && errors['contact'] && <p className='text-sm text-red-500 mt-2'>Contact number is Required</p>} */}
                                    {touched['contact'] && errors['contact'] && <p className='text-sm text-red-500 mt-2'>{errors['contact'] as string}</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-address" className='block text-sm mb-2'>Address</label>
                                    <FormTextArea name='address' id="input-address" className='w-full' placeholder="Enter school address" rows={2} />
                                    {touched['address'] && errors['address'] && <p className='text-sm text-red-500 mt-2'>School address is Required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-location" className='block text-sm mb-2'>School Location</label>
                                    <FormInput type="text" name="location" id="input-location" className="w-full" placeholder="Enter school location" />
                                    {touched['location'] && errors['location'] && <p className='text-sm text-red-500 mt-2'>School location is Required</p>}
                                </div>

                                <div className="mb-10">
                                    <label htmlFor="modal-select-source" className='block text-sm mb-2'>School Source</label>
                                    <SelectInput
                                        items={[
                                            { label: "From MineX 360", value: "MINEX" },
                                            { label: "Fidelity", value: "FIDELITY" },
                                            { label: "From Direct Warrantee", value: "DW" },
                                        ]}
                                        placeholder="Select school source"
                                        value={values["dataSource"]}
                                        onChange={(selected: any) => setFieldValue("dataSource", selected.value)}
                                        onBlur={() => setFieldTouched('dataSource', true)}
                                    />
                                    {touched['dataSource'] && errors['dataSource'] && <p className='text-sm text-red-500 mt-2'>School source is required</p>}
                                </div>

                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        {school ? "Save" : "Create School"}
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required().label(""),
    alias: Yup.string().min(3, 'School alias must be at least 3 characters long.').trim().required("School alias is Required").label(""),
    country: Yup.string().trim().required().label(""),
    //contact: Yup.string().trim().required().label(""),
    contact: Yup.string().trim().required("Contact number is Required").test("invalid-number", "Invalid number provided", value => value ? isValidPhoneNumber(value) : true).label(""),
    address: Yup.string().trim().required().label(""),
    location: Yup.string().trim().required().label(""),
    dataSource: Yup.string().trim().required().label(""),
});



interface Props {
    isOpen: boolean;
    onClose: () => void;
    school: any;
    onSchoolCreated?: (school: any) => void;
    onSchoolUpdated?: (school: any) => void;
}


export default ModalSchoolForm;