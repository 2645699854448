import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';


import { changePageInfo } from '../../../../../../state/store/reducers/ui/headerSlice';
import { Button } from '../../../../../../components/common';
import { FormInput } from '../../../../../../components/form';


function CreateInvoicePage() {
    const { invoiceId } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    const [invoice, setInvoice] = useState<any>(null);



    useEffect(() => {
        dispatch(changePageInfo({ title: invoiceId ? "Edit Invoice" : "Create Invoice" }));

        if (invoiceId) {
            if (location.state && location.state.invoice) {
                setInvoice(location.state.invoice);
            } else {
                getInvoice();
            }
        }
    }, []);


    const getInvoice = async () => {

    };



    const handleFormSubmit = async (formData: object, { resetForm }: any) => {

    };





    return (
        <section>
            <Formik
                initialValues={{
                    name: '',
                    invoiceNumber: '',
                    invoiceItems: invoice ? invoice?.invoiceItems : [newInvoiceObject],
                }}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
                validateOnMount
                enableReinitialize
            >
                {({ values, errors, touched, setErrors, setFieldError, setFieldValue, setFieldTouched, submitCount, isValid, isInitialValid }) => {
                    const invoiceTotal = values.invoiceItems?.reduce((accumulator: number, item: any) => accumulator + Number(item?.total), 0);
                    return (
                        <Form className="">
                            {
                                submitStatus.failedMessage && !submitStatus.loading &&
                                <div className='inline-block bg-red-500/5 rounded-lg px-4 py-2 mb-4'>
                                    <p className='text-red-500' >{submitStatus.failedMessage}</p>
                                </div>
                            }


                            <div className='max-w-xl'>
                                <div className="mb-6">
                                    <label htmlFor="input-invoice-name" className='block mb-2'>Invoice name</label>
                                    <FormInput type="text" name="name" id="input-invoice-name" className="w-full" autoFocus placeholder="Enter invoice name" />
                                    {touched['name'] && errors['name'] && <p className='text-sm text-red-500 mt-2'>Invoice name is required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-invoice-number" className='block mb-2'>Invoice number</label>
                                    <FormInput type="text" name="invoiceNumber" id="input-invoice-number" className="w-full" placeholder="Enter invoice name" />
                                    {touched['invoiceNumber'] && errors['invoiceNumber'] && <p className='text-sm text-red-500 mt-2'>Invoice number is required</p>}
                                </div>
                            </div>

                            <div className='max-w-full overflow-x-auto mb-2'>
                                <table className="border-collapse table-auto w-full">
                                    <thead>
                                        <tr className='text-left border-b-2 dark:border-gray-700'>
                                            <th className='whitespace-nowrap w-0 py-4 pl-2 pr-4'>No.</th>
                                            <th className='whitespace-nowrap w-9/12 py-4 px-4'>Item</th>
                                            <th className='whitespace-nowrap py-4 px-4'>Amount</th>
                                            <th className='whitespace-nowrap py-4 px-4'>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y dark:divide-gray-700/60">
                                        <FieldArray
                                            name="invoiceItems"
                                            render={arrayHelpers => (
                                                <React.Fragment>
                                                    {
                                                        values.invoiceItems.map((invoiceItem: any, invoiceItemIdex: number) =>
                                                            <React.Fragment key={invoiceItem?.id || `invoice-item-${invoiceItemIdex}`}>
                                                                <tr className=''>
                                                                    <td className='pt-2.5 pl-2 pr-4'>{invoiceItemIdex + 1}.</td>
                                                                    <td className='pt-2.5 px-4'>
                                                                        <FormInput name={`invoiceItems[${invoiceItemIdex}].name`} className='w-full border-transparent dark:border-transparent px-3 py-1.5' placeholder='Enter item' autoFocus />
                                                                    </td>
                                                                    <td className='pt-2.5 px-4'>
                                                                        <FormInput
                                                                            type='number'
                                                                            name={`invoiceItems[${invoiceItemIdex}].total`}
                                                                            className='w-full max-w-full border-transparent dark:border-transparent px-3 py-1.5' placeholder='Enter amount'
                                                                            onChange={(event) => {
                                                                                setFieldValue(`invoiceItems[${invoiceItemIdex}].unitPrice`, event.target.value);
                                                                                setFieldValue(`invoiceItems[${invoiceItemIdex}].total`, event.target.value);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className='pt-2.5 px-4 whitespace-nowrap'>
                                                                        {
                                                                            invoiceItemIdex > 0 &&
                                                                            <button type='button' onClick={() => arrayHelpers.remove(invoiceItemIdex)} className='hocus:text-red-500' title='Remove Item'>Remove</button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr className='text-red-500 !border-0 text-sm'>
                                                                    <td></td>
                                                                    <td className='px-6 pb-2.5'>
                                                                        <ErrorMessage
                                                                            name={`invoiceItems[${invoiceItemIdex}].name`}
                                                                            render={() => <span className=''>Name is required</span>}
                                                                        />
                                                                    </td>
                                                                    <td className='px-6 pb-2.5'>
                                                                        <ErrorMessage
                                                                            name={`invoiceItems[${invoiceItemIdex}].total`}
                                                                            render={() => <span className=''>Amount is required</span>}
                                                                        />
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    }

                                                    <tr>
                                                        <td colSpan={2} className='py-4'>
                                                            <div className='flex justify-between gap-8'>
                                                                <Button
                                                                    type='button'
                                                                    onClick={() => {
                                                                        arrayHelpers.push(newInvoiceObject);
                                                                    }}
                                                                    className='flex items-center gap-1.5 text-blue  bg-gray-50 hocus:bg-gray-100  dark:bg-gray-800 dark:hocus:bg-gray-700 px-4 py-2.5'
                                                                >
                                                                    <i className="ri-add-fill"></i>
                                                                    <span>Add New Item</span>
                                                                </Button>

                                                                <span className='font-semibold'>Total:</span>
                                                            </div>
                                                        </td>
                                                        <td className='font-semibold align-top py-4 px-5'>
                                                            {invoiceTotal.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                        />
                                    </tbody>
                                </table>
                            </div>


                            <div className='flex justify-between gap-3 bg-gray-50 dark:bg-white/5 rounded-xl py-3.5 px-6 mb-1'>
                                <div className=''>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Minimum required amount to pay</p>
                                    <p>100%</p>
                                </div>

                                <div className=''>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Gross Total</p>
                                    <p className='text-lg font-semibold text-blue'>GHS {invoiceTotal.toFixed(2)}</p>
                                </div>
                            </div>


                            <div className='flex justify-end items-center mt-6'>
                                <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                    {invoice ? "Save" : "Create Invoice"}
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </section>
    );
}


const newInvoiceObject = {
    name: '',
    compulsory: true,
    hasDiscount: false,
    quantity: 1,
    unitPrice: 0,
    total: '',
};


const invoiceItemValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required().label(""),
    compulsory: Yup.boolean().required(),
    hasDiscount: Yup.boolean().required(),
    quantity: Yup.number().integer().required().min(1),
    unitPrice: Yup.number().required().min(0),
    total: Yup.number().required().min(0),
});


const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required().label(""),
    invoiceNumber: Yup.string().trim().required().label(""),
    invoiceItems: Yup.array().of(invoiceItemValidationSchema).required().min(1),
});


export default CreateInvoicePage;