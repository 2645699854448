import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';

import useClickOutside from '../../state/hooks/useClickOutside';
import PopoverWithFloat from '../hoc/PopoverWithFloat';
import Loader from '../svgs/Loader';


function MultiSelectInput({ value, items = [], valueKey = 'value', labelKey = 'label', selectedLabel, selectedLabelPlural, showSelectedItems = true, placeholder = '', itemsShowLimit, searchable = true, emptyAsAll = false, maxSelection, searching, placement = 'bottom-start', onChange, usePortal = true, wrapOptions = false, onFocus, onBlur, onSearch }: Props) {
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [query, setQuery] = useState('');

    const containerRef = useRef<HTMLDivElement>(null);
    let isClicked = false;


    useClickOutside(containerRef, () => {
        //setQuery('');
        if (onBlur && isClicked) {
            onBlur();
        }
    });


    useEffect(() => {
        if (Array.isArray(value)) {
            setSelectedItems(value);
        }
    }, [value]);



    const filteredItems = useMemo(() => {
        if (!onSearch && query) {
            return items.filter((item) => {
                const filterVal = (typeof item === 'object' && item !== null) ? item[labelKey] : item;
                return filterVal.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''));
            });
        }

        return items;
    }, [query, items, labelKey, onSearch]);


    /*  const filteredItems = query === '' ? items : items.filter((item) => {
         const filterVal = (typeof item === 'object' && item !== null) ? item[labelKey] : item;
         return filterVal.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''));
     }); */


    return (
        <div ref={containerRef} className='' onClick={() => isClicked = true}>
            <Combobox
                value={selectedItems}
                by={(a, b) => {
                    if (typeof a === 'object' && a !== null) {
                        return a[valueKey] === b[valueKey];
                    }
                    return a === b;
                }}
                onChange={(selected) => {
                    if (maxSelection && selected.length > maxSelection) {
                        return;
                    }
                    if (onChange) {
                        onChange(selected);
                    } else {
                        setSelectedItems(selected);
                    }
                }}
                multiple
            >
                {
                    ({ open }) => (
                        <PopoverWithFloat placement={placement} adaptiveWidth portal={usePortal}>
                            <Combobox.Button
                                as='div'
                                //type='button'
                                className={`w-full cursor-pointer flex justify-between text-left bg-transparent autofill:bg-white border ${open ? 'border-blue-500/60 dark:border-blue-500/60' : 'border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600'} rounded-lg py-2.5 px-3.5`}
                            >
                                {selectedItems.length === 0 && !emptyAsAll && <span className='whitespace-nowrap opacity-50 self-center mr-2'>{placeholder}</span>}
                                {selectedItems.length === 0 && emptyAsAll && <span className='whitespace-nowrap self-center mr-2'>All {selectedLabelPlural || selectedLabel || 'items'}</span>}
                                {
                                    showSelectedItems &&
                                    <ul className='flex-grow flex items-center flex-wrap gap-y-2'>
                                        <li className='text-sm py-1'>&nbsp;</li>
                                        {
                                            selectedItems
                                                .slice(0, itemsShowLimit || selectedItems.length)
                                                .map((selectedItem, selectedItemIndex) => (
                                                    <li
                                                        key={typeof selectedItem === 'object' && selectedItem !== null ? `${selectedItem[valueKey]}${selectedItemIndex}` || selectedItemIndex : `${selectedItem}${selectedItemIndex}`}
                                                        //key={person.id} 
                                                        className='flex items-center bg-black/10 dark:bg-white/10 rounded-lg py-1 px-2 mr-3'
                                                    >
                                                        <span className='text-sm  mr-1'>
                                                            {typeof selectedItem === 'object' && selectedItem !== null ? selectedItem[labelKey] || '' : selectedItem}
                                                        </span>
                                                        <button
                                                            type='button'
                                                            className='text-sm transition-opacity duration-200 opacity-50 hover:opacity-70'
                                                            onClick={(event) => {
                                                                const items = selectedItems.filter(item => {
                                                                    if (typeof item === 'object' && item !== null) {
                                                                        return item[valueKey] !== selectedItem[valueKey];
                                                                    } else {
                                                                        return item !== selectedItem;
                                                                    }
                                                                });

                                                                setSelectedItems(items);
                                                                if (onChange) {
                                                                    onChange(items);
                                                                }

                                                                event.stopPropagation();
                                                            }}
                                                        >
                                                            <i className="ri-close-line"></i>
                                                        </button>
                                                    </li>
                                                ))
                                        }
                                        {
                                            itemsShowLimit && selectedItems.length > itemsShowLimit &&
                                            <li className='text-sm bg-black/10 dark:bg-white/10 rounded-lg py-1 px-2 mr-3'>
                                                +{selectedItems.length - itemsShowLimit}
                                            </li>
                                        }
                                    </ul>
                                }

                                {
                                    !showSelectedItems && selectedItems.length > 0 &&
                                    <span className='self-center whitespace-nowrap mr-2'>
                                        {selectedItems.length} {selectedItems.length === 1 && (selectedLabel || 'item')} {selectedItems.length > 1 && (selectedLabelPlural || selectedLabel || 'items')} selected
                                    </span>
                                }

                                <i className="ri-arrow-down-s-line opacity-70"></i>
                            </Combobox.Button>


                            <Combobox.Options className="w-full min-w-fit text-left -left-2.5 origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60 px-4 py-3">
                                {
                                    //searchable && items.length > 0 &&
                                    ((searchable && items.length > 0) || onSearch) &&
                                    <div className='pt-1.5 pb-3'>
                                        <Combobox.Input
                                            className="w-full text-sm bg-white dark:bg-gray-900 autofill:bg-white border border-gray-300 dark:border-gray-700 hocus::border-blue-300 dark:hocus::border-gray-600 focus:outline-none rounded-lg py-2 px-2"
                                            onChange={(event) => {
                                                setQuery(event.target.value);
                                                if (onSearch) {
                                                    onSearch(event.target.value);
                                                }
                                            }}
                                            placeholder='Search'
                                        />
                                    </div>
                                }
                                <div className='max-h-52 overflow-y-scroll'>
                                    {
                                        filteredItems.length === 0 && !searching &&
                                        <p className='text-sm text-gray-500 dark:text-gray-400 px-5 py-2'>
                                            {onSearch ? 'Type to search items' : ' No Item found'}
                                        </p>
                                    }

                                    {
                                        searching &&
                                        <div className='flex justify-center opacity-50 py-3 px-5'>
                                            <span className='w-5 mr-2'><Loader /></span>
                                            <span>Searching</span>
                                        </div>
                                    }
                                    {
                                        filteredItems.length > 0 &&
                                        <React.Fragment>
                                            {
                                                filteredItems.map((item, itemIndex) => (
                                                    <Combobox.Option
                                                        //key={item[valueKey] || itemIndex}
                                                        key={typeof item === 'object' && item !== null ? `${item[valueKey]}${itemIndex}` || itemIndex : `${item}${itemIndex}`}
                                                        value={item}
                                                        className="flex cursor-pointer text-left ui-selected:text-blue hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg w-full pl-4 pr-10 py-3"
                                                    >
                                                        {({ active, selected }) => (
                                                            <React.Fragment>
                                                                <span className='opacity-60 mr-2.5'>
                                                                    {selected ? <i className="ri-checkbox-line"></i> : <i className="ri-checkbox-blank-line"></i>}
                                                                </span>
                                                                <span className={`${wrapOptions ? '' : 'whitespace-nowrap'}`}>
                                                                    {typeof item === 'object' && item !== null ? item[labelKey] || '' : item}
                                                                </span>
                                                            </React.Fragment>
                                                        )}
                                                    </Combobox.Option>
                                                ))
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                                {
                                    items.length > 0 &&
                                    <div className='flex justify-between items-center border-t dark:border-gray-800 pt-2 pb-1 mx-4 mt-2'>
                                        <span className='mr-3'>
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    setSelectedItems([]);
                                                    if (onChange) {
                                                        onChange([]);
                                                    }
                                                }}
                                                className='text-xs transition-opacity duration-200 opacity-60 hover:opacity-80 disabled:opacity-60'
                                                disabled={selectedItems.length === 0}
                                            >
                                                Clear selection
                                            </button>
                                        </span>
                                    </div>
                                }
                            </Combobox.Options>
                        </PopoverWithFloat>
                    )
                }
            </Combobox>
        </div >
    );
}


interface Props {
    value?: any[];
    labelKey?: string;
    valueKey?: string;
    items?: any[];
    placeholder?: string;
    searchable?: boolean;
    itemsShowLimit?: number;
    showSelectedItems?: boolean;
    selectedLabel?: string;
    selectedLabelPlural?: string;
    emptyAsAll?: boolean;
    maxSelection?: number;
    usePortal?: boolean;
    placement?: any;
    wrapOptions?: boolean;
    onChange?: (selections: any[]) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onSearch?: (query: string) => void;
    searching?: boolean;
}


export default MultiSelectInput;