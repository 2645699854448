import { createSlice } from "@reduxjs/toolkit";

import { RootState } from './../../index';
import { DispatchType } from "../..";
import schoolService from "../../../../services/api/unified-api/schoolService";


const initialState = {
    list: {} as { [index: number]: any[] },
    loading: true,
    filters: null,
    pager: {
        pageSize: 0,
        page: 1,
        totalCount: 0
    },
    loadingError: false
};


const slice = createSlice({
    name: "schools",
    initialState: initialState,
    reducers: {
        schoolsRequested: (state, action) => {
            state.loading = true;
            state.filters = action.payload.filters;
            state.pager.page = action.payload.page;
            state.loadingError = false;
        },
        schoolsReceived: (state, action) => {
            state.list[action.payload.pager.page] = action.payload.data;
            state.pager = action.payload.pager;
            state.loading = false;
        },
        schoolsRequestFailed: (state, action) => {
            state.loadingError = true;
            state.loading = false;
        },
        updateSchoolRecord: (state, action) => {
            const { school, page } = action.payload;

            const list = state.list[page];
            if (list) {
                const index = list.findIndex(schoolItem => schoolItem.id === school.id);
                list[index] = { ...school };

                state.list[page] = list;
            }
        },
        getCachedSchools: (state, action) => {
            state.pager.page = action.payload.page;
        },
        clearCachedSchools: (state, action) => {
            state.list = {};
        },
        resetSchools: (state, action) => initialState,
    }
});


const { schoolsRequested, getCachedSchools, schoolsRequestFailed, schoolsReceived, clearCachedSchools } = slice.actions;
export const { resetSchools, updateSchoolRecord } = slice.actions;




// Actions
export const loadSchools = (payload: any) => async (dispatch: DispatchType, getState: () => RootState) => {
    const schools = getState().entities.schools;
    const sameFilters = JSON.stringify(payload.filters) === JSON.stringify(schools.filters);

    // load from cache when page is already loaded
    if (schools.list[payload.page] && sameFilters) {
        dispatch(getCachedSchools({
            page: payload.page
        }));

        return;
    }


    if (!sameFilters) {
        dispatch(clearCachedSchools({}));
    }


    dispatch(schoolsRequested(payload));
    const result = await schoolService.getSchools({
        ...payload.filters,
        page: payload.page,
        pageSize: 10,
        direction: 'asc',
        properties: 'name',
        //sortProperties: 'name',
    });

    if (result.success) {
        dispatch(schoolsReceived(result));
    } else {
        dispatch(schoolsRequestFailed({}));
    }
};




// Selectors
export const selectSchools = (state: RootState): SchoolSelectorType => {
    const schools = { ...state.entities.schools };
    schools.list = schools.list[state.entities.schools.pager.page] || [];

    return schools as SchoolSelectorType;
};


type SchoolSelectorType = Omit<typeof initialState, 'list'> & {
    list: any[];
}


export default slice.reducer;