import React, { useEffect, useState } from 'react';
import ToastPosition, { ToastPositionType } from './ToastPosition';


function ToastItem({ id, removeToast, text, title, duration = 5000, type = "info", position = "top-right", autoClose = true }: Props) {
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        setShowToast(true);

        if (autoClose) {
            setTimeout(() => {
                setShowToast(false);
                //removeToast(id);
            }, duration);
        }
    }, [autoClose, duration]);


    return (
        <ToastPosition
            show={showToast}
            position={position}
            onDone={() => removeToast(id)}
        >
            <div className={`flex max-w-sm bg-white dark:bg-gray-950 rounded-xl shadow-sm dark:shadow-gray-700/60 dark:border border-gray-800 pt-3 pb-2.5 px-3.5`}>
                {
                    type === "success" &&
                    <span className='text-2xl leading-none mr-1.5'>
                        <i className="ri-check-line text-green-500"></i>
                    </span>
                }
                {
                    type === "error" &&
                    <span className='text-2xl leading-none mr-1.5'>
                        <i className="ri-close-circle-line text-red-500"></i>
                    </span>
                }
                {
                    type === "warning" &&
                    <span className='text-2xl leading-none mr-1.5'>
                        <i className="ri-error-warning-line text-yellow-500"></i>
                    </span>
                }
                <div className={`-mt-0.5`}>
                    {title && <p className='font-semibold'>{title}</p>}
                    <p className={`${title ? 'text-gray-500 dark:text-gray-400' : ''}`}>{text}</p>
                </div>
                {
                    !autoClose &&
                    <button type='button' onClick={() => setShowToast(false)} className='transition-opacity duration-300 text-lg opacity-60 hover:opacity-100 ml-2.5 px-0.5'>
                        <i className="ri-close-line"></i>
                    </button>
                }
            </div>
        </ToastPosition>
    );
}


interface Props extends ToastItemType {
    id: number;
    removeToast: (id: number) => void;
}


export interface ToastItemType {
    text?: string;
    title?: string;
    duration?: number;
    type?: "success" | "info" | "warning" | "error";
    position?: ToastPositionType
    autoClose?: boolean;
    //component?: JSX.Element;
}


export default ToastItem;