import { createSlice } from "@reduxjs/toolkit";

import { RootState } from '../../index';
import { DispatchType } from "../..";


const initialState = {
    list: {} as { [index: number]: any[] },
    loading: true,
    filters: null,
    pager: {
        pageSize: 0,
        page: 1,
        totalCount: 0
    },
    loadingError: false
};


const slice = createSlice({
    name: "listPage",
    initialState: initialState,
    reducers: {
        listRequested: (state, action) => {
            state.loading = true;
            state.filters = action.payload.filters;
            state.pager.page = action.payload.page;
            state.loadingError = false;
        },
        listReceived: (state, action) => {
            state.list[action.payload.pager.page] = action.payload.data;
            state.pager = action.payload.pager;
            state.loading = false;
        },
        listRequestFailed: (state, action) => {
            state.loadingError = true;
            state.loading = false;
        },
        updateRecord: (state, action) => {
            const { record, page } = action.payload;

            const list = state.list[page];
            if (list) {
                const index = list.findIndex(item => item.id === record.id);
                list[index] = { ...record };

                state.list[page] = list;
            }
        },
        getCachedList: (state, action) => {
            state.pager.page = action.payload.page;
        },
        clearCachedList: (state, action) => {
            state.list = {};
        },
        resetList: (state, action) => initialState,
    }
});


const { listRequested, getCachedList, listRequestFailed, listReceived, clearCachedList } = slice.actions;
export const { resetList, updateRecord } = slice.actions;




// Actions
export const loadList = (payload: any, apiFunc: CallableFunction) => async (dispatch: DispatchType, getState: () => RootState) => {
    const listData = getState().entities.listPage;
    const sameFilters = JSON.stringify(payload.filters) === JSON.stringify(listData.filters);

    // load from cache when page is already loaded
    if (listData.list[payload.page] && sameFilters) {
        dispatch(getCachedList({
            page: payload.page
        }));

        return;
    }


    if (!sameFilters) {
        dispatch(clearCachedList({}));
    }


    dispatch(listRequested(payload));
    const result = await apiFunc({
        ...payload.filters,
        ...payload.otherProperties,
        page: payload.page,
        // pageSize: 10,
    });

    if (result.success) {
        dispatch(listReceived(result));
    } else {
        dispatch(listRequestFailed({}));
    }
};




// Selectors
export const selectList = (state: RootState): ListSelectorType => {
    const listData = { ...state.entities.listPage };
    listData.list = listData.list[state.entities.listPage.pager.page] || [];

    return listData as ListSelectorType;
};


type ListSelectorType = Omit<typeof initialState, 'list'> & {
    list: any[];
}


export default slice.reducer;