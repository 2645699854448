import { createSlice } from "@reduxjs/toolkit";


const slice = createSlice({
    name: "header",
    initialState: {
        title: ''
    },
    reducers: {
        changePageInfo: (state, action) => {
            state.title = action.payload.title
        },
    }
});


// Actions
export const { changePageInfo } = slice.actions;


// Getters
export const getPageInfo = (state: any) => state.ui.header;


export default slice.reducer;