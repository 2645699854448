import React from 'react';

function SmartSappLogo() {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="currentColor" />
            <path d="M25.8283 22.1793C27.0273 22.1793 27.9993 21.2042 27.9993 20.0013C27.9993 18.7984 27.0273 17.8232 25.8283 17.8232C24.6292 17.8232 23.6572 18.7984 23.6572 20.0013C23.6572 21.2042 24.6292 22.1793 25.8283 22.1793Z" fill="white" />
            <path d="M9.62402 24.0014L16.0006 16.0016H18.7627C19.6161 15.9768 20.4528 16.2423 21.1367 16.755C22.5236 17.8196 22.5899 19.4829 22.5957 19.708C22.6463 20.656 22.3708 21.5928 21.8155 22.3615C21.4409 22.8502 20.9639 23.2503 20.4182 23.5337C19.8725 23.8171 19.2714 23.9767 18.6574 24.0014H9.62402Z" fill="white" />
            <path d="M22.3791 8L16.0005 15.992H13.2384C12.3856 16.017 11.5495 15.7514 10.8664 15.2386C9.46979 14.1819 9.41127 12.5185 9.40737 12.2934C9.35635 11.3453 9.63183 10.4084 10.1876 9.63989C10.5624 9.15098 11.0397 8.75068 11.5858 8.4673C12.1319 8.18392 12.7333 8.02439 13.3476 8H22.3791Z" fill="white" />
            <path d="M6.17105 14.1783C7.37009 14.1783 8.34211 13.2032 8.34211 12.0003C8.34211 10.7974 7.37009 9.82227 6.17105 9.82227C4.97201 9.82227 4 10.7974 4 12.0003C4 13.2032 4.97201 14.1783 6.17105 14.1783Z" fill="white" />
        </svg>
    );
}

export default SmartSappLogo;