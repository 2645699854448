import React, { useEffect, useState } from 'react';

import { Button } from '../../../../../components/common';
import Modal from '../../../../../components/modals/Modal';
import { AvatarSelectInput } from '../../../../../components/form';
import schoolService from '../../../../../services/api/unified-api/schoolService';


function ModalSchoolLogoForm({ isOpen, onClose, school, onLogoChanged }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    const [logo, setLogo] = useState('');


    useEffect(() => {
        if (school && school.configuration?.logo) {
            setLogo(school.configuration.logo);
        }

        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen, school]);


    const handleSave = async () => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        const payload = {
            amPm: school.configuration.amPm,
            configurationId: school.configuration.id,
            latitude: school.configuration.latitude,
            longitude: school.configuration.longitude,
            timezone: school.configuration.timezone,
            dropOffNotificationStartTime: school.configuration.dropOffNotificationStartTime,
            pickupNotificationStartTime: school.configuration.pickupNotificationStartTime,
            dropOffCutOffTime: school.configuration.dropOffCutOffTime,
            dropOffStartTime: school.configuration.dropOffStartTime,
            email: school.configuration.contact_email,
            pickupCutOffTime: school.configuration.pickupCutOffTime,
            pickupStartTime: school.configuration.pickupStartTime,
            logo: logo.split(',')[1]
        };

        const result = await schoolService.updateSchoolConfigurations(school.id, payload);
        if (result.success) {
            if (onLogoChanged) {
                onLogoChanged(logo);
            }
            onClose();
            setSubmitStatus({ loading: false, failedMessage: '' });
        } else {
            setSubmitStatus({ loading: false, failedMessage: result.message });
        }
    }


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[24rem] max-w-full">
                <div className="flex justify-between mb-8 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {logo ? "Change" : "Add"} School Logo
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    {
                        submitStatus.failedMessage && !submitStatus.loading &&
                        <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                    }
                    <div className='text-center mb-12'>
                        <AvatarSelectInput
                            size={10}
                            value={logo}
                            placeholder='Select Logo'
                            onChange={(value) => setLogo(value)}
                        />
                    </div>

                    <div className='flex justify-center items-center'>
                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                            Cancel
                        </Button>

                        <Button type='button' onClick={handleSave} loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


interface Props {
    isOpen: boolean;
    onClose: () => void;
    school: any;
    onLogoChanged?: (logo: string) => void;
}


export default ModalSchoolLogoForm;