import { unifiedApi } from '../httpService';


const getParentProfile = async (parentId: any, query: any = {}) => {
    try {
        const result = await unifiedApi.get(`/api/v1/parents/${parentId}`, {
            params: query,
            timeout: 20000
        });
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getParentProfile
}