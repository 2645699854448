import axios from "axios";

import baseURLs from "../../config/baseURLs";
import { onRequest, onRequestError } from "./interceptors/requestInterceptors";
import { onResponse, onResponseError } from './interceptors/responseInterceptors';


// use for global interceptors
//axios.interceptors.request.use(onRequest, onRequestError);
//axios.interceptors.response.use(onResponse, onResponseError);

const createAxiosInstance = (baseURL: string) => {
    const newInstance = axios.create({
        baseURL: baseURL,
        timeout: 10000,
    });

    newInstance.interceptors.request.use(onRequest, onRequestError);
    newInstance.interceptors.response.use(onResponse, onResponseError);

    return newInstance;
}


export const unifiedApi = createAxiosInstance(baseURLs.unifiedApi);
export const attendanceApi = createAxiosInstance(baseURLs.attendance);
export const authApi = createAxiosInstance(baseURLs.authApi);
export const canteenApi = createAxiosInstance(baseURLs.canteenApi);
export const notificationsApi = createAxiosInstance(baseURLs.notificationApi);
export const elearningApi = createAxiosInstance(baseURLs.elearningApi);
export const paymentsApi = createAxiosInstance(baseURLs.paymentsApi);
export const paroApi = createAxiosInstance(baseURLs.paroApi);


// Axios Codes
export const TIMEOUT_CODE = "ECONNABORTED";