import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Menu } from '@headlessui/react';
import parsePhoneNumber, { getCountries, getPhoneCode, AsYouType, } from 'libphonenumber-js';

import PopoverWithFloat from '../hoc/PopoverWithFloat';
import useClickOutside from '../../state/hooks/useClickOutside';
import { Input } from './Input';
//import countries from '../../config/countries-locale/en.json';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
const countryList = getCountries().map(countryCode => {
    return {
        label: regionNames.of(countryCode) as string, //countries[countryCode],
        value: countryCode,
        code: `+${getPhoneCode(countryCode)}`
    }
}).sort((a, b) => a.label.localeCompare(b.label));




function PhoneNumberInput({ value = '', onChange, onFocus, onBlur, className = '', ...otherInputProps }: Props) {
    const numberInputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [focused, setFocused] = useState(false);

    const [searchInput, setSearchInput] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [numberInput, setNumberInput] = useState('');

    useClickOutside(containerRef, () => setFocused(false));

    const filteredCountryList = useMemo(() => {
        return countryList.filter((item) => item.label.toLowerCase().includes(searchInput.trim().toLowerCase()) || item.value.toLowerCase().includes(searchInput.trim().toLowerCase()))
    }, [searchInput]);



    useEffect(() => {
        let val = value;

        if (val && val.charAt(0) !== '+') {
            val = `+${val}`;
        }
        handleSettingCountry(val);
        setNumberInput(new AsYouType().input(val));
        //setNumberInput(val);
    }, [value]);



    const handleSettingCountry = (value: string = '') => {
        const phoneNumber = parsePhoneNumber(value);
        if (phoneNumber) {
            if (phoneNumber.country !== selectedCountry) {
                setSelectedCountry(phoneNumber.country || '');
            }
        } else {
            setSelectedCountry('');
        }
    }



    const handleFocus = () => {
        setFocused(true);

        if (onFocus) {
            onFocus();
        }
    }


    const handleBlur = () => {
        setFocused(false);

        if (onBlur) {
            onBlur();
        }
    }


    return (
        <div ref={containerRef} className={`flex items-center bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 ${focused ? '!border-blue-500/60 !dark:border-blue-500/60' : ''} focus:outline-none rounded-lg py-2.5 pr-4 ${className}`}>
            <Menu as="div" className="relative inline-block text-left border-r border-gray-200 dark:border-gray-700 mr-3 pr-2">
                {
                    ({ open }) => (
                        <PopoverWithFloat placement='bottom-start'  >
                            <Menu.Button type='button' onFocus={handleFocus} onBlur={handleBlur} className={`flex items-center transition-colors duration-300 bg-opacity-0 pl-4 ${open ? '' : ''}`}>
                                {selectedCountry && <img src={`/img/svgs/country-flags/${selectedCountry}.svg`} className="w-6 mr-0.5" alt="" />}
                                {!selectedCountry && <span className='flex justify-center w-6 mr-0.5'><i className="ri-flag-fill"></i></span>}
                                <i className="ri-arrow-down-s-line opacity-70"></i>
                            </Menu.Button>

                            <Menu.Items as='div' unmount={false} className="w-72 -left-2.5 origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                <div className='px-4 pt-2 pb-3'>
                                    <Input type="text" value={searchInput} onChange={event => setSearchInput(event.target.value)} onKeyDown={(e) => { if (e.code === "Space") { e.stopPropagation(); } }} className='w-full text-sm px-2 py-2' placeholder='Search country' />
                                </div>
                                <div className='max-h-60 overflow-y-auto'>
                                    {
                                        filteredCountryList.length > 0 &&
                                        <React.Fragment>
                                            {
                                                filteredCountryList
                                                    //.filter((item) => item.label.toLowerCase().includes(searchInput.trim().toLowerCase()) || item.value.toLowerCase().includes(searchInput.trim().toLowerCase()))
                                                    .map((item, index: number) =>
                                                        <Menu.Item
                                                            key={item.value}
                                                            as="button" type='button'
                                                            onClick={() => {
                                                                setSelectedCountry(item.value);
                                                                setNumberInput(`+${getPhoneCode(item.value)} `);
                                                                numberInputRef.current?.focus();
                                                                //setSearchInput('');
                                                            }}
                                                            className={`w-full text-sm text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg ${item.value === selectedCountry ? 'text-blue' : ''} px-5 py-3`}
                                                        >
                                                            {item.label}
                                                        </Menu.Item>
                                                    )
                                            }
                                        </React.Fragment>
                                    }

                                    {filteredCountryList.length === 0 && <p className='text-sm text-gray-500 dark:text-gray-400 px-5 py-2'>No country found</p>}
                                </div>
                            </Menu.Items>
                        </PopoverWithFloat>
                    )
                }
            </Menu>

            <input
                ref={numberInputRef}
                type="tel"
                value={numberInput}
                onChange={(e) => {
                    const regexTest = /^[+]?[0-9 ]*$/;
                    let value = e.target.value;

                    if (e.target.value === '' || regexTest.test(value)) {
                        if (value && value.charAt(0) !== '+') {
                            value = `+${value}`;
                        }

                        handleSettingCountry(value);

                        setNumberInput(new AsYouType().input(value));
                        if (onChange) {
                            //const phoneNumber = parsePhoneNumber(numberInput);
                            onChange(value.replace(/\s/g, ''));
                        }
                    }
                }}
                onBlur={handleBlur}
                onFocus={handleFocus}
                className='flex-grow bg-transparent'
                {...otherInputProps}
            />
        </div>
    );
}




//interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur' | 'onFocus' | 'value'> {
    value?: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    onFocus?: () => void;
}


export default PhoneNumberInput;


/* const data = countryList.reduce((r: any, e) => {
    // get first letter of name of current element
    let group = e.label[0];
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, countries: [e] }
    // if there is push current element to children array for that letter
    else r[group].countries.push(e);
    // return accumulator
    return r;
}, {}); */