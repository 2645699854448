import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import canteenServiceProvidersService from '../../../../../../../../services/api/canteen/canteenServiceProvidersService';
import Modal from '../../../../../../../../components/modals/Modal';
import { MultiSelectInput, SelectInput } from '../../../../../../../../components/form';
import { Button } from '../../../../../../../../components/common';
import { Switch } from '@headlessui/react';


function ModalCanteenServiceProviderConfigurations({ isOpen, school, serviceProvider, onConfigurationsChanged, onClose }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });

    const { configuration } = serviceProvider;


    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        const payload: any = { ...formData };
        payload.paymentMethods = payload.paymentMethods.map((item: any) => item.type);

        setSubmitStatus({ loading: true, failedMessage: '' });

        const result = await canteenServiceProvidersService.changeConfigurations(serviceProvider.id, payload, { schoolId: school.id });
        if (result.success) {
            if (onConfigurationsChanged) {
                onConfigurationsChanged({ ...serviceProvider, configuration: { ...result.data } });
            }
            resetForm();
            onClose();
            setSubmitStatus({ loading: false, failedMessage: '' });
        } else {
            setSubmitStatus({ loading: false, failedMessage: result.message });
        }
    }


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-screen max-w-lg">
                <div className="flex justify-between items-start mb-7 px-10 pt-8">
                    <div className='mr-5'>
                        <h2 className="font-medium text-xl mb-1">CSP Configurations</h2>
                        <p className='text-gray-500 dark:text-gray-400 mt-1'>Change configurations for <span className='text-blue font-semibold'>{serviceProvider && serviceProvider.name}</span></p>
                    </div>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            posType: configuration ? configuration.posType : '',
                            paymentMethods: configuration ? configuration.paymentMethods : [],
                            allowCashout: configuration ? configuration.allowCashout : false,
                            showItems: configuration ? configuration.showItems : false,
                            enableStudentSearch: (configuration && configuration.enableStudentSearch) || false,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }


                                <div className="mb-6">
                                    <label htmlFor="modal-pos-type" className='block text-sm mb-2'>POS Type</label>
                                    <SelectInput
                                        items={[
                                            { label: 'Redeem', value: 'REDEEM' },
                                            { label: 'Purchase', value: 'PURCHASE' },
                                            { label: 'Purchase/Redeem', value: 'PURCHASE_OR_REDEEM' },
                                        ]}
                                        placeholder="Select POS Type"
                                        value={values["posType"]}
                                        onChange={(value) => setFieldValue("posType", value.value)}
                                        onBlur={() => setFieldTouched('posType', true)}
                                    />
                                    {touched['posType'] && errors['posType'] && <p className='text-sm text-red-500 mt-2'>POS type is required</p>}
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="input-payment-types" className='block text-sm mb-2'>Payment Methods</label>
                                    <MultiSelectInput
                                        items={[
                                            { label: 'SikaID', type: 'sika_id' },
                                            { label: 'Bank Card', type: 'bank_card' },
                                            { label: 'Cash', type: 'cash' },
                                        ]}
                                        valueKey='type'
                                        placeholder="Select payment types"
                                        selectedLabel='Payment type'
                                        selectedLabelPlural='Payment types'
                                        searchable={false}
                                        //showSelectedItems={false}
                                        value={values["paymentMethods"]}
                                        onChange={(selected: any[]) => setFieldValue("paymentMethods", selected)}
                                        usePortal
                                    />
                                    {touched['paymentMethods'] && errors['paymentMethods'] && <p className='text-sm text-red-500 mt-2'>Select at least a payment methods</p>}
                                </div>

                                <div className='divide-y divide-gray-200 dark:divide-gray-700 mb-6'>
                                    <div className='py-5'>
                                        <div className='flex justify-between items-center gap-x-5 gap-y-1'>
                                            <div className=''>
                                                <p>Allow Cashout</p>
                                                <p className='text-gray-500 dark:text-gray-400'>CSP POS should allow cashout to students</p>
                                            </div>
                                            <Switch
                                                checked={values["allowCashout"]}
                                                onChange={(on: boolean) => setFieldValue("allowCashout", on)}
                                                className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                            >
                                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                            </Switch>
                                        </div>
                                    </div>


                                    <div className='py-5'>
                                        <div className='flex justify-between items-center gap-x-5 gap-y-1'>
                                            <div className=''>
                                                <p>Show Items</p>
                                                <p className='text-gray-500 dark:text-gray-400'>CSP POS should items for purchase</p>
                                            </div>
                                            <Switch
                                                checked={values["showItems"]}
                                                onChange={(on: boolean) => setFieldValue("showItems", on)}
                                                className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                            >
                                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                            </Switch>
                                        </div>
                                    </div>

                                    <div className='py-5'>
                                        <div className='flex justify-between items-center gap-x-5 gap-y-1'>
                                            <div className=''>
                                                <p>Enable Student Search</p>
                                                <p className='text-gray-500 dark:text-gray-400'>Allow POS operator to search of student on device</p>
                                            </div>

                                            <Switch
                                                checked={values["enableStudentSearch"]}
                                                onChange={(on: boolean) => setFieldValue("enableStudentSearch", on)}
                                                className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                            >
                                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                            </Switch>
                                        </div>
                                    </div>
                                </div>


                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    posType: Yup.string().trim().required().label(""),
    paymentMethods: Yup.array().min(1).required().label(""),
});


interface Props {
    isOpen: boolean;
    school: any;
    serviceProvider: any;
    onClose: () => void;
    onConfigurationsChanged?: (serviceProvider: any) => void;
}


export default ModalCanteenServiceProviderConfigurations;