import { createSlice } from "@reduxjs/toolkit";

import { RootState } from './../../index';
import { DispatchType } from "../..";
import userRolesService from "../../../../services/api/user-accounts/userRolesService";


const initialState = {
    list: {} as { [index: number]: any[] },
    loading: true,
    filters: null,
    pager: {
        pageSize: 0,
        page: 1,
        totalCount: 0
    },
    loadingError: false
};


const slice = createSlice({
    name: "userRoles",
    initialState: initialState,
    reducers: {
        userRolesRequested: (state, action) => {
            state.loading = true;
            state.filters = action.payload.filters;
            state.pager.page = action.payload.page;
            state.loadingError = false;
        },
        userRolesReceived: (state, action) => {
            state.list[action.payload.pager.page] = action.payload.data;
            state.pager = action.payload.pager;
            state.loading = false;
        },
        userRolesRequestFailed: (state, action) => {
            state.loadingError = true;
            state.loading = false;
        },
        updateRoleRecord: (state, action) => {
            const { role, page } = action.payload;

            const list = state.list[page];
            if (list) {
                const index = list.findIndex(roleItem => roleItem.id === role.id);
                list[index] = { ...role };

                state.list[page] = list;
            }
        },
        getCachedRoles: (state, action) => {
            state.pager.page = action.payload.page;
        },
        clearCachedRoles: (state, action) => {
            state.list = {};
        },
        resetRoles: (state, action) => initialState,
    }
});


const { userRolesRequested, getCachedRoles, userRolesRequestFailed, userRolesReceived, clearCachedRoles } = slice.actions;
export const { resetRoles, updateRoleRecord } = slice.actions;




// Actions
export const loadUserRoles = (payload: any) => async (dispatch: DispatchType, getState: () => RootState) => {
    const roles = getState().entities.userRoles;
    const sameFilters = JSON.stringify(payload.filters) === JSON.stringify(roles.filters);

    // load from cache when page is already loaded
    if (roles.list[payload.page] && sameFilters) {
        dispatch(getCachedRoles({
            page: payload.page
        }));

        return;
    }


    if (!sameFilters) {
        dispatch(clearCachedRoles({}));
    }


    dispatch(userRolesRequested(payload));
    const result = await userRolesService.getRoles({
        ...payload.filters,
        page: payload.page,
        pageSize: 10,
        direction: 'desc',
        properties: 'id',
    });

    if (result.success) {
        dispatch(userRolesReceived(result));
    } else {
        dispatch(userRolesRequestFailed({}));
    }
};




// Selectors
export const selectUserRoles = (state: RootState): ListSelectorType => {
    const roles = { ...state.entities.userRoles };
    roles.list = roles.list[state.entities.userRoles.pager.page] || [];

    return roles as ListSelectorType;
};


type ListSelectorType = Omit<typeof initialState, 'list'> & {
    list: any[];
}


export default slice.reducer;