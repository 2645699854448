const envBaseURLs = {
    /* development: {
        attendance: "https://dev-attendance.minex360-staging.com",
        unifiedApi: "https://dev-unified-api.minex360-staging.com",
        authApi: "https://dev-auth.minex360-staging.com",
        canteenApi: "https://dev-canteen-v2.minex360-staging.com",
        notificationApi: "https://dev-notifications.minex360-staging.com",
        elearningApi: "https://dev-elearning.minex360-staging.com",
        paymentsApi: "https://dev-smartpay.minex360-staging.com",
        paroApi: "https://dev-paro.minex360-staging.com",
        sentryDSN: "https://87e0f2fe756d48a2b777225a52aa2342@sentry.smartsapp.com/22",
    }, */
    development: {
        attendance: "https://attendance.smartsapp-staging.com",
        unifiedApi: "https://unified-api.smartsapp-staging.com",
        authApi: "https://auth.smartsapp-staging.com",
        canteenApi: "https://canteen-v2.smartsapp-staging.com",
        notificationApi: "https://notifications.smartsapp-staging.com",
        elearningApi: "https://elearning.smartsapp-staging.com",
        paymentsApi: "https://smartpay.smartsapp-staging.com",
        paroApi: "https://paro.smartsapp-staging.com",
        sentryDSN: "https://87e0f2fe756d48a2b777225a52aa2342@sentry.smartsapp.com/22",
    },
    staging: {
        attendance: "https://attendance.smartsapp-staging.com",
        unifiedApi: "https://unified-api.smartsapp-staging.com",
        authApi: "https://auth.smartsapp-staging.com",
        canteenApi: "https://canteen-v2.smartsapp-staging.com",
        notificationApi: "https://notifications.smartsapp-staging.com",
        elearningApi: "https://elearning.smartsapp-staging.com",
        paymentsApi: "https://smartpay.smartsapp-staging.com",
        paroApi: "https://paro.smartsapp-staging.com",
        sentryDSN: "https://87e0f2fe756d48a2b777225a52aa2342@sentry.smartsapp.com/22",
    },
    production: {
        attendance: "https://prod-attendance.smartsapp.com",
        unifiedApi: "https://prod-unified-api.smartsapp.com",
        authApi: "https://prod-auth.smartsapp.com",
        canteenApi: "https://prod-canteen-v2.smartsapp.com",
        notificationApi: "https://prod-notifications.smartsapp.com",
        elearningApi: "https://prod-elearning.smartsapp.com",
        paymentsApi: "https://prod-smartpay.smartsapp.com",
        paroApi: "https://prod-paro.smartsapp.com",
        sentryDSN: "https://feed6d2fafbd483f85ff7abb77ba02d8@sentry.smartsapp.com/25",
    },
    'aws-prod': {
        attendance: "https://attendance.smartsapp.com",
        unifiedApi: "https://unified-api.smartsapp.com",
        authApi: "https://auth.smartsapp.com",
        canteenApi: "https://canteen-v2.smartsapp.com",
        notificationApi: "https://notifications.smartsapp.com",
        elearningApi: "https://elearning.smartsapp.com",
        paymentsApi: "https://smartpay.smartsapp.com",
        paroApi: "https://paro.smartsapp.com",
        sentryDSN: "https://feed6d2fafbd483f85ff7abb77ba02d8@sentry.smartsapp.com/25",
    }
};


export default envBaseURLs[process.env.REACT_APP_ENV_NAME as keyof typeof envBaseURLs];