import React from 'react';


function ErrorLoading({ title, message, className = '', showIcon = true, onTryAgain }: Props) {
    return (
        <div className={`text-center ${className}`}>
            <div className='max-w-xs mx-auto'>
                {showIcon && <img src="/img/svgs/server-error.svg" alt="" className='inline-block w-20 dark:opacity-50 mb-5' />}
                <p className='text-xl mb-1'>{title || 'Error'}</p>
                <p className='text-gray-500 dark:text-gray-400 mb-4'>{message}</p>
                {
                    onTryAgain &&
                    <button type='button' onClick={onTryAgain} className='text-blue-500 hover:underline'>Try Again</button>
                }
            </div>
        </div>
    );
}


interface Props extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    title?: string;
    message: string;
    showIcon?: boolean;
    onTryAgain?: () => void;
}


export default ErrorLoading;