import React from 'react';
import { Float, FloatProps } from '@headlessui-float/react';


function PopoverWithFloat(props: Props) {
    return (
        <Float
            //placement="bottom-end"
            offset={6}
            shift={6}
            flip={10}
            //portal
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            tailwindcssOriginClass
            {...props}
        >
            {props.children}
        </Float>
    );
}


interface Props extends FloatProps {

}


export default PopoverWithFloat;