import React from 'react';
import { format } from 'date-fns';

import Modal from '../../../../../components/modals/Modal';
import { Button } from '../../../../../components/common';
import regexStrings from '../../../../../config/regexStrings';


function ModalAppFeedDetails({ isOpen, feed, viewMode = "details", onClose }: Props) {

    const getYouTubeVideoId = (url: string) => {
        //const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11) ? match[2] : null;
    }


    return (
        <Modal open={isOpen} onClose={onClose}  >
            <div className="inline-block w-screen max-w-2xl">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">Feed {viewMode === "preview" ? 'Preview' : ''}</h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-500 focus:outline-none">
                        <i className="ri-close-line"></i>
                    </button>
                </div>


                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <div className='bg-gray-800 overflow-hidden border dark:border-transparent rounded-xl mb-6'>

                        {
                            feed?.mediaFiles?.length > 0 && feed?.mediaFiles[0].type === 'image' &&
                            <img src={feed.mediaFiles[0].url} className='block w-full aspect-[2/1] object-cover' alt="" />
                        }
                        {
                            feed?.mediaFiles?.length > 0 && feed?.mediaFiles[0].type === 'video' &&
                            <div className='aspect-video'>
                                {
                                    regexStrings.youTubeUrl.test(feed?.mediaFiles[0].url) ?
                                        <iframe title={feed.title} src={`https://www.youtube.com/embed/${getYouTubeVideoId(feed?.mediaFiles[0].url)}?modestbranding=1&rel=0color=#000`} className='w-full h-full' frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={false}></iframe> :
                                        <video src={feed?.mediaFiles[0].url} className='w-full h-full' controls disablePictureInPicture disableRemotePlayback></video>
                                }
                            </div>
                        }
                        {
                            feed?.mediaFiles?.length === 0 &&
                            <div className='flex justify-center items-center aspect-[4/1]'>
                                <span className='font-medium text-xl text-gray-500 dark:text-gray-400'>No Media</span>
                            </div>
                        }
                    </div>


                    <div className='mb-3'>
                        <h3 className='font-semibold text-xl w-11/12 mb-2'>{feed.title}</h3>
                        <p className='text-gray-500 dark:text-gray-400'>{feed.body}</p>
                    </div>

                    <div className='flex flex-wrap gap-2.5 mb-8'>
                        <span className='inline-block text-sm font-medium bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>
                            {feed.category}
                        </span>
                    </div>

                    <div className='mb-5'>
                        <p className='mb-1'>Sent to:</p>
                        <div className='max-h-44 flex flex-wrap gap-2.5 bg-gray-50 dark:bg-gray-900 border dark:border-gray-800 rounded-xl overflow-auto px-2.5 py-3'>
                            {
                                feed.groups && feed.groups.length > 0 &&
                                <React.Fragment>
                                    {
                                        feed.groups.map((groupItem: any) =>
                                            <span key={groupItem.id} className='text-sm text-gray-500 dark:text-gray-300 bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>{groupItem.name}</span>
                                        )
                                    }
                                </React.Fragment>
                            }
                            {
                                feed.groups && feed.groups.length === 0 &&
                                <span className='text-sm text-gray-500 dark:text-gray-300 bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>Everyone</span>
                            }
                        </div>
                    </div>


                    {
                        feed.createdAt && feed.updatedAt &&
                        <div className='text-sm flex justify-between items-center flex-wrap gap-1 text-gray-500 dark:text-gray-400 mb-8'>
                            {feed.createdAt && <span className='mr-3'>Created at: {format(new Date(feed.createdAt), 'd LLL yyyy h:mm a')}</span>}
                            {feed.updatedAt && <span className=''>Updated at: {format(new Date(feed.updatedAt), 'd LLL yyyy h:mm a')}</span>}
                        </div>
                    }

                    <div className='flex justify-end items-center'>
                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


interface Props {
    isOpen: boolean;
    onClose: () => void;
    feed: any;
    viewMode?: "details" | "preview";
}


export default ModalAppFeedDetails;