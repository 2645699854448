import React from 'react';

function ListNoData({ title, description, children, showIcon = true, className = '' }: Props) {
    return (
        <div className={`text-center ${className}`}>
            <div className='max-w-md mx-auto'>
                {showIcon && <img src="/img/svgs/no-data.svg" alt="" className='inline-block w-14 dark:opacity-50 mb-4' />}
                <p className='text-xl mb-1'>{title}</p>
                <p className='text-gray-500 dark:text-gray-400 mb-6'>{description}</p>

                {children}
            </div>
        </div>
    );
}


interface Props extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    showIcon?: boolean;
    description: string;
}


export default ListNoData;