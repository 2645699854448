import React, { LegacyRef, useState } from 'react';
import { Field } from 'formik';



export const PasswordInput = React.forwardRef(({ className, ...otherProps }: Props, ref: LegacyRef<HTMLInputElement>) => {
    const [inputTypePassword, setInputTypePassword] = useState(true);

    return (
        <div className='relative'>
            <input
                ref={ref}
                type={inputTypePassword ? 'password' : 'text'}
                className={`w-full bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 focus:border-blue-500/60 dark:focus:border-blue-500/60 focus:outline-none rounded-lg py-2.5 pl-4 !pr-9 ${className}`}
                {...otherProps}
            />
            <button type='button' onClick={() => setInputTypePassword(prevState => !prevState)} className='text-lg absolute top-1/2 right-2.5 transform -translate-y-1/2 opacity-50 hover:opacity-70'>
                {inputTypePassword ? <i className="ri-eye-line"></i> : <i className="ri-eye-off-line"></i>}
            </button>
        </div>
    );
})



export function FormPasswordInput({ className, ...otherProps }: Props) {
    const [inputTypePassword, setInputTypePassword] = useState(true);

    return (
        <div className='relative'>
            <Field
                type={inputTypePassword ? 'password' : 'text'}
                className={`bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 focus:border-blue-500/60 dark:focus:border-blue-500/60 focus:outline-none rounded-lg py-2.5 pl-4 !pr-9 ${className}`}
                {...otherProps}
            />
            <button type='button' onClick={() => setInputTypePassword(prevState => !prevState)} className='text-lg absolute top-1/2 right-2.5 transform -translate-y-1/2 opacity-50 hover:opacity-70'>
                {inputTypePassword ? <i className="ri-eye-line"></i> : <i className="ri-eye-off-line"></i>}
            </button>
        </div>
    );
}



interface Props extends React.InputHTMLAttributes<HTMLInputElement> { }