import React, { useEffect, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import { ListLoader } from '../../../../../../../../../components/loaders';
import { ErrorLoading } from '../../../../../../../../../components/common';
import configurationService from '../../../../../../../../../services/api/payments/configurationService';
import ModalRequirePaymentAuthorizationForm from '../../modals/ModalRequirePaymentAuthorizationForm';
import useToast from '../../../../../../../../../state/hooks/useToast';
import { Menu } from '@headlessui/react';
import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import ModalAllowNegativeTransactions from '../../modals/ModalAllowNegativeTransactions';
import ModalMaximumNegativeTransactionThresholdForm from '../../modals/ModalMaximumNegativeTransactionThresholdForm';


function SchoolPaymentConfigurationsPage() {
    const { profile, paymentClient }: any = useOutletContext();

    const [pageStatus, setPageStatus] = useState({ loading: false, error: false, notFound: false });
    const [configuration, setConfiguration] = useState<any>(null);
    const [requireAuthorizationForm, setRequireAuthorizationForm] = useState(false);

    const [allowNegativeTransactionForm, setAllowNegativeTransactionForm] = useState(false);
    const [maxNegativeTransactionForm, setMaxNegativeTransactionForm] = useState(false);

    const { addToast } = useToast();


    useEffect(() => {
        getConfiguration();
    }, [paymentClient]);


    const getConfiguration = async () => {
        if (paymentClient.client) {
            if (!configuration) {
                setPageStatus({ loading: true, error: false, notFound: false });
                const result = await configurationService.getConfiguration(paymentClient.client.id);
                if (result?.success) {
                    setConfiguration(result.data);
                    setPageStatus({ loading: false, error: false, notFound: false });
                } else {
                    if (result.notFound) {
                        setPageStatus({ loading: false, error: true, notFound: true });
                    } else {
                        setPageStatus({ loading: false, error: true, notFound: false });
                    }
                }
            }
        } else {
            setPageStatus({ loading: paymentClient.loading, error: paymentClient.error, notFound: paymentClient.notFound, })
        }
    }


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            <div className='flex justify-between items-center flex-wrap gap-2 mb-6'>
                <h2 className='font-medium text-xl mr-4'>Payments Configurations</h2>

                <Menu as="div" className="relative inline-block text-left">
                    <PopoverWithFloat placement='bottom-end' portal>
                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                            <i className="ri-more-2-fill"></i>
                        </Menu.Button>

                        <Menu.Items as='div' unmount={false} className="w-72 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                            <Menu.Item as="button" type='button' onClick={() => setAllowNegativeTransactionForm(true)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                Allow Negative Transactions
                            </Menu.Item>

                            <Menu.Item as="button" type='button' onClick={() => setMaxNegativeTransactionForm(true)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                Set Max Negative Transactions Threshold
                            </Menu.Item>
                        </Menu.Items>
                    </PopoverWithFloat>
                </Menu>
            </div>

            {
                !pageStatus.loading && !pageStatus.error && configuration &&
                <div className='divide-y dark:divide-gray-700'>
                    <div>

                        <div className='pt-4 pb-6'>
                            <h3 className='text-lg font-medium mb-4'>Thresholds</h3>

                            <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                                <div className='group'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Daily Transaction Limit</p>
                                    <p>GHS {configuration.dailyTransactionAmountThreshold.toFixed(2)}</p>
                                </div>
                                <div className=''>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Daily Top Up Limit</p>
                                    <p>GHS {configuration.dailyTopUpAmountThreshold.toFixed(2)}</p>
                                </div>
                                <div className=''>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Daily Withdrawal Limit</p>
                                    <p>GHS {configuration.dailyCashWithdrawalThreshold.toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className='py-6'>
                        <div className='flex flex-wrap gap-x-3 gap-y-1 mb-4'>
                            <h3 className='text-lg font-medium'>Require Payment Authorization</h3>
                            <button type='button' onClick={() => setRequireAuthorizationForm(true)} className='text-blue hover:underline'>Change</button>
                        </div>
                        <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                            <p className='flex items-center gap-x-2'>
                                Turned
                                {configuration.paymentAuthorizationRequired && <span className='inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-2'>On</span>}
                                {!configuration.paymentAuthorizationRequired && <span className='inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-2'>Off</span>}
                                {(!configuration?.paymentAuthorizationRequiredDirectoryIds || configuration?.paymentAuthorizationRequiredDirectoryIds?.length === 0) && "For Every student"}
                                {configuration?.paymentAuthorizationRequiredDirectoryIds?.length === 1 && "For 1 Campus"}
                                {configuration?.paymentAuthorizationRequiredDirectoryIds?.length > 1 && `${configuration?.paymentAuthorizationRequiredDirectoryIds.length} For Campuses`}
                            </p>

                            {/*  <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>For the School</p>
                                <p className='flex items-center gap-x-2'>
                                    Turned
                                    {configuration.paymentAuthorizationRequired && <span className='inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-2'>On</span>}
                                    {!configuration.paymentAuthorizationRequired && <span className='inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-2'>Off</span>}
                                </p>
                            </div> */}
                            {/*  <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Campuses</p>
                                <p>{profile?.configuration?.amPm ? '12 Hour' : '24 Hour'}</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            }

            {
                // if it's loading
                pageStatus.loading &&
                <ListLoader loadingText='Loading Configuration' className='px-8 py-24' />
            }

            {
                // if configuration is not found
                !pageStatus.loading && pageStatus.error && pageStatus.notFound &&
                <ErrorLoading title='Configuration Not Found' message='No configuration exist for this school' className='px-8 py-24' />
            }

            {
                // if there's an error loading
                !pageStatus.loading && pageStatus.error && !pageStatus.notFound &&
                <ErrorLoading title='Error Loading Configuration' message='An unexpected error occurred while loading configuration' className='px-8 py-24' onTryAgain={getConfiguration} />
            }
            {
                configuration &&
                <ModalRequirePaymentAuthorizationForm
                    isOpen={requireAuthorizationForm}
                    school={profile}
                    client={paymentClient.client}
                    paymentConfig={configuration}
                    onRequirementChanged={(config) => {
                        setConfiguration((prevState: any) => ({ ...prevState, ...config }));
                        addToast({
                            text: "Payment Authorization Requirement successfully updated",
                            type: "success"
                        });
                    }}
                    onClose={() => setRequireAuthorizationForm(false)}
                />
            }


            <ModalAllowNegativeTransactions
                isOpen={allowNegativeTransactionForm}
                school={profile}
                client={paymentClient.client}
                //paymentConfig={configuration}
                onRequirementChanged={(config) => {
                    setConfiguration((prevState: any) => ({ ...prevState, ...config }));
                    addToast({
                        text: "Allow negative transactions successfully updated",
                        type: "success"
                    });
                }}
                onClose={() => setAllowNegativeTransactionForm(false)}
            />


            <ModalMaximumNegativeTransactionThresholdForm
                isOpen={maxNegativeTransactionForm}
                school={profile}
                client={paymentClient.client}
                //paymentConfig={configuration}
                onRequirementChanged={(config) => {
                    setConfiguration((prevState: any) => ({ ...prevState, ...config }));
                    addToast({
                        text: "Maximum negative transaction threshold successfully updated",
                        type: "success"
                    });
                }}
                onClose={() => setMaxNegativeTransactionForm(false)}
            />
        </div>
    );
}

export default SchoolPaymentConfigurationsPage;