import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { debounce } from 'lodash';

import { SelectInput } from '../../../../../../components/form';
import schoolService from '../../../../../../services/api/unified-api/schoolService';


function SettlementAccountsListFilters({ initialData, onSearch, searching }: Props) {
    //const [schoolList, setSchoolList] = useState<any[]>([{ id: '', name: "All schools selected" }]);
    const [schoolListData, setSchoolListData] = useState({ loading: false, list: [{ id: '', name: "All schools" }] });

    //const initial = useIsFirstRender();

    const handleSubmit = (values: any, { reset }: any) => {
        onSearch(values);
    }


    const getSchools = debounce(async (searchQuery: string) => {
        setSchoolListData({ loading: true, list: [] });
        const result = await schoolService.getSchools({ name: searchQuery });
        if (result.success) {
            setSchoolListData({ loading: false, list: [{ id: '', name: "All schools" }, ...result.data] });
        } else {
            setSchoolListData({ loading: false, list: [{ id: '', name: "All schools" }] });
        }
    }, 500)



    /* useEffect(() => {
        if (initial) {
            getSchools();
        }
    }, []); */


    /* const getSchools = useCallback(async () => {
        let schools: any[] = [];
        let totalItems = 0;
        let totalLoaded = 0;
        let page = 1;

        do {
            const result = await schoolService.getSchools({
                page: page,
                pageSize: 10,
                direction: 'desc',
                sortProperties: 'id',
            });
            if (result.success) {
                totalItems = result.pager.totalCount;
                totalLoaded += 10;
                page++;

                schools = [...schools, ...result.data];
            }
        } while (totalLoaded < totalItems);

        setSchoolList((prevState) => ([...prevState, ...schools]));
        //setSchoolList([{ id: 0, name: "All schools selected" }, ...schools]);
    }, []); */

    /*  const getGroups = debounce(async (searchQuery: string) => {
         setGroupListData({ loading: true, list: [] });
         const result = await feedGroupsService.getGroups({ pageSize: 20, findFeedGroups: true, name: searchQuery });
         if (result.success) {
             setGroupListData({ loading: false, list: result.data });
         } else {
             setGroupListData({ loading: false, list: [] });
         }
     }, 500); */


    return (
        <Formik
            initialValues={{
                school: initialData['school'] || '',
            }}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, setErrors, submitCount, isValid, values, setFieldValue }) => (
                <Form className="flex-grow flex items-center mr-8 mb-4">
                    <div className='mr-6'>
                        <SelectInput
                            items={schoolListData.list}
                            labelKey='name'
                            valueKey='id'
                            searchable={true}
                            placeholder="Select school"
                            value={values["school"]}
                            onChange={(school: any) => setFieldValue("school", school.id)}
                            onSearch={(query) => getSchools(query)}
                            searching={schoolListData.loading}
                        />
                    </div>
                    <button type='submit' className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}>Search</button>
                </Form>
            )}
        </Formik>
    );
}


interface Props {
    initialData: any;
    onSearch: (values: any) => void;
    searching: boolean;
}


export default SettlementAccountsListFilters;