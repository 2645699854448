import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';

import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
import { ErrorLoading, LinkButton, ListNoData, Pagination } from '../../../../../components/common';
import { ListLoader } from '../../../../../components/loaders';
import { paginationInfo } from '../../../../../utils/pagination';
import useToast from '../../../../../state/hooks/useToast';
import ModalAppFeedDetails from '../modals/ModalAppFeedDetails';
import appFeedService from '../../../../../services/api/paro/appFeedService';
import { Menu, RadioGroup } from '@headlessui/react';
//import { loadList, resetList, selectList } from '../../../../../state/store/reducers/entities/listPageSlice';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import useAlert from '../../../../../state/hooks/useAlert';
import AppFeedListFilters from './components/AppFeedListFilters';
import usePagedListApi from '../../../../../state/hooks/usePagedListApi';


function AppFeedListPage() {
    const dispatch = useDispatch();
    //const feeds = useSelector(selectList);
    const { data: feeds, requestData, resetRecords } = usePagedListApi(appFeedService.getFeeds);
    const [searchParams, setSearchParams] = useSearchParams();

    const [feedDetails, setFeedDetails] = useState({ open: false, feed: null });
    const [viewMode, setViewMode] = useState<'list' | 'card'>('list');

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    // get query params
    //const page = Number(searchParams.get('page')) || feeds.pager.page;
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};
    if (searchParams.get('title')) { searchFilters['title'] = searchParams.get('title') || '' }
    if (searchParams.get('category')) { searchFilters['category'] = searchParams.get('category') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "App Feeds" }));

        /* return () => {
            dispatch(resetList({}));
        } */
    }, []);


    useEffect(() => {
        getFeeds();
    }, [searchParams]);


    const getFeeds = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                '$sort[updatedAt]': -1,
            },
        });
        /* await dispatch<any>(loadList({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                '$sort[createdAt]': -1,
            },
        }, appFeedService.getFeeds)); */
    }



    const handleDelete = async (feed: any) => {
        openAlertConfirm({
            title: 'Delete Feed',
            message: <div>
                <p className='mb-4'>Are you sure you want to delete feed with title:</p>
                <p className=''>{feed.title}</p>
            </div>,
            onConfirmed: () => deleteFeed(feed),
            positiveButtonText: "Delete",
            negativeButtonText: 'Cancel',
            positiveButtonClass: 'bg-red-500 hover:bg-red-600',
        });
    }


    const deleteFeed = async (feed: any) => {
        const result = await appFeedService.deleteFeed(feed.id);
        if (result.success) {
            //dispatch(resetList({}));
            resetRecords();
            getFeeds();
            addToast({
                text: "Feed deleted successfully",
                type: "success"
            });
        } else {
            addToast({
                title: "Error",
                text: "Feed could not be deleted",
                type: "error"
            });
        }
    }


    return (
        <div className=''>
            <div className='flex flex-row-reverse items-center flex-wrap justify-between mb-1'>
                <LinkButton to="/feeds/create" >Create Feed</LinkButton>

                <div className='flex-grow flex flex-wrap items-center flex-row-reverse gap-4'>
                    <AppFeedListFilters
                        initialData={searchFilters}
                        onSearch={(values) => {
                            //setSearchParams({ ...values });
                            setSearchParams(current => {
                                const entries = { ...Object.fromEntries(current.entries()), ...values };
                                if (entries.page) {
                                    entries.page = '1';
                                }
                                return entries;
                            });
                        }}
                        searching={feeds.loading}
                    />


                    <RadioGroup value={viewMode} onChange={setViewMode} className='inline-flex cursor-pointer rounded-md border dark:border-gray-700 overflow-hidden mr-3'>
                        <RadioGroup.Option value="list" className="">
                            <span className='inline-block transition-all ui-not-checked:opacity-60 ui-not-checked:hover:opacity-80 ui-checked:bg-gray-100 dark:ui-checked:bg-gray-800 ui-checked:text-blue px-2.5 py-1'>
                                <i className="ri-list-check"></i>
                            </span>
                        </RadioGroup.Option>
                        <RadioGroup.Option value="card">
                            <span className='inline-block transition-all ui-not-checked:opacity-60 ui-not-checked:hover:opacity-80 ui-checked:bg-gray-100 dark:ui-checked:bg-gray-800 ui-checked:text-blue px-2.5 py-1'>
                                <i className="ri-checkbox-blank-line"></i>
                            </span>
                        </RadioGroup.Option>
                    </RadioGroup>
                </div>
            </div>

            {
                // if there're feeds
                !feeds.loadingError && !feeds.loading && feeds.list?.length > 0 &&
                <React.Fragment>
                    {
                        viewMode === "list" &&
                        <div className='max-w-full overflow-x-auto mb-6'>
                            <table className="border-collapse table-auto w-full">
                                <thead>
                                    <tr className='text-left border-b-2 dark:border-gray-700'>
                                        <th className='py-6 pl-2 pr-5'>Media</th>
                                        <th className='py-6 px-5'>Title</th>
                                        <th className='py-6 px-5'>Body</th>
                                        <th className='py-6 px-5'>Category</th>
                                        <th className='py-6 px-5'>Recipients</th>
                                        <th className='whitespace-nowrap py-6 px-5'>Updated At</th>
                                        <th className='py-6 pl-5 pr-2'>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y dark:divide-gray-700/60">
                                    {
                                        feeds.list.map((feedItem: any) =>
                                            <tr key={feedItem?.id} className='group'>
                                                <td width={1} className='py-4 pl-2 pr-5'>
                                                    <button type='button' onClick={() => setFeedDetails({ open: true, feed: feedItem })} className='flex justify-center items-center border dark:border-transparent h-12 aspect-video rounded-md bg-gray-100 dark:bg-gray-700 overflow-hidden'>
                                                        {
                                                            feedItem?.mediaFiles?.length > 0 && feedItem?.mediaFiles[0].type === 'image' &&
                                                            <img src={feedItem?.mediaFiles[0].url} className='transition-transform duration-200 h-full w-full object-cover group-hover:scale-110' alt="" />
                                                        }
                                                        {
                                                            feedItem?.mediaFiles?.length > 0 && feedItem?.mediaFiles[0].type === 'video' &&
                                                            <span className='text-3xl text-gray-300 dark:text-gray-400'>
                                                                <i className="ri-play-circle-line"></i>
                                                            </span>
                                                        }
                                                        {
                                                            feedItem?.mediaFiles?.length === 0 &&
                                                            <span className='font-medium text-xs text-gray-500 dark:text-gray-400'>No Media</span>
                                                        }
                                                    </button>
                                                </td>
                                                <td className='align-top py-4 px-5'>
                                                    <span onClick={() => setFeedDetails({ open: true, feed: feedItem })} title={feedItem?.title} className='transition-colors duration-300 cursor-pointer w-52 text-left hover:text-blue line-clamp-2'>{feedItem?.title}</span>
                                                </td>
                                                <td className='align-top py-4 px-5'>
                                                    <span className='line-clamp-2' title={feedItem?.body}>{feedItem?.body}</span>
                                                </td>
                                                <td className='align-top py-4 px-5'>
                                                    <span className='inline-block text-sm bg-black/5 dark:bg-white/5 rounded-lg py-2 px-2.5'>
                                                        {feedItem?.category}
                                                    </span>
                                                </td>
                                                <td className='align-top py-4 px-5'>
                                                    <div className='flex gap-2'>
                                                        {
                                                            feedItem?.groups
                                                                ?.slice(0, 1)
                                                                .map((groupItem: any, groupItemIndex: number) =>
                                                                    <span key={groupItem.id} title={groupItem.name} className='max-w-[10rem] line-clamp-1 leading-6 text-sm bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>
                                                                        {groupItem.name}
                                                                    </span>
                                                                )
                                                        }
                                                        {
                                                            feedItem?.groups?.length > 1 &&
                                                            <span className='text-sm bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>
                                                                +{feedItem?.groups?.length - 1}
                                                            </span>
                                                        }
                                                        {
                                                            feedItem?.groups?.length === 0 &&
                                                            <span className=''>Everyone</span>
                                                        }
                                                    </div>
                                                </td>
                                                <td className='align-top py-4 px-5 whitespace-nowrap'>{format(new Date(feedItem?.updatedAt), 'd LLL yyyy h:mm a')}</td>
                                                <td className='pl-5 pr-2 text-right'>
                                                    <Menu as="div" className="relative inline-block text-left">
                                                        <PopoverWithFloat placement='bottom-end' portal>
                                                            <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                                <i className="ri-more-2-fill"></i>
                                                            </Menu.Button>

                                                            <Menu.Items as='div' unmount={false} className="w-40 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                                <Menu.Item as="button" type='button' onClick={() => setFeedDetails({ open: true, feed: feedItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                    View
                                                                </Menu.Item>
                                                                <Menu.Item as={Link} to={`/feeds/${feedItem?.id}`} state={{ feed: feedItem }} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                    Edit
                                                                </Menu.Item>
                                                                <Menu.Item as="button" type='button' onClick={() => handleDelete(feedItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                    Delete
                                                                </Menu.Item>
                                                            </Menu.Items>
                                                        </PopoverWithFloat>
                                                    </Menu>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    }

                    {
                        viewMode === "card" &&
                        <div className='grid sm:grid-col-2 md:grid-col-3 xl:grid-cols-4 gap-6 mt-6 mb-6'>
                            {
                                feeds.list.map((feedItem: any) =>
                                    <div key={feedItem?.id} className='group flex flex-col bg-white dark:bg-gray-800 shadow dark:shadow-none border dark:border-gray-800 overflow-hidden rounded-xl'>
                                        <div className='relative flex justify-center items-center aspect-[2/1] bg-gray-100 dark:bg-gray-700 overflow-hidden'>
                                            {
                                                feedItem?.mediaFiles?.length > 0 && feedItem?.mediaFiles[0].type === 'image' &&
                                                <React.Fragment>
                                                    <img src={feedItem?.mediaFiles[0].url} className='transition-transform duration-200 h-full w-full object-cover group-hover:scale-105' alt="" />
                                                    <span className='absolute inset-0 bg-black transition-opacity duration-300 opacity-10 group-hover:opacity-0'></span>
                                                </React.Fragment>
                                            }
                                            {
                                                feedItem?.mediaFiles?.length > 0 && feedItem?.mediaFiles[0].type === 'video' &&
                                                <span className='text-7xl text-gray-300 dark:text-gray-400'>
                                                    <i className="ri-play-circle-line"></i>
                                                </span>
                                            }
                                            {
                                                feedItem?.mediaFiles?.length === 0 &&
                                                <span className='font-medium text-xl text-gray-500 dark:text-gray-400'>No Media</span>
                                            }
                                        </div>
                                        <div className='flex-grow flex flex-col justify-between py-5'>
                                            <div className='flex justify-between pl-4 pr-2 mb-4'>
                                                <div className='w-10/12'>
                                                    <h3 onClick={() => setFeedDetails({ open: true, feed: feedItem })} title={feedItem?.title} className='font-semibold text-lg cursor-pointer transition-colors duration-300 hover:text-blue line-clamp-2 mb-1'>{feedItem?.title}</h3>
                                                    <p className='text-gray-500 dark:text-gray-400 line-clamp-3' title={feedItem?.body}>{feedItem?.body}</p>
                                                </div>

                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-7 h-7 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-72 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setFeedDetails({ open: true, feed: feedItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                View
                                                            </Menu.Item>
                                                            <Menu.Item as={Link} to={`/feeds/${feedItem?.id}`} state={{ feed: feedItem }} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => handleDelete(feedItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                Delete
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </div>



                                            <div className='px-4'>
                                                <div className='flex flex-wrap gap-2.5 mb-4'>
                                                    <span className='text-sm bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>
                                                        {feedItem?.category}
                                                    </span>
                                                </div>

                                                <div className='flex justify-between flex-wrap gap-1'>
                                                    <span className='text-sm text-gray-500 dark:text-gray-400'>Sent to  {feedItem?.groups && feedItem?.groups?.length > 0 ? `${feedItem?.groups?.length} Group${feedItem?.groups.length > 1 ? 's' : ''}` : 'Everyone'}</span>
                                                    <span className='text-sm text-gray-500 dark:text-gray-400'>{format(new Date(feedItem?.createdAt), 'd LLL yyyy h:mm a')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    }

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(feeds.pager.pageSize, feeds.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={feeds.pager.pageSize}
                            totalCount={feeds.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if feeds are loading
                !feeds.loadingError && feeds.loading &&
                <ListLoader loadingText='Loading Feeds' className='px-8 py-24' />
            }

            {
                // if there're no feeds in the app
                !feeds.loadingError && !feeds.loading && feeds.list.length === 0 && Object.keys(searchFilters).length === 0 && feeds.pager.totalCount === 0 &&
                <ListNoData title='No Feed Found' description='App is yet to send feeds' className='px-8 py-24'>
                    <LinkButton to="/feeds/create" >Create Feed</LinkButton>
                </ListNoData>
            }

            {
                // if there're no feeds for search
                !feeds.loadingError && !feeds.loading && feeds.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Feed Found' description='No feed was found for the selected scenarios' className='px-8 py-24' />
            }

            {
                !feeds.loadingError && !feeds.loading && feeds.list.length === 0 && feeds.pager.totalCount > 0 &&
                <ListNoData title='No Feed Found' description='No feed was found for this page' className='max px-8 py-24'>
                    <div className='flex justify-center flex-wrap gap-x-6 gap-y-2'>
                        <LinkButton to={`/feeds/page?=${page - 1}`} onClick={() => null} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                            Go to Previous Page
                        </LinkButton>

                        <LinkButton to="/feeds" onClick={() => null} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                            Go to First page
                        </LinkButton>
                    </div>
                </ListNoData>
            }

            {
                feeds.loadingError &&
                <ErrorLoading title='Error Loading Feeds' message='There was a problem loading feeds' className='px-8 py-24' onTryAgain={getFeeds} />
            }


            {
                feedDetails.feed &&
                <ModalAppFeedDetails
                    isOpen={feedDetails.open}
                    feed={feedDetails.feed}
                    onClose={() => setFeedDetails((prevState) => ({ ...prevState, open: false }))}
                />
            }
        </div>
    );
}

export default AppFeedListPage;