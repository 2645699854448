import { combineReducers } from "redux";

import sidebarReducer from "./sidebarSlice";
import themeReducer from "./themeSlice";
import headerReducer from "./headerSlice";
import toastReducer from "./toastSlice";
import alertReducer from "./alertSlice";
import mediaViewerReducer from "./mediaViewerSlice";


export default combineReducers({
    sidebar: sidebarReducer,
    theme: themeReducer,
    header: headerReducer,
    toast: toastReducer,
    alert: alertReducer,
    mediaViewer: mediaViewerReducer
});
