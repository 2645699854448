import React from 'react';
import { useDispatch } from 'react-redux';

import { MediaViewerActionProps, openViewer } from '../store/reducers/ui/mediaViewerSlice';


function useMediaViewer() {
    const dispatch = useDispatch();

    const openMediaViewer = (data: MediaViewerActionProps) => {
        dispatch(openViewer(data))
    }

    return { openMediaViewer };
}

export default useMediaViewer;