import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

import { ToastItemType } from "../../../../components/common/toast/ToastItem";


let lastId = 0;


const slice = createSlice({
    name: "toasts",
    initialState: {
        toasts: [] as ToastType[]
    },
    reducers: {
        showToast: (state, action: PayloadAction<ToastItemType>) => {
            state.toasts.push({
                id: ++lastId,
                ...action.payload,
            });
        },
        removeToast: (state, action: PayloadAction<number>) => {
            state.toasts = state.toasts.filter((toast: any) => toast.id !== action.payload);
        },
    }
});


export const { showToast, removeToast } = slice.actions;

// selectors
export const selectToasts = (state: RootState) => state.ui.toast.toasts;



export default slice.reducer;


interface ToastType extends ToastItemType {
    id: number;
}