import { combineReducers } from "redux";

import uiReducers from "./ui/uiSlice";
import entityReducers from "./entities/entitiesSlice";


export default combineReducers({
    entities: entityReducers,
    ui: uiReducers,
});
