import React, { useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import { format } from 'date-fns';

import AppVersionService from '../../../../../services/api/unified-api/AppVersionService';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import ModalParentAppVersionForm from '../modals/ModalParentAppVersionForm';

function AppVersionSection() {
    const [appVersions, setAppVersions] = useState<{ android: any; ios: any }>({ ios: null, android: null });
    const [updateVersionForm, setUpdateVersionForm] = useState({ open: false, platform: null });


    useEffect(() => {
        getAppVersions();
    }, []);


    const getAppVersions = async () => {
        if (!appVersions.ios) {
            const result = await AppVersionService.getParentAppVersion("ios");
            if (result.success) {
                setAppVersions(prevState => ({
                    ...prevState,
                    ios: {
                        key: 'ios',
                        platformName: 'iOS',
                        ...result.data
                    }
                }));
            }
        }

        if (!appVersions.android) {
            const result = await AppVersionService.getParentAppVersion("android");
            if (result.success) {
                setAppVersions(prevState => ({
                    ...prevState,
                    android: {
                        key: 'android',
                        platformName: 'Android',
                        ...result.data
                    }
                }));
            }
        }
    }


    if (!appVersions.android && !appVersions.ios) {
        return null;
    }


    return (
        <div className='mt-12'>
            <h2 className='font-medium text-xl border-b border-gray-300 dark:border-gray-700 pb-3.5 mb-2'>Parent App Versioning</h2>

            <div className='max-w-full overflow-x-auto mb-6'>
                <table className="border-collapse table-auto w-full">
                    <thead>
                        <tr className='text-left border-b-2 dark:border-gray-700'>
                            <th className='py-6 pr-6'>Platform</th>
                            <th className='py-6 px-6'>Previous</th>
                            <th className='py-6 px-6'>Current</th>
                            <th className='py-6 px-6'>Current Release Date</th>
                            <th className='py-6 px-6'>Release Notes</th>
                            <th className='py-6 px-6'>Date Created</th>
                            <th className='py-6 pl-6 text-right'>Action</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y dark:divide-gray-700/60">
                        {
                            Object.values(appVersions)
                                .filter(item => item !== null)
                                .map((versionItem: any) =>
                                    <tr key={versionItem?.id}>
                                        <td className='py-4 pr-6'>{versionItem?.platformName}</td>
                                        <td className='py-4 px-6'>{versionItem?.previous}</td>
                                        <td className='py-4 px-6 text-green-500 font-medium'>{versionItem?.current}</td>
                                        <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(versionItem?.currentReleaseDate), 'd LLL yyyy h:mm a')}</td>
                                        <td className='py-4 px-6 line-clamp-1' title={versionItem?.releaseNote}>{versionItem?.releaseNote}</td>
                                        <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(versionItem?.createdDate), 'd LLL yyyy h:mm a')}</td>
                                        <td className='py-4 pl-6 text-right'>
                                            <Menu as="div" className="relative inline-block text-left">
                                                <PopoverWithFloat placement='bottom-end' portal>
                                                    <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                        <i className="ri-more-2-fill"></i>
                                                    </Menu.Button>

                                                    <Menu.Items as='div' unmount={false} className="w-60 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                        <Menu.Item as="button" type='button' onClick={() => setUpdateVersionForm({ open: true, platform: versionItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                            Update Version
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </PopoverWithFloat>
                                            </Menu>
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>


            <ModalParentAppVersionForm
                isOpen={updateVersionForm.open}
                platform={updateVersionForm.platform}
                onClose={() => setUpdateVersionForm(prevState => ({ ...prevState, open: false }))}
                onVersionUpdated={(platform: any) => {
                    setAppVersions((prevState: any) => {
                        const currentState = { ...prevState };
                        currentState[platform.key as any] = platform;
                        return currentState;
                    });
                }}
            />
        </div>
    );
}

export default AppVersionSection;