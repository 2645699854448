import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';

import { changePageInfo } from '../../../../state/store/reducers/ui/headerSlice';
import appNotificationsService from '../../../../services/api/notifications/appNotificationsService';
import { paginationInfo } from '../../../../utils/pagination';
import { ErrorLoading, ListNoData, Pagination } from '../../../../components/common';
import { ListLoader } from '../../../../components/loaders';
import AppNotificationListFilters from './components/AppNotificationListFilters';
import usePagedListApi from '../../../../state/hooks/usePagedListApi';
//import { loadList, resetList, selectList } from '../../../../state/store/reducers/entities/listPageSlice';



function AppNotificationListPage() {
    const dispatch = useDispatch();
    //const notifications = useSelector(selectList);
    const { data: notifications, requestData } = usePagedListApi(appNotificationsService.getNotifications);

    const [searchParams, setSearchParams] = useSearchParams();

    // get query params
    const page = Number(searchParams.get('page')) || notifications.pager.page;
    const searchFilters: any = {};
    if (searchParams.get('schoolId')) { searchFilters['clientReferenceIds'] = searchParams.get('schoolId') || '' }
    if (searchParams.get('channel')) { searchFilters['channel'] = searchParams.get('channel') || '' }
    if (searchParams.get('service')) { searchFilters['service'] = searchParams.get('service') || '' }
    if (searchParams.get('deliveryStatus')) { searchFilters['deliveryStatus'] = searchParams.get('deliveryStatus') || '' }
    if (searchParams.get('recipient')) { searchFilters['recipient'] = searchParams.get('recipient') || '' }
    if (searchParams.get('fromDate')) { searchFilters['createdAt[$gt]'] = searchParams.get('fromDate') || '' }
    if (searchParams.get('toDate')) { searchFilters['createdAt[$lt]'] = searchParams.get('toDate') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "App Notifications" }));

        /* return () => {
            dispatch(resetList({}));
        } */
    }, []);


    useEffect(() => {
        getNotifications();
    }, [searchParams]);


    const getNotifications = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                origin: 'bo',
                '$sort[createdAt]': -1,
            },
        });
        /*  await dispatch<any>(loadList({
             page: page,
             filters: searchFilters,
             otherProperties: {
                 pageSize: 10,
                 origin: 'bo',
                 '$sort[createdAt]': -1,
             },
         }, appNotificationsService.getNotifications)); */
    };


    return (
        <div className=''>
            <div className='flex flex-wrap justify-between gap-y-4 mb-1'>
                <AppNotificationListFilters
                    initialData={searchFilters}
                    onSearch={(values) => {
                        //setSearchParams({ ...values });
                        setSearchParams(current => {
                            const entries = { ...Object.fromEntries(current.entries()), ...values };
                            if (entries.page) {
                                entries.page = '1';
                            }
                            return entries;
                        });
                    }}
                    searching={notifications.loading}
                />
            </div>

            {
                // if there're notifications
                !notifications.loadingError && !notifications.loading && notifications.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>Recipient</th>
                                    <th className='py-6 px-6'>Sender</th>
                                    <th className='py-6 px-6'>Service</th>
                                    <th className='py-6 px-6'>Channel</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Created At</th>
                                    <th className='py-6 px-6'>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    notifications.list.map((notificationItem: any) =>
                                        <tr key={notificationItem?.id}>
                                            <td className='py-4 px-6'>{notificationItem?.recipient}</td>
                                            <td className='py-4 px-6'>{notificationItem?.sender}</td>
                                            <td className='py-4 px-6'>{notificationItem?.service}</td>
                                            <td className='py-4 px-6'>{notificationItem?.channel}</td>
                                            <td className='py-4 px-6'>
                                                <span className={`inline-block font-medium text-sm uppercase ${notificationItem?.deliveryStatus === "SUCCESSFUL" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>
                                                    {notificationItem?.deliveryStatus}
                                                </span>
                                            </td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(notificationItem?.createdAt), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(notificationItem?.timestamp), 'd LLL yyyy h:mm a')}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(notifications.pager.pageSize, notifications.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={notifications.pager.pageSize}
                            totalCount={notifications.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if notifications are loading
                !notifications.loadingError && notifications.loading &&
                <ListLoader loadingText='Loading Notifications' className='px-8 py-24' />
            }

            {
                // if there're no notifications in the app
                !notifications.loadingError && !notifications.loading && notifications.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Notification Found' description='App is yet to send notifications' className='px-8 py-24'>
                </ListNoData>
            }

            {
                // if there're no notifications in the app
                !notifications.loadingError && !notifications.loading && notifications.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Notification Found' description='No notification was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                notifications.loadingError &&
                <ErrorLoading title='Error Loading Notifications' message='There was a problem loading notifications' className='px-8 py-24' onTryAgain={getNotifications} />
            }
        </div>
    );
}

export default AppNotificationListPage;