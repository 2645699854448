import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Avatar, ListNoData } from '../../../../../../../../components/common';
import ModalDelegateDetails from '../../../../../users/modals/ModalDelegateDetails';



function StudentDelegatesListPage() {
    const { profile, setProfile }: any = useOutletContext();
    const [delegateDetails, setDelegateDetails] = useState({ open: false, delegate: null });


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            {
                profile.delegates.length > 0 &&
                <div>
                    <div className='flex justify-between items-center flex-wrap gap-2 mb-4'>
                        <h2 className='font-medium text-xl mr-4'>Pickup Persons</h2>
                    </div>

                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b dark:border-gray-700'>
                                    <th className='py-4 pl-2 pr-4'>Name</th>
                                    <th className='py-4 px-4'>Relation</th>
                                    <th className='py-4 px-4'>Gender</th>
                                    <th className='whitespace-nowrap py-4 px-4'>Primary Contact</th>
                                    <th className='whitespace-nowrap py-4 px-4'>Created By</th>
                                    <th className='whitespace-nowrap py-4 px-4'>Type</th>
                                    <th className='py-4 pl-4 pr-2'>Status</th>
                                    {/* <th className='py-4 px-4 text-right'>Action</th> */}
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    profile.delegates.map((delegateItem: any) =>
                                        <tr key={delegateItem.id}>
                                            <td className='py-4 pl-2 pr-4'>
                                                <div className='flex items-center gap-2.5'>
                                                    {
                                                        delegateItem.relation === "SELF" ?
                                                            <div className='flex items-center gap-3'>
                                                                <Avatar name={delegateItem?.fullName} imageUrl={delegateItem?.avatar} size={2.6} className='' />
                                                                <span className='group-hover:text-blue transition-colors duration-300'>{delegateItem?.fullName}</span>
                                                            </div> :
                                                            <button type='button' onClick={() => setDelegateDetails({ open: true, delegate: delegateItem })} className='group flex items-center gap-3'>
                                                                <Avatar name={delegateItem?.fullName} imageUrl={delegateItem?.avatar} size={2.6} className='' />
                                                                <span className='group-hover:text-blue transition-colors duration-300'>{delegateItem?.fullName}</span>
                                                            </button>
                                                    }

                                                    {
                                                        delegateItem.primary &&
                                                        <span className={`inline-block font-medium text-sm uppercase bg-blue-500/10 text-blue-500 rounded-lg py-1 px-2.5`}>
                                                            Primary
                                                        </span>
                                                    }
                                                </div>
                                            </td>
                                            <td className='py-4 px-4'>{delegateItem.relation}</td>
                                            <td className='py-4 px-4'>{delegateItem.gender}</td>
                                            <td className='py-4 px-4'>{delegateItem.primaryContact}</td>
                                            <td className='py-4 px-4'>{delegateItem.createdBy}</td>
                                            <td className='py-4 px-4'>{delegateItem.delegateType}</td>
                                            <td className='py-4 pl-4 pr-2'>
                                                {
                                                    delegateItem.status === "ACTIVE" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-3 mr-3`}>{delegateItem.status}</span>
                                                }
                                                {
                                                    delegateItem.status !== "ACTIVE" && delegateItem.status !== "SUSPENDED" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-3 mr-3`}>{delegateItem.status}</span>
                                                }
                                                {
                                                    delegateItem.status === "SUSPENDED" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-1 px-3 mr-3`}>{delegateItem.status}</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }


            {
                profile.delegates.length === 0 &&
                <ListNoData title='No Parent Found' description='No delegate is assigned to student'>

                </ListNoData>
            }

            <ModalDelegateDetails
                isOpen={delegateDetails.open}
                delegate={delegateDetails.delegate}
                onClose={() => setDelegateDetails((prevState) => ({ ...prevState, open: false }))}
            />
        </div>
    );
}

export default StudentDelegatesListPage;