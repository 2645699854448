import { paymentsApi } from '../httpService';


const getDirectories = async (query: any) => {
    try {
        const result = await paymentsApi.get('/api/v1/directories', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



export default {
    getDirectories
}