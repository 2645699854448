import { useEffect } from 'react';


function useAutoLogout({ onLogout, getExpiredTime, onUpdateExpiredTime }: Props) {
    let timeoutTracker: any = null;


    useEffect(() => {
        const activityEvents = ["mousemove", "keydown", "scroll", "mousedown"];

        activityEvents.forEach((event) => {
            window.addEventListener(event, updateExpiredTime);
        });


        const intervalTracker = setInterval(() => {
            const expiredTime = parseInt(getExpiredTime() || '0', 10);

            if (expiredTime < Date.now()) {
                if (onLogout) {
                    onLogout();
                }
            }
        }, 1000);


        return () => {
            if (timeoutTracker) {
                clearTimeout(timeoutTracker);
            }

            if (intervalTracker) {
                clearInterval(intervalTracker);
            }

            activityEvents.forEach((event) => {
                window.removeEventListener(event, updateExpiredTime);
            });
        };
    }, []);


    const updateExpiredTime = () => {
        if (timeoutTracker) {
            clearTimeout(timeoutTracker);
        }

        timeoutTracker = setTimeout(onUpdateExpiredTime, 300);
    }


    return null;
}


interface Props {
    getExpiredTime: () => string;
    onUpdateExpiredTime: () => void;
    onLogout?: () => void;
}


export default useAutoLogout;