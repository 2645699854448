import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import SmartSappLogo from '../../../components/svgs/SmartSappLogo';
import { Button, LinkButton } from '../../../components/common';
import { FormInput, FormPasswordInput } from '../../../components/form';
import regexStrings from '../../../config/regexStrings';
import userAccountsService from '../../../services/api/user-accounts/userAccountsService';


function ResetPasswordPage() {
    const location = useLocation();

    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    const [resetSuccessFull, setResetSuccessFull] = useState(false);


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        const payload = { ...formData, signature: location.state?.signature };
        delete payload.repeatPassword;

        setSubmitStatus({ loading: true, failedMessage: '' });
        const result = await userAccountsService.changePassword(payload);

        if (result.success) {
            setResetSuccessFull(true);
            // navigate('/verify-account', { replace: true, state: { email: formData.email } });
        } else {
            setSubmitStatus({
                loading: false,
                failedMessage: result.message
            });
        }
    }


    return (
        <main className='login-page bg-blue/5 dark:bg-gray-900 bg-no-repeat min-h-screen px-8 py-12 lg:py-16 xl:py-24'>
            {
                !resetSuccessFull &&
                <div className='sm:max-w-lg bg-white dark:bg-gray-950 rounded-3xl mx-auto py-16 px-8 sm:px-10'>
                    <div className='text-center mb-8'>
                        <span className='inline-block w-8 text-blue-500 '>
                            <SmartSappLogo />
                        </span>

                        <p className='text-lg font-medium text-gray-500 dark:text-gray-400 mb-6'>SmartSapp</p>
                        <h1 className='font-medium text-2xl mb-2'>Create New Password</h1>
                        {
                            location.state &&
                            <React.Fragment>
                                <p className='text-center font-medium text-gray-500 dark:text-gray-400'>{location.state?.email}</p>
                                <p className='text-gray-500 dark:text-gray-400 mx-auto'>Enter your new password and repeat it</p>
                                {/*  <p className='text-gray-500 dark:text-gray-400 mx-auto'>Enter the verification code below</p> */}
                            </React.Fragment>
                        }

                        {
                            !location.state &&
                            <div className='bg-red-500/5 border border-red-500/20 text-red-500 rounded-xl py-6 px-5 mt-8'>
                                <p className='font-medium text-lg mb-1'>Invalid Account Provided</p>
                                <p className='text-gray-500 dark:text-gray-400 mx-auto'>A valid account is required to perform this action</p>
                            </div>
                        }
                    </div>

                    <div className='mb-7'>
                        {
                            location.state &&
                            <Formik
                                initialValues={{
                                    newPassword: '',
                                    repeatPassword: ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleFormSubmit}
                            >
                                {({ errors, touched, setErrors, setFieldValue, submitCount, values, isValid }) => (
                                    <Form className="" autoComplete="off">
                                        {
                                            (submitStatus.failedMessage) &&
                                            <p className='text-center text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                        }

                                        <div className="mb-2">
                                            <label htmlFor="input-password" className='block text-sm mb-2'>New Password</label>
                                            <input autoComplete="false" name="hidden" type="password" style={{ display: 'none' }} />
                                            <FormPasswordInput name="newPassword" id="input-password" className="w-full" placeholder="Enter password" autoComplete='off' autoFocus />
                                            {/* <FormInput type="password" name="newPassword" id="input-password" className="w-full" placeholder="Enter password" autoComplete='off' autoFocus /> */}
                                            {touched['newPassword'] && errors['newPassword'] && <p className='text-sm text-red-500 mt-2'>{errors['newPassword'] as string}</p>}
                                        </div>

                                        <div className='bg-gray-50 dark:bg-gray-900 rounded-xl px-4 py-3 mb-6'>
                                            {/*   <p className='text-sm font-medium mb-1'>Password</p> */}
                                            <ul className='text-sm text-gray-500 dark:text-gray-400'>
                                                <li className='flex items-center py-0.5'>
                                                    {/(?=.{8,})/.test(values["newPassword"]) ? <i className="ri-check-line text-green-500"></i> : <i className="ri-checkbox-blank-circle-fill text-[.4rem] leading-none mt-0.5"></i>}
                                                    <span className='ml-1.5'>Password is at least 8 characters long</span>
                                                </li>
                                                <li className='flex items-center py-0.5'>
                                                    {/(?=.*[A-Z])/.test(values["newPassword"]) ? <i className="ri-check-line text-green-500"></i> : <i className="ri-checkbox-blank-circle-fill text-[.4rem] leading-none mt-0.5"></i>}
                                                    <span className='ml-1.5'>Password has at least one uppercase letter</span>
                                                </li>
                                                <li className='flex items-center py-0.5'>
                                                    {/(?=.*[a-z])/.test(values["newPassword"]) ? <i className="ri-check-line text-green-500"></i> : <i className="ri-checkbox-blank-circle-fill text-[.4rem] leading-none mt-0.5"></i>}
                                                    <span className='ml-1.5'>Password has at least one lowercase letter</span>
                                                </li>
                                                <li className='flex items-center py-0.5'>
                                                    {/(?=.*[0-9])/.test(values["newPassword"]) ? <i className="ri-check-line text-green-500"></i> : <i className="ri-checkbox-blank-circle-fill text-[.4rem] leading-none mt-0.5"></i>}
                                                    <span className='ml-1.5'>Password has at least one digit</span>
                                                </li>
                                                <li className='flex items-center py-0.5'>
                                                    {/([^A-Za-z0-9])/.test(values["newPassword"]) ? <i className="ri-check-line text-green-500"></i> : <i className="ri-checkbox-blank-circle-fill text-[.4rem] leading-none mt-0.5"></i>}
                                                    <span className='ml-1.5'>Password has at least one special character</span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="mb-8">
                                            <label htmlFor="input-repeat-password" className='block text-sm mb-2'>Repeat Password</label>
                                            <FormInput type="password" name="repeatPassword" id="input-repeat-password" className="w-full" placeholder="Repeat password" autoComplete='off' />
                                            {touched['repeatPassword'] && errors['repeatPassword'] && <p className='text-sm text-red-500 mt-2'>Passwords do not match</p>}
                                        </div>

                                        <div className='mb-10'>
                                            <Button type='submit' loading={submitStatus.loading} className='w-full' disabled={submitStatus.loading}>
                                                Change Password
                                            </Button>
                                        </div>
                                    </Form>
                                )}

                            </Formik>
                        }
                    </div>


                    <div className='text-center'>
                        <Link to="/login" className='inline-flex justify-center items-center gap-2 text-gray-500 dark:text-gray-400 hover:text-blue dark:hover:text-blue'>
                            <span className='text-lg'><i className="ri-arrow-left-line"></i></span>
                            <span>Go to Login</span>
                        </Link>
                    </div>
                </div>
            }

            {
                resetSuccessFull &&
                <div className='sm:max-w-lg bg-white dark:bg-gray-950 text-center rounded-3xl mx-auto py-16 px-8 sm:px-10'>
                    <span className='inline-block w-8 text-blue-500 '>
                        <SmartSappLogo />
                    </span>

                    <p className='text-lg font-medium text-gray-500 dark:text-gray-400 mb-6'>SmartSapp</p>
                    <h1 className='font-medium text-2xl mb-2'>Password Changed</h1>
                    <p className='max-w-xs mx-auto text-center font-medium text-gray-500 dark:text-gray-400 mb-8'>Your password has been changed successfully</p>

                    <div className='max-w-xs mx-auto'>
                        <LinkButton to="/login" state={{ email: location.state?.email }} replace className='block'>Login Here</LinkButton>
                    </div>
                </div>
            }
        </main>
    );
}


const validationSchema = Yup.object().shape({
    newPassword: Yup.string().trim().required("Password is required").matches(regexStrings.passwordComplexity, "Password is weak").label(""),
    //repeatPassword: Yup.string().trim().required().label(""),
    repeatPassword: Yup.string().trim().oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
});

export default ResetPasswordPage;