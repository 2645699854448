import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from '@headlessui/react';

import { changePageInfo } from '../../../../state/store/reducers/ui/headerSlice';
import appConfigurationsService from '../../../../services/api/unified-api/appConfigurationsService';
import useToast from '../../../../state/hooks/useToast';
import useAlert from '../../../../state/hooks/useAlert';
import SecuritySection from './components/SecuritySection';
import AppVersionSection from './components/AppVersionSection';


function AppConfigurationsPage() {
    const dispatch = useDispatch();




    useEffect(() => {
        dispatch(changePageInfo({ title: 'App Configurations' }));

    }, []);





    return (
        <React.Fragment>
            <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
                <SecuritySection />
                <AppVersionSection />
            </div>
        </React.Fragment>
    );
}

export default AppConfigurationsPage;




<div className='py-4'>
    <h3 className='text-lg text-gray-500 dark:text-gray-400 mb-5'>Feature Activation</h3>
    <div>
        <div className='sm:flex justify-between items-center max-w-lg '>
            <div className='mr-5 mb-5 sm:mb-0'>
                <p className='mb-2'>Drop Off Denial</p>
                <p className='text-gray-500 dark:text-gray-400'>Feature allows schools to denial students during Drop Offs</p>
            </div>
            <div className='flex sm:flex-row-reverse justify-between items-center'>
                {/* <Switch
                checked={dropOffDenialFeature}
                onClick={() => {
                    if (dropOffDenialFeature) {
                        // disable
                        setAlertConfirmFeatureActivation({
                            open: true,
                            details: {
                                title: "Deactivate Drop Off Denial",
                                message: [
                                    `You are about to turn off drop-off denial feature for ${profile.name}?`,
                                    "Once turned off, the school will not see Drop-off denial on the dashboard or in management"
                                ],
                                buttonText: "Turn off Drop Off Denial"
                            },
                            callBack: () => handleFeatureActivation(false)
                        })
                    } else {
                        // enable
                        setAlertConfirmFeatureActivation({
                            open: true,
                            details: {
                                title: "Activate Drop Off Denial",
                                message: [
                                    `You are about to turn on drop-off denial feature for ${profile.name}?`,
                                    "Drop-off denial will show on their attendance dashboard and in management"
                                ],
                                buttonText: "Turn on Drop Off Denial"
                            },
                            callBack: () => handleFeatureActivation(true)
                        })
                    }
                }}
                onChange={(on: boolean) => {

                }}
                className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
            >
                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
            </Switch> */}

                {/*  {
                activationStatus.dropOffDenial.loading &&
                <div className='flex items-center sm:mr-4'>
                    <span className='inline-block w-4 h-4 mr-2'>
                        <Loader />
                    </span>
                    <span className='text-gray-500 dark:text-gray-400'>
                        {dropOffDenialFeature ? "Activating" : "Deactivating"} feature
                    </span>
                </div>
            }
            {
                !activationStatus.dropOffDenial.loading && activationStatus.dropOffDenial.error &&
                <p className='text-red-500 sm:mr-4'>
                    Failed to {dropOffDenialFeature ? "deactivate" : "activate"} feature
                </p>
            } */}
            </div>
        </div>
    </div>
</div>