import { AxiosError, InternalAxiosRequestConfig } from "axios";

import store from '../../../state/store';


export const onRequest = (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
    // get user token from store
    const { userToken } = store.getState().entities.auth;

    if (config.headers) {
        // something
        if (config.url?.includes('/operations') && config.method === 'post') {
            config.headers['X-Request-Platform'] = 'WEB';
        }

        // set user token
        if (userToken) {
            config.headers['Authorization'] = `Bearer ${userToken}`;
        }

        // other headers
        config.headers['Access-Control-Allow-Origin'] = '*';
        // value.headers['Content-Type'] = 'application/json';
    }

    return config;
}


export const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    //console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}