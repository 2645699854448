import { canteenApi } from '../httpService';


const getProviders = async (query: any) => {
    try {
        const result = await canteenApi.get(`service-providers`, {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const createServiceProvider = async (data: any, queryParams: any) => {
    try {
        const result = await canteenApi.post(`service-providers`, data, {
            params: queryParams
        });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating vendor. Please try again later."
        };
    }
}



const updateServiceProvider = async (serviceProviderId: number, data: any, queryParams: any) => {
    try {
        //const result = await canteenApi.put(`service-providers/${serviceProviderId}`, data);
        const result = await canteenApi.patch(`service-providers/${serviceProviderId}`, data, {
            params: queryParams,
        });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating vendor. Please try again later."
        };
    }
}

const changeConfigurations = async (serviceProviderId: number, data: any, query: {}) => {
    //https://dev-canteen-v2.minex360-staging.com/service-providers/78/configurations?schoolId=6
    try {
        const result = await canteenApi.patch(`service-providers/${serviceProviderId}/configurations`, data, {
            params: query
        });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating configurations for vendor. Please try again later."
        };
    }
}



const deleteProvider = async (serviceProviderId: number) => {
    try {
        const result = await canteenApi.delete(`service-providers/${serviceProviderId}`);

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}




export default {
    getProviders,
    createServiceProvider,
    updateServiceProvider,
    changeConfigurations,
    deleteProvider,
}