import React, { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Form, Formik } from 'formik';
import { debounce } from 'lodash';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { FormInput, MultiSelectInput, PhoneNumberInput, RadioGroupInput, SelectInput } from '../../../../../components/form';
import { Button, ErrorLoading } from '../../../../../components/common';
import schoolService from '../../../../../services/api/unified-api/schoolService';
import employeeService from '../../../../../services/api/unified-api/employeeService';
import userRolesService from '../../../../../services/api/user-accounts/userRolesService';
import usersPermissionsService from '../../../../../services/api/user-accounts/usersPermissionsService';
import userAccountsService from '../../../../../services/api/user-accounts/userAccountsService';
import { ListLoader } from '../../../../../components/loaders';


function ModalEmployeeAccountForm({ isOpen, employeeAccount, onClose, onEmployeeAccountCreated, onEmployeeAccountUpdated, onEmployeeCreatedNotAccount }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });

    const [optionsLoadingStatus, setOptionsLoadingStatus] = useState({ loading: false, error: false });

    const [schoolListData, setSchoolListData] = useState({ loading: false, list: [] as any[] });
    const [permissionsList, setPermissionsList] = useState([]);
    const [rolesList, setRolesList] = useState([]);


    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
            getOptions();
        }
    }, [isOpen]);



    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        const payload = { ...formData };

        setSubmitStatus({ loading: true, failedMessage: '' });
        const employeeResult = await employeeService.createEmployee(payload.schoolId, payload);
        if (employeeResult.success) {
            const accountPayload = {
                employeeId: employeeResult.data.id,
                schoolId: payload.schoolId,
                userType: "PORTAL_USER",
                username: payload.email,
                password: "DEFAULT_PASSWORD",
                roleId: employeeResult.data.role.id,
            };

            const accountResult = await userAccountsService.createAccount(accountPayload);
            if (accountResult.success) {
                if (onEmployeeAccountCreated) {
                    onEmployeeAccountCreated(accountResult.data);
                }
            } else {
                if (onEmployeeCreatedNotAccount) {
                    onEmployeeCreatedNotAccount(employeeResult.data);
                }
            }

            resetForm();
            onClose();
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
        else {
            setSubmitStatus({ loading: false, failedMessage: employeeResult.message });
        }
    };



    const getOptions = async () => {
        if (rolesList.length === 0) {
            await getRoles();
        }


        if (permissionsList.length === 0) {
            await getPermissions();
        }
    };


    const getRoles = async () => {
        setOptionsLoadingStatus({ loading: true, error: false });
        const rolesResult = await userRolesService.getRoles({});
        if (rolesResult.success) {
            setRolesList(rolesResult.data);
            setOptionsLoadingStatus({ loading: false, error: false });
        } else {
            setOptionsLoadingStatus({ loading: false, error: true });
        }
    }



    const getPermissions = async () => {
        const permissionsResult = await usersPermissionsService.getPermissions({});
        if (permissionsResult.success) {
            setPermissionsList(permissionsResult.data);
        } else {

        }
    }


    const getSchools = debounce(async (searchQuery: string) => {
        setSchoolListData({ loading: true, list: [] });
        const result = await schoolService.getSchools({ name: searchQuery });
        if (result.success) {
            setSchoolListData({ loading: false, list: [...result.data] });
        } else {
            setSchoolListData({ loading: false, list: [] });
        }
    }, 500);


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[34rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {employeeAccount ? "Edit" : "Create"} Employee Account
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                {
                    !optionsLoadingStatus.loading && !optionsLoadingStatus.error &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <Formik
                            initialValues={{
                                usernameType: 'email',
                                //userType: employeeAccount ? employeeAccount.userType : '',
                                firstName: employeeAccount ? employeeAccount.firstName : '',
                                lastName: employeeAccount ? employeeAccount.lastName : '',

                                email: employeeAccount ? employeeAccount.email : '',
                                primaryContact: employeeAccount ? employeeAccount.primaryContact : '',

                                role: employeeAccount ? employeeAccount.role.id : '',
                                specialPermissions: employeeAccount ? employeeAccount.specialPermissions : [],
                                schoolId: employeeAccount ? employeeAccount.schoolId : '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, errors, touched, setFieldError, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                                <Form className="" autoComplete="off" >
                                    {
                                        submitStatus.failedMessage && !submitStatus.loading &&
                                        <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                    }

                                    {/* <div className="mb-6">
                                        <label htmlFor="input-user-type" className='block text-sm mb-2'>User type</label>
                                        <SelectInput
                                            items={[
                                                { label: "App User", value: "APP_USER" },
                                                { label: "Portal User", value: "PORTAL_USER" },
                                            ]}
                                            placeholder="User type"
                                            value={values["userType"]}
                                            onChange={(value: { label: string, value: string }) => setFieldValue("userType", value.value)}
                                        />
                                        {touched['userType'] && errors['userType'] && <p className='text-sm text-red-500 mt-2'>User type is required</p>}
                                    </div> */}

                                    <div className="mb-6">
                                        <label htmlFor="input-first-name" className='block text-sm mb-2'>First Name</label>
                                        <FormInput type="text" name="firstName" id="input-first-name" className="w-full" placeholder="Enter first name" />
                                        {touched['firstName'] && errors['firstName'] && <p className='text-sm text-red-500 mt-2'>First name is required</p>}
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="input-last-name" className='block text-sm mb-2'>Last Name</label>
                                        <FormInput type="text" name="lastName" id="input-last-name" className="w-full" placeholder="Enter last name" />
                                        {touched['lastName'] && errors['lastName'] && <p className='text-sm text-red-500 mt-2'>Last name is required</p>}
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="modal-input-email" className='block text-sm mb-2'>Email</label>
                                        <FormInput type="email" name="email" id="modal-input-email" className="w-full" placeholder="Enter email address" autoComplete='off' role="presentation" />
                                        {touched['email'] && errors['email'] && <p className='text-sm text-red-500 mt-2'>{errors['email'] as string}</p>}
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="input-contact-number" className='block text-sm mb-2'>Primary Contact</label>
                                        <PhoneNumberInput
                                            id="input-contact-number"
                                            name="contact"
                                            className="w-full"
                                            //placeholder="Enter campus contact number"
                                            value={values["primaryContact"]}
                                            onChange={value => setFieldValue('primaryContact', value)}
                                            onBlur={() => setFieldTouched('primaryContact', true)}
                                        />
                                        {/* {touched['primaryContact'] && errors['primaryContact'] && <p className='text-sm text-red-500 mt-2'>Contact number is required</p>} */}
                                        {touched['primaryContact'] && errors['primaryContact'] && <p className='text-sm text-red-500 mt-2'>{errors['primaryContact'] as string}</p>}
                                    </div>

                                    {/* <div className='flex justify-between flex-wrap gap-4 items-center border dark:border-gray-700 rounded-lg px-5 py-4 mb-6'>
                                        <span>Username</span>

                                        <RadioGroupInput
                                            options={[
                                                { label: 'Use Email', value: 'email' },
                                                { label: 'Use Primary Contact', value: 'number' }
                                            ]}
                                            value={values["usernameType"]}
                                            onChange={(value: any) => setFieldValue("usernameType", value)}
                                        />
                                    </div> */}

                                    <div className="mb-6">
                                        <label htmlFor="input-school" className='block text-sm mb-2'>School</label>
                                        <SelectInput
                                            items={schoolListData.list}
                                            labelKey='name'
                                            valueKey='id'
                                            searchable={true}
                                            placeholder="Select school"
                                            value={values["schoolId"]}
                                            onChange={(school: any) => {
                                                setFieldValue("schoolId", school.id);
                                            }}
                                            onSearch={(query) => getSchools(query)}
                                            searching={schoolListData.loading}
                                            onBlur={() => setFieldTouched('schoolId', true)}
                                            // usePortal
                                            wrapOptions
                                            usePortal
                                        />
                                        {touched['schoolId'] && errors['schoolId'] && <p className='text-sm text-red-500 mt-2'>School is required</p>}
                                    </div>


                                    <div className="mb-6">
                                        <label htmlFor="input-user-type" className='block text-sm mb-2'>Employee role</label>
                                        <SelectInput
                                            items={rolesList}
                                            placeholder="Select employee role"
                                            valueKey='key'
                                            value={values["role"]}
                                            onChange={(value: any) => setFieldValue("role", value.key)}
                                            searchable
                                            usePortal
                                        />
                                        {touched['role'] && errors['role'] && <p className='text-sm text-red-500 mt-2'>User role is required</p>}
                                    </div>

                                    <div className="mb-10">
                                        <label htmlFor="input-user-type" className='block text-sm mb-2'>Add Special Permissions</label>
                                        <MultiSelectInput
                                            items={permissionsList}
                                            valueKey='id'
                                            labelKey='name'
                                            placeholder="Select permissions"
                                            selectedLabel='Permission'
                                            selectedLabelPlural='Permissions'
                                            showSelectedItems={false}
                                            value={values["specialPermissions"]}
                                            onChange={(selected: any[]) => setFieldValue("specialPermissions", selected)}
                                            usePortal
                                        />
                                        {touched['specialPermissions'] && errors['specialPermissions'] && <p className='text-sm text-red-500 mt-2'>Special permission is required</p>}
                                    </div>


                                    <div className='flex justify-end items-center'>
                                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                            Cancel
                                        </Button>

                                        <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                            {employeeAccount ? "Save" : "Create Employee"}
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                }

                {
                    (optionsLoadingStatus.loading || optionsLoadingStatus.error) &&
                    <div className='pb-10'>
                        {
                            optionsLoadingStatus.loading &&
                            <ListLoader loadingText='Loading Options' className='py-10' loaderSize={2} />
                        }
                        {
                            optionsLoadingStatus.error &&
                            <ErrorLoading title='Error Loading Options' message='An unexpected error occurred while loading options' className='px-8 py-16' onTryAgain={getOptions} />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    //userType: Yup.string().trim().required().label(""),
    firstName: Yup.string().trim().required().label(""),
    lastName: Yup.string().trim().required().label(""),

    email: Yup.string().trim().email("A valid email is required").when('usernameType', {
        is: (val: string) => val === 'email',
        then: (schema) => schema.required("Email is required"),
    }),
    primaryContact: Yup.string().trim()
        .test("invalid-number", "Invalid number provided", value => value ? isValidPhoneNumber(value) : true).label("")
        .when('usernameType', {
            is: (val: string) => val === 'number',
            then: (schema) => schema.required("Primary contact is Required"),
        }),
    role: Yup.string().trim().required().label(""),
    schoolId: Yup.string().trim().required().label(""),
    specialPermissions: Yup.array(),
});


interface Props {
    isOpen: boolean;
    employeeAccount?: any;
    onClose: () => void;
    onEmployeeAccountCreated?: (employeeAccount: any) => void;
    onEmployeeCreatedNotAccount?: (employee: any) => void;
    onEmployeeAccountUpdated?: (employeeAccount: any) => void;
}


export default ModalEmployeeAccountForm;