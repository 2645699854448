import { useDispatch } from 'react-redux';

import { ToastItemType } from '../../components/common/toast/ToastItem';
import { showToast, removeToast } from '../store/reducers/ui/toastSlice';


function useToast() {
    const dispatch = useDispatch();

    const addToast = (toastItem: ToastItemType) => {
        dispatch(showToast(toastItem));
    }

    const removeToastItem = (toastId: number) => {
        dispatch(removeToast(toastId))
    }

    return { addToast, removeToastItem };
}

export default useToast;