import { RouteLinksType } from "./appNavLinkList";


export const schoolRouteLinks: RouteLinksType = {
    profile: {
        groupLabel: "School Profile",
        visible: true,
        url: "/schools/:schoolId",
        items: {
            details: {
                label: "Details",
                icon: "ri-article-line",
                url: "/schools/:schoolId",
                visible: true,
                new: false,
                children: {},
            },
            configurations: {
                label: "Configurations",
                icon: "ri-equalizer-fill",
                url: "/schools/:schoolId/configurations",
                visible: true,
                new: false,
                children: {},
            },
            campuses: {
                label: "Campuses",
                icon: "ri-building-fill",
                url: "/schools/:schoolId/campuses",
                visible: true,
                new: false,
                children: {},
            },
            classrooms: {
                label: "Classrooms",
                icon: "ri-presentation-fill",
                url: "/schools/:schoolId/classrooms",
                visible: true,
                new: false,
                children: {},
            },
            adminAccounts: {
                label: "Admin Accounts",
                icon: "ri-account-box-fill",
                url: "/schools/:schoolId/admin-accounts",
                visible: true,
                new: false,
                children: {},
            },
            modules: {
                label: "Modules",
                icon: "ri-box-2-line",
                url: "/schools/:schoolId/modules",
                visible: true,
                new: false,
                children: {},
            },
        }
    },
    attendance: {
        groupLabel: "Attendance",
        visible: false,
        url: "/schools/:schoolId/attendance",
        items: {
            general: {
                label: "General",
                icon: "ri-ai-generate",
                url: "/schools/:schoolId/attendance",
                visible: true,
                new: false,
                children: {},
            },
            configurations: {
                label: "Configurations",
                icon: "ri-equalizer-fill",
                url: "/schools/:schoolId/canteen/configurations",
                visible: false,
                new: false,
                children: {},
            },
        }
    },
    canteen: {
        groupLabel: "Canteen",
        visible: true,
        url: "/schools/:schoolId/canteen",
        items: {
            general: {
                label: "General",
                icon: "ri-ai-generate",
                url: "/schools/:schoolId/canteen",
                visible: false,
                new: false,
                children: {},
            },
            serviceProviders: {
                label: "Canteen Vendors",
                icon: "ri-restaurant-2-line",
                //url: "/schools/:schoolId/canteen/service-providers",
                url: "/schools/:schoolId/canteen",
                visible: true,
                new: false,
                children: {},
            },
            cspManagers: {
                label: "Canteen Owners",
                icon: "ri-user-settings-line",
                url: "/schools/:schoolId/canteen/csp-managers",
                visible: true,
                new: false,
                children: {},
            },
            configurations: {
                label: "Configurations",
                icon: "ri-equalizer-fill",
                url: "/schools/:schoolId/canteen/configurations",
                visible: false,
                new: false,
                children: {},
            },
        }
    },
    payments: {
        groupLabel: "Payments",
        visible: true,
        url: "/schools/:schoolId/payments",
        items: {
            general: {
                label: "Configurations",
                icon: "ri-equalizer-fill",
                url: "/schools/:schoolId/payments",
                visible: true,
                new: false,
                children: {},
            },
        }
    },
    management: {
        groupLabel: "School Management",
        visible: true,
        url: "/schools/:schoolId/manage",
        items: {
            general: {
                label: "General",
                icon: "ri-ai-generate",
                url: "/schools/:schoolId/manage",
                visible: true,
                new: false,
                children: {},
            },
            bulkSMSDetails: {
                label: "Bulk SMS",
                icon: "ri-message-3-line",
                url: "/schools/:schoolId/manage/bulk-sms",
                visible: true,
                new: false,
                children: {},
            },
            configurations: {
                label: "Configurations",
                icon: "ri-equalizer-fill",
                url: "/schools/:schoolId/manage/configurations",
                visible: false,
                new: false,
                children: {},
            },
        }
    },
};
