import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { FormInput, RadioGroupInput, SelectInput, TimePickerInput } from '../../../../../components/form';
import { Button, ErrorLoading } from '../../../../../components/common';
import campusService from '../../../../../services/api/unified-api/campusService';
import { ListLoader } from '../../../../../components/loaders';
import { Switch } from '@headlessui/react';


function ModalCampusConfigurationForm({ isOpen, onClose, schoolId, campus, addNew = false, onConfigurationAdded, onConfigurationUpdated }: Props) {
    const [configuration, setConfiguration] = useState<any>(null);
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    const [configLoadingStatus, setConfigLoadingStatus] = useState({ loading: false, error: false });



    useEffect(() => {
        if (isOpen && !addNew) {
            getConfigurations();
        }

        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen, addNew]);


    const getConfigurations = async () => {
        setConfigLoadingStatus({ loading: true, error: false });
        const result = await campusService.getCampus(schoolId, campus.id);

        if (result.success) {
            setConfigLoadingStatus({ loading: false, error: false });
            setConfiguration(result.data.configuration);
        } else {
            setConfigLoadingStatus({ loading: false, error: true });
        }
    }


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        if (configuration) {
            // update
            const result = await campusService.updateCampusConfigurations(schoolId, campus.id, formData);
            if (result.success) {
                if (onConfigurationUpdated) {
                    onConfigurationUpdated({ ...campus, ...result.data });
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        } else {
            // create
            const result = await campusService.addCampusConfigurations(campus.id, formData);
            if (result.success) {
                if (onConfigurationAdded) {
                    onConfigurationAdded(result.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    };


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[38rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {configuration ? "Edit" : "Add"} Campus Configurations
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>
                {
                    !configLoadingStatus.loading && !configLoadingStatus.error &&
                    <div className={`max-h-[72vh] overflow-y-auto px-10 pb-6`}>
                        <Formik
                            initialValues={{
                                language: configuration ? configuration.language : '',
                                email: configuration ? configuration.email : '',

                                metricUnit: configuration ? configuration.metricUnit : '',
                                geoFenceRadius: configuration ? configuration.geoFenceRadius : 0,
                                latitude: configuration ? configuration.latitude : '',
                                longitude: configuration ? configuration.longitude : '',

                                timezone: configuration ? configuration.timezone : '',
                                amPm: configuration ? configuration.amPm : true,

                                dropOffStartTime: configuration ? configuration.dropOffStartTime : '',
                                dropOffCutOffTime: configuration ? configuration.dropOffCutOffTime : '',
                                dropOffNotificationStartTime: configuration ? configuration.dropOffNotificationStartTime : '',

                                pickupStartTime: configuration ? configuration.pickupStartTime : '',
                                pickupCutOffTime: configuration ? configuration.pickupCutOffTime : '',
                                pickupNotificationStartTime: configuration ? configuration.pickupNotificationStartTime : '',
                                hasEnabledDelegateCheckins: configuration ? configuration.hasEnabledDelegateCheckins : false,
                                strictDelegateVerification: configuration ? configuration.strictDelegateVerification : false,
                                strictStudentVerification: configuration ? configuration.strictStudentVerification : false,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                                <Form className="">
                                    {
                                        submitStatus.failedMessage && !submitStatus.loading &&
                                        <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                    }

                                    <div className="mb-6">
                                        <label htmlFor="modal-select-language" className='block text-sm mb-2'>Language</label>
                                        <SelectInput
                                            items={["English", "French"]}
                                            placeholder="Select language"
                                            value={values["language"]}
                                            onChange={(value) => setFieldValue("language", value)}
                                            onBlur={() => setFieldTouched('language', true)}
                                        />
                                        {touched['language'] && errors['language'] && <p className='text-sm text-red-500 mt-2'>Language is required</p>}
                                    </div>

                                    <div className="mb-12">
                                        <label htmlFor="modal-input-email" className='block text-sm mb-2'>Email</label>
                                        <FormInput type="email" name="email" id="modal-input-email" className="w-full" placeholder="Enter email address" />
                                        {touched['email'] && errors['email'] && <p className='text-sm text-red-500 mt-2'>Email is Required</p>}
                                    </div>


                                    <div className='grid sm:grid-cols-2 gap-y-6 gap-x-10 mb-12'>
                                        <div className="">
                                            <label htmlFor="modal-select-metric-unit" className='block text-sm mb-2'>Metric unit</label>
                                            <SelectInput
                                                items={[
                                                    { label: "Meter", value: "m" },
                                                    { label: "Centimeter", value: "cm" },
                                                    { label: "Millimeter", value: "mm" },
                                                ]}
                                                placeholder="Select unit"
                                                value={values["metricUnit"]}
                                                onChange={(value: { label: string, value: string }) => setFieldValue("metricUnit", value.value)}
                                                onBlur={() => setFieldTouched('metricUnit', true)}
                                            />
                                            {touched['metricUnit'] && errors['metricUnit'] && <p className='text-sm text-red-500 mt-2'>Metric unit is Required</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-input-geofence-radius" className='block text-sm mb-2'>Geofence radius</label>
                                            <FormInput type="number" name="geoFenceRadius" id="modal-input-geofence-radius" className="w-full" placeholder="Enter radius" />
                                            {touched['geoFenceRadius'] && errors['geoFenceRadius'] && <p className='text-sm text-red-500 mt-2'>Geofence radius is Required</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-input-latitude" className='block text-sm mb-2'>Latitude</label>
                                            <FormInput type="number" name="latitude" id="modal-input-latitude" className="w-full" min={-90} max={90} step={'any'} placeholder="Enter latitude" />
                                            {touched['latitude'] && errors['latitude'] && <p className='text-sm text-red-500 mt-2'>{errors['latitude'] as string}</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-input-longitude" className='block text-sm mb-2'>Longitude</label>
                                            <FormInput type="number" name="longitude" id="modal-input-longitude" className="w-full" min={-180} max={180} step={'any'} placeholder="Enter longitude" />
                                            {touched['longitude'] && errors['longitude'] && <p className='text-sm text-red-500 mt-2'>{errors['longitude'] as string}</p>}
                                        </div>
                                    </div>


                                    <div className='grid sm:grid-cols-2 gap-y-6 gap-x-10 mb-12'>
                                        <div className="">
                                            <label htmlFor="modal-select-timezone" className='block text-sm mb-2'>Timezone</label>
                                            <SelectInput
                                                items={["Africa/Accra", "Africa/Casablanca"]}
                                                placeholder="Select timezone"
                                                value={values["timezone"]}
                                                onChange={(value: { label: string, value: string }) => setFieldValue("timezone", value)}
                                                onBlur={() => setFieldTouched('timezone', true)}
                                            />
                                            {touched['timezone'] && errors['timezone'] && <p className='text-sm text-red-500 mt-2'>Timezone is required</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-radio-time-format" className='block text-sm mb-2'>Timezone</label>
                                            <RadioGroupInput
                                                value={values["amPm"]}
                                                options={[
                                                    { label: "12 Hour Format (AM/PM)", value: true },
                                                    { label: "24 Hour Format", value: false },
                                                ]}
                                                onChange={(value) => setFieldValue("amPm", value)}
                                            />
                                            {touched['amPm'] && errors['amPm'] && <p className='text-sm text-red-500 mt-2'>Timezone is required</p>}
                                        </div>
                                    </div>


                                    <div className='grid sm:grid-cols-2 gap-y-6 gap-x-10 mb-8'>
                                        <div className="">
                                            <label htmlFor="modal-pick-dropoff-start" className='block text-sm mb-2'>Drop off start time</label>
                                            <TimePickerInput
                                                use24Hour={!values["amPm"]}
                                                value={values["dropOffStartTime"]}
                                                onChange={(value) => setFieldValue("dropOffStartTime", value.fullTime)}
                                                onBlur={() => setFieldTouched('dropOffStartTime', true)}
                                            />
                                            {touched['dropOffStartTime'] && errors['dropOffStartTime'] && <p className='text-sm text-red-500 mt-2'>Start time is required</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-pick-pickup-start" className='block text-sm mb-2'>Pickup start time</label>
                                            <TimePickerInput
                                                use24Hour={!values["amPm"]}
                                                value={values["pickupStartTime"]}
                                                onChange={(value) => setFieldValue("pickupStartTime", value.fullTime)}
                                                onBlur={() => setFieldTouched('pickupStartTime', true)}
                                            />
                                            {touched['pickupStartTime'] && errors['pickupStartTime'] && <p className='text-sm text-red-500 mt-2'>Start time is required</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-pick-dropoff-cutoff" className='block text-sm mb-2'>Drop off cut off time</label>
                                            <TimePickerInput
                                                use24Hour={!values["amPm"]}
                                                value={values["dropOffCutOffTime"]}
                                                onChange={(value) => setFieldValue("dropOffCutOffTime", value.fullTime)}
                                                onBlur={() => setFieldTouched('dropOffCutOffTime', true)}
                                            />
                                            {touched['dropOffCutOffTime'] && errors['dropOffCutOffTime'] && <p className='text-sm text-red-500 mt-2'>Cut off time is required</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-pick-pickup-cutoff" className='block text-sm mb-2'>Pickup cut off time</label>
                                            <TimePickerInput
                                                use24Hour={!values["amPm"]}
                                                value={values["pickupCutOffTime"]}
                                                onChange={(value) => setFieldValue("pickupCutOffTime", value.fullTime)}
                                                onBlur={() => setFieldTouched('pickupCutOffTime', true)}
                                            />
                                            {touched['pickupCutOffTime'] && errors['pickupCutOffTime'] && <p className='text-sm text-red-500 mt-2'>Cut off time is required</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-pick-dropoff-notification-start" className='block text-sm mb-2'>Drop off notification start time</label>
                                            <TimePickerInput
                                                use24Hour={!values["amPm"]}
                                                value={values["dropOffNotificationStartTime"]}
                                                onChange={(value) => setFieldValue("dropOffNotificationStartTime", value.fullTime)}
                                                onBlur={() => setFieldTouched('dropOffNotificationStartTime', true)}
                                            />
                                            {touched['dropOffNotificationStartTime'] && errors['dropOffNotificationStartTime'] && <p className='text-sm text-red-500 mt-2'>Notification start time is required</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-pick-pickup-notification-start" className='block text-sm mb-2'>Pickup notification start time</label>
                                            <TimePickerInput
                                                use24Hour={!values["amPm"]}
                                                value={values["pickupNotificationStartTime"]}
                                                onChange={(value) => setFieldValue("pickupNotificationStartTime", value.fullTime)}
                                                onBlur={() => setFieldTouched('pickupNotificationStartTime', true)}
                                            />
                                            {touched['pickupNotificationStartTime'] && errors['pickupNotificationStartTime'] && <p className='text-sm text-red-500 mt-2'>Notification start time is required</p>}
                                        </div>
                                    </div>


                                    {/* <div className='bg-gray-50 dark:bg-white/5 divide-y divide-gray-200 dark:divide-gray-700 rounded-xl px-4 mb-10'>
                                        <div className='flex justify-between items-center gap-x-5 py-5'>
                                            <div className=''>
                                                <p>Enable Delegate Check Ins</p>
                                            </div>
                                            <Switch
                                                checked={values["hasEnabledDelegateCheckins"]}
                                                onChange={(on: boolean) => setFieldValue("hasEnabledDelegateCheckins", on)}
                                                className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                            >
                                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                            </Switch>
                                        </div>

                                        <div className='flex justify-between items-center gap-x-5 py-5'>
                                            <div className=''>
                                                <p>Strict Delegate Verification</p>
                                                <p className='text-gray-500 dark:text-gray-400'>Forces scanner to show delegate picture for confirmation after scanning</p>
                                            </div>
                                            <Switch
                                                checked={values["strictDelegateVerification"]}
                                                onChange={(on: boolean) => setFieldValue("strictDelegateVerification", on)}
                                                className={`flex-shrink-0 ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                            >
                                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                            </Switch>
                                        </div>

                                        <div className='flex justify-between items-center gap-x-5 py-5'>
                                            <div className=''>
                                                <p>Strict Student Verification</p>
                                                <p className='text-gray-500 dark:text-gray-400'>Forces scanner to show each student's picture for confirmation after scanning</p>
                                            </div>
                                            <Switch
                                                checked={values["strictStudentVerification"]}
                                                onChange={(on: boolean) => setFieldValue("strictStudentVerification", on)}
                                                className={`flex-shrink-0 ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                            >
                                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                            </Switch>
                                        </div>
                                    </div> */}



                                    <div className='flex justify-end items-center'>
                                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                            Cancel
                                        </Button>

                                        <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                            {configuration ? 'Save' : 'Add Configuration'}
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                }

                {
                    (configLoadingStatus.loading || configLoadingStatus.error) &&
                    <div className='pb-10'>
                        {
                            configLoadingStatus.loading &&
                            <ListLoader loadingText='Loading Configurations' className='py-10' loaderSize={2} />
                        }
                        {
                            configLoadingStatus.error &&
                            <ErrorLoading title='Error Loading Configurations' message='An unexpected error occurred while loading configurations' className='px-8 py-16' onTryAgain={getConfigurations} />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    language: Yup.string().trim().required().label(""),
    email: Yup.string().email().trim().required().label(""),

    metricUnit: Yup.string().trim().required().label(""),
    geoFenceRadius: Yup.number().required().label(""),

    latitude: Yup.number().required('Required').min(-90, 'Invalid number provided').max(90, 'Invalid number provided'),
    longitude: Yup.number().required('Required').min(-180, 'Invalid number provided').max(180, 'Invalid number provided'),

    timezone: Yup.string().trim().required().label(""),
    amPm: Yup.boolean().required().label(""),

    dropOffStartTime: Yup.string().trim().required().label(""),
    dropOffCutOffTime: Yup.string().trim().required().label(""),
    dropOffNotificationStartTime: Yup.string().trim().required().label(""),

    pickupStartTime: Yup.string().trim().required().label(""),
    pickupCutOffTime: Yup.string().trim().required().label(""),
    pickupNotificationStartTime: Yup.string().trim().required().label(""),
});



interface Props {
    isOpen: boolean;
    campus: any;
    addNew?: boolean;
    schoolId: number;
    onClose: () => void;
    onConfigurationAdded?: (configuration: any) => void;
    onConfigurationUpdated?: (configuration: any) => void;
}


export default ModalCampusConfigurationForm;