import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import useToast from '../../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../../state/store/reducers/ui/headerSlice';
import thirdPartyService from '../../../../../../services/api/payments/thirdPartyService';
import { ListLoader } from '../../../../../../components/loaders';
import { ErrorLoading } from '../../../../../../components/common';
import PopoverWithFloat from '../../../../../../components/hoc/PopoverWithFloat';
import { format } from 'date-fns';
import useAlert from '../../../../../../state/hooks/useAlert';



function ThirdPartyDetailsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { thirdPartyId } = useParams();

    const [pageStatus, setPageStatus] = useState({ loading: false, error: false, notFound: false });
    const [thirdParty, setThirdParty] = useState<any>(null);

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();


    useEffect(() => {
        dispatch(changePageInfo({ title: "Third Party Details" }));

        if (thirdPartyId) {
            if (location.state && location.state.thirdParty) {
                setThirdParty(location.state.thirdParty);
            } else {
                getThirdParty();
            }
        }
    }, []);


    const getThirdParty = async () => {
        setPageStatus({ loading: true, error: false, notFound: false });
        const result = await thirdPartyService.getThirdParty(thirdPartyId);
        if (result?.success) {
            setThirdParty(result.data);
            setPageStatus({ loading: false, error: false, notFound: false });
        } else {
            if (result.notFound) {
                setPageStatus({ loading: false, error: true, notFound: true });
            } else {
                setPageStatus({ loading: false, error: true, notFound: false });
            }
        }
    }




    const confirmStatusAction = async (action: "ACTIVE" | "SUSPENDED") => {
        if (action === "ACTIVE") {
            openAlertConfirm({
                title: "Activate Third Party",
                message: `Are you sure you want to make ${thirdParty?.companyName} active?`,
                positiveButtonText: "Activate",
                onConfirmed: () => updateStatus(action),

            });
        }

        if (action === "SUSPENDED") {
            openAlertConfirm({
                title: "Suspend Third Party",
                message: `Are you sure you want to suspend ${thirdParty?.companyName}?`,
                positiveButtonText: "Suspend",
                positiveButtonClass: 'bg-red-500 hover:bg-red-600',
                onConfirmed: () => updateStatus(action),
            });
        }
    }


    const updateStatus = async (action: "ACTIVE" | "SUSPENDED") => {
        const result = await thirdPartyService.updateThirdPartyStatus(thirdParty?.id, { status: action });
        if (result?.success) {
            setThirdParty({ ...result?.data });
            addToast({
                text: `Third party is now ${action.toLowerCase()}`,
            });
        } else {
            addToast({
                type: "error",
                text: `Sorry, unable to perform action`,
            });
        }
    };


    return (
        <React.Fragment>
            {
                !pageStatus.loading && !pageStatus.error &&
                <div className='max-w-4xl bg-white dark:bg-gray-800 shadow dark:shadow-none divide-y dark:divide-gray-700 rounded-xl py-8 px-8'>
                    <div className='flex pb-5'>
                        <div className='flex-grow flex justify-between items-start'>
                            <div>
                                <p className='text-gray-500 dark:text-gray-400 mb-1'>Company Name</p>
                                <h2 className='font-semibold text-lg'>{thirdParty?.companyName}</h2>
                            </div>

                            {
                                thirdParty?.status === "ACTIVE" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-2 px-3 mr-3`}>{thirdParty?.status}</span>
                            }
                            {
                                thirdParty?.status !== "ACTIVE" && thirdParty?.status !== "SUSPENDED" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-2 px-3 mr-3`}>{thirdParty?.status}</span>
                            }
                            {
                                thirdParty?.status === "SUSPENDED" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-2 px-3 mr-3`}>{thirdParty?.status}</span>
                            }
                        </div>


                        <Menu as="div" className="relative inline-block text-left">
                            {
                                ({ open }) => (
                                    <PopoverWithFloat placement='bottom-end' portal>
                                        <Menu.Button type='button' className={`w-5 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ${open ? 'bg-black/5 dark:bg-white/5' : ''}`}>
                                            <i className="ri-more-2-fill"></i>
                                        </Menu.Button>

                                        <Menu.Items as='div' unmount={false} className="w-56 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                            {
                                                thirdParty?.status === "ACTIVE" &&
                                                <Menu.Item
                                                    as="button"
                                                    type='button'
                                                    onClick={() => confirmStatusAction("SUSPENDED")}
                                                    className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                                >
                                                    Suspend
                                                </Menu.Item>
                                            }

                                            {
                                                thirdParty?.status !== "ACTIVE" &&
                                                <Menu.Item
                                                    as="button"
                                                    type='button'
                                                    onClick={() => confirmStatusAction("ACTIVE")}
                                                    className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                                >
                                                    Activate
                                                </Menu.Item>
                                            }
                                        </Menu.Items>
                                    </PopoverWithFloat>
                                )
                            }
                        </Menu>
                    </div>


                    <div className='grid sm:grid-cols-2 gap-8 py-8'>
                        <div className=''>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Drop Off Start Time</p>
                            <p>{thirdParty?.contactPersonFirstName} {thirdParty?.contactPersonLastName}</p>
                        </div>
                        <div className=''>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Domain Name</p>
                            <p>{thirdParty?.domainName}</p>
                        </div>
                        <div className=''>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>E-Mail</p>
                            <p>{thirdParty?.email}</p>
                        </div>
                        <div className=''>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Primary Contact</p>
                            <p>{thirdParty?.primaryContact}</p>
                        </div>
                        <div className=''>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Country</p>
                            <p>{thirdParty?.country}</p>
                        </div>
                        <div className=''>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Timezone</p>
                            <p>{thirdParty?.timeZone}</p>
                        </div>
                        <div className='col-span-2'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Secondary Contacts</p>
                            <p>{thirdParty?.secondaryContacts?.length > 0 ? thirdParty?.secondaryContacts?.join(", ") : 'N/A'}</p>
                        </div>
                    </div>


                    <div className='grid sm:grid-cols-2 gap-8 py-8'>
                        <div className=''>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Data Source</p>
                            <p>{thirdParty?.contactPersonFirstName} {thirdParty?.dataSource}</p>
                        </div>
                        <div className=''>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Used</p>
                            <p>{thirdParty?.lastUsed ? format(new Date(thirdParty?.lastUsed), 'd LLL yyyy h:mm a') : 'N/A'}</p>
                        </div>
                    </div>
                </div>
            }


            {
                // if it's loading
                pageStatus.loading &&
                <ListLoader loadingText='Loading Third Party' className='px-8 py-24' />
            }

            {
                // if is not found
                !pageStatus.loading && pageStatus.error && pageStatus.notFound &&
                <ErrorLoading title='Third Party Not Found' message='Third party may have been removed or never existed' className='px-8 py-24' />
            }

            {
                // if there's an error loading
                !pageStatus.loading && pageStatus.error && !pageStatus.notFound &&
                <ErrorLoading title='Error Loading Third Party' message='An unexpected error occurred while loading third party' className='px-8 py-24' onTryAgain={getThirdParty} />
            }
        </React.Fragment>
    );
}

export default ThirdPartyDetailsPage;