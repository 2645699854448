import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { format } from 'date-fns';

import useToast from '../../../../../../../../../state/hooks/useToast';
import ModalCampusConfigurationForm from '../../../../../modals/ModalCampusConfigurationForm';
import ModalCampusForm from '../../../../../modals/ModalCampusForm';
import { Button, ListNoData } from '../../../../../../../../../components/common';
import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import useAlert from '../../../../../../../../../state/hooks/useAlert';


function SchoolCampusListPage() {
    const { profile, setProfile }: any = useOutletContext();

    const [campusForm, setCampusForm] = useState({ open: false, campus: null });
    const [configurationsForm, setConfigurationsForm] = useState({ open: false, campus: null, new: false });

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            {
                profile.campuses.length > 0 &&
                <div>
                    <div className='flex justify-between items-center flex-wrap gap-2 mb-4'>
                        <h2 className='font-medium text-xl mr-4'>School Campuses</h2>
                        <Button type='button' onClick={() => setCampusForm({ open: true, campus: null })} className='py-2.5'>Add Campus</Button>
                    </div>

                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b dark:border-gray-700'>
                                    <th className='py-3 px-4'>Campus</th>
                                    <th className='py-3 px-4'>Alias</th>
                                    <th className='py-3 px-4'>Address</th>
                                    <th className='py-3 px-4'>Location</th>
                                    <th className='py-3 px-4'>Phone No.</th>
                                    <th className='py-3 px-4'>Date Created</th>
                                    <th className='py-3 px-4 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    profile.campuses.map((campusItem: any) =>
                                        <tr key={campusItem.id}>
                                            <td className='py-3 px-4'>
                                                <button type='button' className="hover:text-blue-500 whitespace-nowrap">
                                                    {campusItem.name}
                                                </button>
                                            </td>
                                            <td className='py-3 px-4'>{campusItem.alias}</td>
                                            <td className='py-3 px-4'>{campusItem.address}</td>
                                            <td className='py-3 px-4'>{campusItem.location}</td>
                                            <td className='py-3 px-4'>{campusItem.contact}</td>
                                            <td className='py-3 px-4 whitespace-nowrap'>{format(new Date(campusItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-3 px-4 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    {
                                                        ({ open }) => (
                                                            <PopoverWithFloat placement='bottom-end' portal>
                                                                <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ${open ? 'bg-black/5 dark:bg-white/5' : ''}`}>
                                                                    <i className="ri-more-2-fill"></i>
                                                                </Menu.Button>

                                                                <Menu.Items as='div' unmount={false} className="w-72 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                                    <Menu.Item
                                                                        as="button"
                                                                        type='button'
                                                                        className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                                                        onClick={() => setCampusForm({ open: true, campus: campusItem })}
                                                                    >
                                                                        Edit Campus
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        as="button"
                                                                        type='button'
                                                                        className="w-full text-left whitespace-nowrap hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                                                        onClick={() => setConfigurationsForm({ open: true, campus: campusItem, new: false })}
                                                                    >
                                                                        Add/Change Configurations
                                                                    </Menu.Item>
                                                                </Menu.Items>
                                                            </PopoverWithFloat>
                                                        )
                                                    }
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }


            {
                profile.campuses.length === 0 &&
                <ListNoData title='No Campus Found' description='There are no campuses found for this school'>
                    <Button type='button'>Add Campus</Button>
                </ListNoData>
            }

            <ModalCampusForm
                isOpen={campusForm.open}
                onClose={() => setCampusForm(prevState => ({ ...prevState, open: false }))}
                schoolId={profile.id}
                campus={campusForm.campus}
                onCampusCreated={(campus) => {
                    setProfile((prevState: any) => ({
                        ...prevState,
                        campuses: [{ ...campus }, ...prevState.campuses]
                    }));
                    openAlertConfirm({
                        title:
                            <div className='flex items-center'>
                                <span className='mr-2.5'>Campus Created</span>
                                <i className="ri-checkbox-circle-line text-2xl text-green-500"></i>
                            </div>,
                        message: `Would you like to add configurations to campus?`,
                        onConfirmed: () => setConfigurationsForm({ open: true, campus: campus, new: true }),
                        positiveButtonText: "Add Configurations",
                        negativeButtonText: 'No, Later',
                    });
                }}
                onCampusUpdated={(campus) => {
                    setProfile((prevState: any) => {
                        const campuses: any[] = [...prevState.campuses];
                        const index = campuses.findIndex(campusItem => campusItem.id === campus.id);
                        campuses[index] = { ...campus };
                        return { ...prevState, campuses }
                    });

                    addToast({
                        text: `Campus has been updated successfully`,
                        type: "success"
                    });
                }}
            />
            <ModalCampusConfigurationForm
                isOpen={configurationsForm.open}
                onClose={() => setConfigurationsForm(prevState => ({ ...prevState, open: false }))}
                campus={configurationsForm.campus}
                schoolId={profile.id}
                addNew={configurationsForm.new}
                onConfigurationAdded={() => {
                    addToast({
                        text: `Configuration added successfully`,
                        type: "success"
                    });
                }}
                onConfigurationUpdated={() => {
                    addToast({
                        text: `Configuration updated successfully`,
                        type: "success"
                    });
                }}
            />
        </div>
    );
}

export default SchoolCampusListPage;