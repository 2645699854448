import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { DatePickerInput, FormInput, FormTextArea } from '../../../../../components/form';
import { Button } from '../../../../../components/common';
import regexStrings from '../../../../../config/regexStrings';
import { compareVersions } from '../../../../../utils/versioning';
import AppVersionService from '../../../../../services/api/unified-api/AppVersionService';


const today = new Date()
function ModalParentAppVersionForm({ isOpen, platform, onClose, onVersionUpdated }: Props) {

    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });



    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        const result = await AppVersionService.createNewParentAppVersion(platform?.key?.toLowerCase(), formData);
        if (result.success) {
            if (onVersionUpdated) {
                onVersionUpdated({ ...platform, ...result.data });
            }
            resetForm();
            onClose();
            setSubmitStatus({ loading: false, failedMessage: '' });
        } else {
            setSubmitStatus({ loading: false, failedMessage: result.message });
        }
    };


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[28rem] max-w-full">
                <div className="flex justify-between items-start mb-2 px-9 pt-8">
                    <div>
                        <h2 className="font-medium text-xl">Update {platform?.platformName || ''} Version </h2>
                        <p className='text-gray-500 dark:text-gray-400 mb-4'>SmartSapp Parent App</p>
                    </div>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>


                <div className="px-9 pb-6">
                    <Formik
                        initialValues={{
                            previous: platform ? platform?.current : '',
                            previousReleaseDate: platform ? platform?.currentReleaseDate : '',
                            current: '',
                            currentReleaseDate: '',
                            releaseNote: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                        enableReinitialize
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }

                                <div className='flex justify-between bg-gray-50 dark:bg-white/5 rounded-xl px-4 py-2.5 mb-6'>
                                    <span>Current Version:</span>
                                    <span className='font-semibold text-green-500'>{platform?.current}</span>
                                </div>


                                <div className="mb-6">
                                    <label htmlFor="input-current" className='block text-sm mb-2'>New Version</label>
                                    <FormInput type="text" name="current" id="input-current" className="w-full" placeholder="Enter next version" />
                                    {touched['current'] && errors['current'] && <p className='text-sm text-red-500 mt-2'>{errors['current'] as string}</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-current-date" className='block text-sm mb-2'>Release Date</label>
                                    <DatePickerInput
                                        placeholder='Select Release Date'
                                        value={values["currentReleaseDate"]}
                                        minDate={today.toISOString().split('T')[0]}
                                        onChange={(value) => setFieldValue("currentReleaseDate", value.fullDate)}
                                    />
                                    {touched['currentReleaseDate'] && errors['currentReleaseDate'] && <p className='text-sm text-red-500 mt-2'>Release date is required</p>}
                                </div>

                                <div className="mb-8">
                                    <label htmlFor="input-release-notes" className='block text-sm mb-2'>Release Notes</label>
                                    <FormTextArea name='releaseNote' id="input-release-notes" className='w-full' placeholder="Enter release notes" />
                                    {touched['releaseNote'] && errors['releaseNote'] && <p className='text-sm text-red-500 mt-2'>Release notes is Required</p>}
                                </div>

                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    previous: Yup.string(),
    current: Yup.string().trim()
        .required('Current version is required')
        .matches(regexStrings.versioning, "Invalid version provided")
        .test("invalid-version", "New version must be higher than current", (value, { parent }) => compareVersions(parent.previous, value) > 0),
    currentReleaseDate: Yup.string().trim().required(),
    releaseNote: Yup.string().trim().required(),
});


interface Props {
    isOpen: boolean;
    platform: any;
    onClose: () => void;
    onVersionUpdated?: (platform: any) => void;
}


export default ModalParentAppVersionForm;