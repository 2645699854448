import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import SmartSappLogo from '../../../components/svgs/SmartSappLogo';
import userAccountsService from '../../../services/api/user-accounts/userAccountsService';
import { Button } from '../../../components/common';
import { OTPPrefixInput } from '../../../components/form';
import Timer from '../../../components/common/Timer';


function AccountVerificationPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    const [timer, setTimer] = useState(15);
    const [showTimer, setShowTimer] = useState(false);
    const [otpPrefix, setOtpPrefix] = useState('');


    useEffect(() => {
        if (location.state && location.state.prefix) {
            setOtpPrefix(location.state.prefix);
        }
    }, [location]);


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        const result = await userAccountsService.verifyOTP({ ...formData, email: location.state?.email, reset: true });

        if (result.success) {
            navigate('/reset-password', { replace: true, state: { email: location.state?.email, signature: result.data.signature } });
        } else {
            resetForm();
            setSubmitStatus({
                loading: false,
                failedMessage: result.message
            });
        }
    }


    const onResendCode = async () => {
        const result = await userAccountsService.requestAccountReset({ email: location.state?.email, action: "FORGOT_PASSWORD" });

        if (result.success) {
            setOtpPrefix(result.data.prefix);
            setTimer(prevState => prevState * 2);
            setShowTimer(true);
        } else {
        }
    }


    return (
        <main className='login-page bg-blue/5 dark:bg-gray-900 bg-no-repeat min-h-screen px-8 py-12 sm:py-14 md:py-16 lg:py-20 xl:py-36'>
            <div className='sm:max-w-lg bg-white dark:bg-gray-950 rounded-3xl mx-auto py-16 px-8 sm:px-10'>
                <div className='text-center mb-8'>
                    <span className='inline-block w-8 text-blue-500 '>
                        <SmartSappLogo />
                    </span>

                    <p className='text-lg font-medium text-gray-500 dark:text-gray-400 mb-6'>SmartSapp</p>
                    <h1 className='font-medium text-2xl mb-2'>Enter Verification Code</h1>
                    {
                        location.state &&
                        <React.Fragment>
                            <p className='text-gray-500 dark:text-gray-400 mx-auto'>We have sent a verification code to</p>
                            <p className='text-center font-medium text-gray-500 dark:text-gray-400'>{location.state?.email}</p>
                            {/*  <p className='text-gray-500 dark:text-gray-400 mx-auto'>Enter the verification code below</p> */}
                        </React.Fragment>
                    }

                    {
                        !location.state &&
                        <div className='bg-red-500/5 border border-red-500/20 text-red-500 rounded-xl py-6 px-5 mt-8'>
                            <p className='font-medium text-lg mb-1'>Invalid Account Provided</p>
                            <p className='text-gray-500 dark:text-gray-400 mx-auto'>A valid account is required to perform this action</p>
                        </div>
                    }
                </div>

                <div className='mb-7'>
                    {
                        location.state &&
                        <Formik
                            initialValues={{
                                code: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, values, isValid }) => (
                                <Form className="" autoComplete="off">
                                    {
                                        (submitStatus.failedMessage) &&
                                        <p className='text-center text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                    }

                                    <div className="mb-4">
                                        {/*  <div className='flex justify-center'>
                                            <OTPInput
                                                value={values["code"]}
                                                length={6}
                                                onChange={(otp) => setFieldValue('code', otp)}
                                            />
                                        </div> */}
                                        <div className='flex justify-center'>
                                            <OTPPrefixInput
                                                prefix={otpPrefix}
                                                maxLength={6}
                                                value={values["code"]}
                                                onChange={(event) => setFieldValue('code', event.target.value)}
                                                onBlur={() => setFieldTouched('code', true)}
                                            />
                                        </div>
                                        {touched['code'] && errors['code'] && <p className='text-sm text-center text-red-500 mt-4'>A valid code is required</p>}
                                    </div>

                                    <div className='text-center mb-8'>
                                        <p>
                                            <span className='text-gray-500 dark:text-gray-400'>Didn't get a code? </span>
                                            {showTimer && <span className='text-gray-500 dark:text-gray-400'>Resend in <Timer timeoutAt={timer} onTimeout={() => setShowTimer(false)} /></span>}
                                            {!showTimer && <button type='button' onClick={onResendCode} className='hover:text-blue'>Click to resend.</button>}
                                        </p>
                                    </div>

                                    <div className='mb-10'>
                                        <Button type='submit' loading={submitStatus.loading} className='w-full' disabled={submitStatus.loading}>
                                            Verify
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    }
                </div>


                <div className='text-center'>
                    <Link to="/login" className='inline-flex justify-center items-center gap-2 text-gray-500 dark:text-gray-400 hover:text-blue dark:hover:text-blue'>
                        <span className='text-lg'><i className="ri-arrow-left-line"></i></span>
                        <span>Go to Login</span>
                    </Link>
                </div>
            </div>
        </main>
    );
}


const validationSchema = Yup.object().shape({
    code: Yup.string().trim().required().label(""),
});


export default AccountVerificationPage;