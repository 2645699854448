import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectToasts, removeToast } from '../../../state/store/reducers/ui/toastSlice';
import ToastItem from './ToastItem';


function ToastContainer() {
    const toasts = useSelector(selectToasts);
    const dispatch = useDispatch();


    return (
        <React.Fragment>
            {
                toasts.map((toastItem: any) => (
                    <ToastItem key={toastItem.id} {...toastItem} removeToast={(id) => dispatch(removeToast(id))} />
                ))
            }
        </React.Fragment>
    );
}


export default ToastContainer;