import React, { useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import usePagedListApi from '../../../../../../../state/hooks/usePagedListApi';
import transactionsService from '../../../../../../../services/api/payments/transactionsService';
import invoicesService from '../../../../../../../services/api/payments/invoicesService';


function StudentPaymentPage() {
    const { profile }: any = useOutletContext();
    const [customerProfile, setCustomerProfile] = useState<any>(null);
    const [transactionStats, setTransactionStats] = useState<any>(null);

    const transactionList = usePagedListApi((query: any) => transactionsService.getCustomerTransactionsByReference(profile.id, query));
    const walletTransactionsList = usePagedListApi((query: any) => transactionsService.getCustomerTransactionsByReference(profile.id, query));
    const invoiceList = usePagedListApi((query: any) => invoicesService.getCustomerInvoicesByReference(profile.id, query));

    return (
        <Outlet
            context={{
                profile,
                customerProfile,
                setCustomerProfile,
                transactionList,
                invoiceList,
                walletTransactionsList,
                transactionStats,
                setTransactionStats
            }}
        />
    );
}

export default StudentPaymentPage;