import { Menu } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import canteenModuleService from '../../../../../../../../../services/api/canteen/canteenModuleService';
import jobsService from '../../../../../../../../../services/api/payments/jobsService';
import clientsService from '../../../../../../../../../services/api/payments/clientsService';
import useAlert from '../../../../../../../../../state/hooks/useAlert';
import useToast from '../../../../../../../../../state/hooks/useToast';


function SchoolModulesListPage() {
    const { profile, paymentClient, setPaymentClient }: any = useOutletContext();
    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    const [modules, setModules] = useState<ModuleItemType[]>([
        { key: "ATTENDANCE", name: "Attendance", active: null },
        { key: "CANTEEN", name: "Canteen", active: null },
        { key: "CCOMMUNITY", name: "Connected Community", active: null },
        { key: "PARO", name: "Messages (Paro)", active: null },
        { key: "ELEARNING", name: "SmartSapp Class", active: null },
        { key: "PAYMENT", name: "SmartSapp Pay", active: null },
    ]);


    useEffect(() => {
        loadCanteenDetails();
    }, []);


    const loadCanteenDetails = async () => {
        const result = await canteenModuleService.getModuleDetails({ schoolId: profile.id });
        if (result?.success) {
            setModules((prevState) => {
                const modules = [...prevState];
                const index = prevState.findIndex(item => item.key === 'CANTEEN');
                modules[index].active = result.data.isActiveModule;
                return modules;
            });
        } else {
        }
    }


    const activateDeactivateModule = async (module: ModuleItemType) => {
        openAlertConfirm({
            title: `${module.active ? "Deactivate" : "Activate"} ${module.name}`,
            message: `Are you sure you want to Deactivate ${module.name} for ${profile.name}?`,
            onConfirmed: async () => handleModuleActivationDeactivation(module),
            positiveButtonText: module.active ? "Deactivate" : "Activate",
            negativeButtonText: "Cancel",
        });
    }


    const handleModuleActivationDeactivation = async (module: ModuleItemType) => {
        if (module.key === "CANTEEN") {
            const result = await canteenModuleService.updateModuleDetails({ isActiveModule: !module.active }, { schoolId: profile.id });
            if (result?.success) {
                addToast({
                    text: `Canteen ${result.data.isActiveModule ? 'Activated' : 'Deactivated'} successfully`,
                    type: "success"
                });
                setModules((prevState) => {
                    const modules = [...prevState];
                    const index = prevState.findIndex(item => item.key === 'CANTEEN');
                    modules[index].active = result.data.isActiveModule;
                    return modules;
                });
            } else {
                addToast({
                    text: `Could not ${module.active ? 'Deactivated' : 'Activated'} canteen`,
                    type: "error"
                });
            }
        }
    }


    const syncPaymentTransactions = async () => {
        let { client } = paymentClient;

        if (!client) {
            const clientResult = await clientsService.getClientByReference(profile.id);
            if (clientResult.success) {
                client = clientResult.data;
            } else {
                addToast({
                    text: `Error syncing transactions`,
                    type: "error"
                });
                return;
            }
        }

        const syncResult = await jobsService.reconcileTransactions({ clientId: client.id });
        if (syncResult.success) {
            addToast({
                text: `Syncing Request sent successfully`,
                type: "success"
            });
        } else {
            addToast({
                text: `Error syncing transactions`,
                type: "error"
            });
        }
    };



    const moduleOptions = {
        PAYMENT: [
            {
                label: "Sync Transactions",
                call: syncPaymentTransactions
            }
        ]
    };


    return (
        <div className='max-w-3xl bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            <div className='flex justify-between items-center flex-wrap gap-2 mb-4'>
                <h2 className='font-medium text-xl mr-4'>SmartSapp Modules</h2>
            </div>

            <div className='max-w-full overflow-x-auto mb-6'>
                <table className="border-collapse table-auto w-full">
                    <thead>
                        <tr className='text-left border-b dark:border-gray-700'>
                            <th className='w-full py-3 px-4'>Module</th>
                            <th className='py-3 px-4'>Status</th>
                            <th className='py-3 px-4 text-right'>Action</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y dark:divide-gray-700/60">
                        {
                            modules.map((moduleItem) => {
                                const options = moduleOptions[moduleItem.key as keyof typeof moduleOptions];

                                return (
                                    <tr key={moduleItem.key}>
                                        <td className='py-3 px-4'>{moduleItem.name}</td>
                                        <td className='py-3 px-4'>
                                            {
                                                moduleItem.active &&
                                                <span className="inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1.5 px-3">
                                                    Active
                                                </span>
                                            }
                                            {
                                                moduleItem.active === false &&
                                                <span className="inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1.5 px-3">
                                                    Inactive
                                                </span>
                                            }
                                        </td>
                                        <td className='py-3 px-4 text-right'>
                                            {
                                                (moduleItem.active !== null || options) &&
                                                <Menu as="div" className="relative inline-block text-left">
                                                    {
                                                        ({ open }) => (
                                                            <PopoverWithFloat placement='bottom-end' portal>
                                                                <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ${open ? 'bg-black/5 dark:bg-white/5' : ''}`}>
                                                                    <i className="ri-more-2-fill"></i>
                                                                </Menu.Button>

                                                                <Menu.Items as='div' unmount={false} className="w-72 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                                    {
                                                                        moduleItem.active !== null &&
                                                                        <Menu.Item as="button" onClick={() => activateDeactivateModule(moduleItem)} type='button' className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                            {moduleItem.active ? "Deactivate" : "Activate"} Module
                                                                        </Menu.Item>
                                                                    }
                                                                    {
                                                                        options && options.length > 0 &&
                                                                        <React.Fragment>
                                                                            {
                                                                                options.map(optionItem =>
                                                                                    <Menu.Item key={moduleItem.key + optionItem.label} as="button" onClick={optionItem.call} type='button' className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                                        {optionItem.label}
                                                                                    </Menu.Item>
                                                                                )
                                                                            }
                                                                        </React.Fragment>
                                                                    }
                                                                </Menu.Items>
                                                            </PopoverWithFloat>
                                                        )
                                                    }
                                                </Menu>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}


interface ModuleItemType {
    key: string;
    name: string;
    active: boolean | null;
}


export default SchoolModulesListPage;