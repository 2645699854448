import { useSelector } from 'react-redux';

import { selectCurrentUser } from '../store/reducers/entities/authSlice';
import permissions from '../../config/permissions';


type Permission = keyof typeof permissions;

function useAuth() {
    const user = useSelector(selectCurrentUser);


    const hasPermission = (permission: Permission) => {
        const permissions: string[] = user?.role?.permissions;

        if (permissions.includes('ROOT')) {
            return true;
        }

        return permissions.includes(permission.toString());
    }


    const hasAllPermission = (permission: Permission[]) => {
        const permissions: string[] = user?.role?.permissions;

        if (permissions.includes('ROOT')) {
            return true;
        }

        return permission.every(item => permissions.includes(item));
    }


    return { hasPermission, hasAllPermission };
}

export default useAuth;