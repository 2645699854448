import React from 'react';
import { Form, Formik } from 'formik';

import { FormInput, PhoneNumberInput } from '../../../../../../../../../../components/form';


function SchoolCanteenCSPManagersListFilters({ initialData, searching, onSearch }: Props) {

    const handleSubmit = (values: any, { reset }: any) => {
        const payload: any = {};

        if (values.name || initialData['name']) { payload['name'] = values.name }
        if (values.primaryContact || initialData['primaryContact']) { payload['primaryContact'] = values.primaryContact }

        onSearch(values);
    }


    return (
        <Formik
            initialValues={{
                name: initialData['name'] || '',
                primaryContact: initialData['primaryContact'] || '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ errors, touched, setErrors, submitCount, values, isValid, setFieldValue, resetForm }) => (
                <Form className="flex-grow flex items-center flex-wrap mr-8 mb-4 gap-y-4">

                    <FormInput type="text" id="input-search-name" name="name" className="lg:w-full lg:max-w-xs mr-4" placeholder="Search name" autoComplete='off' />
                    {/* <FormInput type="text" id="input-search-name" name="primaryContact" className="lg:w-full lg:max-w-xs mr-4" placeholder="Search name" autoComplete='off' /> */}
                    <div className='lg:w-full lg:max-w-xs mr-4'>
                        <PhoneNumberInput
                            id="input-contact-number"
                            name="contact"
                            className="w-full"
                            placeholder="Search number"
                            value={values["primaryContact"]}
                            onChange={value => setFieldValue('primaryContact', value)}
                        />
                    </div>

                    <button type='submit' className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}>Search</button>
                </Form>
            )}
        </Formik>
    );
}


interface Props {
    initialData: any;
    onSearch: (values: any) => void;
    searching: boolean;
}


export default SchoolCanteenCSPManagersListFilters;