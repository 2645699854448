import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { FormInput, MultiSelectInput, TextArea } from '../../../../../components/form';
import { Button, ErrorLoading } from '../../../../../components/common';
import userRolesService from '../../../../../services/api/user-accounts/userRolesService';
import usersPermissionsService from '../../../../../services/api/user-accounts/usersPermissionsService';
import { ListLoader } from '../../../../../components/loaders';


function ModalUserRoleForm({ isOpen, role, onClose, onRoleAdded, onRoleUpdated }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    const [optionsLoadingStatus, setOptionsLoadingStatus] = useState({ loading: false, error: false });
    const [permissions, setPermissions] = useState([]);


    useEffect(() => {
        if (isOpen) {
            if (permissions.length === 0) {
                getOptions();
            }
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);


    const getOptions = async () => {
        setOptionsLoadingStatus({ loading: true, error: false });
        const result = await usersPermissionsService.getPermissions({});

        if (result.success) {
            setOptionsLoadingStatus({ loading: false, error: false });
            setPermissions(result.data);
        } else {
            setOptionsLoadingStatus({ loading: false, error: true });
        }
    }



    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        const payload: any = { ...formData };
        payload['key'] = payload['label'] = payload.name;
        payload.permissions = payload.permissions.map((p: any) => p.name);

        setSubmitStatus({ loading: true, failedMessage: '' });
        if (role) {
            // update
            payload['id'] = role.id;
            const result = await userRolesService.updateRole(payload);
            if (result.success) {
                if (onRoleUpdated) {
                    onRoleUpdated({ ...role, ...result.data });
                }
                //resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        } else {
            // create
            const result = await userRolesService.createRole(payload);

            if (result.success) {
                if (onRoleAdded) {
                    onRoleAdded(result.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    }


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[32rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {role ? "Edit" : "Add"} Role
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>
                {
                    !optionsLoadingStatus.loading && !optionsLoadingStatus.error &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <Formik
                            initialValues={{
                                name: role ? role.label : '',
                                description: role ? role.description : '',
                                permissions: role ? role.permissions : [],
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                                <Form className="">
                                    {
                                        submitStatus.failedMessage && !submitStatus.loading &&
                                        <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                    }

                                    <div className="mb-6">
                                        <label htmlFor="input-role-name" className='block text-sm mb-2'>Role</label>
                                        <FormInput type="text" name="name" id="input-role-name" className="w-full" placeholder="Enter role" autoComplete='off' />
                                        {touched['name'] && errors['name'] && <p className='text-sm text-red-500 mt-2'>Role name is Required</p>}
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="input-role-description" className='block text-sm mb-2'>Description <span className='opacity-50'>(Optional)</span></label>
                                        <TextArea name="description" id="input-role-description" className="w-full" placeholder="Enter description" autoComplete='off' />
                                        {touched['description'] && errors['description'] && <p className='text-sm text-red-500 mt-2'>Role description is Required</p>}
                                    </div>

                                    <div className="mb-10">
                                        <label htmlFor="input-role-permissions" className='block text-sm mb-2'>Permissions</label>
                                        <MultiSelectInput
                                            items={permissions}
                                            valueKey='id'
                                            labelKey='name'
                                            placeholder="Select permissions"
                                            selectedLabel='Permission'
                                            selectedLabelPlural='Permissions'
                                            showSelectedItems={false}
                                            value={values["permissions"]}
                                            onChange={(selected: any[]) => setFieldValue("permissions", selected)}
                                            onBlur={() => setFieldTouched('permissions', true)}
                                        />
                                        {touched['permissions'] && errors['permissions'] && <p className='text-sm text-red-500 mt-2'>Select at least one permission</p>}
                                    </div>


                                    <div className='flex justify-end items-center'>
                                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                            Cancel
                                        </Button>

                                        <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                            {role ? "Save" : "Add Role"}
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                }

                {
                    (optionsLoadingStatus.loading || optionsLoadingStatus.error) &&
                    <div className='pb-10'>
                        {
                            optionsLoadingStatus.loading &&
                            <ListLoader loadingText='Loading Options' className='py-10' loaderSize={2} />
                        }
                        {
                            optionsLoadingStatus.error &&
                            <ErrorLoading title='Error Loading Options' message='An unexpected error occurred while loading options' className='px-8 py-16' onTryAgain={getOptions} />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required().label(""),
    //description: Yup.string().trim().required().label(""),
    permissions: Yup.array().min(1).required().label(""),
});


interface Props {
    isOpen: boolean;
    role: any;
    onClose: () => void;
    onRoleAdded?: (role: any) => void;
    onRoleUpdated?: (role: any) => void;
}


export default ModalUserRoleForm;