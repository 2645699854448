import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { FormInput, FormPasswordInput } from '../../../components/form';
import { Button } from '../../../components/common';
import SmartSappLogo from '../../../components/svgs/SmartSappLogo';
import authService from '../../../services/api/user-accounts/authService';
import { loginAction } from '../../../state/store/reducers/entities/authSlice';
import userSessionService from '../../../services/local/userSessionService';


function LoginPage() {
    const navigate = useNavigate();
    const { state: locationState } = useLocation();
    const dispatch = useDispatch();

    const [loginStatus, setLoginStatus] = useState<SubmitStatus>({ loading: false, failedMessage: '' });


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        setLoginStatus({ loading: true, failedMessage: '' });
        const result = await authService.login(formData);

        if (result.success) {
            if (result.data?.role?.permissions.includes("BO_USER") || result.data?.role?.permissions.includes("ROOT")) {
                dispatch<any>(loginAction({ email: formData.username, ...result.data }));
                userSessionService.setExpiry(Date.now() + (3600 * 1000));

                resetForm();

                if (locationState) {
                    const { redirectTo } = locationState as RedirectLocationState;
                    navigate(`${redirectTo.pathname}${redirectTo.search}`, { replace: true });
                } else {
                    navigate('/', { replace: true });
                }
            } else {
                setLoginStatus({ loading: false, failedMessage: "You don't have permission the access BackOffice" });
            }
        } else {
            setLoginStatus({
                loading: false,
                failedMessage: result.data.message
            });
        }
    }



    return (
        <main className='login-page bg-blue/5 dark:bg-gray-900 bg-no-repeat min-h-screen px-8 py-12 sm:py-14 md:py-16 lg:py-20 xl:py-36'>
            <div className='sm:max-w-md bg-white dark:bg-gray-950 rounded-3xl mx-auto py-12 px-8 sm:px-10'>
                <div className='text-center mb-8'>
                    <span className='inline-block w-14 text-blue-500 '>
                        <SmartSappLogo />
                    </span>
                    <p className='text-2xl font-medium mb-0.5'>SmartSapp</p>
                    <h1 className='text-gray-500 dark:text-gray-400'>Back Office Login</h1>
                </div>
                <div className='mb-7'>
                    <Formik
                        initialValues={{
                            username: (locationState && locationState.email) ? locationState.email : '',
                            password: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ errors, touched, setErrors, submitCount, values, isValid }) => (
                            <Form className="" autoComplete="off">
                                {
                                    (!isValid && submitCount > 0) &&
                                    <p className='text-center text-red-500 mb-4' >Username and password required</p>
                                }

                                {
                                    (loginStatus.failedMessage) &&
                                    <p className='text-center text-red-500 mb-4' >{loginStatus.failedMessage}</p>
                                }

                                <div className="mb-6">
                                    <label htmlFor="input-username" className='block text-sm mb-1.5'>Email</label>
                                    <FormInput type="email" id="input-username" name="username" className="w-full" placeholder="Enter your email" autoFocus />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="input-password" className='block mb-1.5'>Password</label>
                                    <input autoComplete="false" name="hidden" type="password" style={{ display: 'none' }} />
                                    <FormPasswordInput id="input-password" name="password" className="w-full" autoComplete='off' placeholder="Enter your password" />
                                    {/* <FormInput type="password" id="input-password" name="password" className="w-full" autoComplete='off' placeholder="Enter your password" /> */}
                                </div>

                                <div className='flex justify-end mb-8'>
                                    <Link to="/forgot-password" state={{ username: values.username }} className='text-blue-400 hover:text-blue-600'>Forgot Password?</Link>
                                </div>

                                <div className='mb-10'>
                                    <Button type='submit' loading={loginStatus.loading} className='w-full' disabled={loginStatus.loading}>
                                        Login
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </main>
    );
}


const validationSchema = Yup.object().shape({
    username: Yup.string().trim().required().email().label(""),
    password: Yup.string().trim().required().label(""),
});



interface SubmitStatus {
    loading: boolean;
    failedMessage: any;
}


type RedirectLocationState = {
    redirectTo: Location;
};


export default LoginPage;