import { RootState } from './../../index';
import { createSlice } from "@reduxjs/toolkit";
import { DispatchType } from "../..";

import userSessionService from '../../../../services/local/userSessionService';


const initialState = {
    user: null as any,
    userToken: '',
};


const slice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            const { token, ...user } = action.payload;
            state.userToken = token;
            state.user = user;
        },
        resetCurrentUser: (state, action) => {
            state = initialState;
        }
    }
});


const { setCurrentUser, resetCurrentUser } = slice.actions;



// Actions
export const loginAction = (payload: object) => async (dispatch: DispatchType) => {
    // store session locally
    userSessionService.storeToken(payload);

    dispatch(setCurrentUser(payload));
}


export const setUserSessionAction = () => (dispatch: DispatchType, getState: () => RootState) => {
    const { userToken } = getState().entities.auth;

    if (!userToken) {
        const cachedUser = userSessionService.getToken();
        const expires = userSessionService.getExpiry();

        if (cachedUser && expires > Date.now()) {
            dispatch(setCurrentUser(cachedUser));
            return true;
        }

        return false;
    }

    return true;
}



export const logoutAction = () => (dispatch: DispatchType) => {
    userSessionService.removeToken();
    dispatch(resetCurrentUser({}));
}


// selectors
export const selectCurrentUser = (state: RootState) => state.entities.auth.user;
export const selectCurrentUserToken = (state: RootState) => state.entities.auth.userToken;


export default slice.reducer;
