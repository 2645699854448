import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import useToast from '../../../../../../../../../state/hooks/useToast';
import useAlert from '../../../../../../../../../state/hooks/useAlert';
import { Button, ErrorLoading, ListNoData, Pagination } from '../../../../../../../../../components/common';
import { Menu } from '@headlessui/react';
import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import { paginationInfo } from '../../../../../../../../../utils/pagination';
import { ListLoader } from '../../../../../../../../../components/loaders';
import canteenMembersService from '../../../../../../../../../services/api/canteen/canteenMembersService';
import userAccountsService from '../../../../../../../../../services/api/user-accounts/userAccountsService';
import usePagedListApi from '../../../../../../../../../state/hooks/usePagedListApi';
import ModalCanteenCSPManagerForm from '../../modals/ModalCanteenCSPManagerForm';
import SchoolCanteenCSPManagersListFilters from './components/SchoolCanteenCSPManagersListFilters';
//import { loadList, resetList, selectList } from '../../../../../../../../state/store/reducers/entities/listPageSlice';



function SchoolCanteenCSPManagersListPage() {
    const { profile }: any = useOutletContext();

    const dispatch = useDispatch();
    // const cspMangers = useSelector(selectList);
    const { data: cspMangers, requestData, resetRecords, updateRecord } = usePagedListApi(canteenMembersService.getMembers);
    const [searchParams, setSearchParams] = useSearchParams();

    const [managerForm, setManagerForm] = useState<{ open: boolean; manager: any }>({ open: false, manager: null });

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    // get query params
    //const page = Number(searchParams.get('page')) || cspMangers.pager.page;
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};
    if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }
    if (searchParams.get('primaryContact')) { searchFilters['primaryContact'] = searchParams.get('primaryContact') || '' }


    /* useEffect(() => {
        return () => {
            dispatch(resetList({}));
        }
    }, []); */


    useEffect(() => {
        getManagers();
    }, [searchParams, dispatch]);


    const getManagers = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                memberType: 'EMPLOYEE',
                showRelated: true,
                permissions: 'CSP_MANAGER',
                schoolId: profile.id,
                //'$sort[createdAt]': -1,
            },
        });
        /* await dispatch<any>(loadList({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                memberType: 'EMPLOYEE',
                showRelated: true,
                permissions: 'CANTEEN_MANAGER',
                schoolId: profile.id,
                //'$sort[createdAt]': -1,
            },
        }, canteenMembersService.getMembers)); */
    };



    const setManagerPasswordReset = async (manager: any) => {
        const payload: any = {
            primaryContact: manager.primaryContact
        };

        if (manager.accountId) {
            payload.action = 'FORGOT_PASSWORD';
            const resetResult = await userAccountsService.sendPasswordReset(profile.id, payload);
            if (resetResult.success) {
                addToast({
                    text: `Reset code sent to ${manager.firstName} ${manager.lastName}`,
                    type: "success"
                });
                return;
            }
        } else {
            payload.action = 'RESEND_INVITATION';
            payload.accountId = manager.referenceId;

            const resetResult = await userAccountsService.sendPasswordReset(profile.id, payload);
            if (resetResult.success) {
                addToast({
                    text: `Activation code sent to ${manager.firstName} ${manager.lastName}`,
                    type: "success"
                });
                return;
            }
        }

        addToast({
            text: `Could not sent reset code to ${manager.firstName} ${manager.lastName}`,
            type: "error"
        });
    }


    const handleManagerDelete = (manager: any) => {
        openAlertConfirm({
            title: 'Delete Canteen Owner',
            message: `Are you sure you want to delete ${manager.firstName} ${manager.otherNames || ''} ${manager.lastName} from canteen owners?`,
            onConfirmed: () => deleteManager(manager),
            positiveButtonText: "Delete",
            negativeButtonText: 'Cancel',
            positiveButtonClass: 'bg-red-500 hover:bg-red-600',
        });
    }


    const deleteManager = async (manager: any) => {
        const result = await canteenMembersService.deleteMember(manager.id);
        if (result.success) {
            //dispatch(resetList({}));
            resetRecords();
            getManagers();
            addToast({
                text: "Canteen owner deleted successfully",
                type: "success"
            });
        } else {
            addToast({
                title: "Error",
                text: "Canteen owner could not be deleted",
                type: "error"
            });
        }
    }


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            <div className='flex justify-between flex-wrap gap-2 mb-6'>
                <h2 className='font-medium text-xl mr-4'>Canteen Owners</h2>
                {
                    !cspMangers.loadingError && !cspMangers.loading && cspMangers.list.length > 0 &&
                    <Button type='button' onClick={() => setManagerForm({ open: true, manager: null })} className='py-2.5'>Add Owner</Button>
                }
            </div>


            <div>
                <SchoolCanteenCSPManagersListFilters
                    initialData={searchFilters}
                    onSearch={(values) => {
                        //setSearchParams({ ...values });
                        setSearchParams(current => {
                            const entries = { ...Object.fromEntries(current.entries()), ...values };
                            if (entries.page) {
                                entries.page = '1';
                            }
                            return entries;
                        });
                    }}
                    searching={cspMangers.loading}
                />
            </div>


            {
                // if there're managers
                !cspMangers.loadingError && !cspMangers.loading && cspMangers.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 pl-2 pr-6'>Name</th>
                                    <th className='py-6 px-6'>Phone Number</th>
                                    <th className='whitespace-nowrap py-6 px-6'>Vendors</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 pl-6 pr-2 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    cspMangers.list.map((managerItem: any) =>
                                        <tr key={managerItem?.id}>
                                            <td className='py-4 pl-2 pr-6'>
                                                {managerItem?.firstName} {managerItem?.otherNames || ''} {managerItem?.lastName}
                                            </td>
                                            <td className='py-4 px-6'>{managerItem?.primaryContact}</td>
                                            <td className='py-4 px-6'>

                                                <div className='flex gap-2.5'>
                                                    {
                                                        managerItem?.serviceProviders
                                                            .slice(0, 2)
                                                            .map((permissionItem: any) =>
                                                                <span
                                                                    key={permissionItem.id}
                                                                    className='text-sm whitespace-nowrap bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'
                                                                >
                                                                    {permissionItem.name}
                                                                </span>
                                                            )
                                                    }
                                                    {
                                                        managerItem?.serviceProviders.length > 2 &&
                                                        <span className='text-sm bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>
                                                            +{managerItem?.serviceProviders.length - 2}
                                                        </span>
                                                    }
                                                    {/*  {
                                                        managerItem?.serviceProviders.length > 0 &&
                                                        <button type='button' onClick={() => null} className='opacity-0 group-hover:opacity-90 font-medium whitespace-nowrap text-sm transition-all duration-300 hover:text-blue p-2 mx-1'>
                                                            {managerItem?.serviceProviders.length > 2 ? 'View All' : 'View'}
                                                        </button>
                                                    } */}
                                                </div>

                                                {/* {managerItem?.serviceProviders?.length > 0 && managerItem?.serviceProviders[0]?.name} */}
                                            </td>
                                            <td className='py-4 px-6'>
                                                <span className={`inline-block font-medium text-sm uppercase ${managerItem?.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{managerItem?.status || "Inactive"}</span>
                                            </td>
                                            <td className='py-4 pl-6 pr-2 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-72 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setManagerPasswordReset(managerItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                {managerItem?.accountId ? "Send Password Reset Details" : "Resend Activation Details"}
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => setManagerForm({ open: true, manager: managerItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => handleManagerDelete(managerItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                Delete
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(cspMangers.pager.pageSize, cspMangers.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={cspMangers.pager.pageSize}
                            totalCount={cspMangers.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if managers are loading
                !cspMangers.loadingError && cspMangers.loading &&
                <ListLoader loadingText='Loading Canteen Ownerss' className='px-8 py-24' />
            }

            {
                // if there're no managers in the school
                !cspMangers.loadingError && !cspMangers.loading && cspMangers.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Canteen Owner' description='No canteen owner added to school yet' className='px-8 py-24'>
                    {/* <Button type='button' onClick={() => setManagerForm({ open: true, serviceProvider: null })} className='py-2.5'>Add Vendor</Button> */}
                </ListNoData>
            }

            {
                // if there're no manager found in search
                !cspMangers.loadingError && !cspMangers.loading && cspMangers.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Canteen Owner' description='No canteen owner was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                cspMangers.loadingError &&
                <ErrorLoading title='Error Loading Canteen Owners' message='There was a problem loading Canteen Owners' className='px-8 py-24' onTryAgain={getManagers} />
            }


            <ModalCanteenCSPManagerForm
                isOpen={managerForm.open}
                manager={managerForm.manager}
                //serviceProvider={managerForm.manager?.serviceProviders[0]}
                school={profile}
                onManagerAdded={(manager) => {
                    //const serviceProvider: any = serviceManagerForm?.serviceProvider;
                    addToast({
                        text: `Owner added successfully`,
                        type: "success"
                    });

                    resetRecords();
                    setSearchParams({});

                    if (Array.from(searchParams.entries()).length === 0) {
                        getManagers();
                    }
                }}
                onManagerUpdated={(manager) => {
                    //dispatch(updateRecord({ record: manager, page }));
                    updateRecord({ item: manager, page });
                    addToast({
                        text: `Owner updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setManagerForm((prevState) => ({ ...prevState, open: false }))}
            />
        </div>
    );
}

export default SchoolCanteenCSPManagersListPage;