import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';


function Modal({ children, open, initialFocus, onClose, useDefaultStyles = true, closeOnEscapePressed = true, closeOnOutsideClicked = true }: Props) {

    /*     useEffect(() => {
            // Bind the event listener
            document.addEventListener("keydown", handleKeydown);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("keydown", handleKeydown);
            };
        });
    
        const handleKeydown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            }
        }; */

    return (
        <Transition show={open} as={Fragment}>
            <Dialog
                className="relative z-50"
                //onClose={closeOnEscapePressed ? onClose : () => null}
                onClose={() => null}
                initialFocus={initialFocus}
                onClick={(event) => event.stopPropagation()}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                            if (closeOnOutsideClicked) {
                                onClose();
                            }
                        }}
                        className="fixed inset-0 bg-black/40 dark:bg-black/70"
                    />
                </Transition.Child>


                <div className="fixed inset-0 flex justify-center items-start pointer-events-none">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={`relative inline-block max-w-full max-h-full bg-red-400 transition-all transform shadow-2xl overflow-hidden pointer-events-auto ${useDefaultStyles ? "bg-white dark:bg-gray-950 dark:text-gray-50 dark:border border-gray-900 rounded-2xl mt-14 mb-10 mx-4" : ""}`}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )

}


interface Props {
    open: boolean;
    onClose: () => void;
    children: JSX.Element | JSX.Element[];
    initialFocus?: any;
    useDefaultStyles?: boolean;
    closeOnEscapePressed?: boolean;
    closeOnOutsideClicked?: boolean;
}


export default Modal;






{/* <Transition appear show={open} as={Fragment}>
<Dialog
    as="div"
    className="relative z-50"
    onClose={keyboardBackdropClose ? onClose : () => null}
    initialFocus={initialFocus}
    unmount={true}
>
    <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >
        <div className="fixed inset-0 bg-black/40 dark:bg-black/70" />
    </Transition.Child>

    <div className="fixed inset-0 overflow-y-auto" >
        <div className="flex justify-center items-start min-h-full p-4 text-center">
            {
                !preventBackdropClose &&
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="inline-block overflow-hidden text-left align-middle bg-white dark:bg-gray-950 dark:text-gray-50 dark:border border-gray-900 shadow-2xl transition-all transform mt-14 mb-8 rounded-xl">
                        {children}
                    </Dialog.Panel>
                </Transition.Child>
            }
            {
                preventBackdropClose &&
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block overflow-hidden text-left align-middle bg-white dark:bg-gray-950 dark:text-gray-50 dark:border border-gray-900 shadow-2xl transition-all transform mt-14 mb-8 rounded-xl">
                        {children}
                    </div>
                </Transition.Child>
            }
        </div>
    </div>
</Dialog>
</Transition> */}