import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { RadioGroup, Switch } from '@headlessui/react';
import { useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';

import Modal from '../../../../../../../../components/modals/Modal';
import { MultiSelectInput } from '../../../../../../../../components/form';
import { Button, ErrorLoading } from '../../../../../../../../components/common';
import { ListLoader } from '../../../../../../../../components/loaders';
import directoriesService from '../../../../../../../../services/api/payments/directoriesService';
import configurationService from '../../../../../../../../services/api/payments/configurationService';
import useAlert from '../../../../../../../../state/hooks/useAlert';


function ModalAllowNegativeTransactions({ isOpen, client, school, onClose, onRequirementChanged }: Props) {
    const { directoryList, setDirectoryList }: any = useOutletContext();

    const [submitStatus, setSubmitStatus] = useState({ loading: false, error: '' });
    const [optionsLoadingStatus, setOptionsLoadingStatus] = useState({ loading: false, error: false });


    const { openAlertConfirm } = useAlert();


    useEffect(() => {
        if (isOpen && directoryList.length === 0) {
            getOptions();
        }
    }, [isOpen]);



    const getOptions = async () => {
        setOptionsLoadingStatus({ loading: true, error: false });
        const directoriesResult = await directoriesService.getDirectories({ clientId: client.id });

        if (directoriesResult.success) {
            setDirectoryList(directoriesResult.data);
            setOptionsLoadingStatus({ loading: false, error: false });
        } else {
            setOptionsLoadingStatus({ loading: false, error: true });
        }
    };





    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        const payload: any = { ...formData, clientId: client.id };

        if (payload.campuses === 'all') {
            payload.allowNegativeTransactionDirectoryIds = [];
        } else {
            payload.allowNegativeTransactionDirectoryIds = payload.allowNegativeTransactionDirectoryIds.map((item: any) => item.id);
        }
        delete payload.campuses;

        openAlertConfirm({
            title: 'Confirm Changes',
            message: `Are you sure you want to make changes to SikaID transactions for ${client.name}?`,
            onConfirmed: () => handleSave(payload, resetForm),
            positiveButtonText: "Save Changes",
            negativeButtonText: 'Cancel',
        });
    }

    const handleSave = async (payload: any, resetForm: () => void) => {
        setSubmitStatus({ loading: true, error: '' });

        const result = await configurationService.updateNegativeTransactions(payload);
        if (result.success) {
            if (onRequirementChanged) {
                onRequirementChanged({ ...result.data });
            }
            //resetForm();
            onClose();
            setSubmitStatus({ loading: false, error: '' });
        } else {
            setSubmitStatus({ loading: false, error: result.message });
        }
    }



    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-screen max-w-xl">
                <div className="flex justify-between mb-6 px-10 pt-8">
                    <div className='mr-5'>
                        <h2 className="font-medium text-xl">
                            Allow Negative SikaID Transactions
                        </h2>
                    </div>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                {
                    !optionsLoadingStatus.loading && !optionsLoadingStatus.error &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <Formik
                            initialValues={{
                                allowNegativeTransaction: false,
                                allowNegativeTransactionDirectoryIds: [],
                                allowNegativeTransactionGroupIds: [],
                                allowNegativeTransactionCustomerIds: [],

                                campuses: true ? 'all' : 'specific',
                            }}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                                <Form className="">
                                    {
                                        submitStatus.error && !submitStatus.loading &&
                                        <p className='text-red-500 mb-4' >{submitStatus.error}</p>
                                    }


                                    <div className='mb-6'>
                                        <div className='flex justify-between items-center bg-gray-100 dark:bg-white/5 gap-x-5 gap-y-1 rounded-lg py-3.5 px-4'>
                                            <div className=''>
                                                <p className='font-medium'>Allow Negative SikaID Transaction</p>
                                            </div>

                                            <Switch
                                                checked={values["allowNegativeTransaction"]}
                                                onChange={(on: boolean) => setFieldValue("allowNegativeTransaction", on)}
                                                className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                            >
                                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                            </Switch>
                                        </div>
                                    </div>



                                    <div className='mb-10'>
                                        <RadioGroup value={values['campuses']} onChange={(value) => setFieldValue('campuses', value)}>
                                            {/* <RadioGroup.Label as='p' className="font-medium mb-3">Campuses</RadioGroup.Label> */}
                                            <div className={`divide-y ${values['allowNegativeTransaction'] ? "bg-green-500/5 divide-green-500/40" : "bg-yellow-500/5  divide-yellow-500/40"} rounded-lg px-5`}>
                                                <RadioGroup.Option value="all" className="">
                                                    {({ checked }) => (
                                                        <div className="flex cursor-pointer py-6">
                                                            <span className={`text-xl ${checked ? `${values['allowNegativeTransaction'] ? 'text-green-500' : 'text-yellow-500'}` : 'text-gray-500 dark:text-gray-400'} mr-3`}>
                                                                {checked ? <i className="ri-radio-button-line"></i> : <i className="ri-checkbox-blank-circle-line"></i>}
                                                            </span>
                                                            <div>
                                                                {
                                                                    values['allowNegativeTransaction'] ?
                                                                        <p><b>ALLOW NEGATIVE TRANSACTIONS</b> for all students</p> :
                                                                        <p><b>DO NOT ALLOW NEGATIVE TRANSACTIONS</b> for all students</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </RadioGroup.Option>

                                                <div>
                                                    <RadioGroup.Option value="specific">
                                                        {({ checked }) => (
                                                            <div className="flex cursor-pointer py-6">
                                                                <span className={`text-xl ${checked ? `${values['allowNegativeTransaction'] ? 'text-green-500' : 'text-yellow-500'}` : 'text-gray-500 dark:text-gray-400'} -mt-1 mr-3`}>
                                                                    {checked ? <i className="ri-radio-button-line"></i> : <i className="ri-checkbox-blank-circle-line"></i>}
                                                                </span>
                                                                <div>
                                                                    {
                                                                        values['allowNegativeTransaction'] ?
                                                                            <p><b>ALLOW NEGATIVE TRANSACTIONS</b> for students in selected campuses</p> :
                                                                            <p><b>DO NOT ALLOW NEGATIVE TRANSACTIONS</b> for selected campuses</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </RadioGroup.Option>

                                                    {
                                                        values['campuses'] === 'specific' &&
                                                        <div className='pb-5 -mt-2'>
                                                            <label htmlFor="modal-select-groups" className='block mb-2'>Select campuses</label>
                                                            <div className="">
                                                                <MultiSelectInput
                                                                    items={directoryList}
                                                                    valueKey='id'
                                                                    labelKey='name'
                                                                    placeholder="Select Campuses"
                                                                    value={values["allowNegativeTransactionDirectoryIds"]}
                                                                    //itemsShowLimit={6}
                                                                    selectedLabel='Campus'
                                                                    selectedLabelPlural='Campuses'
                                                                    showSelectedItems={false}
                                                                    onChange={(value) => setFieldValue("allowNegativeTransactionDirectoryIds", value)}
                                                                    onBlur={() => setFieldTouched('allowNegativeTransactionDirectoryIds', true)}
                                                                />
                                                                {/*   {touched['groupIds'] && errors['groupIds'] && <p className='text-sm text-red-500 mt-2'>Select at least a group to receive feed</p>} */}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </RadioGroup>
                                    </div>


                                    <div className='flex justify-end items-center mt-10'>
                                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                            Cancel
                                        </Button>

                                        <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                }

                {
                    (optionsLoadingStatus.loading || optionsLoadingStatus.error) &&
                    <div className='pb-10'>
                        {
                            optionsLoadingStatus.loading &&
                            <ListLoader loadingText='Loading Options' className='py-10' loaderSize={2} />
                        }
                        {
                            optionsLoadingStatus.error &&
                            <ErrorLoading title='Error Loading Options' message='An unexpected error occurred while loading options' className='px-8 py-16' onTryAgain={getOptions} />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


interface Props {
    isOpen: boolean;
    client: any;
    school: any;
    //paymentConfig: any;
    onClose: () => void;
    onRequirementChanged?: (paymentConfig: any) => void;
}


const validationSchema = Yup.object().shape({

});


export default ModalAllowNegativeTransactions;