import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { RadioGroup } from '@headlessui/react';
import { debounce } from 'lodash';

import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
import { ListLoader } from '../../../../../components/loaders';
import { Button, ErrorLoading } from '../../../../../components/common';
import { FormInput, FormTextArea, MultiSelectInput, SelectInput } from '../../../../../components/form';
import regexStrings from '../../../../../config/regexStrings';
import useToast from '../../../../../state/hooks/useToast';
import ModalAppFeedDetails from '../modals/ModalAppFeedDetails';
import appFeedService from '../../../../../services/api/paro/appFeedService';
import feedCategoriesService from '../../../../../services/api/paro/feedCategoriesService';
import feedGroupsService from '../../../../../services/api/paro/feedGroupsService';

const maxImageFileSize = 1; // 1MB


function CreateFeedPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { feedId } = useParams();

    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    const [pageStatus, setPageStatus] = useState({ loading: false, error: false, notFound: false });
    const [feed, setFeed] = useState<any>(null);
    const [feedPreview, setFeedPreview] = useState({ open: false });

    //const [groupList, setGroupList] = useState<any[]>();
    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [groupListData, setGroupListData] = useState({ loading: false, list: [] });

    const { addToast } = useToast();


    useEffect(() => {
        dispatch(changePageInfo({ title: feedId ? "Edit Feed" : "Create Feed" }));

        if (feedId) {
            if (location.state && location.state.feed) {
                setFeed(location.state.feed);
            } else {
                getFeed();
            }
        }

        getOptions();
    }, []);



    const getFeed = async () => {
        setPageStatus({ loading: true, error: false, notFound: false });
        const result = await appFeedService.getSingleFeed(feedId);
        if (result?.success) {
            setFeed(result.data);
            setPageStatus({ loading: false, error: false, notFound: false });
            await getOptions();
        } else {
            if (result.notFound) {
                setPageStatus({ loading: false, error: true, notFound: true });
            } else {
                setPageStatus({ loading: false, error: true, notFound: false });
            }
        }
    }


    const getOptions = async () => {
        await getCategories();
        //await getGroups();
    }


    const getCategories = async () => {
        const result = await feedCategoriesService.getCategories({});
        if (result.success) {
            setCategoryList(result.data);
        }
    }

    /*  const getGroups = async () => {
         const result = await feedGroupsService.getGroups({ pageSize: 20, findFeedGroups: true });
         if (result.success) {
             setGroupList(result.data);
         }
     } */

    const getGroups = debounce(async (searchQuery: string) => {
        setGroupListData({ loading: true, list: [] });
        const result = await feedGroupsService.getGroups({ pageSize: 20, findFeedGroups: true, name: searchQuery });
        if (result.success) {
            setGroupListData({ loading: false, list: result.data });
        } else {
            setGroupListData({ loading: false, list: [] });
        }
    }, 500)


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        const payload: any = { ...formData };
        payload['sender'] = "SmartsApp";

        if (payload.mediaType === "none") {
            payload.mediaFiles = [];
        }

        if (payload.mediaType === "videoUrl") {
            payload.mediaFiles = [
                {
                    "url": payload.videoUrl,
                    "type": "video"
                }
            ]
        }

        //if (payload.mediaType === 'image') {
        /* // update
        if (payload['mediaFiles'].length > 0 && !payload['mediaFiles'][0].startsWith('data:image')) {
            // then it's base64
            payload['mediaFiles'] = feed.mediaFiles;
        } */
        //}


        if (payload.to === "all") {
            payload.groupIds = [];
        }

        if (payload.to === "specific") {
            payload.groupIds = payload.groupIds.map((item: any) => item.id);
        }


        payload.hasMedia = payload.mediaFiles.length > 0;


        // clean up payload
        delete payload.to;
        delete payload.mediaType;
        delete payload.videoUrl;

        setSubmitStatus({ loading: true, failedMessage: '' });
        if (feed) {
            const result = await appFeedService.updateFeed(feed.id, payload);
            if (result.success) {
                //setSubmitStatus({ loading: false, failedMessage: '' });
                addToast({
                    text: "Feed updated successfully",
                    type: 'success'
                });
                navigate(-1);
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        } else {
            // create
            const result = await appFeedService.createFeed(payload);
            if (result.success) {
                // setSubmitStatus({ loading: false, failedMessage: '' });
                addToast({
                    text: "Feed added successfully",
                    type: 'success'
                });
                navigate('/feeds');
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    };



    const getFormMediaType = (mediaFiles: any[]): string => {
        if (mediaFiles && mediaFiles.length > 0) {
            if (mediaFiles[0].type === 'image') { return 'image' }
            if (mediaFiles[0].type === 'video') { return 'videoUrl' }
        }

        return 'none'
    }

    const getTo = (groups: any[]): string => {
        if (groups.length > 0) { return 'specific'; }
        return 'all';
    }


    return (
        <React.Fragment>
            {
                !pageStatus.loading && !pageStatus.error &&
                <div>
                    <Formik
                        initialValues={{
                            title: feed ? feed.title : '',
                            body: feed ? feed.body : '',
                            category: feed ? feed.category : '',

                            mediaFiles: feed && feed?.mediaFiles?.length > 0 && feed?.mediaFiles[0].type === 'image' ? feed?.mediaFiles : [],
                            mediaType: feed ? getFormMediaType(feed?.mediaFiles) : 'none',
                            videoUrl: feed && feed?.mediaFiles?.length > 0 && feed?.mediaFiles[0].type === 'video' ? feed?.mediaFiles[0].url : '',

                            groupIds: feed ? feed.groups : [],
                            to: feed ? getTo(feed.groups) : 'all',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                        validateOnMount
                        enableReinitialize
                    >
                        {({ values, errors, touched, setErrors, setFieldError, setFieldValue, setFieldTouched, submitCount, isValid, isInitialValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <div className='inline-block bg-red-500/5 rounded-lg px-4 py-2 mb-4'>
                                        <p className='text-red-500' >{submitStatus.failedMessage}</p>
                                    </div>
                                }
                                <div className='grid md:grid-cols-8 gap-8 items-start'>
                                    <div className='md:col-span-4 bg-white dark:bg-gray-800 shadow-md dark:shadow-none p-10 rounded-xl'>
                                        <div className="mb-7">
                                            <div className='flex justify-between items-center'>
                                                <label htmlFor="input-title" className='block mr-4 mb-2'>Feed Title</label>
                                                <span className='text-sm text-gray-500 dark:text-gray-400'>{values['title'].length} / 80</span>
                                            </div>
                                            <FormInput type="text" name="title" id="input-title" className="w-full" placeholder="Enter feed title" maxLength={80} />
                                            {touched['title'] && errors['title'] && <p className='text-sm text-red-500 mt-2'>Feed title is Required</p>}
                                        </div>


                                        <div className="mb-7">
                                            <label htmlFor="modal-select-category" className='block mb-2'>Feed Category</label>
                                            {/*  <MultiSelectInput
                                                items={categoryList}
                                                //valueKey='id'
                                                //labelKey='name'
                                                placeholder="Select categories"
                                                selectedLabel='Category'
                                                selectedLabelPlural='Categories'
                                                value={values["category"]}
                                                maxSelection={2}
                                                onChange={(selected: any[]) => setFieldValue("category", selected)}
                                            /> */}
                                            <SelectInput
                                                items={categoryList}
                                                placeholder="Select category"
                                                value={values["category"]}
                                                onChange={(value) => setFieldValue("category", value)}
                                                onBlur={() => setFieldTouched('category', true)}
                                            />
                                            {touched['category'] && errors['category'] && <p className='text-sm text-red-500 mt-2'>Category is required</p>}
                                        </div>


                                        <div className="mb-7">
                                            <div className='flex justify-between items-center'>
                                                <label htmlFor="input-body" className='block mr-4 mb-2'>Feed Body</label>
                                                <span className='text-sm text-gray-500 dark:text-gray-400'>{values['body'].length} / 250</span>
                                            </div>
                                            <FormTextArea name='body' id="input-body" className='w-full' placeholder="Enter feed content" rows={5} maxLength={250} />
                                            {touched['body'] && errors['body'] && <p className='text-sm text-red-500'>Feed body is Required</p>}
                                        </div>


                                        <div className=''>
                                            <div className=''>
                                                <RadioGroup
                                                    value={values['mediaType']}
                                                    onChange={(value) => {
                                                        if (value !== 'image') {
                                                            //setFieldValue('mediaFiles', '');
                                                            setFieldTouched('mediaFiles', false);
                                                        }
                                                        if (value !== 'videoUrl') {
                                                            //setFieldValue('videoUrl', '');
                                                            setFieldTouched('videoUrl', false);
                                                        }
                                                        setFieldValue('mediaType', value);
                                                    }}
                                                >
                                                    <RadioGroup.Label as='p' className="text-lg mb-2">Add Media</RadioGroup.Label>
                                                    <div className='flex flex-wrap gap-x-4 gap-y-3'>
                                                        <RadioGroup.Option value="none" className="">
                                                            {({ checked }) => (
                                                                <div className={`flex transition-colors duration-300 cursor-pointer text-gray-500 dark:text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-700 dark:hocus:bg-gray-600 rounded-lg px-4 py-2.5`}>
                                                                    <span className={`${checked ? 'text-blue' : 'text-gray-500 dark:text-gray-400'} mt-px mr-3`}>
                                                                        {checked ? <i className="ri-radio-button-line"></i> : <i className="ri-checkbox-blank-circle-line"></i>}
                                                                    </span>
                                                                    <div className='flex items-center'>
                                                                        <span>None</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </RadioGroup.Option>
                                                        <RadioGroup.Option value="image" className="">
                                                            {({ checked }) => (
                                                                <div className={`flex transition-colors duration-300 cursor-pointer text-gray-500 dark:text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-700 dark:hocus:bg-gray-600 rounded-lg px-4 py-2.5`}>
                                                                    <span className={`${checked ? 'text-blue' : 'text-gray-500 dark:text-gray-400'} mt-px mr-3`}>
                                                                        {checked ? <i className="ri-radio-button-line"></i> : <i className="ri-checkbox-blank-circle-line"></i>}
                                                                    </span>
                                                                    <div className='flex items-center'>
                                                                        <i className="text-lg ri-image-line mr-1.5"></i>
                                                                        <span>Image</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </RadioGroup.Option>
                                                        <RadioGroup.Option value="videoUrl">
                                                            {({ checked }) => (
                                                                <div className={`flex transition-colors duration-300 cursor-pointer text-gray-500 dark:text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-700 dark:hocus:bg-gray-600 rounded-lg px-4 py-2.5`}>
                                                                    <span className={`${checked ? 'text-blue' : 'text-gray-500 dark:text-gray-400'} mt-px mr-3`}>
                                                                        {checked ? <i className="ri-radio-button-line"></i> : <i className="ri-checkbox-blank-circle-line"></i>}
                                                                    </span>
                                                                    <div className='flex items-center'>
                                                                        <i className="text-lg ri-film-line mr-1.5"></i>
                                                                        <span>Video Link</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </RadioGroup.Option>
                                                    </div>
                                                </RadioGroup>
                                            </div>

                                            {
                                                values['mediaType'] === 'image' &&
                                                <div className='mt-4'>
                                                    <div className='relative group aspect-[2/1]'>
                                                        {
                                                            values['mediaFiles'].length > 0 &&
                                                            <button type='button' onClick={(e) => setFieldValue('mediaFiles', [])} className='flex justify-center items-center transition-all duration-300 opacity-0 group-hover:opacity-80 bg-black/20 hover:bg-black/30 dark:bg-white/20 dark:hover:bg-white/30 absolute top-3 right-3 w-7 h-7 rounded-full' title='Remove'>
                                                                <i className="text-xl ri-close-line"></i>
                                                            </button>
                                                        }
                                                        <label htmlFor='modal-input-file-media' className=' w-full h-full flex justify-center items-center border-2 border-dashed dark:border-gray-700 rounded-xl cursor-pointer overflow-hidden'>
                                                            {
                                                                values['mediaFiles'].length === 0 &&
                                                                <div className='text-center opacity-50 p-8'>
                                                                    <span className='inline-block mb-2'>
                                                                        <i className="text-3xl ri-image-add-line"></i>
                                                                    </span>
                                                                    <p className='text-lg'>Click to Select an image</p>
                                                                    <p className=''>(Max Size {maxImageFileSize} MB)</p>
                                                                </div>
                                                            }

                                                            {
                                                                values['mediaFiles'].length > 0 &&
                                                                <img src={values['mediaFiles'][0].url ? values['mediaFiles'][0].url : values['mediaFiles'][0]} className='block w-full h-full object-contain pointer-events-none m-2' alt="" />
                                                            }

                                                            <input
                                                                //ref={refInputFile}
                                                                type="file"
                                                                id='modal-input-file-media'
                                                                onChange={(event) => {
                                                                    if (event.target.files && event.target.files[0]) {
                                                                        const fileSize = 1; // 1MB
                                                                        const file = event.target.files[0];
                                                                        if (file.size > (fileSize * 1024 * 1024)) {
                                                                            setFieldError('mediaFiles', `File size is bigger than ${fileSize} MB`);
                                                                        } else {
                                                                            //setError('');
                                                                            const reader = new FileReader();
                                                                            reader.readAsDataURL(event.target.files[0]); // read file as data url

                                                                            reader.onload = (evt) => {
                                                                                if (evt.target) {
                                                                                    // called once readAsDataURL is completed
                                                                                    const image = evt.target.result as string || '';
                                                                                    setFieldValue('mediaFiles', [image]);
                                                                                }
                                                                            };
                                                                        }
                                                                    }
                                                                }}
                                                                accept="image/jpeg, image/jpg, image/png"
                                                                hidden
                                                            />
                                                        </label>
                                                    </div>
                                                    {touched['mediaFiles'] && errors['mediaFiles'] && <p className='text-sm text-red-500 mt-2'>{errors['mediaFiles'] as string}</p>}
                                                </div>
                                            }


                                            {
                                                values['mediaType'] === 'videoUrl' &&
                                                <div className="mt-4 mb-7">
                                                    {/*  <label htmlFor="input-video-link" className='block mr-4 mb-2'>Video Link</label> */}
                                                    <FormInput type="text" name="videoUrl" id="input-video-link" className="w-full" placeholder="Enter video link" autoComplete='off' />
                                                    {touched['videoUrl'] && errors['videoUrl'] && <p className='text-sm text-red-500 mt-2'>{errors['videoUrl'] as string}</p>}
                                                </div>
                                            }
                                        </div>
                                    </div>



                                    <div className='md:col-span-4 xl:col-span-3'>
                                        <div className='bg-white dark:bg-gray-800 shadow-md dark:shadow-none p-8 rounded-xl mb-6'>
                                            <div className='mb-8'>
                                                <RadioGroup value={values['to']} onChange={(value) => setFieldValue('to', value)}>
                                                    <RadioGroup.Label as='p' className="font-medium mb-3">Recipients</RadioGroup.Label>
                                                    <RadioGroup.Option value="all" className="mb-4">
                                                        {({ checked }) => (
                                                            <div className={`transition-all duration-200 flex border ${checked ? 'border-blue/70' : 'hover:border-gray-300 dark:border-gray-700 dark:hover:border-gray-600'} rounded-xl cursor-pointer p-4`}>
                                                                <span className={`text-xl ${checked ? 'text-blue' : 'text-gray-500 dark:text-gray-400'} mr-3`}>
                                                                    {checked ? <i className="ri-radio-button-line"></i> : <i className="ri-checkbox-blank-circle-line"></i>}
                                                                </span>
                                                                <div>
                                                                    <p className='font-medium text-lg mb-1'>To Everyone</p>
                                                                    <p className='text-gray-500 dark:text-gray-400'>Feed will be sent to every user on SmartSapp.</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </RadioGroup.Option>
                                                    <RadioGroup.Option value="specific">
                                                        {({ checked }) => (
                                                            <div className={`transition-all duration-200 flex border ${checked ? 'border-blue/70' : 'hover:border-gray-300 dark:border-gray-700 dark:hover:border-gray-600'} rounded-xl cursor-pointer p-4`}>
                                                                <span className={`text-xl ${checked ? 'text-blue' : 'text-gray-500 dark:text-gray-400'} mr-3`}>
                                                                    {checked ? <i className="ri-radio-button-line"></i> : <i className="ri-checkbox-blank-circle-line"></i>}
                                                                </span>
                                                                <div>
                                                                    <p className='font-medium text-lg mb-1'>To Specific Groups</p>
                                                                    <p className='text-gray-500 dark:text-gray-400'>Determine groups the feed should be sent to</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </RadioGroup.Option>
                                                </RadioGroup>
                                            </div>

                                            {/* {
                                                values['to'] === 'specific' &&
                                                <div className=''>
                                                    {
                                                        values['groupIds'] &&
                                                        <div className='mt-5 mb-5'>
                                                            <label htmlFor="modal-select-groups" className='block mb-2'>Receiving group(s)</label>
                                                            <div className="">
                                                                <MultiSelectInput
                                                                    items={groupList}
                                                                    valueKey='id'
                                                                    labelKey='name'
                                                                    placeholder="Select groups"
                                                                    value={values["groupIds"]}
                                                                    itemsShowLimit={6}
                                                                    onChange={(value) => setFieldValue("groupIds", value)}
                                                                    onBlur={() => setFieldTouched('groupIds', true)}
                                                                />
                                                                {touched['groupIds'] && errors['groupIds'] && <p className='text-sm text-red-500 mt-2'>Select at least a group to receive feed</p>}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            } */}

                                            {
                                                values['to'] === 'specific' &&
                                                <div className=''>
                                                    {
                                                        values['groupIds'] &&
                                                        <div className='mt-5 mb-5'>
                                                            <label htmlFor="modal-select-groups" className='block mb-2'>Receiving group(s)</label>
                                                            <div className="">
                                                                <MultiSelectInput
                                                                    items={groupListData.list}
                                                                    valueKey='id'
                                                                    labelKey='name'
                                                                    placeholder="Select groups"
                                                                    value={values["groupIds"]}
                                                                    itemsShowLimit={6}
                                                                    onChange={(value) => setFieldValue("groupIds", value)}
                                                                    onBlur={() => setFieldTouched('groupIds', true)}
                                                                    onSearch={(query) => getGroups(query)}
                                                                    searching={groupListData.loading}
                                                                    placement='bottom-end'
                                                                    usePortal
                                                                    wrapOptions
                                                                />
                                                                {touched['groupIds'] && errors['groupIds'] && <p className='text-sm text-red-500 mt-2'>Select at least a group to receive feed</p>}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>

                                        <div className='flex justify-end items-center mt-6'>
                                            {
                                                isValid &&
                                                <Button type='button' onClick={() => setFeedPreview({ open: true })} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                                    Preview
                                                </Button>
                                            }

                                            <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                                {feed ? "Save" : "Create Feed"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <ModalAppFeedDetails
                                    isOpen={feedPreview.open}
                                    feed={{
                                        ...values,
                                        mediaFiles: (function (): any[] {
                                            if (values['mediaType'] === "image") {
                                                return values['mediaFiles'][0]?.url ? values['mediaFiles'] : [{ url: values['mediaFiles'], type: 'image' }]
                                            }

                                            if (values['mediaType'] === "videoUrl") {
                                                return values['mediaFiles'][0]?.url ? values['mediaFiles'] : [{ url: values['videoUrl'], type: 'video' }]
                                            }

                                            return [];
                                        }()),


                                        //mediaFiles: values['mediaType'] === "image" ? [{ url: values['mediaFiles'], type: '' }] : [],//values['mediaFiles'] ? [values['mediaFiles']] : [],
                                        category: [values['category']],
                                        createdAt: feed ? feed.createdAt : '',
                                        groups: values["to"] === "specific" ? values["groupIds"] : [],
                                        //videoUrl: values['mediaType'] === 'videoUrl' ? values['videoUrl'] : '',
                                    }}
                                    viewMode='preview'
                                    onClose={() => setFeedPreview((prevState) => ({ ...prevState, open: false }))}
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            }

            {
                // if it's loading
                pageStatus.loading &&
                <ListLoader loadingText='Loading Feed' className='px-8 py-24' />
            }

            {
                // if is not found
                !pageStatus.loading && pageStatus.error && pageStatus.notFound &&
                <ErrorLoading title='Feed Not Found' message='Feed may have been removed or never existed' className='px-8 py-24' />
            }

            {
                // if there's an error loading
                !pageStatus.loading && pageStatus.error && !pageStatus.notFound &&
                <ErrorLoading title='Error Loading Feed' message='An unexpected error occurred while loading feed' className='px-8 py-24' onTryAgain={getFeed} />
            }
        </React.Fragment>
    );
}


const validationSchema = Yup.object().shape({
    title: Yup.string().trim().required().label(""),
    category: Yup.string().trim().required().label(""),
    //category: Yup.array().min(1).required().label(""),
    body: Yup.string().trim().required().label(""),
    videoUrl: Yup.string().trim().matches(regexStrings.url, "Link must be a valid URL").when('mediaType', {
        is: (val: string) => val === 'videoUrl',
        then: (schema) => schema.required("Add a video link or change media to none"),
        //otherwise: (schema) => schema.min(0),
    }),
    mediaFiles: Yup.array().when('mediaType', {
        is: (val: string) => val === 'image',
        then: (schema) => schema.min(1, "Add an image or change media to none"),
        //otherwise: (schema) => schema.min(0),
    }),
    groupIds: Yup.array().when('to', {
        is: (val: string) => val === 'specific',
        then: (schema) => schema.min(1).required("Add an image or change media to none"),
        //otherwise: (schema) => schema.min(0),
    })
});


export default CreateFeedPage;