import { unifiedApi } from '../httpService';


const getSchools = async (query: any) => {
    try {
        const result = await unifiedApi.get('/api/v1/schools', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



const getSchoolProfile = async (id: any) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${id}`, {});
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}



const createSchool = async (data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/schools`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating school. Please try again later."
        };
    }
}



const updateSchool = async (schoolId: number, data: any) => {
    try {
        const result = await unifiedApi.put(`/api/v1/schools/${schoolId}`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating school. Please try again later."
        };
    }
}



const getSchoolConfigurations = async (id: any) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${id}/configurations`, {});
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}



const addSchoolConfigurations = async (schoolId: any, data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/schools/${schoolId}/configurations`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem adding configurations to school. Please try again later."
        };
    }
}



const updateSchoolConfigurations = async (schoolId: any, data: any) => {
    try {
        const result = await unifiedApi.put(`/api/v1/schools/${schoolId}/configurations`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating school configurations. Please try again later."
        };
    }
}



const getDropOffDeniedCount = async (schoolId: number) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${schoolId}/denials/active-denial-members-count`);
        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



export default {
    getSchools,
    getSchoolProfile,
    getDropOffDeniedCount,
    createSchool,
    updateSchool,
    getSchoolConfigurations,
    addSchoolConfigurations,
    updateSchoolConfigurations,
}