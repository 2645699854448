import React, { useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';

import { RouteLinksType } from '../../config/routes-list/appNavLinkList';
import useClickOutside from '../../state/hooks/useClickOutside';
import { getSidebar, toggleSidebar, closeSidebarOnSmallScreensAction, toggleSidebarOnWindowResizeAction } from '../../state/store/reducers/ui/sidebarSlice';
import SmartSappLogo from '../svgs/SmartSappLogo';
import appConfig from '../../config/appConfig.json';



const currentYear: number = new Date().getFullYear();

function AppSidebar({ routeLinks }: Props) {
    const refSidebar = useRef(null);
    const location = useLocation();

    const dispatch = useDispatch();
    const { isOpen } = useSelector(getSidebar);

    useClickOutside(refSidebar, () => dispatch<any>(closeSidebarOnSmallScreensAction()));


    useLayoutEffect(() => {
        const toggleSidebar = () => dispatch<any>(toggleSidebarOnWindowResizeAction());

        // add a listener for window resize
        window.addEventListener('resize', toggleSidebar);

        return () => {
            window.removeEventListener('resize', toggleSidebar);
        }
    });



    return (
        <aside ref={refSidebar} className={`group fixed z-50 flex flex-col left-0 inset-y-0 w-80 transform lg:transform-none transition-sidebar duration-300 bg-white dark:bg-gray-950 shadow-xl dark:shadow-sm dark:shadow-gray-800/50 overflow-auto px-4 pb-7 ${isOpen ? 'translate-x-0' : '-translate-x-full lg:w-[5.4rem] lg:hover:w-80'}`}>
            <div className='sticky z-10 top-0 flex justify-between bg-white dark:bg-gray-950 pt-7 pb-6'>
                <div className='flex items-center mr-8'>
                    <span className='w-10 h-10 text-blue-500'>
                        <SmartSappLogo />
                    </span>
                    <div className={`pl-3.5 ${isOpen ? '' : 'lg:opacity-0 lg:group-hover:opacity-100'}`}>
                        <p className='font-medium'>SmartSapp</p>
                        <p className='text-sm font-medium whitespace-nowrap text-gray-500 dark:text-gray-400'>Back Office</p>
                    </div>
                </div>

                <button type='button' onClick={() => dispatch(toggleSidebar({}))} className={`hidden lg:block w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ${isOpen ? '' : 'lg:opacity-0 lg:group-hover:opacity-100'}`}>
                    {isOpen ? <i className="ri-menu-2-line"></i> : <i className="ri-menu-3-line"></i>}
                </button>
            </div>

            <nav className='flex-grow mb-8'>
                {
                    Object.values(routeLinks)
                        .filter(groupItem => groupItem.visible)
                        .map((groupItem, groupItemIndex) =>
                            <div key={groupItemIndex} className="mb-8">
                                <p className={`text-xs font-bold uppercase whitespace-nowrap text-gray-500 dark:text-gray-400 mb-3 ${isOpen ? '' : 'lg:opacity-0 lg:group-hover:opacity-100'}`}>{groupItem.groupLabel}</p>
                                <ul>
                                    {
                                        Object.values(groupItem.items)
                                            .filter(parentItem => parentItem.visible)
                                            .map((parentItem, parentItemIndex) =>
                                                <React.Fragment key={parentItemIndex}>
                                                    {
                                                        Object.keys(parentItem.children).length === 0 &&
                                                        <li onClick={() => dispatch<any>(closeSidebarOnSmallScreensAction())}>
                                                            <NavLink to={parentItem.url} className={({ isActive }) => `flex justify-between items-center gap-x-3 w-full rounded-lg transition-colors duration-300 hover:bg-black/5 dark:hover:bg-white/5 pl-4 pr-3 py-2.5 mb-0.5 ${isActive ? 'bg-blue-500/10 text-blue-500' : ''}`}>
                                                                <div className='flex items-center'>
                                                                    <i className={`${parentItem.icon} text-xl`}></i>
                                                                    <span className={`whitespace-nowrap pl-4 ${isOpen ? '' : 'lg:opacity-0 lg:group-hover:opacity-100'}`}>{parentItem.label}</span>
                                                                </div>
                                                                {
                                                                    parentItem.new &&
                                                                    <span className={`text-xs font-medium uppercase bg-red-500/10 text-red-500 rounded-md py-0.5 px-1.5 ${isOpen ? '' : 'lg:opacity-0 lg:group-hover:opacity-100'}`}>New</span>
                                                                }
                                                            </NavLink>
                                                        </li>
                                                    }

                                                    {
                                                        Object.keys(parentItem.children).length > 0 &&
                                                        <Disclosure as="li">
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button className={`flex justify-between items-center w-full rounded-lg transition-colors duration-300 hover:bg-black/5 dark:hover:bg-white/5 pl-4 pr-3 py-2.5 mb-0.5 ${open || location.pathname.includes(parentItem.url) ? 'text-blue-500' : ''}`}>
                                                                        <div className='flex items-center mr-3'>
                                                                            <i className={`${parentItem.icon}`}></i>
                                                                            <span className={`whitespace-nowrap pl-4 ${isOpen ? '' : 'lg:opacity-0 lg:group-hover:opacity-100'}`}>{parentItem.label}</span>
                                                                        </div>
                                                                        <i className={`ri-arrow-right-s-line h-5 w-5 transform transition-transform ${open ? 'rotate-90' : ''}`}></i>
                                                                    </Disclosure.Button>

                                                                    <Transition
                                                                        show={open}
                                                                        className='overflow-hidden'
                                                                        enter='transition transition-[max-height] duration-500 ease-in'
                                                                        enterFrom='transform max-h-0'
                                                                        enterTo='transform max-h-screen'
                                                                        leave='transition transition-[max-height] duration-500 ease-out'
                                                                        leaveFrom='transform max-h-screen'
                                                                        leaveTo='transform max-h-0'
                                                                    >
                                                                        <Disclosure.Panel as='ul' className={`${isOpen ? '' : 'lg:max-h-0 lg:group-hover:max-h-screen'}`} static>
                                                                            {
                                                                                Object.values(parentItem.children)
                                                                                    .filter(childItem => childItem.visible)
                                                                                    .map((childItem, childItemIndex) =>
                                                                                        <li key={childItemIndex} onClick={() => dispatch<any>(closeSidebarOnSmallScreensAction())}>
                                                                                            <NavLink to={childItem.url} className={({ isActive }) => `flex items-center w-full rounded-lg hover:bg-black/5 dark:hover:bg-white/5 pl-7 pr-3 py-2.5 mb-1.5 ${isActive ? 'bg-blue-500/10 text-blue-500' : ''}`}>
                                                                                                {/* <i className="ri-checkbox-blank-circle-fill text-xs"></i> */}
                                                                                                <span className=''>•</span>
                                                                                                <span className='whitespace-nowrap pl-4'>{childItem.label}</span>
                                                                                            </NavLink>
                                                                                        </li>
                                                                                    )
                                                                            }
                                                                        </Disclosure.Panel>
                                                                    </Transition>
                                                                </>
                                                            )}
                                                        </Disclosure>

                                                    }
                                                </React.Fragment>
                                            )
                                    }
                                </ul>
                            </div>
                        )
                }
            </nav>

            <div className={`text-gray-500 dark:text-gray-400 ${isOpen ? '' : 'lg:opacity-0 lg:group-hover:opacity-100'}`}>
                <p className='text-xs font-medium whitespace-nowrap'>SmartSapp Back Office &copy; {currentYear}</p>
                <p className='text-xs whitespace-nowrap'>Version: {appConfig.version}</p>
            </div>
        </aside>
    );
}


interface Props {
    routeLinks: RouteLinksType;
}


export default AppSidebar;