import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { setUserSessionAction } from '../../state/store/reducers/entities/authSlice';


let loggedIn: boolean;
function AdminProtected({ children }: Props) {
    const location = useLocation();
    const dispatch = useDispatch();

    loggedIn = dispatch<any>(setUserSessionAction());

    if (!loggedIn) {
        return <Navigate to="/login" replace={true} state={{ redirectTo: location }} />
    }

    return children;
}


interface Props {
    children: JSX.Element
}

export default AdminProtected;