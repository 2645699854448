import { createSlice } from "@reduxjs/toolkit";

const storeKey = 'preferredTheme';


const slice = createSlice({
    name: "theme",
    initialState: {
        mode: '' as ThemeType
    },
    reducers: {
        setTheme: (state, action) => {
            state.mode = action.payload.mode;
        },
        toggleTheme: (state, action) => {
            if (state.mode === "light") {
                state.mode = "dark";
                return;
            }

            if (state.mode === "dark") {
                state.mode = "light";
                return;
            }
        },
        setThemeDark: (state, action) => {
            if (state.mode === "light") {
                state.mode = "dark";
            }
        },
        setThemeLight: (state, action) => {
            if (state.mode === "dark") {
                state.mode = "light"
            }
        }
    }
});


export const { toggleTheme, setThemeDark, setThemeLight, setTheme } = slice.actions;

export const getTheme = (state: any) => state.ui.theme.mode;



export function setUITheme(theme: ThemeType) {
    // set theme
    const root = window.document.documentElement;
    root.classList.remove("light", "dark");
    root.classList.add(theme);

    // save theme
    localStorage.setItem(storeKey, theme);
}


export function setInitialTheme(): ThemeType {
    // check if a theme is saved in local storage
    const storedTheme = localStorage.getItem(storeKey) as ThemeType;
    if (storedTheme && (storedTheme === "light" || storedTheme === "dark")) {
        setUITheme(storedTheme);
        return storedTheme;
    }

    // check if device theme
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setUITheme('dark');
        return "dark";
    }

    setUITheme('light');
    return "light";
}



type ThemeType = "dark" | "light";

export default slice.reducer;
