import axios from "axios";
import { notificationsApi } from "../httpService";


const bulkSmsService = {
    getClient: async (query: any = {}): Promise<any> => {
        try {
            const result = await notificationsApi.get(`/clients/me`, {
                params: query
            });
            return new Promise((resolve, _) => resolve(result.data?.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },


    getDeliverySummary: async (query: any = {}): Promise<any> => {
        try {
            const result = await notificationsApi.get(`/api/v1/reports`, {
                params: query
            });
            return new Promise((resolve, _) => resolve(result.data?.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },


    topUp: async (data: any, query: any = {}): Promise<any> => {
        try {
            const result = await notificationsApi.post('/api/v1/sms-topup', data, {
                params: query
            });
            return new Promise((resolve, _) => resolve(result.data?.data));
        } catch (error) {
            /* if (axios.isAxiosError(error)) {
                if (error.response?.status === 409) {
                    return new Promise((_, reject) => reject(error.response?.data?.details?.split('=')[0]));
                }

                return new Promise((_, reject) => reject(error.response?.data?.message));
            } */

            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },
};


export default bulkSmsService;