import React from 'react';
import { useOutletContext, Outlet } from 'react-router-dom';

function SchoolManagementPage() {
    const { profile }: any = useOutletContext();

    return (
        <Outlet context={{ profile }} />
    );
}

export default SchoolManagementPage;