import React, { useEffect, useState } from 'react';


function Timer({ timeoutAt, onTimeout }: Props) {
    const [minutes, setMinutes] = useState(Math.floor(timeoutAt / 60));
    const [seconds, setSeconds] = useState(timeoutAt % 60);


    useEffect(() => {
        let timeout: any = null;
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    if (onTimeout) {
                        onTimeout();
                    }
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => {
            clearInterval(myInterval);
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    });



    return (
        <React.Fragment>
            {
                !(minutes === 0 && seconds < 0) &&
                `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
            }
        </React.Fragment>
    );
}

interface Props {
    timeoutAt: number;
    onTimeout?: () => void;
}

export default Timer;