import React from 'react';

import Modal from '../Modal';

function ModalMediaViewer({ isOpen, onClose, description = '', mediaType, link }: ModalMediaViewerProps) {
    return (
        <Modal open={isOpen} onClose={onClose} >
            <div className='relative'>
                {
                    <div className=''>
                        <img src={link} className='min-w-[24rem] max-w-[40rem] max-h-[40rem]' alt={description} />
                    </div>
                }

                <button type="button" onClick={onClose} className="absolute top-2.5 right-2.5 w-9 h-9 bg-gray-50/90 dark:bg-gray-700/90 text-2xl text-gray-500 dark:text-gray-300 rounded-full">
                    <i className="ri-close-line"></i>
                </button>
            </div>
        </Modal>
    );
}


export interface ModalMediaViewerProps {
    isOpen: boolean;
    onClose: () => void;
    mediaType: "videoLink" | "image" | "youtube";
    description?: string;
    link: string;
}


export default ModalMediaViewer;