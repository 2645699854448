import React, { } from 'react';
import { createBrowserRouter } from "react-router-dom";

import LoginPage from "./pages/auth/features/LoginPage";
import ForgotPasswordPage from './pages/auth/features/ForgotPasswordPage';
import AccountVerificationPage from './pages/auth/features/AccountVerificationPage';
import ResetPasswordPage from './pages/auth/features/ResetPasswordPage';

import Admin from "./pages/admin/Admin";
import AdminProtected from "./pages/admin/AdminProtected";
import AuthProtected from "./pages/auth/AuthProtected";
import Dashboard from "./pages/admin/features/dashboard/Dashboard";
import ScannerListPage from "./pages/admin/features/scanners/ScannerListPage";
import SchoolsListPage from "./pages/admin/features/schools/schools-list/SchoolListPage";
import NotFoundPage from "./pages/admin/NotFoundPage";
import SettlementAccountListPage from "./pages/admin/features/settlement-accounts/settlement-account-list/SettlementAccountListPage";
import SettlementAccountDetailsPage from "./pages/admin/features/settlement-accounts/settlement-account-details/SettlementAccountDetailsPage";
import UserPermissionsListPage from "./pages/admin/features/users/user-permissions/UserPermissionsListPage";
import UserRolesListPage from "./pages/admin/features/users/user-roles/UserRolesListPage";
import UserAccountListPage from "./pages/admin/features/users/user-accounts/UserAccountListPage";
import AppRatingsListPage from "./pages/admin/features/app-ratings/AppRatingsListPage";
import AppNotificationListPage from "./pages/admin/features/app-notifications/AppNotificationListPage";
import AppFeedListPage from "./pages/admin/features/app-feeds/feed-list/AppFeedListPage";
import CreateFeedPage from "./pages/admin/features/app-feeds/create-feed/CreateFeedPage";
import ManageSchoolPage from "./pages/admin/features/schools/manage-school/ManageSchoolPage";
import SchoolDetailsPage from './pages/admin/features/schools/manage-school/features/profile/pages/school-details/SchoolDetailsPage';
import SchoolConfigurationsPage from './pages/admin/features/schools/manage-school/features/profile/pages/school-configurations/SchoolConfigurationsPage';
import SchoolCampusListPage from './pages/admin/features/schools/manage-school/features/profile/pages/school-campuses/SchoolCampusListPage';
import SchoolAdminAccountsListPage from './pages/admin/features/schools/manage-school/features/profile/pages/admin-accounts/SchoolAdminAccountsListPage';
import SchoolManagementGeneralPage from './pages/admin/features/schools/manage-school/features/management/pages/general/SchoolManagementGeneralPage';
import SchoolServiceProviderListPage from './pages/admin/features/schools/manage-school/features/canteen/pages/service-providers/SchoolServiceProviderListPage';
import AppConfigurationsPage from './pages/admin/features/app-configurations/AppConfigurationsPage';
import SchoolCanteenCSPManagersListPage from './pages/admin/features/schools/manage-school/features/canteen/pages/csp-managers/SchoolCanteenCSPManagersListPage';
import SchoolPaymentConfigurationsPage from './pages/admin/features/schools/manage-school/features/payments/pages/configurations/SchoolPaymentConfigurationsPage';
import StudentListPage from './pages/admin/features/students/student-list/StudentListPage';
import ManageStudentPage from './pages/admin/features/students/manage-student/ManageStudentPage';
import StudentDetailsPage from './pages/admin/features/students/manage-student/features/profile/student-details/StudentDetailsPage';
import StudentParentsListPage from './pages/admin/features/students/manage-student/features/profile/parents/StudentParentsListPage';
import StudentDelegatesListPage from './pages/admin/features/students/manage-student/features/profile/delegates/StudentDelegatesListPage';
import StudentPaymentPage from './pages/admin/features/students/manage-student/features/payments/StudentPaymentPage';
import StudentInvoiceListPage from './pages/admin/features/students/manage-student/features/payments/pages/invoices/StudentInvoiceListPage';
import StudentTransactionListPage from './pages/admin/features/students/manage-student/features/payments/pages/transactions/StudentTransactionListPage';
import StudentPaymentDetailsPage from './pages/admin/features/students/manage-student/features/payments/pages/payment-details/StudentPaymentDetailsPage';
import SchoolModulesListPage from './pages/admin/features/schools/manage-school/features/profile/pages/modules/SchoolModulesListPage';
import StudentActivitiesPage from './pages/admin/features/students/manage-student/features/profile/activities/StudentActivitiesPage';
import CreateInvoicePage from './pages/admin/features/payments/invoices/create-invoice/CreateInvoicePage';
import ThirdPartyListPage from './pages/admin/features/payments/third-parties/third-party-list/ThirdPartyListPage';
import ThirdPartyDetailsPage from './pages/admin/features/payments/third-parties/third-party-details/ThirdPartyDetailsPage';
import ThirdPartyForm from './pages/admin/features/payments/third-parties/third-party-form/ThirdPartyForm';
import SchoolClassroomList from './pages/admin/features/schools/manage-school/features/profile/pages/classrooms/SchoolClassroomList';
import SchoolProfilePage from './pages/admin/features/schools/manage-school/features/profile/SchoolProfilePage';
import SchoolCanteenPage from './pages/admin/features/schools/manage-school/features/canteen/SchoolCanteenPage';
import SchoolPaymentPage from './pages/admin/features/schools/manage-school/features/payments/SchoolPaymentPage';
import SchoolManagementPage from './pages/admin/features/schools/manage-school/features/management/SchoolManagementPage';
import BulkSmsDetailsPage from './pages/admin/features/schools/manage-school/features/management/pages/bulk-sms/BulkSmsDetailsPage';


const router = createBrowserRouter([
    {
        path: "/login",
        element:
            <AuthProtected>
                <LoginPage />
            </AuthProtected>,
    },
    {
        path: "/forgot-password",
        element:
            <AuthProtected>
                <ForgotPasswordPage />
            </AuthProtected>,
    },
    {
        path: "/verify-account",
        element:
            <AuthProtected>
                <AccountVerificationPage />
            </AuthProtected>,
    },
    {
        path: "/reset-password",
        element:
            <AuthProtected>
                <ResetPasswordPage />
            </AuthProtected>,
    },
    /* {
        path: "*",
        element:
            <AdminProtected>
                <Admin />
            </AdminProtected>,
    }, */
    {
        path: "/",
        element:
            <AdminProtected>
                <Admin />
            </AdminProtected>,
        children: [
            {
                path: "/",
                element: <Dashboard />,
            },
            {
                path: "/schools",
                element: <SchoolsListPage />,
            },
            {
                path: "/schools/:schoolId",
                element: <ManageSchoolPage />,
                children: [
                    {
                        path: "",
                        element: <SchoolProfilePage />,
                        children: [
                            {
                                path: "",
                                element: <SchoolDetailsPage />,
                            },
                            {
                                path: "configurations",
                                element: <SchoolConfigurationsPage />,
                            },
                            {
                                path: "campuses",
                                element: <SchoolCampusListPage />,
                            },
                            {
                                path: "classrooms",
                                element: <SchoolClassroomList />,
                            },
                            {
                                path: "admin-accounts",
                                element: <SchoolAdminAccountsListPage />,
                            },
                            {
                                path: "modules",
                                element: <SchoolModulesListPage />,
                            },
                        ]
                    },
                    {
                        path: "canteen",
                        element: <SchoolCanteenPage />,
                        children: [
                            /* {
                     path: "canteen",
                     element: <SchoolCanteenGeneralPage />,
                 }, */
                            {
                                path: "",
                                element: <SchoolServiceProviderListPage />,
                            },
                            {
                                path: "csp-managers",
                                element: <SchoolCanteenCSPManagersListPage />,
                            },
                        ]
                    },
                    {
                        path: "payments",
                        element: <SchoolPaymentPage />,
                        children: [
                            {
                                path: "",
                                element: <SchoolPaymentConfigurationsPage />,
                            },
                        ]
                    },
                    {
                        path: "manage",
                        element: <SchoolManagementPage />,
                        children: [
                            {
                                path: "",
                                element: <SchoolManagementGeneralPage />,
                            },
                            {
                                path: "bulk-sms",
                                element: <BulkSmsDetailsPage />,
                            },
                        ]
                    },
                    {
                        path: "*",
                        element: <NotFoundPage pageTitle='Manage School' />,
                    },
                ]
            },
            {
                path: "/students",
                element: <StudentListPage />,
            },
            {
                path: "/students/:schoolId/:studentId",
                element: <ManageStudentPage />,
                children: [
                    // profile
                    {
                        path: "",
                        element: <StudentDetailsPage />,
                    },
                    {
                        path: "parents",
                        element: <StudentParentsListPage />,
                    },
                    {
                        path: "pickup-persons",
                        element: <StudentDelegatesListPage />,
                    },
                    {
                        path: "activities",
                        element: <StudentActivitiesPage />,
                    },
                    {
                        path: "payments",
                        element: <StudentPaymentPage />,
                        children: [
                            {
                                path: "",
                                element: <StudentPaymentDetailsPage />,
                            },
                            {
                                path: "invoices",
                                element: <StudentInvoiceListPage />,
                            },
                            {
                                path: "transactions",
                                element: <StudentTransactionListPage />,
                            },
                        ],
                    },
                ]
            },
            {
                path: "/scanners",
                element: <ScannerListPage />,
            },
            {
                path: "/settlement-accounts",
                element: <SettlementAccountListPage />,
            },
            {
                path: "/settlement-accounts/:accountId",
                element: <SettlementAccountDetailsPage />,
            },
            {
                path: "/users/accounts",
                element: <UserAccountListPage />,
            },
            {
                path: "/users/permissions",
                element: <UserPermissionsListPage />,
            },
            {
                path: "/users/roles",
                element: <UserRolesListPage />,
            },
            {
                path: "/payments/invoices/create",
                element: <CreateInvoicePage />,
            },
            {
                path: "/payments/invoices/:invoiceId",
                element: <CreateInvoicePage />,
            },
            {
                path: "/app-ratings",
                element: <AppRatingsListPage />,
            },
            {
                path: "/app-notifications",
                element: <AppNotificationListPage />,
            },
            {
                path: "/feeds",
                element: <AppFeedListPage />,
            },
            {
                path: "/feeds/create",
                element: <CreateFeedPage />,
            },
            {
                path: "/feeds/:feedId",
                element: <CreateFeedPage />,
            },
            {
                path: "/payments/third-parties",
                element: <ThirdPartyListPage />,
            },
            {
                path: "/payments/third-parties/new",
                element: <ThirdPartyForm />,
            },
            {
                path: "/payments/third-parties/:thirdPartyId",
                element: <ThirdPartyDetailsPage />,
            },
            {
                path: "/app-configurations",
                element: <AppConfigurationsPage />,
            },
            {
                path: "*",
                element: <NotFoundPage />,
            },
        ]
    },
]);


export default router;