import React, { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Menu } from '@headlessui/react';

import useAlert from '../../../../../../../../../state/hooks/useAlert';
import useToast from '../../../../../../../../../state/hooks/useToast';
import { ErrorLoading, ListNoData, Pagination } from '../../../../../../../../../components/common';
import { paginationInfo } from '../../../../../../../../../utils/pagination';
import { ListLoader } from '../../../../../../../../../components/loaders';
import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import classroomService from '../../../../../../../../../services/api/unified-api/classroomService';


function SchoolClassroomList() {
    const { profile, classroomsList }: any = useOutletContext();


    //const { data: classrooms, requestData, resetRecords, updateRecord } = usePagedListApi((query: any) => employeeService.getEmployees(profile.id, query));
    const { data: classrooms, requestData, resetRecords, updateRecord } = classroomsList;
    const [searchParams, setSearchParams] = useSearchParams();

    const { addToast } = useToast();
    const { openAlertOk, openAlertConfirm } = useAlert();

    const [classroomForm, setClassroomForm] = useState({ open: false, classroom: null });

    // get query params
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};
    // if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }



    useEffect(() => {
        getData();
    }, [searchParams]);


    const getData = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                properties: "name",
                direction: "ASC"
            },
        });
    };




    const handleDelete = async (classroom: any) => {
        if (classroom.studentCount === 0) {
            openAlertConfirm({
                title: 'Delete Classroom',
                message: `Are you sure you want to ${classroom.name} classroom from ${profile.name}`,
                onConfirmed: () => deleteClassroom(classroom),
                positiveButtonText: "Delete",
                negativeButtonText: 'Cancel',
                positiveButtonClass: 'bg-red-500 hover:bg-red-600',
            });
        } else {
            openAlertOk({
                title: 'Cannot Delete Classroom',
                message: `${classroom.studentCount} students found in ${classroom.name}, Move or Delete students before deleting classroom`,
            });
        }
    }



    const deleteClassroom = async (classroom: any) => {
        const result = await classroomService.deleteClassroom(profile.id, {
            gradeIds: classroom?.id
        });
        if (result.success) {
            resetRecords();
            getData();
            addToast({
                text: "Classroom deleted successfully",
                type: "success"
            });
        } else {
            addToast({
                title: "Error",
                text: "Classroom could not be deleted",
                type: "error"
            });
        }
    }



    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            <div className='flex justify-between flex-wrap gap-2 mb-6'>
                <h2 className='font-medium text-xl mr-4'>Classrooms</h2>
                {/* <Button type='button' onClick={() => setClassroomForm({ open: true, classroom: null })} className='py-2.5'>Add Classroom</Button> */}
            </div>


            {
                // if there's data
                !classrooms.loadingError && !classrooms.loading && classrooms.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto align-top w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-5 pl-2 pr-6'>Name</th>
                                    <th className='py-5 px-6'>Code</th>
                                    <th className='py-5 px-6'>Campus</th>
                                    <th className='py-5 px-6'>No. Students</th>
                                    <th className='py-5 px-6'>No. Teachers</th>
                                    <th className='whitespace-nowrap py-5 px-6'>Date Created</th>
                                    <th className='py-5 pl-6 pr-2 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    classrooms.list.map((dataItem: any) =>
                                        <tr key={dataItem?.id}>
                                            <td className='py-4 pl-2 pr-6'>{dataItem.name}</td>
                                            <td className='py-4 px-6'>{dataItem.code}</td>
                                            <td className='py-4 px-6'>{dataItem?.campus?.name} - {dataItem?.campus?.alias}</td>
                                            <td className='py-4 px-6'>{dataItem.studentCount}</td>
                                            <td className='py-4 px-6'>{dataItem.teacherCount}</td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(dataItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 pl-6 pr-2 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-64 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => handleDelete(dataItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                Delete
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(classrooms.pager.pageSize, classrooms.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={classrooms.pager.pageSize}
                            totalCount={classrooms.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if is loading
                !classrooms.loadingError && classrooms.loading &&
                <ListLoader loadingText='Loading Classrooms' className='px-8 py-24' />
            }

            {
                // if no data added
                !classrooms.loadingError && !classrooms.loading && classrooms.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Classroom Found' description='No classroom found for school' className='px-8 py-24'>
                </ListNoData>
            }

            {
                // if there're no admins found in search
                !classrooms.loadingError && !classrooms.loading && classrooms.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Classroom Found' description='No classroom found for the selected scenarios' className='px-8 py-24' />
            }


            {
                classrooms.loadingError &&
                <ErrorLoading title='Loading Error' message='There was a problem loading classrooms' className='px-8 py-24' onTryAgain={getData} />
            }

        </div>
    );
}

export default SchoolClassroomList;