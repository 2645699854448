import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../..";
import { ModalMediaViewerProps } from "../../../../components/modals/media-viewer/ModalMediaViewer";


const slice = createSlice({
    name: "mediaViewer",
    initialState: {
        isOpen: false,
        data: {},
    },
    reducers: {
        openViewer: (state, action: PayloadAction<MediaViewerActionProps>) => {
            state.isOpen = true;
            state.data = action.payload;
        },
        closeViewer: (state, action) => {
            state.isOpen = false;
        },
    }
});


// Actions
export const { openViewer, closeViewer } = slice.actions;


// Getters
export const selectMediaViewer = (state: RootState) => state.ui.mediaViewer;

export default slice.reducer;

export type MediaViewerActionProps = Omit<ModalMediaViewerProps, 'isOpen' | 'onClose'> & { onOk?: () => void; };