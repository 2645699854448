import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import Modal from '../../../../../../../../components/modals/Modal';
import { Avatar, Button } from '../../../../../../../../components/common';
import transactionsService from '../../../../../../../../services/api/payments/transactionsService';


function ModalTransactionDetails({ isOpen, transaction, onClose }: Props) {
    const [transactionDetails, setTransactionDetails] = useState<any>(null);


    useEffect(() => {
        setTransactionDetails(transaction);
        // if (transaction?.id && transaction?.paymentChannel === "OFFLINE") {
        if (transaction?.id) {
            getTransaction();
        }
    }, [transaction?.id]);


    const getTransaction = async () => {
        const result = await transactionsService.getTransaction(transaction.id);
        if (result?.success) {
            setTransactionDetails(result.data);
        } else {
        }
    };


    if (transaction) {
        return (
            <Modal open={isOpen} onClose={onClose} >
                <div className="w-screen max-w-xl">
                    <div className="flex justify-between mb-5 px-9 pt-8">
                        <h2 className="font-medium text-xl">
                            Transaction Details
                        </h2>

                        <button type="button" onClick={onClose} className="text-2xl text-gray-500 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-500 focus:outline-none">
                            <i className="ri-close-line"></i>
                        </button>
                    </div>

                    <div className='max-h-[80vh] overflow-y-auto px-9 pb-6'>
                        <div className='flex justify-between items-center gap-x-8 gap-y-3 mb-4'>
                            <div className='flex gap-2 text-white'>
                                <span className='font-medium mt-1'>GHS</span>
                                <span className='font-semibold text-3xl'>{transactionDetails?.amountProcessed?.toFixed(2)}</span>
                            </div>

                            {
                                (transactionDetails?.status === "SUCCESS" || transactionDetails?.status === "APPROVED" || transactionDetails?.status === "PAID") &&
                                <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-2 px-3`}>SUCCESS</span>
                            }
                            {
                                transactionDetails?.status === "ERROR" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-2 px-3`}>ERROR</span>
                            }
                            {
                                transactionDetails?.status === "REJECTED" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-2 px-3`}>REJECTED</span>
                            }
                        </div>

                        <div className='flex justify-between gap-3 bg-gray-50 dark:bg-white/5 rounded-xl py-3.5 px-4 mb-1'>
                            <div className='flex items-center gap-3'>
                                <Avatar name={transactionDetails?.customer?.name} imageUrl={transactionDetails?.customer?.avatar} size={2.6} className="shrink-0" />

                                <div>
                                    <p className='font-medium'>{transactionDetails?.customer?.name}</p>
                                    <p className='text-gray-500 dark:text-gray-400 text-sm'>{transactionDetails?.customer?.group?.name}</p>
                                </div>
                            </div>


                            <div className='text-right'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Customer No.</p>
                                <p>{transactionDetails?.customer?.customerNumber}</p>
                            </div>
                        </div>

                        <div className='divide-y dark:divide-gray-800 mb-8'>
                            <div className='py-4'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Invoice</p>
                                <p>{transactionDetails?.invoice?.name}</p>
                                <small className='text-sm text-gray-500 dark:text-gray-400'>{transactionDetails?.invoice?.invoiceNumber}</small>
                            </div>


                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Transaction ID</p>
                                    <p>{transactionDetails?.transactionId}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Transaction Type</p>
                                    <p>{transactionDetails?.type}</p>
                                </div>
                            </div>


                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Payer</p>
                                    <p>{transactionDetails?.payer}</p>
                                    <small className='text-sm text-gray-500 dark:text-gray-400'>{transactionDetails?.customer?.accounts[0]?.accountNumber}</small>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Payment Channel</p>
                                    <p>{transactionDetails?.paymentChannel}</p>
                                </div>
                            </div>

                            <div className='grid grid-cols-2'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Approved By</p>
                                    <p>{transactionDetails?.generatedBy}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Transaction Date</p>
                                    <p>{transactionDetails?.datetime ? format(new Date(transactionDetails?.datetime), 'd LLL yyyy h:mm a') : 'N/A'}</p>
                                </div>
                            </div>

                            <div className='flex justify-between gap-8'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Notes</p>
                                    <p>{transactionDetails?.reason || 'N/A'}</p>
                                </div>

                                {/* <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Payment receipt</p>
                                    <div className='inline-block text-center bg-gray-50 dark:bg-white/5 rounded-lg overflow-hidden'>
                                        {
                                         transactionDetails?.attachedSlip ?
                                            <div className='group relative cursor-pointer'>
                                                <img src="https://picsum.photos/id/237/200/300" className=' w-24 h-24 object-contain' alt="" />
                                                <span className='absolute inset-0 flex justify-center transition-colors duration-200 bg-transparent group-hover:bg-black/10 items-center text-3xl'>
                                                    <i className="transition-all scale-150 opacity-0 group-hover:opacity-100 group-hover:scale-100 text-gray-400 ri-fullscreen-fill"></i>
                                                </span>
                                            </div> :
                                            <p className='text-center py-2 px-4 opacity-70'>N/A</p>
                                        }
                                    </div>
                                </div> */}
                            </div>
                        </div>


                        <div className='flex justify-end items-center'>
                            <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    return null;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    transaction: any;
}


export default ModalTransactionDetails;