import { authApi } from '../httpService';


const getPermissions = async (query: any) => {
    try {
        const result = await authApi.get('/api/v1/permissions', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const createPermission = async (data: any) => {
    try {
        const result = await authApi.post(`/api/v1/permissions`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            const issues: any[] = error.response.data.errors;
            if (issues[0].field === "name") {
                return {
                    success: false,
                    data: null,
                    message: 'Permission name should be at least 3 characters long, and should be separated by "_"'
                };
            }
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem adding user permission. Please try again later."
        };
    }
}


const deletePermission = async (permissionId: number) => {
    try {
        const result = await authApi.delete(`/api/v1/permissions/${permissionId}`);

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



export default {
    getPermissions,
    createPermission,
    deletePermission
}