import React, { useRef } from 'react';

import { Button } from '../../common';
import Modal from '../Modal';


function ModalAlertConfirm({ isOpen, title, message, onClose, onConfirmed, onCanceled, positiveButtonText = 'Yes', positiveButtonClass = '', negativeButtonText = 'No' }: ModalAlertConfirmProps) {
    let focusButtonRef = useRef(null);

    return (
        <Modal initialFocus={focusButtonRef} open={isOpen} onClose={onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[60rem] max-w-xl">
                <div className="flex justify-between mb-4 px-8 pt-6">
                    <h2 className="font-medium text-xl">{title}</h2>
                </div>

                <div className='px-8 pb-7'>
                    <div className='mb-9'>
                        <div className="'text-gray-500 dark:text-gray-400">{message}</div>
                    </div>

                    <div className='flex justify-end items-center'>
                        <Button
                            ref={focusButtonRef}
                            type='button'
                            onClick={() => {
                                if (onCanceled) {
                                    onCanceled();
                                }
                                onClose()
                            }}
                            className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-5'
                        >
                            {negativeButtonText}
                        </Button>

                        <Button type='button' onClick={() => { onConfirmed(); onClose() }} className={`py-2.5 ${positiveButtonClass}`}>
                            {positiveButtonText}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal >
    );
}


export interface ModalAlertConfirmProps {
    isOpen: boolean;
    title: string | JSX.Element;
    message: string | JSX.Element;
    onConfirmed: () => void;
    onCanceled?: () => void;
    onClose: () => void;
    negativeButtonText?: string;
    positiveButtonText?: string;
    positiveButtonClass?: string;
}


export default ModalAlertConfirm;