import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Avatar, ListNoData } from '../../../../../../../../components/common';
import ModalParentDetails from '../../../../../users/modals/ModalParentDetails';


function StudentParentsListPage() {
    const { profile, setProfile }: any = useOutletContext();
    const [parentDetails, setParentDetails] = useState({ open: false, parent: null });


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            {
                profile.parents.length > 0 &&
                <div>
                    <div className='flex justify-between items-center flex-wrap gap-2 mb-4'>
                        <h2 className='font-medium text-xl mr-4'>Parents</h2>
                    </div>

                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b dark:border-gray-700'>
                                    <th className='py-4 pl-2 pr-4'>Name</th>
                                    <th className='py-4 px-4'>Relation</th>
                                    <th className='whitespace-nowrap py-4 px-4'>Primary Contact</th>
                                    <th className='py-4 px-4'>E-Mail</th>
                                    <th className='whitespace-nowrap py-4 px-4'>Delegate Type</th>
                                    <th className='whitespace-nowrap py-4 pl-2 pr-4'>Parent Type</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    profile.parents.map((parentItem: any) =>
                                        <tr key={parentItem.id}>
                                            <td className='py-4 pl-2 pr-4'>
                                                <button type='button' onClick={() => setParentDetails({ open: true, parent: parentItem })} className='group flex items-center gap-3'>
                                                    <Avatar name={`${parentItem?.firstName} ${parentItem?.otherNames} ${parentItem?.lastName}`} imageUrl={parentItem?.avatar} size={2.6} className='' />
                                                    <span className='group-hover:text-blue transition-colors duration-300'>{parentItem?.firstName} {parentItem?.otherNames} {parentItem?.lastName}</span>
                                                </button>


                                                {/*     <button type='button' className="hover:text-blue-500 whitespace-nowrap">
                                                    {parentItem?.firstName} {parentItem?.otherNames} {parentItem?.lastName}
                                                </button> */}
                                            </td>
                                            <td className='py-4 px-4'>{parentItem.parentType}</td>
                                            <td className='py-4 px-4'>{parentItem.primaryContact}</td>
                                            <td className='py-4 pl-4 pr-2'>{parentItem.email}</td>
                                            <td className='py-4 px-4'>{parentItem.delegateType}</td>
                                            <td className='py-4 pl-2 pr-4'>{parentItem.parentType}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }


            {
                profile.parents.length === 0 &&
                <ListNoData title='No Parent Found' description='No parent is assign to student'>

                </ListNoData>
            }

            <ModalParentDetails
                isOpen={parentDetails.open}
                parent={parentDetails.parent}
                onClose={() => setParentDetails((prevState) => ({ ...prevState, open: false }))}
            />
        </div>
    );
}

export default StudentParentsListPage;