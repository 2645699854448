import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import Modal from '../../../../../../../../components/modals/Modal';
import { AvatarSelectInput, FormInput, MultiSelectInput, PhoneNumberInput } from '../../../../../../../../components/form';
import { Button, ErrorLoading } from '../../../../../../../../components/common';
import canteenMembersService from '../../../../../../../../services/api/canteen/canteenMembersService';
import canteenServiceProvidersService from '../../../../../../../../services/api/canteen/canteenServiceProvidersService';
import { ListLoader } from '../../../../../../../../components/loaders';


function ModalCanteenCSPManagerForm({ isOpen, manager, school, serviceProvider, onClose, onManagerAdded, onManagerUpdated }: Props) {
    const [optionsStatus, setOptionsStatus] = useState({ loading: false, error: false });
    const [serviceProviderList, setServiceProviderList] = useState<any[]>([]);

    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });


    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });

            if (!serviceProvider) {
                getOptions();
            }
        }
    }, [isOpen]);


    const getOptions = async () => {
        if (serviceProviderList.length === 0) {
            setOptionsStatus({ loading: true, error: false });
            const result = await canteenServiceProvidersService.getProviders({ pageSize: 60, schoolId: school.id });

            if (result.success) {
                setOptionsStatus({ loading: false, error: false });
                setServiceProviderList(result.data);
            } else {
                setOptionsStatus({ loading: false, error: true });
            }
        }
    }


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        const payload: any = { ...formData };
        //payload.serviceProviderIds = serviceProvider ? [serviceProvider.id] : [];
        payload.serviceProviderIds = payload.serviceProviders.map((item: any) => item.id);

        delete payload.serviceProviders;


        setSubmitStatus({ loading: true, failedMessage: '' });
        if (manager) {
            delete payload.primaryContact;
            delete payload.email;
            // update
            const result = await canteenMembersService.updateMember(manager.id, payload, { schoolId: school.id });
            if (result.success) {
                if (onManagerUpdated) {
                    onManagerUpdated({ ...manager, ...result.data });
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        } else {
            // create
            payload.role = "CSP_MANAGER";
            payload.password = "DEFAULT_PASSWORD";

            const result = await canteenMembersService.createMember(payload, { schoolId: school.id });
            if (result.success) {
                if (onManagerAdded) {
                    onManagerAdded(result.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    }



    const getSelectedServiceProvider = (): any[] => {
        if (manager && manager.serviceProviders) {
            return manager.serviceProviders;
        }

        if (!manager && serviceProvider) {
            return [serviceProvider];
        }

        return [];
    }


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-screen max-w-xl">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <div className='mr-5'>
                        <h2 className="font-medium text-xl">
                            {manager ? "Edit" : "Add"} Canteen Owner
                        </h2>
                        {
                            !manager && serviceProvider &&
                            <p className='text-gray-500 dark:text-gray-400 mt-1'>Add manager to <span className='text-blue font-semibold'>{serviceProvider?.name}</span></p>
                        }
                    </div>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                {
                    !optionsStatus.loading && !optionsStatus.error &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <Formik
                            initialValues={{
                                avatar: manager ? manager.avatar : '',
                                firstName: manager ? manager.firstName : '',
                                otherNames: manager && manager.otherNames ? manager.otherNames : '',
                                lastName: manager ? manager.lastName : '',
                                //email: manager ? manager.email : '',
                                primaryContact: manager ? manager.primaryContact : '',
                                serviceProviders: getSelectedServiceProvider()
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                                <Form className="">
                                    {
                                        submitStatus.failedMessage && !submitStatus.loading &&
                                        <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                    }

                                    <div className='mb-6'>
                                        <AvatarSelectInput
                                            size={6.2}
                                            value={values['avatar']}
                                            placeholder='Select Picture'
                                            label='Select Picture'
                                            layout='inline'
                                            fileSize={0.8}
                                            onChange={(value) => setFieldValue('avatar', value)}
                                        />
                                    </div>


                                    <div className='grid sm:grid-cols-2 gap-6 mb-6'>
                                        <div className="">
                                            <label htmlFor="modal-input-first-name" className='block text-sm mb-2'>First name</label>
                                            <FormInput type="text" name="firstName" id="modal-input-first-name" className="w-full" placeholder="Enter first name" />
                                            {touched['firstName'] && errors['firstName'] && <p className='text-sm text-red-500 mt-2'>First name is Required</p>}
                                        </div>

                                        <div className="">
                                            <label htmlFor="modal-input-last-name" className='block text-sm mb-2'>Last name</label>
                                            <FormInput type="text" name="lastName" id="modal-input-last-name" className="w-full" placeholder="Enter last name" />
                                            {touched['lastName'] && errors['lastName'] && <p className='text-sm text-red-500 mt-2'>Last name is Required</p>}
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="modal-input-other-names" className='block text-sm mb-2'>Other names</label>
                                        <FormInput type="text" name="otherNames" id="modal-input-other-names" className="w-full" placeholder="Enter other names" />
                                        {touched['otherNames'] && errors['otherNames'] && <p className='text-sm text-red-500 mt-2'>Other names is Required</p>}
                                    </div>

                                    {/* <div className="mb-6">
                                        <label htmlFor="modal-input-email" className='block text-sm mb-2'>Email <span className='text-sm text-gray-500 dark:text-gray-400'>(Optional)</span></label>
                                        <FormInput type="email" name="email" id="modal-input-email" className="w-full" placeholder="Enter email address" autoComplete='off' role="presentation" />
                                        {touched['email'] && errors['email'] && <p className='text-sm text-red-500 mt-2'>Email is required</p>}
                                    </div>  */}

                                    <div className="mb-6">
                                        <label htmlFor="input-contact-number" className='block text-sm mb-2'>Primary Contact</label>
                                        <PhoneNumberInput
                                            id="input-contact-number"
                                            name="contact"
                                            className="w-full"
                                            //placeholder="Enter campus contact number"
                                            value={values["primaryContact"]}
                                            onChange={value => setFieldValue('primaryContact', value)}
                                            onBlur={() => setFieldTouched('primaryContact', true)}
                                        />
                                        {touched['primaryContact'] && errors['primaryContact'] && <p className='text-sm text-red-500 mt-2'>{errors['primaryContact'] as string}</p>}
                                    </div>

                                    {
                                        !serviceProvider &&
                                        <div className="mb-6">
                                            <label htmlFor="input-service-provider" className='block text-sm mb-2'>Vendors</label>
                                            <MultiSelectInput
                                                items={serviceProviderList}
                                                labelKey='name'
                                                valueKey='id'
                                                placeholder="Select vendor"
                                                selectedLabel='Vendor'
                                                selectedLabelPlural='Vendors'
                                                //showSelectedItems={false}
                                                itemsShowLimit={6}
                                                value={values["serviceProviders"]}
                                                onChange={(selected: any[]) => setFieldValue("serviceProviders", selected)}
                                                usePortal
                                            />

                                            {touched['serviceProviders'] && errors['serviceProviders'] && <p className='text-sm text-red-500 mt-2'>Select at least a vendor</p>}
                                        </div>
                                    }


                                    <div className='flex justify-end items-center mt-10'>
                                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                            Cancel
                                        </Button>

                                        <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                            {manager ? "Save" : "Add Owner"}
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                }

                {
                    (optionsStatus.loading || optionsStatus.error) &&
                    <div className='pb-10'>
                        {
                            optionsStatus.loading &&
                            <ListLoader loadingText='Loading Options' className='py-10' loaderSize={2} />
                        }
                        {
                            optionsStatus.error &&
                            <ErrorLoading title='Error Loading Options' message='An unexpected error occurred while loading options' className='px-8 py-24' onTryAgain={getOptions} />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


interface Props {
    isOpen: boolean;
    manager: any;
    school: any;
    serviceProvider?: any;
    onClose: () => void;
    onManagerAdded?: (manager: any) => void;
    onManagerUpdated?: (manager: any) => void;
}


const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required().label(""),
    //otherNames: Yup.string().trim().required().label(""),
    lastName: Yup.string().trim().required().label(""),
    //email: Yup.string().email().trim().label(""),
    primaryContact: Yup.string().trim().required("Contact number is Required").test("invalid-number", "Invalid number provided", value => value ? isValidPhoneNumber(value) : true).label(""),
    serviceProviders: Yup.array().min(1).required(),
});


export default ModalCanteenCSPManagerForm;