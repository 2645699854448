import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Menu } from '@headlessui/react';

import PopoverWithFloat from '../hoc/PopoverWithFloat';
import useClickOutside from '../../state/hooks/useClickOutside';


const range = (start: number, stop: number, step: number, startPad: number = 1) => {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => String(start + i * step).padStart(startPad, '0'));
}

function TimePickerInput({ value = '', minuitStep = 5, use24Hour = false, className = '', minHour = 0, maxHour = 23, onChange, onBlur, onFocus }: Props) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [focused, setFocused] = useState(false);

    const [meridiem, setMeridiem] = useState<"AM" | "PM">("AM");
    const [timeValue, setTimeValue] = useState<TimeType>({ hour: 0, minute: 0, seconds: 0, fullTime: '' });

    const minuteList = useMemo(() => range(0, 59, minuitStep, 2), [minuitStep]);
    const hourListIn24 = useMemo(() => range(minHour, maxHour, 1, 2), [minHour, maxHour]);

    let isClicked = false;
    useClickOutside(containerRef, () => {
        setFocused(false);
        if (onBlur && isClicked) {
            onBlur();
        }
    });


    const [amList, pmList]: [][] = useMemo(() => hourListIn24.reduce(([amList, pmList]: any[], hour: string) => {
        const numHour = Number(hour);
        if (numHour > 11) {
            return [amList, [...pmList, numHour]];
        }

        if (numHour === 0) {
            return [[...amList, 12], pmList];
        }

        return [[...amList, numHour], pmList];
    }, [[], []]), [hourListIn24]);


    useEffect(() => {
        if (amList.length === 0 && pmList.length > 0) {
            setMeridiem("PM");
        }

        if (pmList.length === 0 && amList.length > 0) {
            setMeridiem("AM");
        }
    }, [amList, pmList]);



    useEffect(() => {
        setInputLabel(value, use24Hour);
    }, [value, use24Hour]);


    const setInputLabel = (timeValue: string, use24Hour: boolean) => {
        if (timeValue) {

            const [hour, min] = timeValue.split(':');
            let hourInNumber: any = Number(hour);

            if (!use24Hour) {
                if (hourInNumber > 11) {
                    setMeridiem("PM");
                }

                if (hourInNumber > 12) {
                    hourInNumber = hourInNumber % 12;
                }

                if (hourInNumber === 0) {
                    hourInNumber = 12;
                }
            }
            if (use24Hour) {
                hourInNumber = String(hourInNumber).padStart(2, '0')
            }

            setTimeValue({
                hour: Number(hour),
                minute: Number(min),
                seconds: 0,
                fullTime: `${hourInNumber}:${min}`
            });
        }
    }



    const hoursList = (): any[] => {
        if (use24Hour) {
            return hourListIn24;
        }

        return meridiem === "AM" ? amList : pmList;
    }


    const getHour = (hour: number): number => {
        let hourIn24 = hour;
        if (!use24Hour && meridiem === "PM" && hour >= 1 && hour <= 11) {
            hourIn24 += 12;
        }

        return hourIn24;
    }



    return (
        <div ref={containerRef} onClick={() => isClicked = true}>
            <Menu as="div" className="relative" onFocus={onFocus}>
                {
                    ({ open }) => (
                        <PopoverWithFloat placement='bottom-start'>
                            <Menu.Button type='button' className={`w-full flex items-center text-left bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 ${focused || open ? '!border-blue-500/60 !dark:border-blue-500/60' : ''} focus:outline-none rounded-lg py-2.5 px-4 ${className}`}>
                                <span className='flex-grow mr-3.5'>
                                    {timeValue.fullTime} {!use24Hour && timeValue.fullTime !== '' ? meridiem : ''}
                                </span>
                                <i className="ri-time-line opacity-60"></i>
                            </Menu.Button>

                            <Menu.Items as='div' unmount={false} className="w-48 -left-2.5 origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                <div className='max-h-64 flex py-3 px-5'>
                                    {
                                        !use24Hour &&
                                        <div className='text-left text-sm mr-6'>
                                            {amList.length > 0 && <button type='button' onClick={() => setMeridiem("AM")} className={`rounded-md ${meridiem === "AM" ? 'bg-blue/25 hocus:bg-blue/40' : 'hocus:bg-gray-100 dark:hover:bg-gray-600 text-gray-500 dark:text-gray-300'} px-2 py-1 mb-2`}>AM</button>}
                                            {pmList.length > 0 && <button type='button' onClick={() => setMeridiem("PM")} className={`rounded-md ${meridiem === "PM" ? 'bg-blue/25 hocus:bg-blue/40' : 'hocus:bg-gray-100 dark:hover:bg-gray-600 text-gray-500 dark:text-gray-300'} px-2 py-1 mb-2`}>PM</button>}
                                        </div>
                                    }
                                    <div className='divide-y divide-gray-200 dark:divide-gray-700 overflow-y-auto'>
                                        {
                                            hoursList().map((hourItem) =>
                                                <div key={`hour-${hourItem}`} className="py-2">
                                                    {
                                                        minuteList.map((minuteItem) =>
                                                            <Menu.Item
                                                                key={`minute-${minuteItem}`}
                                                                as="button" type='button'
                                                                onClick={() => {
                                                                    const value: TimeType = {
                                                                        hour: getHour(Number(hourItem)),
                                                                        minute: Number(minuteItem),
                                                                        seconds: 0,
                                                                        fullTime: `${String(getHour(Number(hourItem))).padStart(2, '0')}:${minuteItem}`
                                                                    }

                                                                    setInputLabel(value.fullTime, use24Hour);
                                                                    //setTimeValue(value);
                                                                    if (onChange) {
                                                                        onChange(value);
                                                                    }
                                                                }}
                                                                className={`w-full text-left ${timeValue.hour === Number(hourItem) && timeValue.minute === Number(minuteItem) ? 'text-blue hocus:to-blue-600' : 'hocus:text-blue'} my-1`}
                                                            >
                                                                {hourItem}:{minuteItem}
                                                            </Menu.Item>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </Menu.Items>
                        </PopoverWithFloat>
                    )
                }
            </Menu>
        </div>
    );
}


interface Props {
    value?: string;
    minuitStep?: number;
    use24Hour?: boolean;
    className?: string;
    minHour?: number;
    maxHour?: number;
    onChange?: (value: TimeType) => void;
    onBlur?: () => void;
    onFocus?: () => void;
}


interface TimeType {
    hour: number;
    minute: number;
    seconds: number;
    fullTime: string;
}


export default TimePickerInput;