import React, { useEffect, useState } from 'react';

import appConfigurationsService from '../../../../../services/api/unified-api/appConfigurationsService';
import useToast from '../../../../../state/hooks/useToast';
import { Switch } from '@headlessui/react';
import useAlert from '../../../../../state/hooks/useAlert';

function SecuritySection() {
    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();


    const [passPinComplexity, setPassPinComplexity] = useState({ passwordComplexity: false, pinComplexity: false });

    useEffect(() => {
        getPasswordPinComplexity();
    }, []);


    const getPasswordPinComplexity = async () => {
        const result = await appConfigurationsService.getPasswordPinComplexity();
        if (result.success) {
            setPassPinComplexity(result.data);
        }
    }


    const handlePassPinComplexitySave = async (data: any): Promise<boolean> => {
        const result = await appConfigurationsService.updatePasswordPinComplexity(data);
        if (result.success) {
            setPassPinComplexity({ ...result.data });
        }
        return result.success;
    }

    return (
        <div>
            <h2 className='font-medium text-xl border-b border-gray-300 dark:border-gray-700 pb-3.5 mb-2'>Security</h2>

            <div className='max-w-lg'>
                <div className=''>
                    <div className='py-5'>
                        <div className='flex justify-between items-center gap-x-5 gap-y-1'>
                            <div className=''>
                                <p>Password Complexity</p>
                                <p className='text-gray-500 dark:text-gray-400'>Turn On/Off password complexity for all users</p>
                            </div>
                            <Switch
                                checked={passPinComplexity.passwordComplexity}
                                onChange={(on: boolean) => {
                                    openAlertConfirm({
                                        title: `Turn ${on ? 'On' : 'Off'} Password Complexity`,
                                        message:
                                            <div>
                                                <p className='mb-4'>When the feature is turned {on ? 'ON' : 'OFF'}, user accounts will {on ? '' : 'not'} be required to comply with the password complexity requirements.</p>
                                                <p>Are you sure you want to turn {on ? 'ON' : 'OFF'} password complexity for all accounts.</p>
                                            </div>,
                                        onConfirmed: async () => {
                                            const result = await handlePassPinComplexitySave({ ...passPinComplexity, passwordComplexity: on });
                                            if (result) {
                                                addToast({
                                                    text: `Password Complexity turned ${on ? 'ON' : 'OFF'}.`,
                                                    type: 'success'
                                                });
                                            } else {
                                                addToast({
                                                    title: 'Error',
                                                    text: `Could not ${on ? 'ON' : 'OFF'} Password Complexity`,
                                                    type: 'error'
                                                });
                                            }
                                        },
                                        positiveButtonText: on ? "Turn On" : "Turn Off"
                                    });
                                }}
                                className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                            >
                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                            </Switch>
                        </div>
                    </div>

                    <div className='py-5'>
                        <div className='flex justify-between items-center gap-x-5 gap-y-1'>
                            <div className=''>
                                <p>Pin Complexity</p>
                                <p className='text-gray-500 dark:text-gray-400'>Turn On/Off pin complexity for all users</p>
                            </div>
                            <Switch
                                checked={passPinComplexity.pinComplexity}
                                onChange={(on: boolean) => {
                                    openAlertConfirm({
                                        title: `Turn ${on ? 'On' : 'Off'} Pin Complexity`,
                                        message:
                                            <div>
                                                <p className='mb-4'>When the feature is turned {on ? 'ON' : 'OFF'}, user accounts will {on ? '' : 'not'} be required to comply with the pin complexity requirements.</p>
                                                <p>Are you sure you want to turn {on ? 'ON' : 'OFF'} pin complexity for all accounts.</p>
                                            </div>,
                                        onConfirmed: async () => {
                                            const result = await handlePassPinComplexitySave({ ...passPinComplexity, pinComplexity: on });
                                            if (result) {
                                                addToast({
                                                    text: `Pin Complexity turned ${on ? 'ON' : 'OFF'}.`,
                                                    type: 'success'
                                                });
                                            } else {
                                                addToast({
                                                    title: 'Error',
                                                    text: `Could not ${on ? 'ON' : 'OFF'} Pin Complexity`,
                                                    type: 'error'
                                                });
                                            }
                                        },
                                        positiveButtonText: on ? "Turn On" : "Turn Off"
                                    });
                                }}
                                className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                            >
                                <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecuritySection;