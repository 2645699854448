import { canteenApi } from '../httpService';


const getModuleDetails = async (query: any) => {
    try {
        const result = await canteenApi.get(`clients/me`, { params: query });
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


const updateModuleDetails = async (data: any, query: any = {}) => {
    try {
        const result = await canteenApi.patch(`clients/me`, data, { params: query });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating module. Please try again later."
        };
    }
}


export default {
    getModuleDetails,
    updateModuleDetails
}