export const routeLinks: RouteLinksType = Object.freeze({
    schoolManagement: {
        groupLabel: "School Management",
        url: '',
        visible: true,
        items: {
            dashboard: {
                label: "Dashboard",
                icon: "ri-dashboard-3-line",
                url: "/",
                visible: true,
                new: false,
                children: {},
            },
            schools: {
                label: "Schools",
                icon: "ri-community-line",
                url: "/schools",
                visible: true,
                new: false,
                children: {},
            },
            students: {
                label: "Students",
                icon: "ri-graduation-cap-line",
                url: "/students",
                visible: true,
                new: false,
                children: {},
            },
            users: {
                label: "Users",
                icon: "ri-group-line",
                url: "/users",
                visible: true,
                new: false,
                children: {
                    userAccounts: {
                        label: "Accounts",
                        icon: "ri-user-3-line",
                        url: "/users/accounts",
                        visible: true,
                        new: false,
                    },
                    roles: {
                        label: "Roles",
                        icon: "ri-user-settings-line",
                        url: "/users/roles",
                        visible: true,
                        new: false,
                    },
                    permissions: {
                        label: "Permissions",
                        icon: "ri-user-follow-line",
                        url: "/users/permissions",
                        visible: true,
                        new: false,
                    }
                },
            },
            scanners: {
                label: "Scanners",
                icon: "ri-cellphone-fill",
                url: "/scanners",
                visible: true,
                new: false,
                children: {},
            },
            settlementAccount: {
                label: "Settlement Accounts",
                icon: "ri-bank-line",
                url: "/settlement-accounts",
                visible: true,
                new: false,
                children: {},
            },
        }
    },
    appManagement: {
        groupLabel: "App Management",
        url: '',
        visible: true,
        items: {
            invoicing: {
                label: "Invoicing",
                icon: "ri-file-list-line",
                url: "/payments/invoices/create",
                visible: false,
                new: true,
                children: {},
            },
            appFeeds: {
                label: "App Feeds",
                icon: "ri-rss-fill",
                url: "/feeds",
                visible: true,
                new: false,
                children: {},
            },
            appNotifications: {
                label: "App Notifications",
                icon: "ri-notification-3-line",
                url: "/app-notifications",
                visible: true,
                new: false,
                children: {},
            },
            appRatings: {
                label: "App Ratings",
                icon: "ri-star-half-line",
                url: "/app-ratings",
                visible: true,
                new: false,
                children: {},
            },
            payments: {
                label: "Payments",
                icon: "ri-bank-card-line",
                url: "/payments",
                visible: false,
                new: true,
                children: {
                    userAccounts: {
                        label: "Third Parties",
                        icon: "ri-shape-fill",
                        url: "/payments/third-parties",
                        visible: true,
                        new: true,
                    },
                },
            },
            appConfigurations: {
                label: "App Configurations",
                icon: "ri-equalizer-fill",
                url: "/app-configurations",
                visible: true,
                new: false,
                children: {},
            },
        },
    }
});


export interface RouteLinksType {
    [groupKey: string]: RouteLinksGroupType
}


export interface RouteLinksGroupType {
    groupLabel: string;
    visible: boolean;
    url: string;
    items: {
        [itemKey: string]: LinkItem & {
            children: {
                [childKey: string]: LinkItem
            }
        }
    }
}


interface LinkItem {
    label: string;
    icon: string;
    url: string;
    visible: boolean;
    new: boolean;
}
