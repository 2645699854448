import React, { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Menu } from '@headlessui/react';

import useToast from '../../../../../../../../../../state/hooks/useToast';
import invoicesService from '../../../../../../../../../../services/api/payments/invoicesService';
import { Pagination, ListNoData, ErrorLoading } from '../../../../../../../../../../components/common';
import PopoverWithFloat from '../../../../../../../../../../components/hoc/PopoverWithFloat';
import { ListLoader } from '../../../../../../../../../../components/loaders';
import { paginationInfo } from '../../../../../../../../../../utils/pagination';
import ModalInvoiceDetails from '../../../modals/ModalInvoiceDetails';


const unlockableInvoices = ['UNPAID', 'PARTIALLY_PAID'];

function StudentInvoicesListSection() {
    const { invoiceList }: any = useOutletContext();

    //const { data: invoices, requestData, resetRecords, updateRecord } = usePagedListApi((query: any) => invoicesService.getCustomerInvoicesByReference(profile.id, query));
    const { data: invoices, requestData, resetRecords, updateRecord } = invoiceList;
    const [searchParams, setSearchParams] = useSearchParams();

    const { addToast } = useToast();

    const [invoiceDetails, setInvoiceDetails] = useState({ open: false, invoice: null });

    // get query params
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};
    // if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }


    useEffect(() => {
        getInvoices();
    }, [searchParams]);


    const getInvoices = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                properties: "invoiceNumber",
                direction: "ASC"
            },
        });
    };



    const handleInvoiceUnlock = async (invoice: any) => {
        const result = await invoicesService.unlockInvoices({
            invoiceIds: [invoice.id]
        });

        if (result.success) {
            addToast({
                text: "Invoice successfully unlocked",
                type: "success",
            });
        } else {
            addToast({
                text: "Could not unlock invoice",
                type: "error",
            });
        }
    }



    const handleInvoiceRecompute = async (invoice: any) => {
        const result = await invoicesService.recomputeInvoice(invoice.id);

        if (result.success) {
            addToast({
                text: "Invoice amount successfully recomputed",
                type: "success",
            });
        } else {
            addToast({
                text: "Could not recompute invoice amount",
                type: "error",
            });
        }
    }



    return (
        <div className=''>
            <div className='flex justify-between flex-wrap gap-2 mb-2'>
                <h2 className='font-medium text-xl mr-4'>Invoices</h2>

            </div>


            {
                // if there're invoices
                !invoices.loadingError && !invoices.loading && invoices.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto align-top w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='whitespace-nowrap py-6 pl-2 pr-6'>Invoice No.</th>
                                    <th className='whitespace-nowrap py-6 px-6'>Invoice Name</th>
                                    <th className='whitespace-nowrap py-6 px-6'>Total Amount</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Created</th>
                                    <th className='whitespace-nowrap py-6 px-6'>Due Date</th>
                                    <th className='py-6 pl-6 pr-2 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    invoices.list.map((invoiceItem: any) =>
                                        <tr key={invoiceItem?.id}>
                                            <td className='py-4 pl-2 pr-6'>
                                                <button type='button' onClick={() => setInvoiceDetails({ open: true, invoice: invoiceItem })} className='hover:text-blue'>{invoiceItem.invoiceNumber}</button>
                                            </td>
                                            <td className='py-4 px-6'>{invoiceItem.name}</td>
                                            <td className='whitespace-nowrap py-4 px-6'>GHS {invoiceItem.invoiceTotalAmount.toFixed(2)}</td>
                                            <td className='py-4 px-6'>{invoiceItem.status}</td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(invoiceItem?.generatedDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(invoiceItem?.dueDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 pl-6 pr-2 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-8 h-8 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-60 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            {
                                                                unlockableInvoices.includes(invoiceItem.status) &&
                                                                <React.Fragment>
                                                                    <Menu.Item as="button" type='button' onClick={() => handleInvoiceRecompute(invoiceItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                        Recompute Invoice
                                                                    </Menu.Item>

                                                                    <Menu.Item as="button" type='button' onClick={() => handleInvoiceUnlock(invoiceItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                        Unlock Invoice
                                                                    </Menu.Item>
                                                                </React.Fragment>
                                                            }

                                                            <Menu.Item as="button" type='button' onClick={() => setInvoiceDetails({ open: true, invoice: invoiceItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                View Details
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(invoices.pager.pageSize, invoices.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={invoices.pager.pageSize}
                            totalCount={invoices.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if invoices are loading
                !invoices.loadingError && invoices.loading &&
                <ListLoader loadingText='Loading Invoices' className='px-8 py-24' />
            }

            {
                // if there're no invoices on the student's account
                !invoices.loadingError && !invoices.loading && invoices.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Invoices' description='Student is yet to make a invoice' className='px-8 py-24'>
                </ListNoData>
            }

            {
                // if there're no invoice found in search
                !invoices.loadingError && !invoices.loading && invoices.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Invoices' description='No invoice found for the selected scenarios' className='px-8 py-24' />
            }


            {
                invoices.loadingError &&
                <ErrorLoading title='Error Loading Invoices' message='There was a problem loading invoices' className='px-8 py-24' onTryAgain={getInvoices} />
            }


            <ModalInvoiceDetails
                isOpen={invoiceDetails.open}
                invoice={invoiceDetails.invoice}
                onClose={() => setInvoiceDetails((prevState) => ({ ...prevState, open: false }))}
            />
        </div>
    );
}

export default StudentInvoicesListSection;