

export function compareVersions(previousVersion: string, currentVersion: string): number {
    if (currentVersion === previousVersion) {
        return 0;
    }

    const currentVersionArray = currentVersion.split('.');
    const previousVersionArray = previousVersion.split('.');

    // Loop through each component of the version numbers
    for (let i = 0; i < Math.max(currentVersionArray.length, previousVersionArray.length); i++) {
        const currentPart = parseInt(currentVersionArray[i] || '0');
        const previousPart = parseInt(previousVersionArray[i] || '0');

        if (currentPart > previousPart) {
            return 1; // Current version is higher
        } else if (currentPart < previousPart) {
            return -1; // Previous version is higher
        }
    }

    return 0;
}