import React, { Component, ErrorInfo } from 'react'; // Note: ErrorBoundary requires a class component


class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }


    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can log the error to an error reporting service
        //console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.children;;
        }

        return this.props.children;
    }
}


interface ErrorBoundaryProps {
    children: React.ReactNode;
}


interface ErrorBoundaryState {
    hasError: boolean;
    // error?: Error | null;
    //errorInfo?: React.ErrorInfo | null;
}


export default ErrorBoundary;