import React, { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import useToast from '../../../../../../../../../state/hooks/useToast';
import { Button, ErrorLoading, ListNoData, Pagination } from '../../../../../../../../../components/common';
import employeeService from '../../../../../../../../../services/api/unified-api/employeeService';
import { format } from 'date-fns';
import ModalAdminAccountForm from '../../../../../modals/ModalAdminAccountForm';
import { ListLoader } from '../../../../../../../../../components/loaders';
import usePagedListApi from '../../../../../../../../../state/hooks/usePagedListApi';
import { paginationInfo } from '../../../../../../../../../utils/pagination';
import ModalEmployeeDetails from '../../../../../../users/modals/ModalEmployeeDetails';


function SchoolAdminAccountsListPage() {
    const { profile, adminAccountList }: any = useOutletContext();


    //const { data: adminAccounts, requestData, resetRecords, updateRecord } = usePagedListApi((query: any) => employeeService.getEmployees(profile.id, query));
    const { data: adminAccounts, requestData, resetRecords, updateRecord } = adminAccountList;
    const [searchParams, setSearchParams] = useSearchParams();

    const { addToast } = useToast();

    const [adminAccountForm, setAdminAccountForm] = useState({ open: false, account: null });
    const [employeeDetailsModal, setEmployeeDetailsModal] = useState({ open: false, employee: null });

    // get query params
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};
    // if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }


    useEffect(() => {
        getSchoolAdmins();
    }, [searchParams]);


    const getSchoolAdmins = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                properties: "firstName",
                direction: "ASC"
            },
        });
    };



    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl overflow-x-auto py-8 px-8'>
            <div className='flex justify-between flex-wrap gap-2 mb-6'>
                <h2 className='font-medium text-xl mr-4'>School Admin Accounts</h2>
                <Button type='button' onClick={() => setAdminAccountForm({ open: true, account: null })} className='py-2.5'>Add Admin Account</Button>
            </div>


            {
                // if there're admins
                !adminAccounts.loadingError && !adminAccounts.loading && adminAccounts.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto align-top w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-5 pl-2 pr-6'>Name</th>
                                    <th className='py-5 px-6'>Email</th>
                                    <th className='whitespace-nowrap py-5 px-6'>Phone No.</th>
                                    <th className='py-5 px-6'>Status</th>
                                    <th className='whitespace-nowrap py-5 pl-6 pr-2'>Date Created</th>
                                    {/*  <th className='py-6 pl-6 pr-2 text-right'>Action</th> */}
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    adminAccounts.list.map((adminItem: any) =>
                                        <tr key={adminItem?.id}>
                                            <td className='py-4 pl-2 pr-6'>
                                                <button type='button' onClick={() => setEmployeeDetailsModal({ open: true, employee: adminItem })} className='hover:text-blue'>
                                                    {adminItem.firstName} {adminItem.otherNames || ''} {adminItem.lastName}
                                                </button>
                                            </td>
                                            <td className='py-4 px-6'>{adminItem.email}</td>
                                            <td className='py-4 px-6'>{adminItem.primaryContact}</td>
                                            <td className='py-4 px-6'>
                                                <span className={`inline-block font-medium text-sm ${adminItem.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{adminItem.status}</span>
                                            </td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(adminItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(adminAccounts.pager.pageSize, adminAccounts.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={adminAccounts.pager.pageSize}
                            totalCount={adminAccounts.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if admins are loading
                !adminAccounts.loadingError && adminAccounts.loading &&
                <ListLoader loadingText='Loading Admins' className='px-8 py-24' />
            }

            {
                // if there're no admins for added
                !adminAccounts.loadingError && !adminAccounts.loading && adminAccounts.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Accounts Found' description='No admin account found for school' className='px-8 py-24'>
                </ListNoData>
            }

            {
                // if there're no admins found in search
                !adminAccounts.loadingError && !adminAccounts.loading && adminAccounts.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Accounts Found' description='No admin account found for the selected scenarios' className='px-8 py-24' />
            }


            {
                adminAccounts.loadingError &&
                <ErrorLoading title='Error Admin Accounts' message='There was a problem admin accounts' className='px-8 py-24' onTryAgain={getSchoolAdmins} />
            }


            <ModalAdminAccountForm
                isOpen={adminAccountForm.open}
                adminAccount={adminAccountForm.account}
                schoolId={profile.id}
                onClose={() => setAdminAccountForm({ open: false, account: null })}
                onAdminAccountCreated={(account) => {
                    addToast({
                        text: `Admin account successfully created`,
                        type: "success"
                    });

                    resetRecords()
                    setSearchParams({});

                    if (Array.from(searchParams.entries()).length === 0) {
                        getSchoolAdmins();
                    }
                }}
                onAdminAccountUpdated={(account) => {
                    addToast({
                        text: `Admin account successfully updated`,
                        type: "success"
                    });
                }}
                onAdminCreatedNotAccount={(account: any) => {
                    addToast({
                        title: 'Could not activate account',
                        text: `Employee account created successfully`,
                        type: "warning",
                    });

                    resetRecords()
                    setSearchParams({});

                    if (Array.from(searchParams.entries()).length === 0) {
                        getSchoolAdmins();
                    }
                }}
            />


            <ModalEmployeeDetails
                isOpen={employeeDetailsModal.open}
                employee={employeeDetailsModal.employee}
                onClose={() => setEmployeeDetailsModal((prevState) => ({ ...prevState, open: false }))}
                schoolId={profile.id}
            />
        </div>
    );
}

export default SchoolAdminAccountsListPage;