import React from 'react';
import { RadioGroup } from '@headlessui/react';


function RadioGroupInput({ options, value, className = '', onChange }: Props) {
    return (
        <RadioGroup value={value} onChange={onChange} className={`flex flex-wrap gap-x-6 gap-y-3 ${className}`}>
            {
                options.map((option, optionIndex) =>
                    <RadioGroup.Option key={`${option.value}${optionIndex}`} value={option.value} className="flex items-center cursor-pointer">
                        {({ checked }) => (
                            <React.Fragment>
                                <span className={`${checked ? 'text-blue' : ''} leading-none mr-1.5`}>
                                    {checked ? <i className="ri-radio-button-line"></i> : <i className="ri-checkbox-blank-circle-line"></i>}
                                </span>
                                <span className="whitespace-nowrap ">{option.label}</span>
                            </React.Fragment>
                        )}
                    </RadioGroup.Option>
                )
            }
        </RadioGroup>
    );
}

interface Props {
    options: OptionsType[];
    value: any;
    className?: string;
    onChange: (value: any) => void;
}

interface OptionsType {
    label: string;
    value: string | boolean | number;
}

export default RadioGroupInput;