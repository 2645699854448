import { Field } from 'formik';
import React, { LegacyRef } from 'react';


export const TextArea = React.forwardRef(({ className, ...otherProps }: Props, ref: LegacyRef<HTMLTextAreaElement>) => {
    return (
        <textarea
            ref={ref}
            className={`bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 focus:border-blue-500/60 dark:focus:border-blue-500/60 focus:outline-none rounded-lg py-2.5 px-4 ${className}`}
            {...otherProps}
        />
    );
})


export const FormTextArea = React.forwardRef(({ className, rows = 3, ...otherProps }: Props, ref: LegacyRef<HTMLTextAreaElement>) => {
    return (
        <Field
            component="textarea"
            ref={ref}
            className={`bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 focus:border-blue-500/60 dark:focus:border-blue-500/60 focus:outline-none rounded-lg py-2.5 px-4 ${className}`}
            {...otherProps}
            rows={rows}
        />
    );
})


interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> { }


//export default TextArea;