import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';

import thirdPartyService from '../../../../../../services/api/payments/thirdPartyService';
import usePagedListApi from '../../../../../../state/hooks/usePagedListApi';
import { Button, ErrorLoading, LinkButton, ListNoData, Pagination } from '../../../../../../components/common';
import { paginationInfo } from '../../../../../../utils/pagination';
import { ListLoader } from '../../../../../../components/loaders';
import { changePageInfo } from '../../../../../../state/store/reducers/ui/headerSlice';


function ThirdPartyListPage() {
    const dispatch = useDispatch();

    const { data: thirdParties, requestData, resetRecords, updateRecord } = usePagedListApi(thirdPartyService.getThirdParties);
    const [searchParams, setSearchParams] = useSearchParams();

    // get query params
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};
    if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }
    if (searchParams.get('primaryContact')) { searchFilters['primaryContact'] = searchParams.get('primaryContact') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "Third Parties" }));

        getThirdParties();
    }, [searchParams, dispatch]);


    const getThirdParties = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                direction: 'asc',
                properties: 'companyName',
            },
        });
    };


    return (
        <div className=''>
            <div className='flex flex-row-reverse flex-wrap justify-between mb-1'>
                <LinkButton to="/payments/third-parties/new" onClick={() => null} className='mb-4'>Create Third Party</LinkButton>
            </div>

            {
                // if there're third parties
                !thirdParties.loadingError && !thirdParties.loading && thirdParties.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>Company</th>
                                    <th className='py-6 px-6'>Domain</th>
                                    <th className='py-6 px-6'>E-Mail</th>
                                    <th className='py-6 px-6'>Source</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Last Used</th>
                                    {/*  <th className='py-6 px-6'>Phone No.</th>
                                    <th className='py-6 px-6'>Source</th>
                                    <th className='py-6 px-6'>Date Created</th>
                                    <th className='py-6 px-6 text-right'>Action</th> */}
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    thirdParties.list.map((thirdPartyItem: any) =>
                                        <tr key={thirdPartyItem.id}>
                                            <td className='py-4 px-6'>
                                                <Link to={`/payments/third-parties/${thirdPartyItem.id}`} state={{ thirdParty: thirdPartyItem }} className="transition-colors duration-300 hover:text-blue-500">
                                                    {thirdPartyItem?.companyName}
                                                </Link>
                                            </td>
                                            <td className='py-4 px-6'>{thirdPartyItem?.domainName}</td>
                                            <td className='py-4 px-6'>{thirdPartyItem?.email}</td>
                                            <td className='py-4 px-6'>{thirdPartyItem?.dataSource}</td>
                                            <td className='py-4 px-6'>
                                                {
                                                    thirdPartyItem?.status === "ACTIVE" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-3 mr-3`}>{thirdPartyItem?.status}</span>
                                                }
                                                {
                                                    thirdPartyItem?.status !== "ACTIVE" && thirdPartyItem?.status !== "SUSPENDED" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-3 mr-3`}>{thirdPartyItem?.status}</span>
                                                }
                                                {
                                                    thirdPartyItem?.status === "SUSPENDED" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-1 px-3 mr-3`}>{thirdPartyItem?.status}</span>
                                                }
                                            </td>
                                            <td className='py-4 px-6 whitespace-nowrap'>
                                                {
                                                    thirdPartyItem?.lastUsed ? format(new Date(thirdPartyItem?.lastUsed), 'd LLL yyyy h:mm a') : 'N/A'
                                                }
                                            </td>
                                            {/*  <td className='py-4 px-6 text-right'>
                                            <Menu as="div" className="relative inline-block text-left">
                                                <PopoverWithFloat placement='bottom-end' portal>
                                                    <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                        <i className="ri-more-2-fill"></i>
                                                    </Menu.Button>

                                                    <Menu.Items as='div' unmount={false} className="w-60 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                        <Menu.Item as={Link} to={`/schools/${schoolItem.id}`} state={{ schoolName: schoolItem.name }} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                            Go to Profile
                                                        </Menu.Item>
                                                        <Menu.Item as="button" type='button' onClick={() => setSchoolForm({ open: true, school: schoolItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                            Edit
                                                        </Menu.Item>
                                                        <Menu.Item as="button" type='button' onClick={() => setConfigurationsForm({ open: true, school: schoolItem, configuration: schoolItem?.configuration })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                            {schoolItem?.configuration ? "Change" : "Add"} Configurations
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </PopoverWithFloat>
                                            </Menu>
                                        </td> */}
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(thirdParties.pager.pageSize, thirdParties.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={thirdParties.pager.pageSize}
                            totalCount={thirdParties.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if data are loading
                !thirdParties.loadingError && thirdParties.loading &&
                <ListLoader loadingText='Loading Third Parties' className='px-8 py-24' />
            }

            {
                // if there're no third parties in the app
                !thirdParties.loadingError && !thirdParties.loading && thirdParties.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Third Parties Found' description='There are no third parties currently setup in SmartSapp' className='px-8 py-24'>
                    {/* <Button type='button' onClick={() => setSchoolForm({ open: true, school: null })}>Add a School Here</Button> */}
                </ListNoData>
            }

            {
                // if there're no third parties found
                !thirdParties.loadingError && !thirdParties.loading && thirdParties.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Third Parties Found' description='No third parties was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                thirdParties.loadingError &&
                <ErrorLoading title='Error Loading Third Parties' message='There was a problem loading third parties' className='px-8 py-24' onTryAgain={getThirdParties} />
            }
        </div>
    );
}

export default ThirdPartyListPage;