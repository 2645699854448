import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { useOutletContext } from 'react-router-dom';
import { format } from 'date-fns';

import useToast from '../../../../../../../../../state/hooks/useToast';
import { Avatar } from '../../../../../../../../../components/common';
import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import ModalSchoolForm from '../../../../../modals/ModalSchoolForm';
import ModalSchoolLogoForm from '../../../../../modals/ModalSchoolLogoForm';


function SchoolDetailsPage() {
    const { profile, setProfile }: any = useOutletContext();

    const [schoolFormOpen, setSchoolFormOpen] = useState(false);
    const [schoolLogoFormOpen, setSchoolLogoFormOpen] = useState(false);
    const { addToast } = useToast();


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            <div className='flex justify-between border-b dark:border-gray-700 pb-8'>
                <div className='flex items-center mr-5'>
                    <Avatar name={profile.name} imageUrl={profile.configuration?.logo} size={5.6} className="shrink-0 mr-5" />

                    <div>
                        <h2 className='text-xl mb-1'>{profile.name}</h2>
                        <p className='text-gray-500 dark:text-gray-400'>({profile.alias})</p>
                    </div>
                </div>

                <Menu as="div" className="relative inline-block text-left">
                    {
                        ({ open }) => (
                            <PopoverWithFloat placement='bottom-end' portal>
                                <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ${open ? 'bg-black/5 dark:bg-white/5' : ''}`}>
                                    <i className="ri-more-2-fill"></i>
                                </Menu.Button>

                                <Menu.Items as='div' unmount={false} className="w-56 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                    <Menu.Item
                                        as="button"
                                        type='button'
                                        className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                        onClick={() => setSchoolFormOpen(true)}
                                    >
                                        Edit School Details
                                    </Menu.Item>
                                    <Menu.Item
                                        as="button"
                                        type='button'
                                        className="w-full text-left whitespace-nowrap hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                        onClick={() => setSchoolLogoFormOpen(true)}
                                    >
                                        {profile.configuration?.logo ? "Change" : "Add"} School Logo
                                    </Menu.Item>
                                </Menu.Items>
                            </PopoverWithFloat>
                        )
                    }
                </Menu>
            </div>


            <div className='lg:col-span-4 xl:col-span-2 pt-6 px-6 pb-3'>
                <h2 className='font-medium text-xl mb-2'>School Details</h2>

                <div className='grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-8'>
                    <div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Contact Email</p>
                            {
                                profile.configuration?.contact_email ?
                                    <a href={`mailto:${profile.configuration?.contact_email}`} className='hover:underline'>{profile.configuration?.contact_email}</a> :
                                    <span>N/A</span>
                            }
                        </div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Contact Number</p>
                            <a href={`tel:${profile.contact}`} className='hover:underline'>{profile.contact}</a>
                        </div>

                    </div>

                    <div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Address</p>
                            <p>{profile.address || 'N/A'}</p>
                        </div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Location</p>
                            <p>{profile.location}</p>
                        </div>
                    </div>

                    <div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Country</p>
                            <p>{profile.country}</p>
                        </div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Locale</p>
                            <p>{profile.locale || 'N/A'}</p>
                        </div>
                    </div>

                    <div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date Created</p>
                            <p>{format(new Date(profile.createdDate), 'do LLLL yyyy h:mm a')}</p>
                        </div>
                        {
                            profile.updatedDate &&
                            <div className='py-3.5'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Updated</p>
                                <p>{format(new Date(profile.updatedDate), 'do LLLL yyyy h:mm a')}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>



            <ModalSchoolForm
                isOpen={schoolFormOpen}
                school={profile}
                onSchoolUpdated={(school) => {
                    setProfile((prevState: any) => ({ ...prevState, ...school }));
                    addToast({
                        text: `School updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setSchoolFormOpen(false)}
            />

            <ModalSchoolLogoForm
                isOpen={schoolLogoFormOpen}
                school={profile}
                onLogoChanged={(logo) => {
                    const existingLogo = profile.configuration.logo;
                    setProfile((prevState: any) => ({
                        ...prevState,
                        configuration: { ...prevState.configuration, logo }
                    }));

                    if (existingLogo) {
                        addToast({
                            text: `School logo updated successfully`,
                            type: "success"
                        });
                    } else {
                        addToast({
                            text: `School logo added successfully`,
                            type: "success"
                        });
                    }
                }}
                onClose={() => setSchoolLogoFormOpen(false)}
            />
        </div>
    );
}

export default SchoolDetailsPage;