import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
import { doSidebarOpen } from '../../../../../state/store/reducers/ui/sidebarSlice';
import schoolService from '../../../../../services/api/unified-api/schoolService';
import { ListLoader } from '../../../../../components/loaders';
import { Avatar, ErrorLoading } from '../../../../../components/common';
import { schoolRouteLinks } from '../../../../../config/routes-list/schoolNavLinkList';
import { RouteLinksGroupType } from '../../../../../config/routes-list/appNavLinkList';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';


function ManageSchoolPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { schoolId } = useParams();

    const [pageStatus, setPageStatus] = useState({ loading: false, error: false, schoolNotFound: false });
    const [profile, setProfile] = useState<any>(null);
    const [selectedModule, setSelectedModule] = useState<RouteLinksGroupType>();


    useEffect(() => {
        dispatch(doSidebarOpen({ open: false, keepClosed: true }));
        dispatch(changePageInfo({ title: 'Manage School' }));
        loadSchoolProfile();

        return () => {
            dispatch(doSidebarOpen({ open: true, keepClosed: false }));
        }
    }, []);


    useEffect(() => {
        determineSelectedModule();
    }, [location]);


    const determineSelectedModule = async () => {
        const moduleBase = location.pathname.split('/')[3];

        switch (moduleBase) {
            case 'canteen':
                setSelectedModule(schoolRouteLinks['canteen']);
                break;
            case 'manage':
                setSelectedModule(schoolRouteLinks['management']);
                break;
            case 'payments':
                setSelectedModule(schoolRouteLinks['payments']);
                break;
            default:
                setSelectedModule(schoolRouteLinks['profile']);
        }
    };


    const loadSchoolProfile = async () => {
        await getSchool();
    }



    const getSchool = async () => {
        setPageStatus({ loading: true, error: false, schoolNotFound: false });
        const result = await schoolService.getSchoolProfile(schoolId);
        if (result?.success) {
            setProfile(result.data);
            setPageStatus({ loading: false, error: false, schoolNotFound: false });
        } else {
            if (result.notFound) {
                setPageStatus({ loading: false, error: true, schoolNotFound: true });
            } else {
                setPageStatus({ loading: false, error: true, schoolNotFound: false });
            }
        }
    }



    return (
        <React.Fragment>
            {
                !pageStatus.loading && !pageStatus.error && profile &&
                <div className='xl:flex items-start gap-8'>
                    <div className='xl:sticky top-20 flex flex-wrap xl:block xl:min-w-[16rem] xl:w-[19rem] xl:bg-white xl:dark:bg-gray-800 xl:shadow dark:shadow-none rounded-xl py-6 px-5 gap-x-6 gap-y-2.5'>
                        <div className='flex items-center xl:mb-6'>
                            <Avatar name={profile?.name} imageUrl={profile?.configuration?.logo} size={3.2} className="shrink-0 mr-3" />

                            <div>
                                <h2 className='font-medium'>{profile?.name} <span className='text-gray-500 dark:text-gray-400'>({profile?.alias})</span></h2>
                            </div>
                        </div>

                        <div className='xl:mb-6'>
                            <Menu as="div" className="relative">
                                {
                                    ({ open }) => (
                                        <PopoverWithFloat placement='bottom-start' adaptiveWidth>
                                            <Menu.Button
                                                className={`w-full cursor-pointer flex justify-between text-left bg-transparent autofill:bg-white border  ${open ? 'border-blue-500/60 dark:border-blue-500/60' : 'border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600'} rounded-lg py-2.5 px-3.5`}
                                            >
                                                <span className='mr-4'>{selectedModule ? selectedModule.groupLabel : ''}</span>
                                                <i className="ri-arrow-down-s-line opacity-70"></i>
                                            </Menu.Button>
                                            <Menu.Items className="max-h-64 w-full min-w-fit text-left -left-2.5 origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60 overflow-y-scroll px-2 py-3">
                                                {
                                                    Object.values(schoolRouteLinks)
                                                        .filter(item => item.visible)
                                                        .map((moduleGroup) =>
                                                            <Menu.Item
                                                                key={moduleGroup.url}
                                                                as={NavLink}
                                                                to={moduleGroup.url.replace(':schoolId', profile.id)}
                                                                className={`block cursor-pointer text-left whitespace-nowrap ${selectedModule && selectedModule.url === moduleGroup.url ? 'text-blue' : ''} hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg w-full pl-5 pr-10 py-3`}
                                                            >
                                                                {moduleGroup.groupLabel}
                                                            </Menu.Item>
                                                        )
                                                }
                                            </Menu.Items>
                                        </PopoverWithFloat>
                                    )
                                }
                            </Menu>
                        </div>

                        <div className='xl:hidden'>
                            {
                                selectedModule &&
                                <Menu as="div" className="relative">
                                    {
                                        ({ open }) => (
                                            <PopoverWithFloat placement='bottom-start' adaptiveWidth>
                                                <Menu.Button
                                                    className={`w-full cursor-pointer flex justify-between text-left bg-transparent autofill:bg-white border  ${open ? 'border-blue-500/60 dark:border-blue-500/60' : 'border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600'} rounded-lg py-2.5 px-3.5`}
                                                >
                                                    <span className='mr-4'>Go to...</span>
                                                    <i className="ri-arrow-down-s-line opacity-70"></i>
                                                </Menu.Button>
                                                <Menu.Items className="max-h-64 w-full min-w-fit text-left -left-2.5 origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60 overflow-y-scroll px-2 py-3">
                                                    {
                                                        Object.values(selectedModule.items)
                                                            .filter(item => item.visible)
                                                            .map((item) =>
                                                                <Menu.Item
                                                                    key={item.url}
                                                                // as={NavLink}
                                                                //to={item.url.replace(':schoolId', profile.id)}
                                                                //className={({ isActive }: any) => `block cursor-pointer text-left whitespace-nowrap ${isActive ? 'text-blue' : ''} hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg w-full pl-5 pr-10 py-3`}
                                                                >
                                                                    <NavLink
                                                                        to={item.url.replace(':schoolId', profile.id)}
                                                                        className={({ isActive }) => `block cursor-pointer text-left whitespace-nowrap ${isActive ? 'text-blue' : ''} hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg w-full pl-5 pr-10 py-3`}
                                                                        end
                                                                    >
                                                                        <i className={`${item.icon} mr-2.5`}></i>
                                                                        <span>{item.label}</span>
                                                                    </NavLink>
                                                                </Menu.Item>
                                                            )
                                                    }
                                                </Menu.Items>
                                            </PopoverWithFloat>
                                        )
                                    }
                                </Menu>
                            }
                        </div>

                        {
                            selectedModule &&
                            <ul className='hidden xl:block'>
                                {
                                    Object.values(selectedModule.items)
                                        .filter(item => item.visible)
                                        .map(item =>
                                            <li
                                                key={item.url}
                                                className=''
                                            >
                                                <NavLink
                                                    to={item.url.replace(':schoolId', profile.id)}
                                                    className={({ isActive }) => `flex items-center w-full rounded-lg hover:bg-black/5 dark:hover:bg-white/5 whitespace-nowrap px-3 py-3 mb-1.5 ${isActive ? 'bg-blue-500/10 text-blue-500' : ''}`}
                                                    end
                                                >
                                                    <i className={`${item.icon} mr-2.5`}></i>
                                                    <span>{item.label}</span>
                                                </NavLink>
                                            </li>
                                        )
                                }
                            </ul>
                        }
                    </div>

                    {/* content area */}
                    <div className='flex-grow'>
                        <Outlet context={{ profile, setProfile }} />
                    </div>
                </div>
            }

            {
                // if it's loading
                pageStatus.loading &&
                <ListLoader loadingText='Loading Profile' className='px-8 py-24' />
            }

            {
                // if school is not found
                !pageStatus.loading && pageStatus.error && pageStatus.schoolNotFound &&
                <ErrorLoading title='School Not Found' message='School may have been removed or never existed' className='px-8 py-24' />
            }

            {
                // if there's an error loading
                !pageStatus.loading && pageStatus.error && !pageStatus.schoolNotFound &&
                <ErrorLoading title='Error Loading School' message='An unexpected error occurred while loading school' className='px-8 py-24' onTryAgain={loadSchoolProfile} />
            }
        </React.Fragment>
    );
}

export default ManageSchoolPage;