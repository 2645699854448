import { createSlice } from "@reduxjs/toolkit";

import { RootState } from './../../index';
import { DispatchType } from "../..";
import scannerService from "../../../../services/api/unified-api/scannerService";


const initialState = {
    list: {} as { [index: number]: any[] },
    loading: true,
    filters: null,
    pager: {
        pageSize: 0,
        page: 1,
        totalCount: 0
    },
    loadingError: false
};


const slice = createSlice({
    name: "scanners",
    initialState: initialState,
    reducers: {
        scannersRequested: (state, action) => {
            state.loading = true;
            state.filters = action.payload.filters;
            state.pager.page = action.payload.page;
            state.loadingError = false;
        },
        scannersReceived: (state, action) => {
            state.list[action.payload.pager.page] = action.payload.data;
            state.pager = action.payload.pager;
            state.loading = false;
        },
        scannersRequestFailed: (state, action) => {
            state.loadingError = true;
            state.loading = false;
        },
        updateScannerRecord: (state, action) => {
            const { scanner, page } = action.payload;

            const list = state.list[page];
            if (list) {
                const index = list.findIndex(scannerItem => scannerItem.id === scanner.id);
                list[index] = { ...scanner };

                state.list[page] = list;
            }
        },
        getCachedScanner: (state, action) => {
            state.pager.page = action.payload.page;
        },
        clearCachedScanner: (state, action) => {
            state.list = {};
        },
        resetScanners: (state, action) => initialState,
    }
});


const { scannersRequested, getCachedScanner, scannersRequestFailed, scannersReceived, clearCachedScanner } = slice.actions;
export const { resetScanners, updateScannerRecord } = slice.actions;




// Actions
export const loadScanners = (payload: any) => async (dispatch: DispatchType, getState: () => RootState) => {
    const scanners = getState().entities.scanners;
    const sameFilters = JSON.stringify(payload.filters) === JSON.stringify(scanners.filters);

    // load from cache when page is already loaded
    if (scanners.list[payload.page] && sameFilters) {
        dispatch(getCachedScanner({
            page: payload.page
        }));

        return;
    }


    if (!sameFilters) {
        dispatch(clearCachedScanner({}));
    }


    dispatch(scannersRequested(payload));
    const result = await scannerService.getScanners({
        ...payload.filters,
        page: payload.page,
        pageSize: 10,
        direction: 'desc',
        properties: 'id',
    });

    if (result.success) {
        dispatch(scannersReceived(result));
    } else {
        dispatch(scannersRequestFailed({}));
    }
};




// Selectors
export const selectScanners = (state: RootState): ScannersSelectorType => {
    const scanners = { ...state.entities.scanners };
    scanners.list = scanners.list[state.entities.scanners.pager.page] || [];

    return scanners as ScannersSelectorType;
};


type ScannersSelectorType = Omit<typeof initialState, 'list'> & {
    list: any[];
}


export default slice.reducer;