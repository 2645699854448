import React, { useRef } from 'react';

import { Button } from '../../common';
import Modal from '../Modal';


function ModalAlertOk({ isOpen, title, message, onOk, buttonText = 'Ok', buttonClassNames = '' }: ModalAlertOkProps) {
    let focusButtonRef = useRef(null);

    return (
        <Modal initialFocus={focusButtonRef} open={isOpen} onClose={onOk} closeOnOutsideClicked={false}>
            <div className="inline-block w-[40rem] max-w-xl">
                <div className="flex justify-between mb-4 px-8 pt-6">
                    <h2 className="font-medium text-xl">{title}</h2>
                </div>

                <div className='px-8 pb-7'>
                    <div className="'text-gray-500 dark:text-gray-400 mb-5">{message}</div>
                    <div className='text-right'>
                        <Button ref={focusButtonRef} type='button' onClick={onOk} className={`py-2.5 ${buttonClassNames}`}>
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


export interface ModalAlertOkProps {
    isOpen: boolean;
    title: string | JSX.Element;
    message: string | JSX.Element;
    onOk: () => void;
    buttonText?: string;
    buttonClassNames?: string;
}

export default ModalAlertOk;