import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import classroomService from '../../../../../../../services/api/unified-api/classroomService';
import employeeService from '../../../../../../../services/api/unified-api/employeeService';
import usePagedListApi from '../../../../../../../state/hooks/usePagedListApi';

function SchoolProfilePage() {
    const { profile, setProfile }: any = useOutletContext();

    const adminAccountList = usePagedListApi((query: any) => employeeService.getEmployees(profile.id, query));
    const classroomsList = usePagedListApi((query: any) => classroomService.getClassrooms(profile.id, query));

    return (
        <Outlet context={{ profile, setProfile, classroomsList, adminAccountList }} />
    );
}

export default SchoolProfilePage;