import { paymentsApi } from '../httpService';


const getSchoolSettlementAccounts = async (query: any) => {
    try {
        const result = await paymentsApi.get('/api/v1/accounts/collecting', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const getAccountDetails = async (id: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/accounts/collecting/${id}`, {});
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


const getAccountProviders = async (accountId: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/accounts/collecting/${accountId}/processor-credentials`);

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



const getProcessorProviders = async () => {
    try {
        const result = await paymentsApi.get("/api/v1/processors");
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem loading processors."
        };
    }
}




const activateAccount = async (accountId: any) => {
    try {
        const result = await paymentsApi.patch(`/api/v1/accounts/collecting/approve`, { accountId });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: null,
                message: error.response.data.errors[0].message
            }
        }


        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem activating account."
        };
    }
}



const addProcessorProvider = async (accountProcessorId: number, data: any) => {
    try {
        const result = await paymentsApi.post(`/api/v1/processor-credentials/processor/${accountProcessorId}`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: null,
                message: error.response.data.errors[0].message
            }
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem adding a provider. Please try again later."
        };
    }
}


const updateProcessorProvider = async (providerId: number, data: any) => {
    try {
        const result = await paymentsApi.put(`/api/v1/processor-credentials/${providerId}`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: null,
                message: error.response.data.errors[0].message
            }
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating a provider. Please try again later."
        };
    }
}



const deleteProcessorProvider = async (providerId: number) => {
    try {
        const result = await paymentsApi.delete(`/api/v1/processor-credentials/${providerId}`);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating a provider. Please try again later."
        };
    }
}


export default {
    getSchoolSettlementAccounts,
    activateAccount,
    getAccountDetails,
    getAccountProviders,
    getProcessorProviders,
    addProcessorProvider,
    updateProcessorProvider,
    deleteProcessorProvider
}