import React, { useEffect, useState } from 'react';

import Modal from '../../../../../components/modals/Modal';
import { Button } from '../../../../../components/common';
import useToast from '../../../../../state/hooks/useToast';
import userRolesService from '../../../../../services/api/user-accounts/userRolesService';
import useAlert from '../../../../../state/hooks/useAlert';


function ModalUserRoleDetails({ isOpen, role, onClose, onPermissionsChanged }: Props) {
    const [permissions, setPermissions] = useState<any[]>([]);
    const [changed, setChanged] = useState(false);

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();


    useEffect(() => {
        if (isOpen) {
            setPermissions(role.permissions);
        }
    }, [isOpen, role]);


    const handleClose = () => {
        if (changed && onPermissionsChanged) {
            onPermissionsChanged({ ...role, permissions });
        }
        onClose();
    }


    const handlePermissionRemove = async (permission: any, index: any) => {
        openAlertConfirm({
            title: 'Remove Permission',
            message: `Are you sure you want to remove ${permission.name} from this role?`,
            onConfirmed: () => removePermission(permission, index),
            positiveButtonText: "Remove",
            negativeButtonText: 'Cancel',
            positiveButtonClass: 'bg-red-500 hover:bg-red-600',
        });
    };


    const removePermission = async (permission: any, index: any) => {
        const result = await userRolesService.removePermissionFromRole(role.id, permission.id);
        if (result.success) {
            setChanged(true);
            setPermissions((prevState) => {
                const items = [...prevState];
                items.splice(index, 1);
                return items;
            });

            addToast({
                text: "Permission Removed",
                type: "success",
                position: "top-center"
            });
        } else {
            addToast({
                text: "Could not remove permission",
                type: "error",
                position: "top-center"
            });
        }
    }


    return (
        <React.Fragment>
            <Modal open={isOpen} onClose={handleClose}>
                <div className="inline-block w-[38rem] max-w-full">
                    <div className="flex justify-between mb-5 px-10 pt-8">
                        <h2 className="font-medium text-xl">
                            Role Permissions
                        </h2>

                        <button type="button" onClick={handleClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none">
                            <i className="ri-close-line"></i>
                        </button>
                    </div>

                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <div className='mb-5'>
                            <p className='mb-1'>{role.label}</p>
                            {role.description && <p className='text-gray-500 dark:text-gray-400 mb-1'>{role.description}</p>}
                        </div>

                        <div className='mb-8'>
                            <p className='text-gray-500 dark:text-gray-400 mb-2'>Permissions:</p>
                            {
                                permissions.length > 0 &&
                                <div className='bg-gray-50 dark:bg-gray-900/80 flex flex-wrap gap-3 max-h-64 overflow-y-auto rounded-xl p-4'>
                                    {
                                        permissions.map((permissionItem, permissionItemIndex) =>
                                            <div key={permissionItem.id} className='flex items-center transition-colors duration-300 bg-black/5 dark:bg-white/5 hover:bg-black/10 dark:hover:bg-white/10 rounded-lg py-1 px-2'>
                                                <span className='font-medium text-sm whitespace-nowrap mr-1'>{permissionItem.name}</span>
                                                <button key={permissionItem.id} type='button' onClick={() => handlePermissionRemove(permissionItem, permissionItemIndex)} className='text-lg text transition-opacity duration-200 opacity-50 hover:opacity-70'>
                                                    <i className="ri-close-line"></i>
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            }

                            {
                                permissions.length === 0 &&
                                <div className='bg-gray-50 dark:bg-gray-900/80 text-gray-500 dark:text-gray-400 rounded-xl p-4'>
                                    There are no permissions for this role
                                </div>
                            }
                        </div>

                        <div className='flex justify-end items-center'>
                            <Button type='button' onClick={handleClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}


interface Props {
    isOpen: boolean;
    role: any;
    onClose: () => void;
    onPermissionsChanged?: (role: any) => void;
}


export default ModalUserRoleDetails;