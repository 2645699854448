import React, { useEffect, useState } from 'react';

function HideReveal({ text, revealDuration = 3000 }: Props) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        let timeOutId;
        if (show) {
            timeOutId = setTimeout(() => {
                setShow(false);
            }, revealDuration);
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId);
            }
        }
    }, [show, revealDuration]);

    return (
        <div className='flex items-center'>
            <span className={`${show ? '' : 'text-lg'} mr-2`}>{show ? text : '•'.repeat(text.length)}</span>
            <button type='button' onClick={() => setShow(!show)} className='opacity-70' title={show ? "Hide" : "Show"}>
                {show ? <i className="ri-eye-off-line"></i> : <i className="ri-eye-line"></i>}
            </button>
        </div>
    );
}


interface Props {
    text: string;
    revealDuration?: number;
}


export default HideReveal;