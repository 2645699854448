import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalAlertOk from './ModalAlertOk';
import ModalAlertConfirm from './ModalAlertConfirm';
import { selectAlertOk, selectAlertConfirm, alertOkClose, alertConfirmClose } from '../../../state/store/reducers/ui/alertSlice';


function AlertContainer() {
    const dispatch = useDispatch();

    const alertOk: any = useSelector(selectAlertOk);
    const alertConfirm: any = useSelector(selectAlertConfirm);


    return (
        <React.Fragment>
            <ModalAlertOk
                isOpen={alertOk.isOpen}
                onOk={() => {
                    if (alertOk.data.onOk) {
                        alertOk.data.onOk();
                    }
                    dispatch(alertOkClose({}));
                }}
                {...alertOk.data}
            />
            <ModalAlertConfirm
                isOpen={alertConfirm.isOpen}
                onClose={() => dispatch(alertConfirmClose({}))}
                {...alertConfirm.data}
            />
        </React.Fragment>
    );
}

export default AlertContainer;