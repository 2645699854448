import React from 'react';
import { Form, Formik } from 'formik';
import { Popover } from '@headlessui/react';
import { format, subDays, differenceInDays } from 'date-fns';

import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import { FormInput, SelectInput } from '../../../../../components/form';
import { Button } from '../../../../../components/common';



function AppRatingsListFilters({ initialData, searching, onSearch }: Props) {

    const handleSubmit = (values: any, { reset }: any) => {
        const payload = { ...values };

        payload['fromDate'] = '';
        payload['toDate'] = '';

        if (values.period) {
            payload.fromDate = format(subDays(new Date(), parseInt(values.period)), 'yyyy-MM-dd');
            payload.toDate = format(new Date(), 'yyyy-MM-dd');
        }
        delete payload.period;

        onSearch(payload);
    }


    const getPeriod = (from: string, to: string): string => {
        const fromObj = new Date(from);
        const toObj = new Date(to);

        if (fromObj.getTime() && toObj.getTime()) {
            return differenceInDays(toObj, fromObj).toString();
        }

        return '';
    }


    return (
        <Formik
            initialValues={{
                score: initialData['score'] || '',
                period: getPeriod(initialData['fromDate'], initialData['toDate']) || "",
                os: initialData['os'] || "",
                isOnAppStore: initialData['isOnAppStore'] || "",
                name: initialData['name'] || "",
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ errors, touched, setErrors, submitCount, values, isValid, setFieldValue, resetForm }) => (
                <Form className="flex-grow flex items-center flex-wrap mr-8 mb-4 gap-y-4">
                    <div className='mr-4'>
                        <SelectInput
                            items={[
                                { label: "All Ratings", value: "" },
                                { label: "1 Star Rating", value: "1" },
                                { label: "2 Stars Rating", value: "2" },
                                { label: "3 Stars Rating", value: "3" },
                                { label: "4 Stars Rating", value: "4" },
                                { label: "5 Stars Rating", value: "5" },
                            ]}
                            placeholder="Select Rating"
                            value={values["score"]}
                            onChange={(value: { label: string, value: string }) => setFieldValue("score", value.value)}
                        />
                    </div>

                    <div className='hidden md:block mr-4'>
                        <SelectInput
                            items={[
                                { label: "All Time", value: "" },
                                { label: "Last 7 Days", value: "7" },
                                { label: "Last 15 Days", value: "15" },
                                { label: "Last 30 Days", value: "30" },
                                { label: "Last 90 Days", value: "90" },
                                { label: "Last 180 Days", value: "180" },
                                { label: "Last 365 Days", value: "365" },
                            ]}
                            placeholder="Select Period"
                            value={values["period"]}
                            onChange={(value: { label: string, value: string }) => setFieldValue("period", value.value)}
                        />
                    </div>

                    <div className='hidden lg:block mr-4'>
                        <SelectInput
                            items={[
                                { label: "All Platforms", value: "" },
                                { label: "iOS", value: "ios" },
                                { label: "Android", value: "android" },
                            ]}
                            placeholder="Select Platform"
                            value={values["os"]}
                            onChange={(value: { label: string, value: string }) => setFieldValue("os", value.value)}
                        />
                    </div>

                    <div className='hidden 2xl:block mr-4'>
                        <SelectInput
                            items={[
                                { label: "From Everywhere", value: "" },
                                { label: "From App Store", value: "true" },
                                { label: "Not From App Store", value: "false" },
                            ]}
                            placeholder=""
                            value={values["isOnAppStore"]}
                            onChange={(value: { label: string, value: string }) => setFieldValue("isOnAppStore", value.value)}
                        />
                    </div>


                    <Popover as="div" className="relative inline-block text-left mr-4">
                        {
                            ({ open, close }) => (
                                <PopoverWithFloat placement='bottom-end' portal={false}>
                                    <Popover.Button type='button' className={`flex items-center transition-colors duration-300 hocus:bg-gray-50 ui-open:bg-gray-50 dark:hocus:bg-gray-800 dark:ui-open:bg-gray-800 rounded-lg py-2 px-2.5`}>
                                        <span className='text-sm whitespace-nowrap mr-1.5'>More Filters</span>
                                        <i className="text-lg ri-more-2-fill"></i>
                                    </Popover.Button>

                                    <Popover.Panel as='div' unmount={false} className="w-screen max-w-sm sm:max-w-md  lg:max-w-lg right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60  mt-1 py-5 px-5 mr-2">
                                        <div className='grid sm:grid-cols-2 gap-y-5 gap-x-5 mb-5'>
                                            <div className=''>
                                                <SelectInput
                                                    items={[
                                                        { label: "All Ratings", value: "" },
                                                        { label: "1 Star", value: 1 },
                                                        { label: "2 Stars", value: 2 },
                                                        { label: "3 Stars", value: 3 },
                                                        { label: "4 Stars", value: 4 },
                                                        { label: "5 Stars", value: 5 },
                                                    ]}
                                                    placeholder="Select Rating"
                                                    value={values["score"]}
                                                    onChange={(value: { label: string, value: string }) => setFieldValue("score", value.value)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className=''>
                                                <SelectInput
                                                    items={[
                                                        { label: "All Time", value: "" },
                                                        { label: "Last 7 Days", value: 7 },
                                                        { label: "Last 15 Days", value: 15 },
                                                        { label: "Last 30 Days", value: 30 },
                                                        { label: "Last 90 Days", value: 90 },
                                                        { label: "Last 180 Days", value: 180 },
                                                        { label: "Last 365 Days", value: 365 },
                                                    ]}
                                                    placeholder="Select Period"
                                                    value={values["period"]}
                                                    onChange={(value: { label: string, value: string }) => setFieldValue("period", value.value)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className=''>
                                                <SelectInput
                                                    items={[
                                                        { label: "All Platforms", value: "" },
                                                        { label: "iOS", value: "ios" },
                                                        { label: "Android", value: "android" },
                                                    ]}
                                                    placeholder="Select Platform"
                                                    value={values["os"]}
                                                    onChange={(value: { label: string, value: string }) => setFieldValue("os", value.value)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className=''>
                                                <SelectInput
                                                    items={[
                                                        { label: "From Everywhere", value: "" },
                                                        { label: "From App Store", value: "true" },
                                                        { label: "Not From App Store", value: "false" },
                                                    ]}
                                                    placeholder=""
                                                    value={values["isOnAppStore"]}
                                                    onChange={(value: { label: string, value: string }) => setFieldValue("isOnAppStore", value.value)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className='sm:col-span-2'>
                                                <FormInput type="text" id="input-search-name" name="name" className="sm:w-full" placeholder="Enter name" autoComplete='off' />
                                            </div>
                                        </div>


                                        <div className='flex justify-between items-center'>
                                            <button
                                                type='reset'
                                                onClick={() => resetForm({
                                                    values: {
                                                        score: '',
                                                        period: "",
                                                        os: "",
                                                        isOnAppStore: "",
                                                        name: "",
                                                    }
                                                })}
                                                className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}
                                            >
                                                Reset
                                            </button>

                                            <Button type='submit' onClick={close} loading={searching} className='!py-2' disabled={searching}>
                                                Search
                                            </Button>
                                        </div>
                                    </Popover.Panel>
                                </PopoverWithFloat>
                            )
                        }
                    </Popover>
                    <button type='submit' className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}>Search</button>
                </Form>
            )}
        </Formik>
    );
}


interface Props {
    initialData: any;
    onSearch: (values: any) => void;
    searching: boolean;
}


export default AppRatingsListFilters;