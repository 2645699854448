import React from 'react';
import Loader from '../svgs/Loader';


function ListLoader({ loadingText, className = '', loaderSize = 3.5 }: Props) {
    return (
        <div className={`text-center ${className}`}>
            <div style={{ width: `${loaderSize}rem` }} className='text-gray-300 dark:text-gray-500 mx-auto mb-4'>
                <Loader />
            </div>
            {
                loadingText &&
                <p className='text-gray-500 dark:text-gray-400'>{loadingText}</p>
            }
        </div>
    );
}



interface Props extends React.HTMLAttributes<HTMLDivElement> {
    loadingText?: string;
    loaderSize?: number;
}


export default ListLoader;