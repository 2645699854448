import { unifiedApi } from '../httpService';


const getStudents = async (schoolId: number | string, query: any) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${schoolId}/students`, {
            params: query,
            timeout: 10000
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const getStudentProfile = async (schoolId: any, id: any) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${schoolId}/students/${id}`, {
            timeout: 8000
        });
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


const getStudentScanHistory = async (studentId: any, query: any) => {
    try {
        const result = await unifiedApi.get(`/api/v1/students/${studentId}/scans/history`, {
            params: query,
            timeout: 10000
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getStudents,
    getStudentProfile,
    getStudentScanHistory,
}