import { unifiedApi } from '../httpService';


const getDelegateProfile = async (delegateId: any, query: any = {}) => {
    try {
        const result = await unifiedApi.get(`/api/v1/delegates/${delegateId}`, {
            params: query,
            timeout: 8000
        });
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}



const generateQrCode = async (data: any, query: any = {}) => {
    try {
        const result = await unifiedApi.post(`/api/v1/delegates/qr-code/generate`, data, {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}



export default {
    getDelegateProfile,
    generateQrCode,
}