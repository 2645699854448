import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { Avatar, Button, ErrorLoading, ListNoData, Pagination } from '../../../../../../../../../components/common';
import canteenServiceProvidersService from '../../../../../../../../../services/api/canteen/canteenServiceProvidersService';
import { paginationInfo } from '../../../../../../../../../utils/pagination';
import { ListLoader } from '../../../../../../../../../components/loaders';
import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import useToast from '../../../../../../../../../state/hooks/useToast';
import useAlert from '../../../../../../../../../state/hooks/useAlert';
import ModalCanteenServiceProviderForm from '../../modals/ModalCanteenServiceProviderForm';
import ModalCanteenCSPManagerForm from '../../modals/ModalCanteenCSPManagerForm';
import ModalCanteenServiceProviderConfigurations from '../../modals/ModalCanteenServiceProviderConfigurations';
import usePagedListApi from '../../../../../../../../../state/hooks/usePagedListApi';
//import { loadList, resetList, selectList, updateRecord } from '../../../../../../../../state/store/reducers/entities/listPageSlice';


function SchoolServiceProviderListPage() {
    const { profile }: any = useOutletContext();

    const dispatch = useDispatch();
    //const serviceProviders = useSelector(selectList);
    const { data: serviceProviders, requestData, resetRecords, updateRecord } = usePagedListApi(canteenServiceProvidersService.getProviders);
    const [searchParams, setSearchParams] = useSearchParams();

    const [serviceProviderForm, setServiceProviderForm] = useState({ open: false, serviceProvider: null });
    const [cspConfigurationForm, setCspConfigurationForm] = useState({ open: false, serviceProvider: null });
    const [serviceManagerForm, setServiceManagerForm] = useState({ open: false, manager: null, serviceProvider: null });

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    // get query params
    //const page = Number(searchParams.get('page')) || serviceProviders.pager.page;
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};



    /* useEffect(() => {
        return () => {
            dispatch(resetList({}));
        }
    }, []); */


    useEffect(() => {
        getServiceProviders();
    }, [searchParams, dispatch]);


    const getServiceProviders = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                schoolId: profile.id,
                //clientId: profile.id,
                '$sort[name]': 1,
            },
        });
        /* await dispatch<any>(loadList({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                schoolId: profile.id,
                //clientId: profile.id,
                '$sort[name]': 1,
            },
        }, canteenServiceProvidersService.getProviders)); */
    };


    const handleServiceProviderDelete = (serviceProvider: any) => {
        openAlertConfirm({
            title: 'Delete Canteen Vendor',
            message: `Are you sure you want to delete ${serviceProvider.name} vendor from ${profile.name} school?`,
            onConfirmed: () => deleteServiceProvider(serviceProvider),
            positiveButtonText: "Delete",
            negativeButtonText: 'Cancel',
            positiveButtonClass: 'bg-red-500 hover:bg-red-600',
        });
    }


    const deleteServiceProvider = async (serviceProvider: any) => {
        const result = await canteenServiceProvidersService.deleteProvider(serviceProvider.id);
        if (result.success) {
            //dispatch(resetList({}));
            resetRecords();
            getServiceProviders();
            addToast({
                text: "Canteen vendor deleted successfully",
                type: "success"
            });
        } else {
            addToast({
                title: "Error",
                text: "Canteen vendor could not be deleted",
                type: "error"
            });
        }
    }


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            <div className='flex justify-between flex-wrap gap-2 mb-6'>
                <h2 className='font-medium text-xl mr-4'>Canteen Vendors</h2>
                {
                    !serviceProviders.loadingError && !serviceProviders.loading && serviceProviders.list.length > 0 &&
                    <Button type='button' onClick={() => setServiceProviderForm({ open: true, serviceProvider: null })} className='py-2.5'>Add Vendor</Button>
                }
            </div>


            {
                // if there're service providers
                !serviceProviders.loadingError && !serviceProviders.loading && serviceProviders.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 pl-2 pr-6'>Vendor Name</th>
                                    <th className='py-6 px-6'>Email</th>
                                    <th className='py-6 px-6'>Phone Number</th>
                                    <th className='whitespace-nowrap py-6 px-6'>POS Type</th>
                                    <th className='whitespace-nowrap py-6 px-6'>Payment Types</th>
                                    <th className='whitespace-nowrap py-6 px-6'>Cash Out</th>
                                    {/* <th className='py-6 px-6'>Created At</th> */}
                                    <th className='py-6 pl-6 pr-2 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    serviceProviders.list.map((providerItem: any) =>
                                        <tr key={providerItem?.id}>
                                            <td className='py-4 pl-2 pr-6'>
                                                <div className='flex items-center'>
                                                    <Avatar name={providerItem?.name} imageUrl={providerItem?.logo} size={3} className='mr-2.5' />
                                                    <span>{providerItem?.name}</span>
                                                </div>
                                            </td>
                                            <td className='py-4 px-6'>{providerItem?.email}</td>
                                            <td className='py-4 px-6'>{providerItem?.primaryContact}</td>
                                            <td className='py-4 px-6'>{providerItem?.configuration?.posType}</td>
                                            <td className='py-4 px-6'>
                                                <div className='flex gap-2'>
                                                    {
                                                        providerItem?.configuration?.paymentMethods
                                                            //.slice(0, 1)
                                                            .map((paymentMethod: any, paymentMethodIndex: number) =>
                                                                <span key={paymentMethod.type} className='text-sm whitespace-nowrap bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>
                                                                    {paymentMethod.label}
                                                                </span>
                                                            )
                                                    }
                                                    {/* {
                                                            providerItem?.configuration?.paymentMethods.length > 1 &&
                                                            <span className='text-sm bg-black/5 dark:bg-white/5 rounded-lg py-1.5 px-2.5'>
                                                                +{providerItem?.configuration?.paymentMethods.length - 1}
                                                            </span>
                                                        } */}
                                                    {/* {
                                                        feedItem.permissions.length > 0 &&
                                                        <button type='button' onClick={() => setUserRoleDetails({ open: true, role: roleItem })} className='opacity-0 group-hover:opacity-90 font-medium whitespace-nowrap text-sm transition-all duration-300 hover:text-blue p-2 mx-1'>
                                                            {roleItem.permissions.length > 2 ? 'View All' : 'View'}
                                                        </button>
                                                    } */}
                                                </div>
                                            </td>
                                            <td className='py-4 px-6'>
                                                {providerItem?.configuration?.allowCashout && <span className='inline-block whitespace-nowrap font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-3'>Allowed</span>}
                                                {!providerItem?.configuration?.allowCashout && <span className='inline-block whitespace-nowrap font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-3'>Not Allowed</span>}
                                            </td>
                                            {/* <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(providerItem?.createdAt), 'd LLL yyyy h:mm a')}</td> */}
                                            <td className='py-4 pl-6 pr-2 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-64 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setCspConfigurationForm({ open: true, serviceProvider: providerItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Change Configurations
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => setServiceManagerForm({ open: true, manager: null, serviceProvider: providerItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Add Manager
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => setServiceProviderForm({ open: true, serviceProvider: providerItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => handleServiceProviderDelete(providerItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                Delete
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(serviceProviders.pager.pageSize, serviceProviders.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={serviceProviders.pager.pageSize}
                            totalCount={serviceProviders.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if providers are loading
                !serviceProviders.loadingError && serviceProviders.loading &&
                <ListLoader loadingText='Loading Vendors' className='px-8 py-24' />
            }

            {
                // if there're no service providers in the school
                !serviceProviders.loadingError && !serviceProviders.loading && serviceProviders.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Vendor Found' description='No vendor added to school yet' className='px-8 py-24'>
                    <Button type='button' onClick={() => setServiceProviderForm({ open: true, serviceProvider: null })} className='py-2.5'>Add Canteen Vendor</Button>
                </ListNoData>
            }

            {
                // if there're no service providers in the app
                !serviceProviders.loadingError && !serviceProviders.loading && serviceProviders.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No vendor Found' description='No vendor was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                serviceProviders.loadingError &&
                <ErrorLoading title='Error Loading Vendors' message='There was a problem loading vendors' className='px-8 py-24' onTryAgain={getServiceProviders} />
            }


            <ModalCanteenServiceProviderForm
                isOpen={serviceProviderForm.open}
                serviceProvider={serviceProviderForm.serviceProvider}
                school={profile}
                onServiceProviderAdded={(serviceProvider) => {
                    addToast({
                        text: `Canteen Vendor added successfully`,
                        type: "success"
                    });

                    //dispatch(resetList({}));
                    resetRecords();
                    setSearchParams({});

                    if (Array.from(searchParams.entries()).length === 0) {
                        getServiceProviders();
                    }
                }}
                onServiceProviderUpdated={(serviceProvider) => {
                    //dispatch(updateRecord({ record: serviceProvider, page }));
                    updateRecord({ item: serviceProvider, page });
                    addToast({
                        text: `Canteen Vendor updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setServiceProviderForm((prevState) => ({ ...prevState, open: false }))}
            />

            {
                cspConfigurationForm.serviceProvider &&
                <ModalCanteenServiceProviderConfigurations
                    isOpen={cspConfigurationForm.open}
                    serviceProvider={cspConfigurationForm.serviceProvider}
                    school={profile}
                    onConfigurationsChanged={(serviceProvider) => {
                        //dispatch(updateRecord({ record: serviceProvider, page }));
                        updateRecord({ item: serviceProvider, page });
                        addToast({
                            text: `Configurations changed successfully`,
                            type: "success"
                        });
                    }}
                    onClose={() => setCspConfigurationForm((prevState) => ({ ...prevState, open: false }))}
                />
            }

            <ModalCanteenCSPManagerForm
                isOpen={serviceManagerForm.open}
                manager={serviceManagerForm.manager}
                serviceProvider={serviceManagerForm.serviceProvider}
                school={profile}
                onManagerAdded={(manager) => {
                    const serviceProvider: any = serviceManagerForm?.serviceProvider;
                    addToast({
                        text: `Manager successfully added to ${serviceProvider.name}`,
                        type: "success"
                    });

                    resetRecords();
                    setSearchParams({});

                    if (Array.from(searchParams.entries()).length === 0) {
                        getServiceProviders();
                    }
                }}
                onManagerUpdated={(manager) => {
                    //dispatch(updateRecord({ record: manager, page }));
                    updateRecord({ item: manager, page });
                    addToast({
                        text: `Manager updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setServiceManagerForm((prevState) => ({ ...prevState, open: false }))}
            />
        </div>
    );
}

export default SchoolServiceProviderListPage;