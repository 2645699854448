import { notificationsApi } from '../httpService';


const getNotifications = async (query: any) => {
    try {
        const result = await notificationsApi.get('notifications', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getNotifications
}