import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { MultiSelectInput, SelectInput } from '../../../../../components/form';
import { Button, ErrorLoading } from '../../../../../components/common';
import { ListLoader } from '../../../../../components/loaders';
import usersPermissionsService from '../../../../../services/api/user-accounts/usersPermissionsService';
import userRolesService from '../../../../../services/api/user-accounts/userRolesService';
import userAccountsService from '../../../../../services/api/user-accounts/userAccountsService';
import useAlert from '../../../../../state/hooks/useAlert';


function ModalUserAccountForm({ isOpen, account, onClose, onAccountCreated, onAccountUpdated }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    //const [optionsLoadingStatus, setOptionsLoadingStatus] = useState({ loading: false, error: false });
    const [accountStatus, setAccountStatus] = useState({ loading: false, error: false });

    const [permissionsList, setPermissionsList] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [apiAccount, setApiAccount] = useState<any>(null);

    const { openAlertConfirm } = useAlert();


    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });

            getOptions();
            getAccount();
        }
    }, [isOpen]);



    const getOptions = async () => {
        try {
            setAccountStatus({ loading: true, error: false });
            await Promise.all([getAccount(), getRoles(), getPermissions()]);
            setAccountStatus({ loading: false, error: false });
        } catch (error) {
            setAccountStatus({ loading: false, error: true });
        }
    };



    const getRoles = async () => {
        if (rolesList.length === 0) {
            const rolesResult = await userRolesService.getRoles({});
            if (rolesResult.success) {
                setRolesList(rolesResult.data);
            } else {
                throw new Error('Roles');
            }
        }
    };


    const getPermissions = async () => {
        if (permissionsList.length === 0) {
            const permissionsResult = await usersPermissionsService.getPermissions({});
            if (permissionsResult.success) {
                setPermissionsList(permissionsResult.data);
            } else {
                throw new Error('Permissions');
            }
        }
    }


    const getAccount = async () => {
        if (account && account.id !== apiAccount?.id) {
            const accountResult = await userAccountsService.getUserAccount(account.id);
            if (accountResult.success) {
                setApiAccount(accountResult.data);
            } else {
            }
        } else {
            throw new Error('Account');
        }
    }


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        const payload = { ...formData, userId: account.id };
        payload.specialPermissions = payload.specialPermissions.map((item: any) => item.name);

        if (account) {
            openAlertConfirm({
                title: 'Confirm Changes',
                message: `Are you sure you want to make changes to ${account.username} account?`,
                onConfirmed: () => handleUpdateSave(payload, resetForm),
                positiveButtonText: "Save Changes",
                negativeButtonText: 'Cancel',
            });
        } else {
            // create
            setSubmitStatus({ loading: true, failedMessage: '' });

        }
    }


    const handleUpdateSave = async (payload: any, resetForm: () => void) => {
        // update
        setSubmitStatus({ loading: true, failedMessage: '' });
        const result = await userAccountsService.updateAccount(account.id, payload);
        if (result.success) {
            if (onAccountUpdated) {
                onAccountUpdated({ ...account, ...result.data });
            }
            //resetForm();
            onClose();
            setSubmitStatus({ loading: false, failedMessage: '' });
        } else {
            setSubmitStatus({ loading: false, failedMessage: result.message });
        }
    }


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[32rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {account ? "Edit" : "Add"} User Account
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>
                {
                    !accountStatus.loading && !accountStatus.error &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <Formik
                            initialValues={{
                                userType: apiAccount ? apiAccount.userType : '',
                                roleId: apiAccount ? apiAccount.role.id : '',
                                specialPermissions: apiAccount ? apiAccount.specialPermissions : [],
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                                <Form className="">
                                    {
                                        submitStatus.failedMessage && !submitStatus.loading &&
                                        <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                    }

                                    <div className='mb-6'>
                                        <p className='text-gray-500 dark:text-gray-400 mb-1'>Account:</p>
                                        <p className='font-medium text-lg'>{account?.username}</p>
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="input-user-type" className='block text-sm mb-2'>User type</label>
                                        <SelectInput
                                            items={[
                                                { label: "App User", value: "APP_USER" },
                                                { label: "Portal User", value: "PORTAL_USER" },
                                            ]}
                                            placeholder="User type"
                                            value={values["userType"]}
                                            onChange={(value: { label: string, value: string }) => setFieldValue("userType", value.value)}
                                        />
                                        {touched['userType'] && errors['userType'] && <p className='text-sm text-red-500 mt-2'>User type is required</p>}
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="input-user-type" className='block text-sm mb-2'>User role</label>
                                        <SelectInput
                                            items={rolesList}
                                            placeholder="User Role"
                                            valueKey='id'
                                            value={values["roleId"]}
                                            onChange={(value: { label: string, id: string }) => setFieldValue("roleId", value.id)}
                                            searchable
                                            usePortal
                                        />
                                        {touched['roleId'] && errors['roleId'] && <p className='text-sm text-red-500 mt-2'>User role is required</p>}
                                    </div>

                                    <div className="mb-10">
                                        <label htmlFor="input-user-type" className='block text-sm mb-2'>Special Permissions</label>
                                        <MultiSelectInput
                                            items={permissionsList}
                                            valueKey='id'
                                            labelKey='name'
                                            placeholder="Select permissions"
                                            selectedLabel='Permission'
                                            selectedLabelPlural='Permissions'
                                            showSelectedItems={false}
                                            value={values["specialPermissions"]}
                                            onChange={(selected: any[]) => setFieldValue("specialPermissions", selected)}
                                            usePortal
                                        />
                                        {touched['specialPermissions'] && errors['specialPermissions'] && <p className='text-sm text-red-500 mt-2'>Special permission is required</p>}
                                    </div>


                                    <div className='flex justify-end items-center'>
                                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                            Cancel
                                        </Button>

                                        <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                }

                {
                    (accountStatus.loading || accountStatus.error) &&
                    <div className='pb-10'>
                        {
                            accountStatus.loading &&
                            <ListLoader loadingText='Loading Account' className='py-10' loaderSize={2} />
                        }
                        {
                            accountStatus.error &&
                            <ErrorLoading title='Error Loading Account' message='An unexpected error occurred while loading account' className='px-8 py-16' onTryAgain={getOptions} />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    userType: Yup.string().trim().required().label(""),
    roleId: Yup.string().trim().required().label(""),
    specialPermissions: Yup.array(),
});


interface Props {
    isOpen: boolean;
    account: any;
    onClose: () => void;
    onAccountCreated?: (account: any) => void;
    onAccountUpdated?: (account: any) => void;
}


export default ModalUserAccountForm;