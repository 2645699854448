import { paroApi } from '../httpService';


const getFeeds = async (query: any) => {
    try {
        const result = await paroApi.get('feed-items', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const getSingleFeed = async (id: any) => {
    try {
        const result = await paroApi.get(`/feed-items/${id}`, {});
        return {
            success: true,
            // data: result.data.data,
            data: result.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}



const createFeed = async (data: any) => {
    try {
        const result = await paroApi.post(`feed-items`, data, {
            /*   headers: {
                  "Content-Type": 'multipart/form-data'
              } */
        });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating feed. Please try again later."
        };
    }
}


const updateFeed = async (feedId: number, data: any) => {
    try {
        const result = await paroApi.patch(`/feed-items/${feedId}`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating feed. Please try again later."
        };
    }
}


const deleteFeed = async (feedId: number) => {
    try {
        const result = await paroApi.delete(`feed-items/${feedId}`);

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



export default {
    getFeeds,
    getSingleFeed,
    createFeed,
    updateFeed,
    deleteFeed,
}