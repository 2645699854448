import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import { format } from 'date-fns';

import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
import { Avatar, Button, ErrorLoading, LinkButton, ListNoData, Pagination } from '../../../../../components/common';
import { ListLoader } from '../../../../../components/loaders';
import { paginationInfo } from '../../../../../utils/pagination';
import useToast from '../../../../../state/hooks/useToast';
import { Menu, RadioGroup } from '@headlessui/react';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import useAlert from '../../../../../state/hooks/useAlert';
import studentService from '../../../../../services/api/unified-api/studentService';
import usePagedListApi from '../../../../../state/hooks/usePagedListApi';
import schoolService from '../../../../../services/api/unified-api/schoolService';
import { SelectInput } from '../../../../../components/form';
import useIsFirstRender from '../../../../../state/hooks/useIsFirstRender';
import StudentsListFilters from './components/StudentsListFilters';
import ModalStudentDetails from '../modals/ModalStudentDetails';


function StudentListPage() {
    const dispatch = useDispatch();

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();
    const initial = useIsFirstRender();

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedSchool = searchParams.get('school') || '';
    const [schoolListData, setSchoolListData] = useState({ loading: false, list: [] as any[] });

    const [studentDetails, setStudentDetails] = useState({ open: false, student: null });

    // get query params
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};
    if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }
    if (searchParams.get('studentNumber')) { searchFilters['studentNumber'] = searchParams.get('studentNumber') || '' }

    const { data: students, requestData, resetRecords } = usePagedListApi((query: any) => studentService.getStudents(selectedSchool, query));


    useEffect(() => {
        dispatch(changePageInfo({ title: "Students" }));

        if (initial && selectedSchool) {
            getSelectedSchool(selectedSchool);
        }
    }, []);


    useEffect(() => {
        getStudents();
    }, [searchParams, dispatch]);


    const getStudents = async () => {
        if (selectedSchool) {
            await requestData({
                page: page,
                filters: { ...searchFilters, schoolId: selectedSchool },
                otherProperties: {
                    pageSize: 10,
                    properties: "firstName",
                    direction: "ASC",
                },
            });
        }
    }


    const getSelectedSchool = async (schoolId: string) => {
        const result = await schoolService.getSchoolProfile(schoolId);
        if (result.success) {
            setSchoolListData({ loading: false, list: [result.data] });
        } else {
        }
    }


    const getSchools = debounce(async (searchQuery: string) => {
        setSchoolListData({ loading: true, list: [] });
        const result = await schoolService.getSchools({ name: searchQuery });
        if (result.success) {
            setSchoolListData({ loading: false, list: result.data });
        } else {
            setSchoolListData({ loading: false, list: [] });
        }
    }, 500);



    const changeSchool = (schoolId: number | string) => {
        setSearchParams(current => ({
            //...Object.fromEntries(current.entries()),
            school: schoolId.toString(),
        }));
    }


    return (
        <React.Fragment>
            {
                selectedSchool &&
                <div className=''>
                    <div className='flex flex-wrap items-start justify-between mb-5'>
                        <div className='flex-grow flex flex-col sm:flex-row flex-wrap gap-5'>
                            <div className='min-w-[14rem] max-w-[18rem]'>
                                <SelectInput
                                    items={schoolListData.list}
                                    labelKey='name'
                                    valueKey='id'
                                    searchable={true}
                                    placeholder="Select school"
                                    value={selectedSchool}
                                    onChange={(school: any) => changeSchool(school.id)}
                                    onSearch={(query) => getSchools(query)}
                                    searching={schoolListData.loading}
                                />
                            </div>

                            <div className='border-b sm:border-b-0 sm:border-r border-gray-200 dark:border-gray-700'></div>

                            <StudentsListFilters
                                initialData={searchFilters}
                                onSearch={(values) => {
                                    //setSearchParams({ ...values });
                                    setSearchParams(current => {
                                        const entries = { ...Object.fromEntries(current.entries()), ...values };
                                        if (entries.page) {
                                            entries.page = '1';
                                        }
                                        return entries;
                                    });
                                }}
                                searching={students.loading}
                            />
                        </div>
                    </div>


                    {
                        // if there're students
                        !students.loadingError && !students.loading && students.list?.length > 0 &&
                        <React.Fragment>

                            <div className='max-w-full overflow-x-auto mb-6'>
                                <table className="border-collapse table-auto w-full">
                                    <thead>
                                        <tr className='text-left border-b-2 dark:border-gray-700'>
                                            <th className='py-4 pl-2 pr-5'>Student</th>
                                            <th className='white py-4 px-5'>Student Number</th>
                                            <th className='py-4 px-5'>Class</th>
                                            <th className='py-4 px-5'>Campus</th>
                                            <th className='whitespace-nowrap py-4 px-5'>Updated At</th>
                                            <th className='py-4 pl-5 pr-2'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y dark:divide-gray-700/60">
                                        {
                                            students.list.map((studentItem: any) =>
                                                <tr key={studentItem?.id} className=''>
                                                    <td className='py-4 pl-2 pr-6'>
                                                        <button type='button' onClick={() => setStudentDetails({ open: true, student: studentItem })} className='group flex items-center gap-3'>
                                                            <Avatar name={`${studentItem?.firstName} ${studentItem?.otherNames} ${studentItem?.lastName}`} imageUrl={studentItem?.avatar} size={2.6} className='' />
                                                            <span className='group-hover:text-blue transition-colors duration-300'>{studentItem?.firstName} {studentItem?.otherNames} {studentItem?.lastName}</span>
                                                        </button>
                                                    </td>
                                                    <td className='py-4 px-5'>{studentItem?.studentNumber}</td>
                                                    <td className='py-4 px-5'>
                                                        {studentItem?.grade?.name}<br />
                                                        <span className='text-sm text-gray-500 dark:text-gray-400'>({studentItem?.grade?.code})</span>
                                                    </td>
                                                    <td className='py-4 px-5'>
                                                        {studentItem?.campus?.name}<br />
                                                        <span className='text-sm text-gray-500 dark:text-gray-400'>({studentItem?.campus?.alias})</span>
                                                    </td>
                                                    <td className='align-top py-4 px-5 whitespace-nowrap'>{format(new Date(studentItem?.updatedDate), 'd LLL yyyy h:mm a')}</td>
                                                    <td className='text-right py-4 pl-5 pr-2'>
                                                        <Menu as="div" className="relative inline-block text-left">
                                                            <PopoverWithFloat placement='bottom-end' portal>
                                                                <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                                    <i className="ri-more-2-fill"></i>
                                                                </Menu.Button>

                                                                <Menu.Items as='div' unmount={false} className="w-60 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                                    <Menu.Item as={Link} to={`/students/${selectedSchool}/${studentItem.id}`} state={{ student: studentItem }} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                        Go to Profile
                                                                    </Menu.Item>
                                                                </Menu.Items>
                                                            </PopoverWithFloat>
                                                        </Menu>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>


                            <div className='flex justify-between flex-wrap'>
                                <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(students.pager.pageSize, students.pager.totalCount, page)}</p>

                                <Pagination
                                    currentPage={page}
                                    pageSize={students.pager.pageSize}
                                    totalCount={students.pager.totalCount}
                                    onPageChange={(page) => {
                                        setSearchParams(current => ({
                                            ...Object.fromEntries(current.entries()),
                                            page: page.toString(),
                                        }));
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    }

                    {
                        // if students are loading
                        !students.loadingError && students.loading &&
                        <ListLoader loadingText='Loading Students' className='px-8 py-24' />
                    }

                    {
                        // if there're no students in the app
                        !students.loadingError && !students.loading && students.list.length === 0 && Object.keys(searchFilters).length === 0 && students.pager.totalCount === 0 &&
                        <ListNoData title='No Student Found' description='School is yet to add students' className='px-8 py-24'>
                        </ListNoData>
                    }

                    {
                        // if there're no students for search
                        !students.loadingError && !students.loading && students.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                        <ListNoData title='No Student Found' description='No student was found for the selected scenarios' className='px-8 py-24' />
                    }

                    {
                        !students.loadingError && !students.loading && students.list.length === 0 && students.pager.totalCount > 0 &&
                        <ListNoData title='No Student Found' description='No student was found for this page' className='max px-8 py-24'>
                            <div className='flex justify-center flex-wrap gap-x-6 gap-y-2'>
                                <LinkButton to={`/students/page?=${page - 1}`} onClick={() => null} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                                    Go to Previous Page
                                </LinkButton>

                                <LinkButton to="/students" onClick={() => null} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                                    Go to First page
                                </LinkButton>
                            </div>
                        </ListNoData>
                    }

                    {
                        students.loadingError &&
                        <ErrorLoading title='Error Loading Students' message='There was a problem loading students' className='px-8 py-24' onTryAgain={getStudents} />
                    }

                    <ModalStudentDetails
                        isOpen={studentDetails.open}
                        student={studentDetails.student}
                        schoolId={selectedSchool}
                        onClose={() => setStudentDetails(prevState => ({ ...prevState, open: false }))}
                    />
                </div>
            }

            {
                !selectedSchool &&
                <div className='max-w-lg bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-3xl mt-10 mx-auto py-10 px-6 sm:px-10'>
                    <div className=' mb-6'>
                        <h2 className='font-medium text-xl mb-1'>Select School</h2>
                        <p className='text-gray-500 dark:text-gray-400'>Provide a school to see students</p>
                    </div>

                    <Formik
                        initialValues={{
                            school: '',
                        }}
                        onSubmit={(values, { reset }: any) => {
                            changeSchool(values.school);
                        }}
                        validationSchema={Yup.object().shape({
                            school: Yup.string().trim().required().label(""),
                        })}
                    >
                        {({ errors, values, touched, setErrors, submitCount, setFieldValue, isValid }) => (
                            <Form className="">
                                <div className="mb-7">
                                    <SelectInput
                                        items={schoolListData.list}
                                        labelKey='name'
                                        valueKey='id'
                                        searchable={true}
                                        placeholder="Select school"
                                        value={values["school"]}
                                        onChange={(school: any) => setFieldValue("school", school.id)}
                                        onSearch={(query) => getSchools(query)}
                                        searching={schoolListData.loading}
                                    />
                                    {touched['school'] && errors['school'] && <p className='text-sm text-red-500 mt-2'>School is required</p>}
                                </div>

                                <div className='text-right'>
                                    <Button type='submit' className=''>
                                        View Students
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            }
        </React.Fragment>
    );
}

export default StudentListPage;