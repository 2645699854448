import React, { useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';

import { Button, ErrorLoading, ListNoData, Pagination } from '../../../../../components/common';
import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
//import { selectSchools, resetSchools, loadSchools, updateSchoolRecord } from '../../../../../state/store/reducers/entities/schoolsSlice';
import { paginationInfo } from '../../../../../utils/pagination';
import FilterSection from './components/FilterSection';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import { ListLoader } from '../../../../../components/loaders';
import ModalSchoolForm from '../modals/ModalSchoolForm';
import useToast from '../../../../../state/hooks/useToast';
import ModalSchoolConfigurationForm from '../modals/ModalSchoolConfigurationForm';
import useAlert from '../../../../../state/hooks/useAlert';
import usePagedListApi from '../../../../../state/hooks/usePagedListApi';
import schoolService from '../../../../../services/api/unified-api/schoolService';


function SchoolsListPage() {
    const dispatch = useDispatch();
    // const schools = useSelector(selectSchools);

    const { data: schools, requestData, resetRecords, updateRecord } = usePagedListApi(schoolService.getSchools);
    const [searchParams, setSearchParams] = useSearchParams();

    const [schoolForm, setSchoolForm] = useState({ open: false, school: null });
    const [configurationsForm, setConfigurationsForm] = useState({ open: false, school: null, configuration: null });

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    // get query params
    const page = Number(searchParams.get('page')) || schools.pager.page;
    const searchFilters: any = {};
    if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "School List" }));
    }, []);


    useEffect(() => {
        getSchools();
    }, [searchParams]);



    const getSchools = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                direction: 'asc',
                properties: 'name',
            },
        });
    };


    return (
        <div className=''>
            <div className='flex flex-row-reverse flex-wrap justify-between mb-1'>
                <Button type='button' onClick={() => setSchoolForm({ open: true, school: null })} className='mb-4'>Create School</Button>
                <FilterSection
                    initialData={searchFilters}
                    onSearch={(values) => {
                        //setSearchParams({ ...values });
                        setSearchParams(current => {
                            const entries = { ...Object.fromEntries(current.entries()), ...values };
                            if (entries.page) {
                                entries.page = '1';
                            }
                            return entries;
                        });
                    }}
                    searching={schools.loading}
                />
            </div>

            {
                // if there're schools
                !schools.loadingError && !schools.loading && schools.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>School</th>
                                    <th className='py-6 px-6'>Alias</th>
                                    <th className='py-6 px-6'>Address</th>
                                    <th className='py-6 px-6'>Location</th>
                                    <th className='py-6 px-6'>Phone No.</th>
                                    <th className='py-6 px-6'>Has Config.</th>
                                    <th className='py-6 px-6'>Source</th>
                                    <th className='py-6 px-6'>Date Created</th>
                                    <th className='py-6 px-6 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    schools.list.map((schoolItem: any) =>
                                        <tr key={schoolItem.id}>
                                            <td className='py-4 px-6'>
                                                <Link to={`/schools/${schoolItem.id}`} state={{ school: schoolItem }} className="transition-colors duration-300 hover:text-blue-500">
                                                    {schoolItem.name}
                                                </Link>
                                            </td>
                                            <td className='py-4 px-6'>{schoolItem.alias}</td>
                                            <td className='py-4 px-6'>{schoolItem.address}</td>
                                            <td className='py-4 px-6'>{schoolItem.location}</td>
                                            <td className='py-4 px-6'>{schoolItem.contact}</td>
                                            <td className='py-4 px-6'>
                                                {
                                                    schoolItem.configuration ?
                                                        <span className='font-medium text-green-500'>Yes</span> :
                                                        <span className='font-medium text-yellow-500'>No</span>
                                                }
                                            </td>
                                            <td className='py-4 px-6'>{schoolItem.dataSource}</td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(schoolItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 px-6 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-60 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as={Link} to={`/schools/${schoolItem.id}`} state={{ schoolName: schoolItem.name }} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Go to Profile
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => setSchoolForm({ open: true, school: schoolItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => setConfigurationsForm({ open: true, school: schoolItem, configuration: schoolItem?.configuration })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                {schoolItem?.configuration ? "Change" : "Add"} Configurations
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(schools.pager.pageSize, schools.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={schools.pager.pageSize}
                            totalCount={schools.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if schools are loading
                !schools.loadingError && schools.loading &&
                <ListLoader loadingText='Loading Schools' className='px-8 py-24' />
            }

            {
                // if there're no schools in the app
                !schools.loadingError && !schools.loading && schools.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No School Found' description='There are no school currently setup in SmartSapp' className='px-8 py-24'>
                    <Button type='button' onClick={() => setSchoolForm({ open: true, school: null })}>Add a School Here</Button>
                </ListNoData>
            }

            {
                // if there're no schools in the app
                !schools.loadingError && !schools.loading && schools.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No School Found' description='No school was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                schools.loadingError &&
                <ErrorLoading title='Error Loading Schools' message='There was a problem loading schools' className='px-8 py-24' onTryAgain={getSchools} />
            }


            <ModalSchoolForm
                isOpen={schoolForm.open}
                school={schoolForm.school}
                onSchoolCreated={(school) => {
                    addToast({
                        text: `School created successfully`,
                        type: "success"
                    });

                    openAlertConfirm({
                        title:
                            <div className='flex items-center'>
                                <span className='mr-2.5'>School Created</span>
                                <i className="ri-checkbox-circle-line text-2xl text-green-500"></i>
                            </div>,
                        message: `Would you like to add configurations to school?`,
                        onConfirmed: () => setConfigurationsForm({ open: true, school: school, configuration: null }),
                        positiveButtonText: "Add Configurations",
                        negativeButtonText: 'No, Later',
                    });

                    //dispatch(resetSchools({}));
                    resetRecords();
                    setSearchParams({});

                    if (Array.from(searchParams.entries()).length === 0) {
                        getSchools();
                    }
                }}
                onSchoolUpdated={(school) => {
                    //dispatch(updateSchoolRecord({ school, page }));
                    updateRecord({ item: school, page });
                    addToast({
                        text: `School updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setSchoolForm((prevState) => ({ ...prevState, open: false }))}
            />

            <ModalSchoolConfigurationForm
                isOpen={configurationsForm.open}
                onClose={() => setConfigurationsForm(prevState => ({ ...prevState, open: false }))}
                school={configurationsForm.school}
                configuration={configurationsForm.configuration}
                onConfigurationAdded={(configuration: any) => {
                    if (configurationsForm.school) {
                        const school: any = { ...configurationsForm.school as any };
                        school.configuration = configuration;
                        updateRecord({ item: school, page });
                    }

                    addToast({
                        text: `Configuration added successfully`,
                        type: "success"
                    });
                }}
                onConfigurationUpdated={(configuration: any) => {
                    if (configurationsForm.school) {
                        const school: any = { ...configurationsForm.school as any };
                        school.configuration = configuration;
                        updateRecord({ item: school, page });
                    }

                    addToast({
                        text: `Configuration updated successfully`,
                        type: "success"
                    });
                }}
            />
        </div>
    );
}

export default SchoolsListPage;