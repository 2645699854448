import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Menu } from '@headlessui/react';

import useToast from '../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
import settlementAccountsService from '../../../../../services/api/payments/settlementAccountsService';
import { ListLoader } from '../../../../../components/loaders';
import { Button, ErrorLoading, HideReveal, ListNoData } from '../../../../../components/common';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import ModalAccountProcessorProviderForm from '../modals/ModalAccountProcessorProviderForm';
import useAlert from '../../../../../state/hooks/useAlert';


function SettlementAccountDetailsPage() {
    const dispatch = useDispatch();
    const { accountId } = useParams();
    const location = useLocation();


    const [pageStatus, setPageStatus] = useState({ loading: false, error: false, accountNotFound: false });
    const [settlementAccount, setSettlementAccount] = useState<any>(null);

    const [providerForm, setProviderForm] = useState({ open: false, provider: null });
    const [providersStatus, setProvidersStatus] = useState({ loading: false, error: false });
    const [providers, setProviders] = useState<any[]>([]);

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();


    useEffect(() => {
        dispatch(changePageInfo({ title: 'Settlement Account Details' }));

        if (location.state && location.state.settlementAccount) {
            setSettlementAccount(location.state.settlementAccount);
        } else {
            //loadStudentProfile();
            loadDetails();
        }

    }, []);



    const loadDetails = async () => {
        await getAccount();
        await getProviders();
    };


    const getAccount = async () => {
        setPageStatus({ loading: true, error: false, accountNotFound: false });
        const result = await settlementAccountsService.getAccountDetails(accountId);
        if (result?.success) {
            setSettlementAccount(result.data);
            setPageStatus({ loading: false, error: false, accountNotFound: false });
        } else {
            if (result.notFound) {
                setPageStatus({ loading: false, error: true, accountNotFound: true });
            } else {
                setPageStatus({ loading: false, error: true, accountNotFound: false });
            }
        }
    }


    const getProviders = async () => {
        setProvidersStatus({ loading: true, error: false });
        const result = await settlementAccountsService.getAccountProviders(accountId);

        if (result?.success) {
            setProviders(result.data);
            setProvidersStatus({ loading: false, error: false });
        } else {
            setProvidersStatus({ loading: false, error: true });
        }
    }



    const handleActivateAccount = async () => {
        const result = await settlementAccountsService.activateAccount(accountId);
        if (result.success) {
            setSettlementAccount((prevState: any) => ({ ...prevState, status: "ACTIVE" }));
            addToast({
                text: "Account has been activated successfully",
                type: "success",
            });
        } else {
            addToast({
                title: "Account activation failed",
                text: result.message,
                type: "error",
            });
        }
    };


    const handleProviderDelete = async (provider: any) => {
        const result = await settlementAccountsService.deleteProcessorProvider(provider.id);
        if (result.success) {
            addToast({
                text: "Provider deleted successfully",
                type: "success"
            });

            const list = [...providers];
            const withRemoved = list.filter((item) => item.id !== provider.id);
            setProviders(withRemoved);
        } else {
            addToast({
                title: "Error",
                text: "Provider could not be deleted",
                type: "error"
            });
        }
    }


    return (
        <React.Fragment>
            <div>
                {
                    !pageStatus.loading && !pageStatus.error && settlementAccount &&
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-8'>
                        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none p-8 rounded-xl'>
                            <div className='flex justify-between mb-6'>
                                <div className='mr-6'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>School</p>
                                    <h2 className='font-medium text-lg mb-2'>{settlementAccount?.client.name}</h2>
                                    <span className={`inline-block font-medium text-sm ${settlementAccount.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>
                                        {settlementAccount.status}
                                    </span>
                                </div>


                                {
                                    settlementAccount.status === "INACTIVE" &&
                                    <Menu as="div" className="relative inline-block text-left">
                                        <PopoverWithFloat placement='bottom-end' portal>
                                            <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:dark:bg-white/5`}>
                                                <i className="ri-more-2-fill"></i>
                                            </Menu.Button>

                                            <Menu.Items as='div' unmount={false} className="w-48 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                <Menu.Item
                                                    as="button"
                                                    type='button'
                                                    className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                                    onClick={() => openAlertConfirm({
                                                        title: settlementAccount.status === 'ACTIVE' ? "Deactivate Account" : "Activate Account",
                                                        message: `${settlementAccount.status === "ACTIVE" ? "Are you sure you want to deactivate" : "Are you sure you are ready to go live with"} ${settlementAccount.accountName}'s account?`,
                                                        onConfirmed: () => handleActivateAccount(),
                                                        positiveButtonText: settlementAccount.status === 'ACTIVE' ? "Deactivate" : "Activate",
                                                        negativeButtonText: 'No, Later',
                                                    })}
                                                >
                                                    Activate Account
                                                </Menu.Item>
                                            </Menu.Items>
                                        </PopoverWithFloat>
                                    </Menu>
                                }
                            </div>


                            <div className='divide-y dark:divide-gray-700'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Allow Canteen Usage</p>
                                    <p>{settlementAccount?.allowCanteenUsage ? "Yes" : "No"}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date Created</p>
                                    <p>{format(new Date(settlementAccount?.createdDate), 'do LLLL yyyy h:mm a')}</p>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none p-8 rounded-xl'>
                            <h2 className='font-medium text-lg mb-2'>Account</h2>

                            <div className='divide-y dark:divide-gray-700'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Account Name</p>
                                    <p>{settlementAccount?.accountName}</p>
                                </div>

                                <div className='grid grid-cols-2'>
                                    <div className='py-4'>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Account Type</p>
                                        <p>{settlementAccount?.accountType}</p>
                                    </div>

                                    <div className='py-4'>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Account Provider</p>
                                        <p>{settlementAccount?.accountProvider}</p>
                                    </div>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Account Number</p>
                                    <p>{settlementAccount?.accountNumber}</p>
                                </div>

                                {
                                    settlementAccount?.accountType === "BANK" &&
                                    <React.Fragment>
                                        <div className='py-4'>
                                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Bank Name</p>
                                            <p>{settlementAccount?.bankName || "N/A"}</p>
                                        </div>

                                        <div className='py-4'>
                                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Bank Branch</p>
                                            <p>{settlementAccount?.branch || "N/A"}</p>
                                        </div>
                                    </React.Fragment>
                                }

                                <div className='grid grid-cols-2'>
                                    <div className='py-4'>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Country</p>
                                        <p className='uppercase'>{settlementAccount?.country}</p>
                                    </div>

                                    <div className='py-4'>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Currency</p>
                                        <p>{settlementAccount?.currency}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none p-8 rounded-xl'>
                            <h2 className='font-medium text-lg mb-2'>Processor</h2>

                            <div className='divide-y dark:divide-gray-700'>
                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Name</p>
                                    <p>{settlementAccount?.processor.name}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Country</p>
                                    <p>{settlementAccount?.processor.country}</p>
                                </div>

                                <div className='py-4'>
                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Support Email</p>
                                    <a href={`mailto:${settlementAccount?.processor.customerSupportEmail}`} className='hover:underline'>{settlementAccount?.processor.customerSupportEmail}</a>
                                </div>
                            </div>
                        </div>

                    </div>
                }


                {
                    // if it's loading
                    pageStatus.loading &&
                    <ListLoader loadingText='Loading Details' className='px-8 py-24' />
                }

                {
                    // if account is not found
                    !pageStatus.loading && pageStatus.error && pageStatus.accountNotFound &&
                    <ErrorLoading title='Account Not Found' message='Settlement account may have been removed or never existed' className='px-8 py-24' />
                }

                {
                    // if there's an error loading
                    !pageStatus.loading && pageStatus.error && !pageStatus.accountNotFound &&
                    <ErrorLoading title='Error Loading Account' message='An unexpected error occurred while loading settlementAccount' className='px-8 py-24' onTryAgain={loadDetails} />
                }
            </div>


            <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none p-8 rounded-xl'>
                <div className='flex justify-between mb-8'>
                    <h2 className='font-medium text-lg mb-2 mr-6'>Payment Providers</h2>
                    <Button type='button' onClick={() => setProviderForm({ open: true, provider: null })} className='py-2.5'>Add Payment Provider</Button>
                </div>

                {
                    !providersStatus.loading && !providersStatus.error && providers.length > 0 &&
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b dark:border-gray-700'>
                                    <th className='py-3 px-4'>Processor</th>
                                    <th className='py-3 px-4'>Environment</th>
                                    <th className='py-3 px-4'>Base URL</th>
                                    <th className='py-3 px-4'>API Key</th>
                                    <th className='py-3 px-4'>Secret</th>
                                    <th className='py-3 px-4'>Date Created</th>
                                    <th className='py-3 px-4 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700">
                                {
                                    providers.map((providerItem: any) =>
                                        <tr key={providerItem.id}>
                                            <td className='whitespace-nowrap py-3 px-4'>{providerItem.name}</td>
                                            <td className='py-3 px-4'>{providerItem.environment}</td>
                                            <td className='py-3 px-4'>{providerItem.baseUrl}</td>
                                            <td className='py-3 px-4'>{providerItem.apiKey}</td>
                                            <td className='py-3 px-4'>
                                                <HideReveal text={providerItem.apiSecret} />
                                            </td>
                                            <td className='py-3 px-4 whitespace-nowrap'>{format(new Date(providerItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-3 px-4 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:dark:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-52 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setProviderForm({ open: true, provider: providerItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit
                                                            </Menu.Item>
                                                            <Menu.Item
                                                                as="button"
                                                                type='button'
                                                                onClick={() => openAlertConfirm({
                                                                    title: 'Delete Provider',
                                                                    message: `Are you sure you want to delete ${providerItem.environment} environment from ${providerItem.name}`,
                                                                    onConfirmed: () => handleProviderDelete(providerItem),
                                                                    positiveButtonText: "Delete",
                                                                    negativeButtonText: 'Cancel',
                                                                    positiveButtonClass: 'bg-red-500 hover:bg-red-600',
                                                                })}
                                                                className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3"
                                                            >
                                                                Remove
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                }

                {
                    providersStatus.loading &&
                    <ListLoader loadingText='Loading Provider' className='px-8 py-24' />
                }

                {
                    // if there're no provider
                    !providersStatus.loading && !providersStatus.error && providers.length === 0 &&
                    <ListNoData title='No Provider Found' className='py-0' description='There are no provider found for this Settlement Account'>
                    </ListNoData>
                }

                {
                    providersStatus.error &&
                    <ErrorLoading title='Error Loading Provider' message='There was a problem account provider' className='px-8 py-24' onTryAgain={getProviders} />
                }
            </div>



            <ModalAccountProcessorProviderForm
                isOpen={providerForm.open}
                provider={providerForm.provider}
                settlementAccount={settlementAccount}
                onClose={() => setProviderForm((prevState) => ({ ...prevState, open: false }))}
                onProviderCreated={(provider) => {
                    addToast({
                        text: "Provider added successfully",
                        type: "success"
                    });
                    setProviders((prevState) => ([provider, ...prevState]));
                }}
                onProviderUpdated={(provider) => {
                    addToast({
                        text: "Provider updated successfully",
                        type: "success"
                    });

                    const list = [...providers];
                    const index = list.findIndex(item => item.id === provider.id);
                    list[index] = { ...provider };
                    setProviders(list);
                }}
            />
        </React.Fragment>
    );
}

export default SettlementAccountDetailsPage;