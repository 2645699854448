import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import customersService from '../../../../../../../../../services/api/payments/customersService';
import { ListLoader } from '../../../../../../../../../components/loaders';
import { ErrorLoading } from '../../../../../../../../../components/common';
import WalletTransactionsListSection from './components/WalletTransactionsListSection';


function StudentPaymentDetailsPage() {
    const { profile, customerProfile, setCustomerProfile }: any = useOutletContext();
    const [pageStatus, setPageStatus] = useState({ loading: false, error: false, notFound: false });


    useEffect(() => {
        if (!customerProfile) {
            getCustomer();
        }
    }, []);


    const getCustomer = async () => {
        setPageStatus({ loading: true, error: false, notFound: false });
        const result = await customersService.getCustomerByReference(profile.id);
        if (result?.success) {
            if (Array.isArray(result.data.accounts)) {
                const canteenAccount = result.data.accounts.find((item: any) => item.accountType === 'CANTEEN');
                if (canteenAccount) {
                    setCustomerProfile({ ...result.data, accounts: [canteenAccount] });
                } else {
                    setCustomerProfile(result.data);
                }
            } else {
                setCustomerProfile(result.data);
            }

            setPageStatus({ loading: false, error: false, notFound: false });
        } else {
            if (result.notFound) {
                setPageStatus({ loading: false, error: true, notFound: true });
            } else {
                setPageStatus({ loading: false, error: true, notFound: false });
            }
        }
    }



    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            {
                !pageStatus.loading && !pageStatus.error && customerProfile &&
                <React.Fragment>
                    {
                        customerProfile?.accounts?.length > 0 &&
                        <div className='grid md:grid-cols-3 gap-8 mb-10'>
                            <div className='group relative w-full flex flex-col justify-between gap-10 bg-gradient-to-r from-blue dark:from-blue-600 to-purple-600 dark:to-purple-700 rounded-2xl overflow-hidden py-8 px-8'>
                                <div className='relative z-[1] flex justify-between flex-wrap gap-6'>
                                    <div className='flex gap-2 text-white'>
                                        <span className='font-medium mt-1'>GHS</span>
                                        <span className='font-semibold text-4xl'>{customerProfile?.accounts[0].availableBalance.toFixed(2)}</span>
                                    </div>


                                    <div className='flex'>
                                        <span className='font-medium text-white'>SikaID</span>

                                    </div>
                                </div>

                                <div className='relative z-[1] flex justify-between flex-wrap text-white gap-x-5 gap-y-2'>
                                    <div className=''>
                                        <p className='text-sm opacity-70'>Account Name</p>
                                        <p className='text-lg font-medium whitespace-nowrap'>{customerProfile?.firstName} {customerProfile?.otherNames} {customerProfile?.lastName}</p>
                                    </div>

                                    <div className=''>
                                        <p className='text-sm opacity-70'>Account No.</p>
                                        <p className='text-lg font-medium'>{customerProfile?.accounts[0].accountNumber}</p>
                                    </div>
                                </div>


                                <span className='absolute right-4 -top-2/3 transition-transform duration-500 group-hover:translate-y-4 aspect-square h-full rounded-full scale-150 bg-white/5'></span>
                                <span className='absolute -right-16 top-6 transition-transform duration-500 group-hover:-translate-x-4 aspect-square h-full rounded-full scale-150 bg-white/5'></span>
                            </div>


                            <div className='md:col-span-2 border dark:border-gray-700 rounded-2xl p-8'>
                                <div className='grid grid-cols-2 md:grid-cols-3 gap-6'>
                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Status</p>
                                        {
                                            customerProfile?.accounts[0].status === "ACTIVE" ?
                                                <p className='font-semibold text-green-500'>Active</p> :
                                                <p className='font-semibold text-yellow-500'>Inactive</p>
                                        }
                                    </div>


                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Payment Authorization</p>
                                        {
                                            customerProfile?.accounts[0].paymentAuthorizationRequired ?
                                                <p className='font-semibold'>Required</p> :
                                                <p className='font-semibold'>Not Required</p>
                                        }
                                    </div>

                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Redeem without SikaID</p>
                                        {
                                            customerProfile?.accounts[0].allowNegativeTransaction ?
                                                <p className='font-semibold'>Allowed</p> :
                                                <p className='font-semibold'>Not Allowed</p>
                                        }
                                    </div>

                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Daily Transaction Threshold</p>
                                        <p className='font-semibold'>GHS {customerProfile?.accounts[0].dailyTransactionAmountThreshold.toFixed(2)}</p>
                                    </div>

                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Daily Top Up Threshold</p>
                                        <p className='font-semibold'>GHS {customerProfile?.accounts[0].dailyTopUpAmountThreshold.toFixed(2)}</p>
                                    </div>

                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Daily Withdrawal Threshold</p>
                                        <p className='font-semibold'>GHS {customerProfile?.accounts[0].dailyCashWithdrawalThreshold.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }

                    <WalletTransactionsListSection />
                </React.Fragment>
            }


            {
                // if it's loading
                pageStatus.loading &&
                <ListLoader loadingText='Loading Details' className='px-8 py-24' />
            }

            {
                // if student is not found
                !pageStatus.loading && pageStatus.error && pageStatus.notFound &&
                <ErrorLoading title='Not Available' message='No information found for this student in payment' className='px-8 py-24' />
            }

            {
                // if there's an error loading
                !pageStatus.loading && pageStatus.error && !pageStatus.notFound &&
                <ErrorLoading title='Error Loading Details' message='An unexpected error occurred while loading payment details' className='px-8 py-24' onTryAgain={getCustomer} />
            }
        </div>
    );
}

export default StudentPaymentDetailsPage;