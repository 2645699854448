import { attendanceApi } from '../httpService';


const getSchoolConfigurations = async (schoolId: any) => {
    try {
        const result = await attendanceApi.get(`/api/v1/schools/${schoolId}/configuration`, {});
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}



const updateSchoolConfigurations = async (schoolId: any, data: any) => {
    try {
        const result = await attendanceApi.put(`/api/v1/schools/${schoolId}/configuration`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getSchoolConfigurations,
    updateSchoolConfigurations,
}