import React, { useEffect } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import usePagedListApi from '../../../../../../../../state/hooks/usePagedListApi';
import studentService from '../../../../../../../../services/api/unified-api/studentService';
import { ErrorLoading, ListNoData, Pagination } from '../../../../../../../../components/common';
import { paginationInfo } from '../../../../../../../../utils/pagination';
import { ListLoader } from '../../../../../../../../components/loaders';
import { format } from 'date-fns';

function StudentActivitiesPage() {
    const { profile, scanHistoryList }: any = useOutletContext();

    //const { data: scanHistory, requestData, resetRecords, updateRecord } = usePagedListApi((query: any) => studentService.getStudentScanHistory(profile.id, query));
    const { data: scanHistory, requestData, resetRecords, updateRecord } = scanHistoryList;
    const [searchParams, setSearchParams] = useSearchParams();

    // get query params
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};
    // if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }



    useEffect(() => {
        getScanHistory();
    }, [searchParams]);


    const getScanHistory = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                properties: "scanDate",
                direction: "DESC"
            },
        });
    };


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl overflow-x-auto py-8 px-8'>
            <div className='flex justify-between flex-wrap gap-2 mb-6'>
                <h2 className='font-medium text-xl mr-4'>Scan Activities</h2>

            </div>

            {
                // if there're scans
                !scanHistory.loadingError && !scanHistory.loading && scanHistory.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto align-top w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-5 pl-2 pr-5'>Activity</th>
                                    <th className='py-5 px-5'>Timestamp</th>
                                    <th className='py-5 px-5'>Description</th>
                                    <th className='py-5 px-5'>Channel</th>
                                    <th className='py-5 px-5'>Delegate</th>
                                    <th className='py-5 pl-5 pr-2'>Relationship</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    scanHistory.list.map((scanItem: any) =>
                                        <tr key={scanItem?.id}>
                                            <td className='py-4 pl-2 pr-6'>
                                                {(() => {
                                                    const scanType = scanItem.scanType.replace('_', ' ');
                                                    switch (scanItem.scanType) {
                                                        case 'DROP_OFF':
                                                        case 'EARLY_DROP_OFF':
                                                            return <span className='whitespace-nowrap font-medium bg-blue/5 text-blue rounded-lg py-2 px-3.5'>{scanType}</span>;
                                                        case 'PICKUP':
                                                            return <span className='whitespace-nowrap font-medium bg-green-500/5 text-green-500 rounded-lg py-2 px-3.5'>{scanType}</span>;
                                                        case 'LATE_DROP_OFF':
                                                        case 'LATE_PICKUP':
                                                            return <span className='whitespace-nowrap font-medium bg-red-500/5 text-red-500 rounded-lg py-2 px-3.5'>{scanType}</span>;
                                                        default:
                                                            return <span className='whitespace-nowrap font-medium bg-black/5 dark:bg-white/5 rounded-lg py-2 px-3.5'>{scanType}</span>;
                                                    }
                                                })()}
                                            </td>
                                            <td className='py-4 px-5 whitespace-nowrap'>{format(new Date(scanItem?.scanDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 px-5'>{scanItem.comment}</td>
                                            <td className='py-4 px-5'>{scanItem.scanChannel}</td>
                                            <td className='py-4 px-5'>{scanItem.delegateName}</td>
                                            <td className='py-4 pl-6 pr-2'>{scanItem.delegateRelation}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(scanHistory.pager.pageSize, scanHistory.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={scanHistory.pager.pageSize}
                            totalCount={scanHistory.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if scans are loading
                !scanHistory.loadingError && scanHistory.loading &&
                <ListLoader loadingText='Loading Scan Activities' className='px-8 py-24' />
            }

            {
                // if there're no scans on the student's account
                !scanHistory.loadingError && !scanHistory.loading && scanHistory.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Scan Activity Found' description='Scans are yet to be made for student' className='px-8 py-24'>
                </ListNoData>
            }

            {
                // if there're no scans found in search
                !scanHistory.loadingError && !scanHistory.loading && scanHistory.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Scan Activity Found' description='No scan activity found for the selected scenarios' className='px-8 py-24' />
            }


            {
                scanHistory.loadingError &&
                <ErrorLoading title='Error Loading Scan Activities' message='There was a problem loading activities' className='px-8 py-24' onTryAgain={getScanHistory} />
            }
        </div>
    );
}

export default StudentActivitiesPage;