import { canteenApi } from '../httpService';


const getMembers = async (params: any) => {
    try {
        const result = await canteenApi.get(`members`, {
            params: params
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const createMember = async (data: any, query: any = {}) => {
    try {
        const result = await canteenApi.post(`members`, data, {
            params: query
        });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: null,
                message: error.response.data.errors[0].error
            }
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating owner. Please try again later."
        };
    }
}



const updateMember = async (memberId: number, data: any, query: any = {}) => {
    try {
        const result = await canteenApi.patch(`members/${memberId}`, data, { params: query });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating a owner. Please try again later."
        };
    }
}



const deleteMember = async (memberId: number) => {
    try {
        const result = await canteenApi.delete(`members/${memberId}`);

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getMembers,
    createMember,
    updateMember,
    deleteMember
}