import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { FormInput } from '../../../../../components/form';
import { Button } from '../../../../../components/common';
import usersPermissionsService from '../../../../../services/api/user-accounts/usersPermissionsService';


function ModalUserPermissionForm({ isOpen, permission, onClose, onPermissionAdded, onPermissionUpdated }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });


    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        if (permission) {
            // update
            /*  const result = await usersPermissionsService.updatePermission(permission.id, formData);
             if (result.success) {
                 if (onPermissionUpdated) {
                     onPermissionUpdated({ ...permission, ...result.data });
                 }
                 //resetForm();
                 onClose();
                 setSubmitStatus({ loading: false, failedMessage: '' });
             } else {
                 setSubmitStatus({ loading: false, failedMessage: result.message });
             } */
        } else {
            // create
            const result = await usersPermissionsService.createPermission(formData);

            if (result.success) {
                if (onPermissionAdded) {
                    onPermissionAdded(result.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    }


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[32rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {permission ? "Edit" : "Add"} Permission
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            name: permission ? permission.name : '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }

                                <div className="mb-12">
                                    <label htmlFor="input-permission-name" className='block text-sm mb-2'>Permission</label>
                                    <FormInput type="text" name="name" id="input-permission-name" className="w-full" placeholder="Enter permission" autoComplete='off' />
                                    {touched['name'] && errors['name'] && <p className='text-sm text-red-500 mt-2'>Permission name is Required</p>}
                                </div>


                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        {permission ? "Save" : "Add Permission"}
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required().label(""),
});


interface Props {
    isOpen: boolean;
    onClose: () => void;
    permission: any;
    onPermissionAdded?: (permission: any) => void;
    onPermissionUpdated?: (permission: any) => void;
}


export default ModalUserPermissionForm;