import { authApi } from '../httpService';
import { unifiedApi } from '../httpService';


const getAccounts = async (query: any) => {
    try {
        const result = await authApi.get('/api/v1/search-users', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const getUserAccount = async (accountId: any) => {
    try {
        const result = await authApi.get(`/api/v1/users/${accountId}`, {});
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


const createAccount = async (data: any) => {
    try {
        const result = await authApi.post(`/api/v1/users`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        /*  if (error.response && error.response.status === 400) {
             const issues: any[] = error.response.data.errors;
             if (issues[0].field === "contact") {
                 return {
                     success: false,
                     data: null,
                     message: "Invalid contact number provided"
                 };
             }
         } */


        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating campus. Please try again later."
        };
    }
}


const updateAccount = async (accountId: any, data: any) => {
    try {
        const result = await authApi.patch(`/api/v1/users/${accountId}`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating account. Please try again later."
        };
    }
}


const sendPasswordReset = async (schoolId: number, data: any) => {
    try {
        //https://dev-unified-api.minex360-staging.com/api/v1/accounts/<schoolId>/operations
        const result = await unifiedApi.post(`/api/v1/accounts/${schoolId}/operations`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem sending request. Please try again later."
        };
    }
}


const requestAccountReset = async (data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v2/accounts/-1/operations`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: null,
                message: "Sorry, account does not exist.",
            };
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem sending request. Please try again later."
        };
    }
}


const verifyOTP = async (data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v2/otp/verify`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: null,
                message: "Invalid code provided"
            };
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem verifying code. Please try again later."
        };
    }
}


const changePassword = async (data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v2/accounts/change-password`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        /* if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: null,
                message: "Invalid code provided"
            };
        } */

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem changing password. Please try again later."
        };
    }
}



export default {
    getAccounts,
    getUserAccount,
    createAccount,
    updateAccount,
    sendPasswordReset,
    requestAccountReset,
    verifyOTP,
    changePassword
}