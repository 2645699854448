import { paymentsApi } from '../httpService';


const rewriteStudentAccountData = async (query: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/jobs/accounting/books/rewrite`, {
            params: query,
        });
        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


const createPin = async (data: any) => {
    try {
        const result = await paymentsApi.post(`/api/v1/accounts/create-pin`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return {
                success: false,
                data: null,
                message: error.response.data.errors[0].message
            }
        }

        return {
            success: false,
            data: null,
            message: "Sorry, an unexpected error occurred. Please try again later."
        };
    }
}



export default {
    rewriteStudentAccountData,
    createPin
}