import React, { LegacyRef } from 'react';
import { Field } from 'formik';
import { twMerge } from 'tailwind-merge';


export const Input = React.forwardRef(({ className, ...otherProps }: Props, ref: LegacyRef<HTMLInputElement>) => {
    return (
        <input
            ref={ref}
            className={twMerge('bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 focus:border-blue-500/60 dark:focus:border-blue-500/60 focus:outline-none rounded-lg py-2.5 px-4', className)}
            {...otherProps}
        />
    );
})


export function FormInput({ className, ...otherProps }: Props) {
    return (
        <Field
            className={twMerge('bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 focus:border-blue-500/60 dark:focus:border-blue-500/60 focus:outline-none rounded-lg py-2.5 px-4', className)}
            {...otherProps}
        />
    );
}


interface Props extends React.InputHTMLAttributes<HTMLInputElement> { }


//export default Input;