import { authApi } from '../httpService';


const getRoles = async (query: any) => {
    try {
        const result = await authApi.get('/api/v1/user-roles', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const createRole = async (data: any) => {
    try {
        const result = await authApi.post(`/api/v1/user-roles`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            /* const issues: any[] = error.response.data.errors;
            if (issues[0].field === "name") {
                return {
                    success: false,
                    data: null,
                    message: 'Permission name should be at least 3 characters long, and should be separated by "_"'
                };
            } */
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem adding a permission. Please try again later."
        };
    }
}


const updateRole = async (data: any) => {
    try {
        const result = await authApi.put(`/api/v1/user-roles`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            const issues: any[] = error.response.data.errors;
            if (issues[0].field === "name") {
                return {
                    success: false,
                    data: null,
                    message: 'Role name should be at least 3 characters long, and should be separated by "_"'
                };
            }
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem adding user role. Please try again later."
        };
    }
}


const removePermissionFromRole = async (roleId: number, permissionId: number) => {
    try {
        const result = await authApi.delete(`/api/v1/user-roles/${roleId}/${permissionId}`);

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const deleteRole = async (roleId: number) => {
    try {
        const result = await authApi.delete(`/api/v1/user-roles/${roleId}`);

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getRoles,
    createRole,
    updateRole,
    deleteRole,
    removePermissionFromRole
}