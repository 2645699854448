import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';

import useToast from '../../../../../../../../../state/hooks/useToast';
import useAlert from '../../../../../../../../../state/hooks/useAlert';
import { Button, ErrorLoading, ListNoData, Pagination } from '../../../../../../../../../components/common';
import { Menu } from '@headlessui/react';
import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import { paginationInfo } from '../../../../../../../../../utils/pagination';
import { ListLoader } from '../../../../../../../../../components/loaders';
import usePagedListApi from '../../../../../../../../../state/hooks/usePagedListApi';
import transactionsService from '../../../../../../../../../services/api/payments/transactionsService';
import ModalTransactionDetails from '../../modals/ModalTransactionDetails';
import ModalReversePaymentTransaction from '../../modals/ModalReversePaymentTransaction';
import ModalInvoiceDetails from '../../modals/ModalInvoiceDetails';


function StudentTransactionListPage() {
    const { profile, transactionList }: any = useOutletContext();

    //const { data: transactions, requestData, resetRecords, updateRecord } = usePagedListApi((query: any) => transactionsService.getCustomerTransactionsByReference(profile.id, query));
    const { data: transactions, requestData, resetRecords, updateRecord } = transactionList;
    const [searchParams, setSearchParams] = useSearchParams();

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    const [transactionDetails, setTransactionDetails] = useState({ open: false, transaction: null });
    const [invoiceDetails, setInvoiceDetails] = useState({ open: false, invoice: null });
    const [reverseTransactionForm, setReverseTransactionForm] = useState({ open: false, transaction: null });

    // get query params
    const page = Number(searchParams.get('page')) || 1;
    const searchFilters: any = {};
    // if (searchParams.get('name')) { searchFilters['name'] = searchParams.get('name') || '' }


    useEffect(() => {
        getTransactions();
    }, [searchParams]);


    const getTransactions = async () => {
        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                properties: "datetime",
                direction: "DESC"
            },
        });
    };


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl overflow-x-auto py-8 px-8'>
            <div className='flex justify-between flex-wrap gap-2 mb-6'>
                <h2 className='font-medium text-xl mr-4'>Transactions</h2>

            </div>


            {
                // if there're transactions
                !transactions.loadingError && !transactions.loading && transactions.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto align-top w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='whitespace-nowrap py-6 pl-2 pr-6'>Transaction ID</th>
                                    <th className='whitespace-nowrap py-6 px-6'>Invoice No.</th>
                                    <th className='py-6 px-6'>Amount</th>
                                    <th className='py-6 px-6'>Type</th>
                                    {/* <th className='whitespace-nowrap py-6 px-6'>Payer</th> */}
                                    <th className='py-6 px-6'>Channel</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Date</th>
                                    <th className='py-6 pl-6 pr-2 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    transactions.list.map((transactionItem: any) =>
                                        <tr key={transactionItem?.id}>
                                            <td className='py-4 pl-2 pr-6'>
                                                <button type='button' onClick={() => setTransactionDetails({ open: true, transaction: transactionItem })} className='hover:text-blue'>{transactionItem.transactionId}</button>
                                            </td>
                                            <td className='py-4 px-6'>{transactionItem.invoice.invoiceNumber}</td>
                                            <td className='whitespace-nowrap py-4 px-6'>GHS {transactionItem.amountProcessed.toFixed(2)}</td>
                                            <td className='py-4 px-6'>{transactionItem.type}</td>
                                            {/*  <td className='py-4 px-6'>{transactionItem.payer}</td> */}
                                            <td className='py-4 px-6'>{transactionItem.paymentChannel}</td>
                                            <td className='py-4 px-6'>{transactionItem.status}</td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(transactionItem?.datetime), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 pl-6 pr-2 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-60 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setTransactionDetails({ open: true, transaction: transactionItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                View Details
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => setInvoiceDetails({ open: true, invoice: transactionItem.invoice })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                View Invoice
                                                            </Menu.Item>
                                                            {
                                                                transactionItem.status !== "REVERSED" && (transactionItem.paymentChannel === "CASH" || transactionItem.paymentChannel === "OFFLINE") &&
                                                                <Menu.Item as="button" type='button' onClick={() => setReverseTransactionForm({ open: true, transaction: transactionItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                    Reverse Transaction
                                                                </Menu.Item>
                                                            }
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(transactions.pager.pageSize, transactions.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={transactions.pager.pageSize}
                            totalCount={transactions.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if transactions are loading
                !transactions.loadingError && transactions.loading &&
                <ListLoader loadingText='Loading Transactions' className='px-8 py-24' />
            }

            {
                // if there're no transactions on the student's account
                !transactions.loadingError && !transactions.loading && transactions.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Transactions' description='Student is yet to make a transaction' className='px-8 py-24'>
                </ListNoData>
            }

            {
                // if there're no transaction found in search
                !transactions.loadingError && !transactions.loading && transactions.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Transactions' description='No transaction found for the selected scenarios' className='px-8 py-24' />
            }


            {
                transactions.loadingError &&
                <ErrorLoading title='Error Loading Transactions' message='There was a problem loading transactions' className='px-8 py-24' onTryAgain={getTransactions} />
            }



            <ModalTransactionDetails
                isOpen={transactionDetails.open}
                transaction={transactionDetails.transaction}
                onClose={() => setTransactionDetails((prevState) => ({ ...prevState, open: false }))}
            />

            <ModalReversePaymentTransaction
                isOpen={reverseTransactionForm.open}
                transaction={reverseTransactionForm.transaction}
                onClose={() => setReverseTransactionForm((prevState) => ({ ...prevState, open: false }))}
                onTransactionReversed={(transaction: any) => {
                    updateRecord({ item: transaction, page });
                    addToast({
                        text: `Transaction reversed successfully`,
                        type: "success"
                    });
                }}
            />

            <ModalInvoiceDetails
                isOpen={invoiceDetails.open}
                invoice={invoiceDetails.invoice}
                onClose={() => setInvoiceDetails((prevState) => ({ ...prevState, open: false }))}
            />
        </div>
    );
}

export default StudentTransactionListPage;