import { unifiedApi } from '../httpService';



const getEmployees = async (schoolId: any, query: any) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${schoolId}/employees`, {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



const getEmployeeProfile = async (employeeId: any, schoolId: any) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${schoolId}/employees/${employeeId}`, {});
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}



const getEmployeeQRCode = async (employeeId: any, schoolId: any) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${schoolId}/employees/${employeeId}/qr-code`);
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}



const createEmployee = async (schoolId: number, data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/schools/${schoolId}/employees`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            const issues: any[] = error.response.data.errors;
            if (issues[0].field === "contact") {
                return {
                    success: false,
                    data: null,
                    message: "Invalid contact number provided"
                };
            }

            if (issues[0].field === "error") {
                return {
                    success: false,
                    data: null,
                    message: "Primary contact already exist"
                };
            }
        }

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating campus. Please try again later."
        };
    }
}



const createAdminAccountCredentials = async (schoolId: number, employeeId: number, data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/schools/${schoolId}/employees/${employeeId}/credentials`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        /* if (error.response && error.response.status === 400) {
            const issues: any[] = error.response.data.errors;
            if (issues[0].field === "contact") {
                return {
                    success: false,
                    data: null,
                    message: "Invalid contact number provided"
                };
            }
        } */

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating campus. Please try again later."
        };
    }
}


/* const activateAdminAccount = async (schoolId: number, data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/accounts/${schoolId}/operations`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem activating account. Please try again later."
        };
    }
} */


const moveToDifferentSchool = async (data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/employees/migrate`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem moving student to school. Please try again later."
        };
    }
}


export default {
    getEmployees,
    getEmployeeProfile,
    getEmployeeQRCode,
    createEmployee,
    createAdminAccountCredentials,
    //activateAdminAccount,
    moveToDifferentSchool,
}