import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { selectCurrentUser } from '../../state/store/reducers/entities/authSlice';


function AuthProtected({ children }: Props) {
    const user = useSelector(selectCurrentUser);

    if (user) {
        return <Navigate to="/" replace={true} />;;
    }

    return children;
}

interface Props {
    children: JSX.Element
}

export default AuthProtected;