import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Popover } from '@headlessui/react';
import { debounce } from 'lodash';

import { FormInput, SelectInput } from '../../../../../../components/form';
import PopoverWithFloat from '../../../../../../components/hoc/PopoverWithFloat';
import { Button } from '../../../../../../components/common';
import schoolService from '../../../../../../services/api/unified-api/schoolService';
import userRolesService from '../../../../../../services/api/user-accounts/userRolesService';
import useIsFirstRender from '../../../../../../state/hooks/useIsFirstRender';


function UserAccountListFilters({ initialData, searching, onSearch }: Props) {
    //const [schoolList, setSchoolList] = useState<any[]>([]);
    const [schoolListData, setSchoolListData] = useState({ loading: false, list: [{ id: '', name: "All schools" }] });
    const [roleList, setRoleList] = useState<any[]>([{ label: "All Roles", key: "" }]);

    const initial = useIsFirstRender();

    useEffect(() => {
        if (initial) {
            //getSchools();
            getRoles();
            if (initialData['schoolIds']) {
                getSelectedSchool(initialData['schoolIds']);
            }
        }
    }, []);


    const handleSubmit = (values: any, { reset }: any) => {
        const payload = { ...values };

        payload['username'] = payload.user;
        delete payload.user;
        //payload.schoolIds = payload.schoolIds.map((item: any) => item.id).join(',');
        /* Object.keys(payload).forEach(key => {
            if (!payload[key]) {
                delete payload[key];
            }
        }); */

        onSearch(payload);
    }


    const getSelectedSchool = async (schoolId: string) => {
        const result = await schoolService.getSchoolProfile(schoolId);
        if (result.success) {
            setSchoolListData({ loading: false, list: [{ id: '', name: "All schools" }, { ...result.data }] });
        } else {
        }
    }


    const getSchools = debounce(async (searchQuery: string) => {
        setSchoolListData({ loading: true, list: [] });
        const result = await schoolService.getSchools({ name: searchQuery });
        if (result.success) {
            setSchoolListData({ loading: false, list: [{ id: '', name: "All schools" }, ...result.data] });
        } else {
            setSchoolListData({ loading: false, list: [{ id: '', name: "All schools" }] });
        }
    }, 500);


    const getRoles = async () => {
        const result = await userRolesService.getRoles({
            page: 1,
            pageSize: 100,
            direction: 'ASC',
            properties: 'label',
        });

        if (result.success) {
            setRoleList((prevState) => ([...prevState, ...result.data]));
        }
    };


    /*  const getSelectedSchools = (schoolIds: string): any[] => {
         const idArrays = schoolIds.split(',');
         return schoolList.filter(item => idArrays.includes(item.id.toString()));
     } */

    return (
        <Formik
            initialValues={{
                user: initialData['username'] || '',
                schoolId: initialData['schoolIds'] || '',
                userType: initialData['userType'] || "",
                roleLabel: initialData['roleLabel'] || "",
                status: initialData['status'] || "",
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ errors, touched, setErrors, submitCount, values, isValid, setFieldValue, resetForm }) => (
                <Form className="flex-grow flex items-center flex-wrap mr-8 mb-4 gap-y-4" autoComplete="off">
                    <div className='hidden sm:block max-w-[16rem] mr-4'>
                        <SelectInput
                            items={schoolListData.list}
                            labelKey='name'
                            valueKey='id'
                            searchable={true}
                            placeholder="Select school"
                            value={values["schoolId"]}
                            onChange={(school: any) => setFieldValue("schoolId", school.id)}
                            onSearch={(query) => getSchools(query)}
                            searching={schoolListData.loading}
                        />

                        {/*  <MultiSelectInput
                            items={schoolList}
                            valueKey='id'
                            labelKey='name'
                            placeholder="Select schools"
                            selectedLabel='School'
                            selectedLabelPlural='Schools'
                            showSelectedItems={false}
                            value={values["schoolIds"]}
                            emptyAsAll
                            onChange={(selected: any[]) => setFieldValue("schoolIds", selected)}
                        /> */}
                    </div>

                    <div className='hidden lg:block mr-4'>
                        <SelectInput
                            items={[
                                { label: "All Users", value: "" },
                                { label: "App User", value: "APP_USER" },
                                { label: "Portal User", value: "PORTAL_USER" },
                            ]}
                            placeholder="User type"
                            value={values["userType"]}
                            onChange={(value: { label: string, value: string }) => setFieldValue("userType", value.value)}
                        />
                    </div>

                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <FormInput type="text" id="input-search-username" name="user" className="lg:w-full lg:max-w-xs mr-4" placeholder="Email or Phone number" autoComplete='off' />

                    <Popover as="div" className="relative inline-block text-left mr-4">
                        {
                            ({ open, close }) => (
                                <PopoverWithFloat placement='bottom-end' portal={false}>
                                    <Popover.Button type='button' className={`flex items-center transition-colors duration-300 hocus:bg-gray-50 ui-open:bg-gray-50 dark:hocus:bg-gray-800 dark:ui-open:bg-gray-800 rounded-lg py-2 px-2.5`}>
                                        <span className='text-sm whitespace-nowrap mr-1.5'>More Filters</span>
                                        <i className="text-lg ri-more-2-fill"></i>
                                    </Popover.Button>

                                    <Popover.Panel as='div' unmount={false} className="w-screen max-w-sm sm:max-w-md  lg:max-w-xl right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60  mt-1 py-5 px-5 mr-2">
                                        <div className='grid sm:grid-cols-2 gap-y-5 gap-x-5 mb-5'>
                                            <div className=''>
                                                <SelectInput
                                                    items={schoolListData.list}
                                                    labelKey='name'
                                                    valueKey='id'
                                                    searchable={true}
                                                    placeholder="Select school"
                                                    value={values["schoolId"]}
                                                    onChange={(school: any) => setFieldValue("schoolId", school.id)}
                                                    onSearch={(query) => getSchools(query)}
                                                    searching={schoolListData.loading}
                                                />
                                            </div>

                                            <div className=''>
                                                <SelectInput
                                                    items={[
                                                        { label: "All Users", value: "" },
                                                        { label: "App User", value: "APP_USER" },
                                                        { label: "Portal User", value: "PORTAL_USER" },
                                                    ]}
                                                    placeholder="User type"
                                                    value={values["userType"]}
                                                    onChange={(value: { label: string, value: string }) => setFieldValue("userType", value.value)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className=''>
                                                <SelectInput
                                                    items={roleList}
                                                    placeholder="User Role"
                                                    valueKey='key'
                                                    value={values["roleLabel"]}
                                                    onChange={(value: { label: string, key: string }) => setFieldValue("roleLabel", value.key)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className=''>
                                                <SelectInput
                                                    items={[
                                                        { label: "All Statuses", value: "" },
                                                        { label: 'Active', value: 'ACTIVE' },
                                                        { label: 'Inactive', value: 'INACTIVE' },
                                                        { label: 'Suspended', value: 'SUSPEND' },
                                                    ]}
                                                    placeholder="User type"
                                                    value={values["status"]}
                                                    onChange={(value: { label: string, value: string }) => setFieldValue("status", value.value)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className='sm:col-span-2'>
                                                <FormInput type="text" id="input-search-username" name="user" className="sm:w-full" placeholder="Email or Phone number" autoComplete='off' />
                                            </div>
                                        </div>


                                        <div className='flex justify-between items-center'>
                                            <button
                                                type='reset'
                                                onClick={() => resetForm({
                                                    values: {
                                                        user: '',
                                                        schoolId: '',
                                                        userType: "",
                                                        roleLabel: "",
                                                        status: "",

                                                    }
                                                })}
                                                className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}
                                            >
                                                Reset
                                            </button>

                                            <Button type='submit' onClick={close} loading={searching} className='!py-2' disabled={searching}>
                                                Search
                                            </Button>
                                        </div>
                                    </Popover.Panel>
                                </PopoverWithFloat>
                            )
                        }
                    </Popover>
                    <button type='submit' className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}>Search</button>
                </Form>
            )}
        </Formik>
    );
}


interface Props {
    initialData: any;
    onSearch: (values: any) => void;
    searching: boolean;
}


export default UserAccountListFilters;