import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { FormInput, FormTextArea, SelectInput } from '../../../../../components/form';
import { Button, ErrorLoading } from '../../../../../components/common';
import { ListLoader } from '../../../../../components/loaders';
import settlementAccountsService from '../../../../../services/api/payments/settlementAccountsService';


function ModalAccountProcessorProviderForm({ isOpen, provider, settlementAccount, onClose, onProviderCreated, onProviderUpdated }: Props) {
    const [providerOptions, setProviderOptions] = useState([]);
    const [optionsLoadingStatus, setOptionsLoadingStatus] = useState({ loading: false, error: false });

    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });


    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });

            if (providerOptions.length === 0) {
                getOptions();
            }
        }
    }, [isOpen]);


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        if (provider) {
            //update
            const result = await settlementAccountsService.updateProcessorProvider(provider.id, { ...formData, collectingAccountId: settlementAccount.id });
            if (result.success) {
                if (onProviderUpdated) {
                    onProviderUpdated({ ...provider, ...result.data });
                }
                //resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        } else {
            // add
            const result = await settlementAccountsService.addProcessorProvider(settlementAccount.processor.id, { ...formData, collectingAccountId: settlementAccount.id });
            if (result.success) {
                if (onProviderCreated) {
                    onProviderCreated(result.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    };



    const getOptions = async () => {
        setOptionsLoadingStatus({ loading: true, error: false });
        const result = await settlementAccountsService.getProcessorProviders();

        if (result.success) {
            setOptionsLoadingStatus({ loading: false, error: false });
            setProviderOptions(result.data);
        } else {
            setOptionsLoadingStatus({ loading: false, error: true });
        }
    }


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[34rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {provider ? "Update" : "Add"} Payment Provider
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                {
                    !optionsLoadingStatus.loading && !optionsLoadingStatus.error &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <Formik
                            initialValues={{
                                name: provider ? provider.name : '',
                                environment: provider ? provider.environment : '',
                                apiKey: provider ? provider.apiKey : '',
                                apiSecret: provider ? provider.apiSecret : '',
                                baseUrl: provider ? provider.baseUrl : '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                                <Form className="">
                                    {
                                        submitStatus.failedMessage && !submitStatus.loading &&
                                        <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                    }


                                    <div className="mb-6">
                                        <label htmlFor="modal-select-provider" className='block text-sm mb-2'>Provider name</label>
                                        <SelectInput
                                            items={providerOptions}
                                            placeholder="Select a provider"
                                            value={values["name"]}
                                            labelKey='name'
                                            valueKey='name'
                                            onChange={(value) => setFieldValue("name", value.name)}
                                            onBlur={() => setFieldTouched('name', true)}
                                        />
                                        {touched['name'] && errors['name'] && <p className='text-sm text-red-500 mt-2'>Provider is required</p>}
                                    </div>


                                    <div className="mb-6">
                                        <label htmlFor="modal-select-environment" className='block text-sm mb-2'>Environment</label>
                                        <SelectInput
                                            items={["Live", "Staging", "Develop"]}
                                            placeholder="Select environment"
                                            value={values["environment"]}
                                            onChange={(value) => setFieldValue("environment", value)}
                                            onBlur={() => setFieldTouched('environment', true)}
                                        />
                                        {touched['environment'] && errors['environment'] && <p className='text-sm text-red-500 mt-2'>Environment is required</p>}
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="input-api-key" className='block text-sm mb-2'>API Key</label>
                                        <FormTextArea name='apiKey' id="input-api-key" className='w-full' placeholder="Enter API Key" />
                                        {touched['apiKey'] && errors['apiKey'] && <p className='text-sm text-red-500 mt-2'>API Key is Required</p>}
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="input-api-secret" className='block text-sm mb-2'>API Secret</label>
                                        <FormTextArea name='apiSecret' id="input-api-secret" className='w-full' placeholder="Enter API Secret" />
                                        {touched['apiSecret'] && errors['apiSecret'] && <p className='text-sm text-red-500 mt-2'>API Secret is Required</p>}
                                    </div>

                                    <div className="mb-10">
                                        <label htmlFor="input-api-base-url" className='block text-sm mb-2'>API base URL</label>
                                        <FormInput type="text" name="baseUrl" id="input-api-base-url" className="w-full" placeholder="Enter Url" />
                                        {touched['baseUrl'] && errors['baseUrl'] && <p className='text-sm text-red-500 mt-2'>Base URL is required</p>}
                                    </div>


                                    <div className='flex justify-end items-center'>
                                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                            Cancel
                                        </Button>

                                        <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                            {provider ? "Save" : "Add Provider"}
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                }

                {
                    (optionsLoadingStatus.loading || optionsLoadingStatus.error) &&
                    <div className='pb-10'>
                        {
                            optionsLoadingStatus.loading &&
                            <ListLoader loadingText='Loading Options' className='py-10' loaderSize={2} />
                        }
                        {
                            optionsLoadingStatus.error &&
                            <ErrorLoading title='Error Loading Options' message='An unexpected error occurred while loading options' className='px-8 py-24' onTryAgain={getOptions} />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required().label(""),
    environment: Yup.string().trim().required().label(""),
    apiKey: Yup.string().trim().required().label(""),
    apiSecret: Yup.string().trim().required().label(""),
    baseUrl: Yup.string().trim().required().label(""),
});


interface Props {
    isOpen: boolean;
    provider: any;
    settlementAccount: any;
    onClose: () => void;
    onProviderCreated?: (provider: any) => void;
    onProviderUpdated?: (provider: any) => void;
}


export default ModalAccountProcessorProviderForm;