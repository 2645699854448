import { createSlice } from "@reduxjs/toolkit";

import { RootState } from '../../index';
import { DispatchType } from "../..";
import appRatingService from "../../../../services/api/unified-api/appRatingService";


const initialState = {
    list: {} as { [index: number]: any[] },
    loading: true,
    filters: null,
    pager: {
        pageSize: 0,
        page: 1,
        totalCount: 0
    },
    loadingError: false
};


const slice = createSlice({
    name: "appFeeds",
    initialState: initialState,
    reducers: {
        listRequested: (state, action) => {
            state.loading = true;
            state.filters = action.payload.filters;
            state.pager.page = action.payload.page;
            state.loadingError = false;
        },
        listReceived: (state, action) => {
            state.list[action.payload.pager.page] = action.payload.data;
            state.pager = action.payload.pager;
            state.loading = false;
        },
        listRequestFailed: (state, action) => {
            state.loadingError = true;
            state.loading = false;
        },
        updateRecord: (state, action) => {
            const { appRatings, page } = action.payload;

            const list = state.list[page];
            if (list) {
                const index = list.findIndex(appRatingsItem => appRatingsItem.id === appRatings.id);
                list[index] = { ...appRatings };

                state.list[page] = list;
            }
        },
        getCachedList: (state, action) => {
            state.pager.page = action.payload.page;
        },
        clearCachedList: (state, action) => {
            state.list = {};
        },
        resetList: (state, action) => initialState,
    }
});


const { listRequested, getCachedList, listRequestFailed, listReceived, clearCachedList } = slice.actions;
export const { resetList, updateRecord } = slice.actions;




// Actions
export const loadAppRatings = (payload: any) => async (dispatch: DispatchType, getState: () => RootState) => {
    const appRatings = getState().entities.appRatings;
    const sameFilters = JSON.stringify(payload.filters) === JSON.stringify(appRatings.filters);

    // load from cache when page is already loaded
    if (appRatings.list[payload.page] && sameFilters) {
        dispatch(getCachedList({
            page: payload.page
        }));

        return;
    }


    if (!sameFilters) {
        dispatch(clearCachedList({}));
    }


    dispatch(listRequested(payload));
    const result = await appRatingService.getRatings({
        ...payload.filters,
        page: payload.page,
        pageSize: 10,
        direction: 'desc',
        properties: 'id',
    });

    if (result.success) {
        dispatch(listReceived(result));
    } else {
        dispatch(listRequestFailed({}));
    }
};




// Selectors
export const selectAppFeeds = (state: RootState): ListSelectorType => {
    const appFeeds = { ...state.entities.appFeeds };
    appFeeds.list = appFeeds.list[state.entities.appFeeds.pager.page] || [];

    return appFeeds as ListSelectorType;
};


type ListSelectorType = Omit<typeof initialState, 'list'> & {
    list: any[];
}


export default slice.reducer;