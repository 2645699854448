import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { format } from 'date-fns';

import { loadSettlementAccounts, resetSettlementAccounts, selectSettlementAccounts, updateSettlementAccountRecord } from '../../../../../state/store/reducers/entities/settlementAccountSlice';
import useToast from '../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
import { ErrorLoading, ListNoData, Pagination } from '../../../../../components/common';
import { ListLoader } from '../../../../../components/loaders';
import { paginationInfo } from '../../../../../utils/pagination';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import SettlementAccountsListFilters from './components/SettlementAccountsListFilters';
import settlementAccountsService from '../../../../../services/api/payments/settlementAccountsService';
import useAlert from '../../../../../state/hooks/useAlert';
import usePagedListApi from '../../../../../state/hooks/usePagedListApi';


function SettlementAccountListPage() {
    const dispatch = useDispatch();
    //const settlementAccounts = useSelector(selectSettlementAccounts);
    const { data: settlementAccounts, requestData, resetRecords } = usePagedListApi(settlementAccountsService.getSchoolSettlementAccounts);
    const [searchParams, setSearchParams] = useSearchParams();

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    // get query params
    const page = Number(searchParams.get('page')) || settlementAccounts.pager.page;
    const searchFilters: any = {};
    if (searchParams.get('school')) { searchFilters['schoolId'] = searchParams.get('school') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "Settlement Accounts" }));

        return () => {
            dispatch(resetSettlementAccounts({}));
        }
    }, []);


    useEffect(() => {
        getSettlementAccounts();
    }, [searchParams, dispatch]);



    const getSettlementAccounts = async () => {
        /*  await dispatch<any>(loadSettlementAccounts({
             page: page,
             filters: searchFilters,
         })); */

        await requestData({
            page: page,
            filters: searchFilters,
            otherProperties: {
                pageSize: 10,
                includeArchives: true,
            },
        });


        /* ...payload.filters,
        page: payload.page,
        pageSize: 10,
        includeArchives: true, */
    };


    const handleActivateAccount = (account: any) => {
        openAlertConfirm({
            title: `${account.status === "ACTIVE" ? "Deactivate" : "Activate"} Account`,
            message: `${account.status === "ACTIVE" ? "Are you sure you want to deactivate" : "Are you sure you are ready to go live with"} ${account.accountName}'s account?`,
            onConfirmed: () => activateAccount(account),
            positiveButtonText: `${account.status === "ACTIVE" ? "Deactivate Account" : "Activate Account"}`,
            negativeButtonText: 'Cancel',
        });
    };


    const activateAccount = async (account: any) => {
        const result = await settlementAccountsService.activateAccount(account.id);
        if (result.success) {
            dispatch(updateSettlementAccountRecord({ settlementAccount: { ...account, status: "ACTIVE" }, page }));
            addToast({
                text: "Account has been activated successfully",
                type: "success"
            });
        } else {
            addToast({
                title: "Account activation failed",
                text: result.message,
                type: "error"
            });
        }
    }



    return (
        <div className=''>
            <div className='flex flex-row-reverse flex-wrap justify-between mb-1'>
                <SettlementAccountsListFilters
                    initialData={searchFilters}
                    onSearch={(values) => {
                        //setSearchParams({ ...values });
                        setSearchParams(current => {
                            const entries = { ...Object.fromEntries(current.entries()), ...values };
                            if (entries.page) {
                                entries.page = '1';
                            }
                            return entries;
                        });
                    }}
                    searching={settlementAccounts.loading}
                />
            </div>

            {
                // if there're settlement accounts
                !settlementAccounts.loadingError && !settlementAccounts.loading && settlementAccounts.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>School</th>
                                    <th className='py-6 px-6'>Account Name</th>
                                    <th className='py-6 px-6'>Account Type</th>
                                    <th className='py-6 px-6'>Processor</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Date Created</th>
                                    <th className='py-6 px-6 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    settlementAccounts.list.map((accountItem: any) =>
                                        <tr key={accountItem.id}>
                                            <td className='py-4 px-6'>
                                                <Link to={`/settlement-accounts/${accountItem.id}`} state={{ settlementAccount: accountItem }} className="hover:text-blue-500">
                                                    {accountItem.client.name}
                                                </Link>
                                            </td>
                                            <td className='py-4 px-6'>{accountItem.accountName}</td>
                                            <td className='py-4 px-6'>{accountItem.accountType}</td>
                                            <td className='py-4 px-6'>{accountItem.processor.name}</td>
                                            <td className='py-4 px-6'>
                                                <span className={`inline-block font-medium text-sm ${accountItem.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{accountItem.status}</span>
                                            </td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(accountItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 px-6 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-52 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            {
                                                                accountItem.status === "INACTIVE" &&
                                                                <Menu.Item as="button" type='button' onClick={() => handleActivateAccount(accountItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                    {accountItem.status === "ACTIVE" ? "Deactivate Account" : "Activate Account"}
                                                                </Menu.Item>
                                                            }
                                                            <Menu.Item as={Link} to={`/settlement-accounts/${accountItem.id}`} state={{ settlementAccount: accountItem }} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Go to Details
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(settlementAccounts.pager.pageSize, settlementAccounts.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={settlementAccounts.pager.pageSize}
                            totalCount={settlementAccounts.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if Settlement Account are loading
                !settlementAccounts.loadingError && settlementAccounts.loading &&
                <ListLoader loadingText='Loading Settlement Accounts' className='px-8 py-24' />
            }

            {
                // if there're no Settlement Account in the app
                !settlementAccounts.loadingError && !settlementAccounts.loading && settlementAccounts.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Settlement Accounts Found' description='There are no Settlement Account currently added in SmartSapp' className='px-8 py-24'>
                </ListNoData>
            }

            {
                // if there're no Settlement Account for search
                !settlementAccounts.loadingError && !settlementAccounts.loading && settlementAccounts.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Settlement Accounts Found' description='No Settlement Account was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                settlementAccounts.loadingError &&
                <ErrorLoading title='Error Loading Settlement Accounts' message='There was a problem Settlement Accounts' className='px-8 py-24' onTryAgain={getSettlementAccounts} />
            }
        </div>
    );
}

export default SettlementAccountListPage;