import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';

import useClickOutside from '../../state/hooks/useClickOutside';
import PopoverWithFloat from '../hoc/PopoverWithFloat';
import Loader from '../svgs/Loader';
import { Input } from './Input';


function SelectInput({ value, items = [], valueKey = 'value', labelKey = 'label', placeholder = '', searchable = false, usePortal = true, searching, placement = 'bottom-start', wrapOptions = false, onChange, onFocus, onBlur, onSearch }: Props) {
    const [selected, setSelected] = useState<any>('');
    const [query, setQuery] = useState('');

    const containerRef = useRef<HTMLDivElement>(null);
    let isClicked = false;


    useClickOutside(containerRef, () => {
        if (onBlur && isClicked) {
            onBlur();
        }
    });


    useEffect(() => {
        //if (value) {
        const itemSample = items[0];
        if (itemSample) {
            if (typeof itemSample === 'object' && itemSample !== null) {
                const selection = items.find((item) => {
                    if (value !== undefined && value !== null) {
                        return item[valueKey].toString() === value.toString();
                    }
                    return false;
                });
                setSelected(selection || '')
            } else {
                setSelected(value);
            }
        }
        // }
    }, [value, items, valueKey]);



    const filteredItems = useMemo(() => {
        if (!onSearch && query) {
            return items.filter((item) => {
                const filterVal = (typeof item === 'object' && item !== null) ? item[labelKey] : item;
                return filterVal.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''));
            });
        }

        return items;
    }, [query, items, labelKey, onSearch]);



    /*  const filteredItems = (onSearch && query === '' ) ? items : items.filter((item) => {
          const filterVal = (typeof item === 'object' && item !== null) ? item[labelKey] : item;
          return filterVal.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''));
      }); */


    return (
        <div ref={containerRef} className='' onClick={() => isClicked = true}>
            <Combobox
                as="div"
                value={selected}
                onChange={(val) => {
                    if (onChange) {
                        onChange(val);
                    } else {
                        setSelected(val);
                    }
                }}
                className="relative w-full"
                onFocus={onFocus}
            //onBlur={onBlur}
            >
                {
                    ({ open }) => (
                        <PopoverWithFloat placement={placement} adaptiveWidth portal={usePortal}>
                            <Combobox.Button
                                type='button'
                                className={`w-full cursor-pointer flex justify-between text-left bg-transparent autofill:bg-white border  ${open ? 'border-blue-500/60 dark:border-blue-500/60' : 'border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600'} rounded-lg py-2.5 px-3.5`}
                                title={typeof selected === 'object' && selected !== null ? selected[labelKey] || '' : selected}
                            >
                                {!selected && <span className='opacity-50 mr-3'>{placeholder}</span>}
                                {
                                    selected &&
                                    <span className='whitespace-nowrap flex-grow line-clamp-1 mr-3'>{typeof selected === 'object' && selected !== null ? selected[labelKey] || '' : selected}</span>
                                }

                                <i className="ri-arrow-down-s-line opacity-70"></i>
                            </Combobox.Button>

                            <Combobox.Options className="max-h-64 w-60 min-w-fit text-left -left-2.5 origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60 overflow-y-scroll px-2 py-3">
                                {
                                    //searchable && items.length > 0 &&
                                    ((searchable && items.length > 0) || onSearch) &&
                                    <div className='px-4 pt-1.5 pb-3'>
                                        <Combobox.Input
                                            className="w-full text-sm bg-white dark:bg-gray-900 autofill:bg-white border border-gray-300 dark:border-gray-700 hocus::border-blue-300 dark:hocus::border-gray-600 focus:outline-none rounded-lg py-2 px-2"
                                            //value={query}
                                            displayValue={() => query}
                                            onChange={(event) => {
                                                setQuery(event.target.value);
                                                if (onSearch) {
                                                    onSearch(event.target.value);
                                                }
                                            }}
                                            placeholder='Search'
                                        />
                                    </div>
                                }
                                {
                                    filteredItems.length === 0 && !searching &&
                                    <p className='text-sm text-gray-500 dark:text-gray-400 px-5 py-2'>
                                        {onSearch ? 'Type to search items' : ' No Item found'}
                                    </p>
                                }

                                {/*  {
                                    filteredItems.length === 0 && 
                                    <p className='text-sm text-gray-500 dark:text-gray-400 px-5 py-2'>No Item found</p>
                                } */}
                                {
                                    searching &&
                                    <div className='flex justify-center opacity-50 py-3 px-5'>
                                        <span className='w-5 mr-2'><Loader /></span>
                                        <span>Searching</span>
                                    </div>
                                }
                                {
                                    filteredItems.length > 0 &&
                                    <React.Fragment>
                                        {filteredItems.map((item, itemIndex) => (
                                            <Combobox.Option
                                                //key={item[valueKey] || itemIndex}
                                                key={typeof item === 'object' && item !== null ? `${item[valueKey]}${itemIndex}` || itemIndex : `${item}${itemIndex}`}
                                                value={item}
                                                className={`cursor-pointer text-left ${wrapOptions ? '' : 'whitespace-nowrap'} ui-selected:text-blue hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg w-full pl-5 pr-10 py-3`}
                                            // disabled={person.unavailable}
                                            >
                                                {typeof item === 'object' && item !== null ? item[labelKey] || '' : item}
                                                {/* {item[labelKey] || ''} */}
                                            </Combobox.Option>
                                        ))}
                                    </React.Fragment>
                                }
                            </Combobox.Options>
                        </PopoverWithFloat>
                    )
                }
            </Combobox>
        </div>
    );
}

interface Props {
    value?: string;
    labelKey?: string;
    valueKey?: string;
    items?: any[];
    placeholder?: string;
    searchable?: boolean;
    usePortal?: boolean;
    placement?: any;
    wrapOptions?: boolean;
    onChange?: (value: any) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onSearch?: (query: string) => void;
    searching?: boolean;
}

export default SelectInput;