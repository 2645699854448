import React, { useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { loadUserAccounts, resetAccounts, selectUserAccounts, updateAccountRecord } from '../../../../../state/store/reducers/entities/userAccountSlice';
import useToast from '../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../state/store/reducers/ui/headerSlice';
import { Button, ErrorLoading, ListNoData, Pagination } from '../../../../../components/common';
import { ListLoader } from '../../../../../components/loaders';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import { paginationInfo } from '../../../../../utils/pagination';
import UserAccountListFilters from './components/UserAccountListFilters';
import ModalMoveUserToDIfferentSchoolForm from '../modals/ModalMoveUserToDIfferentSchoolForm';
import ModalUserAccountForm from '../modals/ModalUserAccountForm';
import ModalEmployeeDetails from '../modals/ModalEmployeeDetails';
import ModalEmployeeAccountForm from '../modals/ModalEmployeeAccountForm';
import ModalDelegateDetails from '../modals/ModalDelegateDetails';


function UserAccountListPage() {
    const dispatch = useDispatch();
    const userAccounts = useSelector(selectUserAccounts);
    const [searchParams, setSearchParams] = useSearchParams();

    const [userAccountForm, setUserAccountForm] = useState({ open: false, account: null });
    const [employeeAccountForm, setEmployeeAccountForm] = useState({ open: false, employeeAccount: null });
    const [existingEmployeeForm, setExistingEmployeeForm] = useState({ open: false });
    // const [parentAccountForm, setParentAccountForm] = useState({ open: false, parentAccount: null });

    const [moveUserToSchoolForm, setMoveUserToSchoolForm] = useState({ open: false, user: null });
    const [employeeDetailsModal, setEmployeeDetailsModal] = useState({ open: false, employee: null });
    const [delegateDetailsModal, setDelegateDetailsModal] = useState<{ open: boolean; delegate: any }>({ open: false, delegate: null });

    const { addToast } = useToast();

    // get query params
    const page = Number(searchParams.get('page')) || userAccounts.pager.page;
    const searchFilters: any = {
        //name: searchParams.get('name') || '',
    };
    if (searchParams.get('username')) { searchFilters['username'] = searchParams.get('username') || '' }
    if (searchParams.get('schoolId')) { searchFilters['schoolIds'] = searchParams.get('schoolId') || '' }
    if (searchParams.get('userType')) { searchFilters['userType'] = searchParams.get('userType') || '' }
    if (searchParams.get('roleLabel')) { searchFilters['roleLabel'] = searchParams.get('roleLabel') || '' }
    if (searchParams.get('status')) { searchFilters['status'] = searchParams.get('status') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "User Accounts" }));

        return () => {
            dispatch(resetAccounts({}));
        }
    }, []);


    useEffect(() => {
        getAccounts();
    }, [searchParams, dispatch]);



    const getAccounts = async () => {
        await dispatch<any>(loadUserAccounts({
            page: page,
            filters: searchFilters,
        }));
    };


    return (
        <div className=''>
            <div className='flex flex-row-reverse flex-wrap items-start justify-between mb-1'>
                {/*  <Menu as="div" className="relative inline-block text-left">
                    <PopoverWithFloat placement='bottom-end'>
                        <Menu.Button as={Button} type='button' className="flex items-center gap-0.5">
                            <span>Add Account</span>
                            <i className="text-lg leading-none ri-arrow-down-s-line"></i>
                        </Menu.Button>

                        <Menu.Items as='div' unmount={false} className="w-80 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-950 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                            <Menu.Item as="button" type='button' onClick={() => setEmployeeAccountForm({ open: true, employeeAccount: null })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                Add Employee Account
                            </Menu.Item>
                     
                        </Menu.Items>
                    </PopoverWithFloat>
                </Menu> */}

                <Button type='button' onClick={() => setEmployeeAccountForm({ open: true, employeeAccount: null })} className='mb-4'>Add Employee</Button>
                <UserAccountListFilters
                    initialData={searchFilters}
                    onSearch={(values) => {
                        //setSearchParams({ ...values });
                        setSearchParams(current => {
                            const entries = { ...Object.fromEntries(current.entries()), ...values };
                            if (entries.page) {
                                entries.page = '1';
                            }
                            return entries;
                        });
                    }}
                    searching={userAccounts.loading}
                />
            </div>

            {
                // if there're accounts
                !userAccounts.loadingError && !userAccounts.loading && userAccounts.list.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>Username</th>
                                    <th className='py-6 px-6'>User Type</th>
                                    <th className='py-6 px-6'>Role</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Verified</th>
                                    <th className='py-6 px-6 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    userAccounts.list.map((accountItem: any) =>
                                        <tr key={accountItem.id}>
                                            <td className='py-4 px-6'>
                                                <button
                                                    type='button'
                                                    className='transition-colors duration-300 hover:text-blue-500'
                                                    onClick={() => {
                                                        if (accountItem.employeeId) {
                                                            setEmployeeDetailsModal({ open: true, employee: accountItem });
                                                            return;
                                                        }

                                                        if (accountItem.delegateId) {
                                                            setDelegateDetailsModal({ open: true, delegate: accountItem });
                                                            return;
                                                        }
                                                    }}
                                                >
                                                    {accountItem.username}
                                                </button>
                                            </td>
                                            <td className='py-4 px-6'>{accountItem.userType}</td>
                                            <td className='py-4 px-6'>{accountItem.roleLabel}</td>
                                            <td className='py-4 px-6'>
                                                {
                                                    accountItem.status === "ACTIVE" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-3`}>{accountItem.status}</span>
                                                }
                                                {
                                                    accountItem.status !== "ACTIVE" && accountItem.status !== "SUSPENDED" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-3`}>{accountItem.status}</span>
                                                }
                                                {
                                                    accountItem.status === "SUSPENDED" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-1 px-3`}>{accountItem.status}</span>
                                                }
                                            </td>
                                            <td className='py-4 px-6'>
                                                <span className={`inline-block font-medium text-sm uppercase ${accountItem.verified ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{accountItem.verified ? "Verified" : "Not Verified"}</span>
                                            </td>
                                            <td className='py-4 px-6 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-64 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setUserAccountForm({ open: true, account: accountItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit Account
                                                            </Menu.Item>
                                                            <Menu.Item as="button" type='button' onClick={() => setMoveUserToSchoolForm({ open: true, user: accountItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Move to a Different School
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(userAccounts.pager.pageSize, userAccounts.pager.totalCount, page)}</p>

                        <Pagination
                            currentPage={page}
                            pageSize={userAccounts.pager.pageSize}
                            totalCount={userAccounts.pager.totalCount}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            {
                // if accounts are loading
                !userAccounts.loadingError && userAccounts.loading &&
                <ListLoader loadingText='Loading User Accounts' className='px-8 py-24' />
            }

            {
                // if there're no account in the app
                !userAccounts.loadingError && !userAccounts.loading && userAccounts.list.length === 0 && Object.keys(searchFilters).length === 0 &&
                <ListNoData title='No Account Found' description='There is no user account currently setup in SmartSapp' className='px-8 py-24'>
                    <Button type='button' onClick={() => null}>Add a User Here</Button>
                </ListNoData>
            }

            {
                // if there're no accounts in the app
                !userAccounts.loadingError && !userAccounts.loading && userAccounts.list.length === 0 && Object.keys(searchFilters).length > 0 &&
                <ListNoData title='No Account Found' description='No user account was found for the selected scenarios' className='px-8 py-24' />
            }


            {
                userAccounts.loadingError &&
                <ErrorLoading title='Error Loading Accounts' message='There was a problem loading user accounts' className='px-8 py-24' onTryAgain={getAccounts} />
            }


            <ModalUserAccountForm
                isOpen={userAccountForm.open}
                account={userAccountForm.account}
                onClose={() => setUserAccountForm((prevState) => ({ ...prevState, open: false }))}
                onAccountUpdated={(account) => {
                    dispatch(updateAccountRecord({ account, page }));
                    addToast({
                        text: `Account updated successfully`,
                        type: "success"
                    });
                }}
            />


            <ModalMoveUserToDIfferentSchoolForm
                isOpen={moveUserToSchoolForm.open}
                user={moveUserToSchoolForm.user}
                onClose={() => setMoveUserToSchoolForm((prevState) => ({ ...prevState, open: false }))}
                onUserMoved={() => {
                    addToast({
                        text: "User moved successfully",
                        type: 'success'
                    });
                }}
            />

            <ModalEmployeeDetails
                isOpen={employeeDetailsModal.open}
                employee={employeeDetailsModal.employee}
                idKey='employeeId'
                onClose={() => setEmployeeDetailsModal((prevState) => ({ ...prevState, open: false }))}
            />

            <ModalDelegateDetails
                isOpen={delegateDetailsModal.open}
                delegate={delegateDetailsModal.delegate}
                idKey='delegateId'
                displayAsParent={delegateDetailsModal.delegate && delegateDetailsModal.delegate.roleLabel === "ROLE_PARENT"}
                onClose={() => setDelegateDetailsModal((prevState) => ({ ...prevState, open: false }))}
            />

            <ModalEmployeeAccountForm
                isOpen={employeeAccountForm.open}
                employeeAccount={employeeAccountForm.employeeAccount}
                onClose={() => setEmployeeAccountForm((prevState) => ({ ...prevState, open: false }))}
                onEmployeeAccountCreated={(employeeAccount: any) => {
                    addToast({
                        text: `Employee account created successfully`,
                        type: "success"
                    });

                    dispatch(resetAccounts({}));
                    setSearchParams({});

                    if (Array.from(searchParams.entries()).length === 0) {
                        getAccounts();
                    }
                }}
                onEmployeeCreatedNotAccount={(employee: any) => {
                    addToast({
                        title: 'Could not create employee account',
                        text: `Employee account created successfully`,
                        type: "warning",
                    });
                }}
            />
        </div>
    );
}

export default UserAccountListPage;