import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import canteenServiceProvidersService from '../../../../../../../../services/api/canteen/canteenServiceProvidersService';
import Modal from '../../../../../../../../components/modals/Modal';
import { AvatarSelectInput, FormInput, PhoneNumberInput, SelectInput } from '../../../../../../../../components/form';
import { Button } from '../../../../../../../../components/common';



function ModalCanteenServiceProviderForm({ isOpen, serviceProvider, school, onClose, onServiceProviderAdded, onServiceProviderUpdated }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });

    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });

        const payload = { ...formData, modeOfOperation: [], schoolId: school.id };

        if (process.env.REACT_APP_ENV_NAME !== "development") {
            delete payload?.service;
        }

        if (serviceProvider) {
            // update
            const result = await canteenServiceProvidersService.updateServiceProvider(serviceProvider.id, payload, { schoolId: school.id });
            if (result.success) {
                if (onServiceProviderUpdated) {
                    onServiceProviderUpdated({ ...serviceProvider, ...result.data });
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        } else {
            // create
            const result = await canteenServiceProvidersService.createServiceProvider(payload, { schoolId: school.id });
            if (result.success) {
                if (onServiceProviderAdded) {
                    onServiceProviderAdded(result.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    }


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-screen max-w-lg">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {serviceProvider ? "Edit" : "Add"} Canteen Vendor
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            logo: serviceProvider ? serviceProvider.logo : '',
                            name: serviceProvider ? serviceProvider.name : '',
                            email: serviceProvider ? serviceProvider.email : '',
                            primaryContact: serviceProvider ? serviceProvider.primaryContact : '',
                            service: serviceProvider ? serviceProvider.service : '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }

                                <div className='mb-6'>
                                    <AvatarSelectInput
                                        size={6.2}
                                        value={values['logo']}
                                        placeholder='Select Logo'
                                        label='Select Logo'
                                        layout='inline'
                                        fileSize={0.8}
                                        onChange={(value) => setFieldValue('logo', value)}
                                    />
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="modal-input-name" className='block text-sm mb-2'>Name</label>
                                    <FormInput type="text" name="name" id="modal-input-name" className="w-full" placeholder="Enter vendor name" />
                                    {touched['name'] && errors['name'] && <p className='text-sm text-red-500 mt-2'>Vendor name is Required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="modal-input-email" className='block text-sm mb-2'>Email <span className='text-sm text-gray-500 dark:text-gray-400'>(Optional)</span></label>
                                    <FormInput type="email" name="email" id="modal-input-email" className="w-full" placeholder="Enter email address" autoComplete='off' role="presentation" />
                                    {touched['email'] && errors['email'] && <p className='text-sm text-red-500 mt-2'>Email is required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-contact-number" className='block text-sm mb-2'>Primary Contact</label>
                                    <PhoneNumberInput
                                        id="input-contact-number"
                                        name="contact"
                                        className="w-full"
                                        //placeholder="Enter campus contact number"
                                        value={values["primaryContact"]}
                                        onChange={value => setFieldValue('primaryContact', value)}
                                        onBlur={() => setFieldTouched('primaryContact', true)}
                                    />
                                    {touched['primaryContact'] && errors['primaryContact'] && <p className='text-sm text-red-500 mt-2'>{errors['primaryContact'] as string}</p>}
                                </div>


                                {
                                    process.env.REACT_APP_ENV_NAME === "development" &&
                                    <div className="mb-10">
                                        <label htmlFor="input-service" className='block text-sm mb-2'>Service Type</label>
                                        <SelectInput
                                            items={[
                                                { label: "Canteen", value: "CANTEEN" },
                                                { label: 'Bus', value: 'BUS' },
                                                { label: 'Book Shop', value: 'BOOK_SHOP' },
                                            ]}
                                            placeholder="Select Service"
                                            value={values["service"]}
                                            onChange={(value: { label: string, value: string }) => setFieldValue("service", value.value)}
                                        />
                                        {touched['service'] && errors['service'] && <p className='text-sm text-red-500 mt-2'>Service is required</p>}
                                    </div>
                                }


                                <div className='flex justify-end items-center mt-10'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        {serviceProvider ? "Save" : "Add Vendor"}
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required().label(""),
    email: Yup.string().email().trim().label(""),
    primaryContact: Yup.string().trim().required("Contact number is Required").test("invalid-number", "Invalid number provided", value => value ? isValidPhoneNumber(value) : true).label(""),
});


interface Props {
    isOpen: boolean;
    onClose: () => void;
    serviceProvider: any;
    school: any;
    onServiceProviderAdded?: (serviceProvider: any) => void;
    onServiceProviderUpdated?: (serviceProvider: any) => void;
}


export default ModalCanteenServiceProviderForm;