import { unifiedApi } from '../httpService';


const getScanners = async (query: any) => {
    try {
        const result = await unifiedApi.get('/api/v1/devices', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const createScanner = async (data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/devices`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem adding a scanner. Please try again later."
        };
    }
}


const updateScanner = async (scannerId: number, data: any) => {
    try {
        const result = await unifiedApi.put(`/api/v1/devices/${scannerId}`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem adding a scanner. Please try again later."
        };
    }
}



const deleteScanner = async (serialNumber: number) => {
    try {
        const result = await unifiedApi.delete(`/api/v1/devices/${serialNumber}`);

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getScanners,
    createScanner,
    updateScanner,
    deleteScanner,
}