import React, { useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import { useOutletContext } from 'react-router-dom';

import accountingService from '../../../../../../../../../../services/api/payments/accountingService';
import PopoverWithFloat from '../../../../../../../../../../components/hoc/PopoverWithFloat';
import useAuth from '../../../../../../../../../../state/hooks/useAuth';
import useToast from '../../../../../../../../../../state/hooks/useToast';
import customersService from '../../../../../../../../../../services/api/payments/customersService';
import { ListLoader } from '../../../../../../../../../../components/loaders';
import { ErrorLoading } from '../../../../../../../../../../components/common';


function StudentAccountSummarySection() {
    const { profile, transactionStats, setTransactionStats }: any = useOutletContext();
    const [sectionStatus, setSectionStatus] = useState({ loading: false, error: false });


    const { addToast } = useToast();
    const { hasPermission } = useAuth();


    useEffect(() => {
        if (!transactionStats) {
            getTransactionStats();
        }
    }, []);



    const getTransactionStats = async () => {
        setSectionStatus({ loading: true, error: false });
        const result = await customersService.getCustomersTransactionStatsByReference({
            studentIds: profile.id,
            schoolIds: 6
        });

        if (result?.success) {
            setTransactionStats(result.data[0]);
            setSectionStatus({ loading: false, error: false });
        } else {
            setSectionStatus({ loading: false, error: true });
        }
    }


    const handleAccountRewrite = async () => {
        const result = await accountingService.rewriteStudentAccountData({
            customerIds: transactionStats?.customerId
        });

        if (result?.success) {
            addToast({
                text: "Account rewrite successfully activated",
                type: "success",
            });
        } else {
            addToast({
                text: "Sorry, Could not activate account rewrite.",
                type: "error",
            });
        }
    }


    return (
        <div className='mb-12'>
            <div className='flex justify-between items-center flex-wrap gap-2 mb-5'>
                <h2 className='font-medium text-xl mr-4'>Account Summary</h2>

                {
                    transactionStats && hasPermission("REWRITE_JOURNALS") &&
                    <Menu as="div" className="relative inline-block text-left">
                        {
                            ({ open }) => (
                                <PopoverWithFloat placement='bottom-end' portal>
                                    <Menu.Button
                                        className="!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-700 dark:hocus:bg-gray-600 rounded-lg py-2.5 px-4"
                                    >
                                        <span className='mr-2'>Action</span>
                                        <i className="ri-arrow-down-s-line opacity-70"></i>
                                    </Menu.Button>

                                    <Menu.Items as='div' unmount={false} className="w-52 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                        <Menu.Item as="button" type='button' onClick={handleAccountRewrite} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-5 py-3">
                                            Rewrite Account
                                        </Menu.Item>
                                    </Menu.Items>
                                </PopoverWithFloat>

                            )
                        }
                    </Menu>
                }
            </div>


            {
                !sectionStatus.loading && !sectionStatus.error &&
                <React.Fragment>
                    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-7 mb-6'>
                        <div className={`rounded-2xl ${transactionStats?.currentBalance > 0 ? 'bg-red-500/10' : 'bg-green-500/10'} p-5`}>
                            <p className='mb-1'>Current Balance</p>
                            <p className={`font-semibold text-xl ${transactionStats?.currentBalance > 0 ? 'text-red-500' : 'text-green-500'}`}>{transactionStats?.currency} {transactionStats?.currentBalance?.toFixed(2)}</p>
                        </div>

                        <div className='rounded-2xl bg-gray-50 dark:bg-gray-500/10 p-5'>
                            <p className='text-gray-500 dark:text-gray-400 mb-1'>Previous Balance</p>
                            <p className={`font-semibold text-xl ${transactionStats?.previousBalance > 0 ? 'text-red-500' : 'text-green-500'}`}>{transactionStats?.currency} {transactionStats?.previousBalance?.toFixed(2)}</p>
                        </div>

                        <div className='rounded-2xl bg-gray-50 dark:bg-gray-500/10 p-5'>
                            <p className='text-gray-500 dark:text-gray-400 mb-1'>Total Outstanding</p>
                            <p className='font-semibold text-xl'>{transactionStats?.currency} {transactionStats?.totalOutstanding?.toFixed(2)}</p>
                        </div>

                        <div className='rounded-2xl bg-gray-50 dark:bg-gray-500/10 p-5'>
                            <p className='text-gray-500 dark:text-gray-400 mb-1'>Total Discount</p>
                            <p className='font-semibold text-xl'>{transactionStats?.currency} {transactionStats?.totalDiscountAmount?.toFixed(2)}</p>
                        </div>

                        <div className='rounded-2xl bg-gray-50 dark:bg-gray-500/10 p-5'>
                            <p className='text-gray-500 dark:text-gray-400 mb-1'>Total Overdue</p>
                            <p className='font-semibold text-xl'>{transactionStats?.currency} {transactionStats?.totalAmountOverdue?.toFixed(2)}</p>
                        </div>

                        <div className='rounded-2xl bg-gray-50 dark:bg-gray-500/10 p-5'>
                            <p className='text-gray-500 dark:text-gray-400 mb-1'>Total Paid</p>
                            <p className='font-semibold text-xl'>{transactionStats?.currency} {transactionStats?.totalAmountPaid?.toFixed(2)}</p>
                        </div>
                    </div>


                    <div className='flex flex-wrap gap-x-10 gap-y-3'>
                        <div className='flex gap-x-1.5'>
                            <span className='text-gray-500 dark:text-gray-400'>Total Amount:</span>
                            <span className='font-medium'>{transactionStats?.currency} {transactionStats?.totalAmount?.toFixed(2)}</span>
                        </div>

                        <div className='flex gap-x-1.5'>
                            <span className='text-gray-500 dark:text-gray-400'>Total Required Amount:</span>
                            <span className='font-medium'>{transactionStats?.currency} {transactionStats?.totalRequiredAmount?.toFixed(2)}</span>
                        </div>

                        <div className='flex gap-x-1.5'>
                            <span className='text-gray-500 dark:text-gray-400'>Total Required Outstanding:</span>
                            <span className='font-medium'>{transactionStats?.currency} {transactionStats?.totalRequiredOutstanding?.toFixed(2)}</span>
                        </div>

                        <div className='flex gap-x-1.5'>
                            <span className='text-gray-500 dark:text-gray-400'>Total Required Amount Overdue:</span>
                            <span className='font-medium'>{transactionStats?.currency} {transactionStats?.totalRequiredAmountOverdue?.toFixed(2)}</span>
                        </div>
                    </div>
                </React.Fragment>
            }


            {
                // if it's loading
                sectionStatus.loading &&
                <ListLoader loadingText='Loading Stats' loaderSize={2} className='px-8 py-6' />
            }


            {
                // if there's an error loading
                !sectionStatus.loading && sectionStatus.error &&
                <ErrorLoading title='Error Loading Stats' message='An unexpected error occurred while loading account stats' className='px-8 py-6' onTryAgain={getTransactionStats} />
            }
        </div>
    );
}

export default StudentAccountSummarySection;