import { unifiedApi } from '../httpService';


const getClassrooms = async (schoolId: number | string, query: any) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${schoolId}/grades`, {
            params: query,
            timeout: 10000
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const deleteClassroom = async (schoolId: number | string, query: any) => {
    try {
        const result = await unifiedApi.delete(`/api/v1/schools/${schoolId}/grades`, {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getClassrooms,
    deleteClassroom
}