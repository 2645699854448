import { Menu, Transition } from '@headlessui/react';
import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';

function AvatarSelectInput({ value, size = 8, fileSize = 1.6, label, placeholder = "Select Photo", layout = "block", onChange, onBlur, onFileChange }: Props) {
    const refInputFile = useRef<HTMLInputElement>(null);
    const [imgVal, setImgVal] = useState('');
    const [error, setError] = useState('');


    useEffect(() => {
        if (value) {
            setImgVal(value);
        }
    }, [value]);


    return (
        <div className={`flex gap-2.5 ${layout === 'block' ? 'flex-col items-center text-center' : 'items-center'}`}>
            <div className='group inline-block rounded-full' style={{ width: `${size}rem`, height: `${size}rem` }}>
                {
                    !imgVal &&
                    <button
                        type='button'
                        className='w-full h-full transition-colors border border-black/10 dark:border-white/5 hocus:border-black/20 dark:hocus:border-white/10 border-dashed rounded-full  p-1.5'
                        onClick={() => {
                            if (refInputFile.current) {
                                refInputFile.current.click();
                            }
                        }}
                    >

                        <div className='flex justify-center items-center h-full rounded-full transition-colors duration-300 bg-black/5 dark:bg-white/5 group-hover:bg-black/10 dark:group-hover:bg-white/10'>
                            <div className='text-center opacity-60'>
                                <i className="ri-image-add-fill" style={{ fontSize: `${size * 0.2}rem` }} ></i>
                                <span className='block' style={{ fontSize: `${size * 0.08}rem` }} >{placeholder}</span>
                            </div>
                        </div>
                    </button>
                }

                {
                    imgVal &&
                    <Menu as="div" className="relative w-full h-full">
                        <Menu.Button type='button' className="w-full h-full transition-colors border border-black/10 dark:border-white/5 hocus:border-black/20 dark:hocus:border-white/10 border-dashed rounded-full overflow-hidden p-1.5">
                            <div className='relative w-full h-full rounded-full overflow-hidden'>
                                <img src={imgVal} className='w-full h-full rounded-full object-cover' alt='' />
                                <span className='absolute inset-0 rounded-full bg-black transition-opacity duration-300 opacity-0 group-hover:opacity-20 ui-open:opacity-20'></span>
                            </div>
                        </Menu.Button>


                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-300"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-200"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            unmount={false}
                        >
                            <Menu.Items as='div' unmount={false} className="absolute top-1/2 left-1/2 text-sm w-auto origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden mt-2 px-2 py-2">
                                <Menu.Item
                                    as="button"
                                    type='button'
                                    className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg pl-4 pr-8 py-2"
                                    onClick={() => {
                                        if (refInputFile.current) {
                                            refInputFile.current.click();
                                        }
                                    }}
                                >
                                    Change
                                </Menu.Item>
                                <Menu.Item
                                    as="button"
                                    type='button'
                                    onClick={() => {
                                        setImgVal('');
                                        if (onChange) {
                                            onChange('');
                                        }
                                    }}
                                    className="w-full text-left text-red-500 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg pl-4 pr-8 py-2"
                                >
                                    Remove
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                }

                <input
                    ref={refInputFile}
                    type="file"
                    id="input-type-file-for-photo"
                    onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                            const file = event.target.files[0];

                            if (file.size > (fileSize * 1024 * 1024)) {
                                setError(`File size is bigger than ${fileSize} MB`);
                            } else {
                                setError('');

                                const reader = new FileReader();
                                reader.readAsDataURL(event.target.files[0]); // read file as data url

                                reader.onload = (evt) => {
                                    if (evt.target) {
                                        // called once readAsDataURL is completed
                                        const image = evt.target.result as string || '';
                                        setImgVal(image);
                                        if (onChange) {
                                            onChange(image);
                                        }
                                    }
                                };
                            }
                        }

                        if (onFileChange) {
                            onFileChange(event);
                        }
                    }}
                    onBlur={onBlur}
                    accept="image/jpeg, image/jpg, image/png"
                    hidden
                />
            </div>

            <div>

                {label && <label htmlFor="input-type-file-for-photo" className='block mb-1'>{label}</label>}
                <div className='text-sm text-gray-500 dark:text-gray-400'>
                    <p>Allowed *.jpeg, *.jpg, *.png</p>
                    <p>max size of {fileSize} MB</p>
                    {
                        error &&
                        <p className='text-red-500 mt-2'>{error}</p>
                    }
                </div>
            </div>
        </div>
    );
}


interface Props {
    value?: string;
    layout?: 'block' | 'inline';
    label?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    onFileChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    size?: number;
    fileSize?: number;
}


export default AvatarSelectInput;