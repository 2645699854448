import React, { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import Modal from '../../../../../components/modals/Modal';
import { Avatar, Button, ErrorLoading, ListNoData } from '../../../../../components/common';
import studentService from '../../../../../services/api/unified-api/studentService';
import delegateService from '../../../../../services/api/unified-api/delegateService';
import { ListLoader } from '../../../../../components/loaders';


function ModalStudentDetails({ isOpen, schoolId, student, onClose }: Props) {
    const [loadingStatus, setLoadingStatus] = useState({ loading: false, error: false, notFound: false });
    const [qrCodeStatus, setQrCodeStatus] = useState({ loading: false, error: false, notFound: false });
    const [studentDetails, setStudentDetails] = useState<any>(null);
    const [qrCode, setQrCode] = useState('');


    useEffect(() => {
        if (isOpen && student) {
            getStudent();
        }
    }, [student?.id]);


    const getStudent = async () => {
        setLoadingStatus({ loading: true, error: false, notFound: false });
        const result = await studentService.getStudentProfile(schoolId, student.id);
        if (result?.success) {
            setStudentDetails(result.data);
            setLoadingStatus({ loading: false, error: false, notFound: false });
        } else {
            if (result.notFound) {
                setLoadingStatus({ loading: false, error: true, notFound: true });
            } else {
                setLoadingStatus({ loading: false, error: true, notFound: false });
            }
        }
    }


    const getQRCode = async (delegates: any[] = []) => {
        const delegateList: any[] = studentDetails?.delegates || delegates;

        const selfDelegate = delegateList.find((delegate: any) => delegate.relation === 'SELF');

        if (selfDelegate) {
            setQrCodeStatus({ loading: true, error: false, notFound: false });
            const result = await delegateService.generateQrCode({
                delegateId: selfDelegate.id,
                parentId: -1
            }, { forSchool: false });

            if (result?.success) {
                setQrCode(result.data);
                setQrCodeStatus({ loading: false, error: false, notFound: false });
            } else {
                if (result.notFound) {
                    setQrCodeStatus({ loading: false, error: true, notFound: true });
                } else {
                    setQrCodeStatus({ loading: false, error: true, notFound: false });
                }
            }
        } else {
            setQrCodeStatus({ loading: false, error: true, notFound: true });
        }
    };


    return (
        <Modal open={isOpen} onClose={onClose} >
            <div className="inline-block w-screen max-w-3xl">
                <div className="flex justify-between mb-6 px-10 pt-8">
                    <h2 className="font-medium text-xl">Student Profile</h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none">
                        <i className="ri-close-line"></i>
                    </button>
                </div>


                {
                    !loadingStatus.loading && !loadingStatus.error && studentDetails &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <div className='flex justify-between items-center flex-wrap gap-4 mb-4'>
                            <div className='flex items-center'>
                                <Avatar imageUrl={student?.avatar} name={`${student?.firstName} ${student?.otherNames || ''} ${student?.lastName}`} size={4} className='mr-4' />
                                <div>
                                    <p className='font-medium text-lg '>{student?.firstName} {student?.otherNames || ''} {student?.lastName}</p>
                                    <p className='text-gray-500 dark:text-gray-400'>{student?.studentNumber}</p>
                                </div>
                            </div>
                        </div>


                        <div className=''>
                            <Tab.Group>
                                <Tab.List as='div' className="border-b dark:border-gray-700">
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Details</Tab>
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Enrollment</Tab>
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Parents</Tab>
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Delegates</Tab>
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">QR Code</Tab>
                                </Tab.List>
                                <Tab.Panels as='div' className="pb-4">
                                    <Tab.Panel unmount={false} as='div' className="divide-y dark:divide-gray-800">
                                        <div className='pt-4 sm:px-4'>
                                            <div className='grid grid-cols-2 gap-x-6'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Gender</p>
                                                    <p>{student?.gender || 'N/A'}</p>
                                                </div>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Nationality</p>
                                                    <p>{student?.nationality || 'N/A'}</p>
                                                </div>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-6'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date of Birth</p>
                                                    <p>{student?.dob ? format(new Date(student?.dob), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                                                </div>

                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Admission Date</p>
                                                    <p>{student?.dateOfAdmission ? format(new Date(student?.dateOfAdmission), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                                                </div>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-6'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last DropOff Date/Time</p>
                                                    <p>{studentDetails?.lastDropOffDatetime ? format(new Date(studentDetails?.lastDropOffDatetime), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                                                </div>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Drop Off Person</p>
                                                    <p>{studentDetails?.lastDropOffDelegate?.fullName || 'N/A'}</p>
                                                </div>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-6'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Pickup Date/Time</p>
                                                    <p>{studentDetails?.lastPickupDatetime ? format(new Date(studentDetails?.lastPickupDatetime), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                                                </div>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Pickup Person</p>
                                                    <p>{studentDetails?.lastPickupDelegate?.fullName || 'N/A'}</p>
                                                </div>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-6'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date Created</p>
                                                    <p>{student?.createdDate ? format(new Date(student?.createdDate), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                                                </div>

                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Updated</p>
                                                    <p>{student?.updatedDate ? format(new Date(student?.updatedDate), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                    <Tab.Panel unmount={false} as='div' className="divide-y dark:divide-gray-800">
                                        <div className='pt-4 sm:px-4'>
                                            <div className='grid grid-cols-2 gap-x-6'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>School</p>
                                                    <p>{studentDetails?.school?.name} <span className='text-gray-500 dark:text-gray-400'>({studentDetails?.school?.alias})</span></p>
                                                </div>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Campus</p>
                                                    <p>{studentDetails?.campus?.name} <span className='text-gray-500 dark:text-gray-400'>({studentDetails?.campus?.alias})</span></p>
                                                </div>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-6'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Campus</p>
                                                    <p>{studentDetails?.campus?.name} <span className='text-gray-500 dark:text-gray-400'>({studentDetails?.campus?.alias})</span></p>
                                                </div>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Student No.</p>
                                                    <p>{studentDetails?.studentNumber}</p>
                                                </div>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-6'>
                                                <div className='py-4'>
                                                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Admission Date</p>
                                                    <p>{student?.dateOfAdmission ? format(new Date(student?.dateOfAdmission), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                    <Tab.Panel unmount={false} as='div' className="pt-5">
                                        {
                                            studentDetails.parents && studentDetails.parents.length > 0 &&
                                            <div className='max-w-full overflow-x-auto mb-6'>
                                                <table className="border-collapse table-auto text-sm w-full">
                                                    <thead>
                                                        <tr className='text-left border-b dark:border-gray-700'>
                                                            <th className='py-3 pl-2 pr-4'>Name</th>
                                                            <th className='py-3 px-4'>Relation</th>
                                                            <th className='whitespace-nowrap py-3 px-4'>Primary Contact</th>
                                                            <th className='py-3 pl-4 pr-2'>E-Mail</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y dark:divide-gray-700/60">
                                                        {
                                                            studentDetails.parents.map((parentItem: any, parentItemIndex: number) =>
                                                                <tr key={parentItem.id}>
                                                                    <td className='whitespace-nowrap py-3 pl-2 pr-4'>
                                                                        {parentItem?.firstName} {parentItem?.otherNames} {parentItem?.lastName}
                                                                    </td>
                                                                    <td className='py-3 px-4'>{parentItem.parentType}</td>
                                                                    <td className='py-3 px-4'>{parentItem.primaryContact}</td>
                                                                    <td className='py-3 pl-4 pr-2'>{parentItem.email}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                        {
                                            !studentDetails.parents &&
                                            <ListNoData title='No Parent Found' description={`No parent assigned to student`} className='!pt-10 !pb-0'>
                                            </ListNoData>
                                        }
                                    </Tab.Panel>
                                    <Tab.Panel unmount={false} as='div' className="pt-5">
                                        {
                                            studentDetails.delegates && studentDetails.delegates.length > 0 &&
                                            <div className='max-w-full overflow-x-auto mb-6'>
                                                <table className="border-collapse table-auto text-sm w-full">
                                                    <thead>
                                                        <tr className='text-left border-b dark:border-gray-700'>
                                                            <th className='py-3 pl-2 pr-4'>Name</th>
                                                            <th className='py-3 px-4'>Relation</th>
                                                            <th className='whitespace-nowrap py-3 px-4'>Primary Contact</th>
                                                            <th className='py-3 pl-4 pr-2'>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y dark:divide-gray-700/60">
                                                        {
                                                            studentDetails.delegates.map((delegateItem: any, parentItemIndex: number) =>
                                                                <tr key={delegateItem.id}>
                                                                    <td className='whitespace-nowrap py-3 pl-2 pr-4'>
                                                                        <div className='flex items-center gap-2.5'>
                                                                            <span>{delegateItem?.fullName}</span>


                                                                            {
                                                                                delegateItem.primary &&
                                                                                <span className={`inline-block font-medium text-sm uppercase bg-blue-500/10 text-blue-500 rounded-lg py-1 px-2.5`}>
                                                                                    Primary
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className='py-3 px-4'>{delegateItem.relation}</td>
                                                                    <td className='py-3 px-4'>{delegateItem.primaryContact}</td>
                                                                    <td className='py-3 pl-4 pr-2'>
                                                                        {
                                                                            delegateItem.status === "ACTIVE" &&
                                                                            <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-3 mr-3`}>{delegateItem.status}</span>
                                                                        }
                                                                        {
                                                                            delegateItem.status !== "ACTIVE" && delegateItem.status !== "SUSPENDED" &&
                                                                            <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-3 mr-3`}>{delegateItem.status}</span>
                                                                        }
                                                                        {
                                                                            delegateItem.status === "SUSPENDED" &&
                                                                            <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-1 px-3 mr-3`}>{delegateItem.status}</span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                        {
                                            !studentDetails.delegates &&
                                            <ListNoData title='No Delegate Found' description="No delegate assigned to student" className='!pt-10 !pb-0'>
                                            </ListNoData>
                                        }
                                    </Tab.Panel>
                                    <Tab.Panel unmount={false} as='div' className="pt-8">
                                        {
                                            !qrCodeStatus.loading && !qrCode &&
                                            <ListNoData title='' showIcon={false} description={`Click the button below to generate QR COde`} className='!pt-10 pb-20'>
                                                <Button type='button' onClick={() => getQRCode()} className='py-2'>Generate QR Code</Button>
                                            </ListNoData>
                                        }
                                        {
                                            !qrCodeStatus.loading && qrCode &&
                                            <div className='text-center'>
                                                <img src={qrCode} className='inline-block max-w-xs mb-4' alt="Student QR Code" />
                                                <div>
                                                    <a href={qrCode} download={`qrcode-for-student-${student.firstName}-${student.otherNames || ''}-${student.lastName}.png`} className='transition-colors text-blue-500 hover:underline'>Download QR Code</a>
                                                </div>
                                            </div>
                                        }
                                        {
                                            qrCodeStatus.loading &&
                                            <ListLoader loadingText='Loading QR Code' className='py-10' loaderSize={2} />
                                        }
                                        {
                                            qrCodeStatus.error && qrCodeStatus.notFound &&
                                            <ListNoData title='No QR Code Found' showIcon={false} description={`No QR Code found for ${student.firstName} ${student.otherNames || ''} ${student.lastName}`} className='!pt-10 !pb-0'>
                                            </ListNoData>
                                        }
                                        {
                                            qrCodeStatus.error && !qrCodeStatus.notFound &&
                                            <ErrorLoading title='Error Loading QR Code' showIcon={false} message='An unexpected error occurred while loading QR Code' className='px-8 py-16' onTryAgain={getQRCode} />
                                        }
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>

                        <div className='flex justify-between gap-y-1 gap-x-6 items-center'>
                            <Link to={`/students/${schoolId}/${student?.id}`} state={{ profile: studentDetails }} onClick={onClose} className="text-blue hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                Go to Full Profile
                            </Link>

                            <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                                Close
                            </Button>
                        </div>
                    </div>
                }

                {
                    (loadingStatus.loading || loadingStatus.error) &&
                    <div className='pb-10'>
                        {
                            loadingStatus.loading &&
                            <ListLoader loadingText='Loading Profile' className='py-10' loaderSize={2} />
                        }
                        {
                            loadingStatus.error && !loadingStatus.notFound &&
                            <ErrorLoading title='Error Loading Profile' message="An unexpected error occurred while loading student profile" className='px-8 py-16' onTryAgain={getStudent} />
                        }
                        {
                            loadingStatus.error && loadingStatus.notFound &&
                            <ErrorLoading title='Profile Not Found' message="Student may have been removed or never existed" className='px-8 py-16' />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


interface Props {
    isOpen: boolean;
    student: any;
    schoolId: number | string;
    onClose: () => void;
}

export default ModalStudentDetails;