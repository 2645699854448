import { paymentsApi } from '../httpService';


const getCustomerInvoicesByReference = async (referenceId: any, query: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/invoices/customers/references/${referenceId}/list`, {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const getInvoice = async (invoiceId: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/invoices/${invoiceId}`, {
            timeout: 8000
        });
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


const unlockInvoices = async (data: any) => {
    try {
        const result = await paymentsApi.patch(`/api/v1/invoices/statuses/unlock`, data);

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


const recomputeInvoice = async (invoiceId: number) => {
    try {
        const result = await paymentsApi.get(`/api/v1/invoices/${invoiceId}/recompute`, {});

        return {
            success: true,
            data: result.data.data,
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getCustomerInvoicesByReference,
    getInvoice,
    unlockInvoices,
    recomputeInvoice,
}