import { unifiedApi } from '../httpService';


const getCampus = async (schoolId: number, campusId: number) => {
    try {
        const result = await unifiedApi.get(`/api/v1/schools/${schoolId}/campuses/${campusId}`, {});
        return {
            success: true,
            data: result.data.data,
            message: ""
        };
    } catch (error: any) {
        /* if (error.response && error.response.status === 404) {
            return {
                success: false,
                data: null,
                message: "An unexpected Error"
            };
        } */

        return {
            success: false,
            data: null,
            message: "An unexpected Error"
        };
    }
}


const createCampus = async (schoolId: number, data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/schools/${schoolId}/campuses`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            const issues: any[] = error.response.data.errors;
            if (issues[0].field === "contact") {
                return {
                    success: false,
                    data: null,
                    message: "Invalid contact number provided"
                };
            }
        }


        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating campus. Please try again later."
        };
    }
}


const updateCampus = async (schoolId: number, campusId: number, data: any) => {
    try {
        const result = await unifiedApi.put(`/api/v1/schools/${schoolId}/campuses/${campusId}`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating campus. Please try again later."
        };
    }
}


const addCampusConfigurations = async (campusId: number, data: any) => {
    try {
        const result = await unifiedApi.post(`/api/v1/campuses/${campusId}/configurations`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem adding configurations to campus. Please try again later."
        };
    }
}


const updateCampusConfigurations = async (schoolId: number, campusId: number, data: any) => {
    try {
        const result = await unifiedApi.put(`/api/v1/schools/${schoolId}/configurations`, data, {
            params: {
                campusId: campusId
            }
        });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating campus configurations. Please try again later."
        };
    }
}







export default {
    getCampus,
    createCampus,
    updateCampus,
    addCampusConfigurations,
    updateCampusConfigurations
}