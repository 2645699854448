import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Popover } from '@headlessui/react';

import { DatePickerInput, FormInput, SelectInput } from '../../../../../components/form';
import PopoverWithFloat from '../../../../../components/hoc/PopoverWithFloat';
import { Button } from '../../../../../components/common';
import schoolService from '../../../../../services/api/unified-api/schoolService';
import { debounce } from 'lodash';
import useIsFirstRender from '../../../../../state/hooks/useIsFirstRender';


function AppNotificationListFilters({ initialData, searching, onSearch }: Props) {
    //const [schoolList, setSchoolList] = useState<any[]>([{ id: '', name: "All schools" }]);
    const [schoolListData, setSchoolListData] = useState({ loading: false, list: [{ id: '', name: "All schools" }] });

    const initial = useIsFirstRender();


    useEffect(() => {
        if (initial && initialData['clientReferenceIds']) {
            getSelectedSchool(initialData['clientReferenceIds']);
        }
    });


    const handleSubmit = (values: any, { reset }: any) => {
        const payload: any = { ...values };

        /* if (values.schoolId || initialData['schoolId']) { payload['schoolId'] = values.schoolId }
        if (values.channel || initialData['channel']) { payload['channel'] = values.channel }
        if (values.service || initialData['service']) { payload['service'] = values.service }
        if (values.deliveryStatus || initialData['deliveryStatus']) { payload['deliveryStatus'] = values.deliveryStatus }
        if (values.recipient || initialData['recipient']) { payload['recipient'] = values.recipient }
        if (values.fromDate || initialData['fromDate']) { payload['fromDate'] = values.fromDate }
        if (values.toDate || initialData['toDate']) { payload['toDate'] = values.toDate } */

        onSearch(payload);
    }


    const getSelectedSchool = async (schoolId: string) => {
        const result = await schoolService.getSchoolProfile(schoolId);
        if (result.success) {
            setSchoolListData({ loading: false, list: [{ id: '', name: "All schools" }, { ...result.data }] });
        } else {
        }
    }


    const getSchools = debounce(async (searchQuery: string) => {
        setSchoolListData({ loading: true, list: [] });
        const result = await schoolService.getSchools({ name: searchQuery });
        if (result.success) {
            setSchoolListData({ loading: false, list: [{ id: '', name: "All schools" }, ...result.data] });
        } else {
            setSchoolListData({ loading: false, list: [{ id: '', name: "All schools" }] });
        }
    }, 500)


    return (
        <Formik
            initialValues={{
                schoolId: initialData['clientReferenceIds'] || '',
                channel: initialData['channel'] || '',
                service: initialData['service'] || '',
                deliveryStatus: initialData['deliveryStatus'] || '',
                recipient: initialData['recipient'] || '',
                fromDate: initialData['createdAt[$gt]'] || '',
                toDate: initialData['createdAt[$lt]'] || '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ errors, touched, setErrors, submitCount, values, isValid, setFieldValue, resetForm }) => (
                <Form className="flex-grow flex items-center flex-wrap mr-8 mb-4 gap-y-4">
                    <div className='hidden sm:block max-w-[16rem] mr-4'>
                        <SelectInput
                            items={schoolListData.list}
                            labelKey='name'
                            valueKey='id'
                            searchable={true}
                            placeholder="Select school"
                            value={values["schoolId"]}
                            onChange={(school: any) => setFieldValue("schoolId", school.id)}
                            onSearch={(query) => getSchools(query)}
                            searching={schoolListData.loading}
                        />
                    </div>

                    <div className='hidden xl:block mr-4'>
                        <SelectInput
                            items={[
                                { label: "All Channels", value: "" },
                                { label: "Email", value: "EMAIL" },
                                { label: "SMS", value: "SMS" },
                                { label: "Push Notification", value: "FCM" },
                            ]}
                            placeholder="Select Channel"
                            value={values["channel"]}
                            onChange={(value: { label: string, value: string }) => setFieldValue("channel", value.value)}
                        />
                    </div>

                    <div className='hidden md:block mr-4'>
                        <SelectInput
                            items={[
                                { label: "All Services", value: "" },
                                { label: 'Child Security', value: 'UNIFIED_API' },
                                { label: 'Canteen Manager', value: 'CANTEEN_MANAGER' },
                                { label: 'Connected Community', value: 'CONNECTED_COMMUNITY' },
                                { label: 'Elearning', value: 'ELEARNING' },
                            ]}
                            placeholder="Select Service"
                            value={values["service"]}
                            onChange={(value: { label: string, value: string }) => setFieldValue("service", value.value)}
                        />
                    </div>

                    <div className='hidden 2xl:block mr-4'>
                        <SelectInput
                            items={[
                                { label: "All Statuses", value: "" },
                                { label: 'Successful', value: 'SUCCESS' },
                                { label: 'Failed', value: 'FAILED' },
                                { label: 'Unknown', value: 'UNKNOWN' },
                            ]}
                            placeholder="Select Status"
                            value={values["deliveryStatus"]}
                            onChange={(value: { label: string, value: string }) => setFieldValue("deliveryStatus", value.value)}
                        />
                    </div>

                    <FormInput type="text" id="input-search-recipient" name="recipient" className="lg:w-full lg:max-w-xs mr-4" placeholder="Enter recipient" autoComplete='off' />


                    <Popover as="div" className="relative inline-block text-left mr-4">
                        {
                            ({ open, close }) => (
                                <PopoverWithFloat placement='bottom-end' portal={false}>
                                    <Popover.Button type='button' className={`flex items-center transition-colors duration-300 hocus:bg-gray-50 ui-open:bg-gray-50 dark:hocus:bg-gray-800 dark:ui-open:bg-gray-800 rounded-lg py-2 px-2.5`}>
                                        <span className='text-sm whitespace-nowrap mr-1.5'>More Filters</span>
                                        <i className="text-lg ri-more-2-fill"></i>
                                    </Popover.Button>

                                    <Popover.Panel as='div' unmount={false} className="w-screen max-w-sm sm:max-w-md  lg:max-w-lg right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60  mt-1 py-5 px-5 mr-2">
                                        <div className='grid sm:grid-cols-2 gap-y-5 gap-x-5 mb-5'>
                                            <div className=''>
                                                <SelectInput
                                                    items={schoolListData.list}
                                                    labelKey='name'
                                                    valueKey='id'
                                                    // searchable={true}
                                                    placeholder="Select school"
                                                    value={values["schoolId"]}
                                                    onChange={(school: any) => setFieldValue("schoolId", school.id)}
                                                    usePortal={false}
                                                    onSearch={(query) => getSchools(query)}
                                                    searching={schoolListData.loading}
                                                />
                                            </div>

                                            <div className=''>
                                                <SelectInput
                                                    items={[
                                                        { label: "All Channels", value: "" },
                                                        { label: "Email", value: "EMAIL" },
                                                        { label: "SMS", value: "SMS" },
                                                        { label: "Push Notification", value: "FCM" },
                                                    ]}
                                                    placeholder="Select Channel"
                                                    value={values["channel"]}
                                                    onChange={(value: { label: string, value: string }) => setFieldValue("channel", value.value)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className=''>
                                                <SelectInput
                                                    items={[
                                                        { label: "All Services", value: "" },
                                                        { label: 'Child Security', value: 'UNIFIED_API' },
                                                        { label: 'Canteen Manager', value: 'CANTEEN_MANAGER' },
                                                        { label: 'Connected Community', value: 'CONNECTED_COMMUNITY' },
                                                        { label: 'Elearning', value: 'ELEARNING' },
                                                    ]}
                                                    placeholder="Select Service"
                                                    value={values["service"]}
                                                    onChange={(value: { label: string, value: string }) => setFieldValue("service", value.value)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className=''>
                                                <SelectInput
                                                    items={[
                                                        { label: "All Statuses", value: "" },
                                                        { label: 'Successful', value: 'SUCCESS' },
                                                        { label: 'Failed', value: 'FAILED' },
                                                        { label: 'Unknown', value: 'UNKNOWN' },
                                                    ]}
                                                    placeholder="Select Status"
                                                    value={values["deliveryStatus"]}
                                                    onChange={(value: { label: string, value: string }) => setFieldValue("deliveryStatus", value.value)}
                                                    usePortal={false}
                                                />
                                            </div>

                                            <div className=''>
                                                <DatePickerInput
                                                    placeholder='From Date'
                                                    value={values["fromDate"]}
                                                    maxDate={values["toDate"]}
                                                    onChange={(value) => setFieldValue("fromDate", value.fullDate)}
                                                />
                                            </div>

                                            <div className=''>
                                                <DatePickerInput
                                                    placeholder='To Date'
                                                    value={values["toDate"]}
                                                    minDate={values["fromDate"]}
                                                    onChange={(value) => setFieldValue("toDate", value.fullDate)}
                                                />
                                            </div>

                                            <div className='sm:col-span-2'>
                                                <FormInput type="text" id="input-search-more-recipient" name="recipient" className="sm:w-full" placeholder="Enter recipient" autoComplete='off' />
                                            </div>
                                        </div>


                                        <div className='flex justify-between items-center'>
                                            <button
                                                type='reset'
                                                onClick={() => resetForm({
                                                    values: {
                                                        schoolId: '',
                                                        channel: '',
                                                        service: '',
                                                        deliveryStatus: '',
                                                        recipient: '',
                                                        fromDate: '',
                                                        toDate: '',
                                                    }
                                                })}
                                                className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}
                                            >
                                                Reset
                                            </button>

                                            <Button type='submit' onClick={close} loading={searching} className='!py-2' disabled={searching}>
                                                Search
                                            </Button>
                                        </div>
                                    </Popover.Panel>
                                </PopoverWithFloat>
                            )
                        }
                    </Popover>
                    <button type='submit' className='text-blue-500 hover:text-blue-600 disabled:text-blue-400 px-2' disabled={searching}>Search</button>
                </Form>
            )}
        </Formik>
    );
}


interface Props {
    initialData: any;
    onSearch: (values: any) => void;
    searching: boolean;
}


export default AppNotificationListFilters;