import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';

import PopoverWithFloat from '../hoc/PopoverWithFloat';
import CalendarInput, { CalendarInputProps } from './CalendarInput';
import { Popover } from '@headlessui/react';
import useClickOutside from '../../state/hooks/useClickOutside';


function DatePickerInput({ value = '', dateFormat = "do MMM yyyy", placeholder = '', onBlur, onFocus, onChange, usePortal = true, placement = 'bottom-start', ...otherProps }: Props) {
    let isClicked = false;
    const containerRef = useRef<HTMLDivElement>(null);
    const [dateValue, setDateValue] = useState('');


    useClickOutside(containerRef, () => {
        if (onBlur && isClicked) {
            onBlur();
        }
    });


    useEffect(() => {
        setDateValue(value);
    }, [value]);


    return (
        <div ref={containerRef} className='' onClick={() => isClicked = true}>
            <Popover as="div" className="relative" onFocus={onFocus}>
                {
                    ({ open, close }) => (
                        <PopoverWithFloat placement={placement} portal={usePortal}>
                            <Popover.Button type='button' className={`w-full flex justify-between items-center text-left bg-transparent autofill:bg-white border ${open ? 'border-blue-500/60 dark:border-blue-500/60' : 'border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600'}  focus:outline-none rounded-lg py-2.5 px-4`}>
                                {!dateValue && <span className='opacity-50 mr-3.5'>{placeholder}</span>}
                                {
                                    dateValue &&
                                    <span className='flex-grow mr-3.5'>
                                        {dateValue && format(new Date(dateValue), dateFormat)}
                                    </span>
                                }
                                <i className="ri-calendar-2-line opacity-60"></i>
                            </Popover.Button>

                            <Popover.Panel as='div' unmount={false} className="-left-2.5 origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-700/60 overflow-visible py-3">
                                <div className='py-3 px-5'>
                                    <CalendarInput
                                        value={dateValue}
                                        onChange={(date) => {
                                            setDateValue(date.fullDate);
                                            close();
                                            if (onChange) {
                                                onChange(date);
                                            }
                                        }}
                                        {...otherProps}
                                    />
                                </div>
                            </Popover.Panel>
                        </PopoverWithFloat>
                    )
                }
            </Popover>
        </div>
    );
}


interface Props extends CalendarInputProps {
    placeholder?: string;
    dateFormat?: string;
    onBlur?: () => void;
    onFocus?: () => void;

    usePortal?: boolean;
    placement?: any;
}


export default DatePickerInput;