import React, { useState } from 'react';

function OTPPrefixInput({ prefix, onFocus, onBlur, className, ...otherProps }: Props) {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className={`flex items-center gap-3 transition-all duration-300 border ${isFocused ? 'border-blue-500/60 dark:border-blue-500/60' : 'border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600'} outline-none rounded-lg py-4 px-6 ${className}`}>
            {prefix && <span className='text-2xl whitespace-nowrap text-gray-500 dark:text-gray-400'>{prefix} - </span>}
            <input
                onFocus={event => {
                    setIsFocused(true);
                    if (onFocus) {
                        onFocus(event);
                    }
                }}
                onBlur={event => {
                    setIsFocused(false);
                    if (onBlur) {
                        onBlur(event);
                    }
                }}
                className='block bg-transparent tracking-widest text-3xl max-w-[9rem]' {...otherProps}
            />
        </div>
    );
}


interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    prefix: string;
}

export default OTPPrefixInput;