import React, { useState } from 'react';


const getInitials = (name: string) => {
    const initialsArr = name.match(/\b[a-zA-Z]/g) || [];
    const initials = (initialsArr.shift() || "") + (initialsArr.pop() || "");
    return initials.toUpperCase() || "U";
};

function Avatar({ name, imageUrl, size = 2.4, className = '' }: Props) {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div
            className={`flex-shrink-0 inline-flex justify-center items-center bg-blue-500/20 text-blue-500 rounded-full ${className}`}
            style={{ width: `${size}rem`, height: `${size}rem`, fontSize: `${size * 0.38}rem` }}
        >
            <img
                src={imageUrl}
                onLoad={() => setImageLoaded(true)}
                className={`inline-block object-cover rounded-full ${imageLoaded ? 'w-full h-full' : 'absolute h-0 w-0'}`}
                alt={name + "'s picture"}
            />

            {!imageLoaded && <span>{getInitials(name || '')}</span>}
        </div>
    )
}


interface Props {
    name: string;
    imageUrl: string;
    size?: number;
    className?: string;
}


export default Avatar;