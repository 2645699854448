import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { FormInput } from '../../../../../components/form';
import { Button } from '../../../../../components/common';
import scannerService from '../../../../../services/api/unified-api/scannerService';


function ModalScannerForm({ isOpen, scanner, onClose, onScannerAdded, onScannerUpdated }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });

    useEffect(() => {
        if (isOpen) {
            setSubmitStatus({ loading: false, failedMessage: '' });
        }
    }, [isOpen]);


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        if (scanner) {
            // update
            const result = await scannerService.updateScanner(scanner.id, formData);
            if (result.success) {
                if (onScannerUpdated) {
                    onScannerUpdated({ ...scanner, ...result.data });
                }
                //resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        } else {
            // create
            const result = await scannerService.createScanner(formData);
            if (result.success) {
                if (onScannerAdded) {
                    onScannerAdded(result.data);
                }
                resetForm();
                onClose();
                setSubmitStatus({ loading: false, failedMessage: '' });
            } else {
                setSubmitStatus({ loading: false, failedMessage: result.message });
            }
        }
    }



    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[28rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {scanner ? "Edit" : "Add"} Scanner
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            batchNumber: scanner ? scanner.batchNumber : '',
                            serialNumber: scanner ? scanner.serialNumber : '',
                            version: scanner ? scanner.version : '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.failedMessage && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }

                                <div className="mb-6">
                                    <label htmlFor="input-serial-number" className='block text-sm mb-2'>Serial Number</label>
                                    <FormInput type="text" name="serialNumber" id="input-serial-number" className="w-full" placeholder="Enter scanner serial number" />
                                    {touched['serialNumber'] && errors['serialNumber'] && <p className='text-sm text-red-500 mt-2'>Scanner serial number is Required</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="input-batch-number" className='block text-sm mb-2'>Batch Number</label>
                                    <FormInput type="text" name="batchNumber" id="input-batch-number" className="w-full" placeholder="Enter batch number" />
                                    {touched['batchNumber'] && errors['batchNumber'] && <p className='text-sm text-red-500 mt-2'>Scanner batch number is Required</p>}
                                </div>

                                <div className="mb-10">
                                    <label htmlFor="input-version" className='block text-sm mb-2'>Version</label>
                                    <FormInput type="text" name="version" id="input-version" className="w-full" placeholder="Enter app version" />
                                    {touched['version'] && errors['version'] && <p className='text-sm text-red-500 mt-2'>App version is Required</p>}
                                </div>

                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        {scanner ? "Save" : "Add Scanner"}
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    batchNumber: Yup.string().trim().required().label(""),
    serialNumber: Yup.string().trim().required().label(""),
    version: Yup.string().trim().required().label(""),
});

interface Props {
    isOpen: boolean;
    onClose: () => void;
    scanner: any;
    onScannerAdded?: (scanner: any) => void;
    onScannerUpdated?: (scanner: any) => void;
}


export default ModalScannerForm;