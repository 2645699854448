import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { format } from 'date-fns';

import { Avatar, ErrorLoading, ListNoData } from '../../../../../../../../components/common';
import { ListLoader } from '../../../../../../../../components/loaders';
import Loader from '../../../../../../../../components/svgs/Loader';


function StudentDetailsPage() {
    const { profile, setProfile, qrCode, qrCodeStatus, getQRCode }: any = useOutletContext();

    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl divide-y dark:divide-gray-700 py-8 px-8'>
            <div className='flex justify-between pb-6'>
                <div className='flex items-center mr-5'>
                    <Avatar name={`${profile?.firstName} ${profile?.otherNames} ${profile?.lastName}`} imageUrl={profile?.avatar} size={4.8} className="shrink-0 mr-5" />

                    <div>
                        <h2 className='text-2xl font-medium mb-1'>{profile?.firstName} {profile?.otherNames} {profile?.lastName}</h2>
                        <p className='text-gray-500 dark:text-gray-400'>Student Number: {profile?.studentNumber || 'N/A'}</p>
                    </div>
                </div>
            </div>


            <div className='lg:col-span-4 xl:col-span-2 pt-6 pb-6'>
                <h2 className='font-medium text-xl mb-2'>Student Details</h2>

                <div className='grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-8'>
                    <div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Gender</p>
                            <p>{profile?.gender || 'N/A'}</p>
                        </div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date of Birth</p>
                            <p>{profile?.dob ? format(new Date(profile?.dob), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                        </div>
                    </div>

                    <div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Nationality</p>
                            <p>{profile?.nationality || 'N/A'}</p>
                        </div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>General ID</p>
                            <p>{profile?.generalId}</p>
                        </div>
                    </div>

                    <div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date Created</p>
                            <p>{format(new Date(profile?.createdDate), 'do LLLL yyyy h:mm a')}</p>
                        </div>
                        {
                            profile?.updatedDate &&
                            <div className='py-3.5'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Updated</p>
                                <p>{format(new Date(profile?.updatedDate), 'do LLLL yyyy h:mm a')}</p>
                            </div>
                        }
                    </div>


                    {/*  <div className='flex justify-center'>
                        <div className='relative max-h-36 rounded-lg bg-black/5 dark:bg-white/5 aspect-square overflow-hidden'>
                            {
                                !qrCodeStatus.loading && qrCode &&
                                <React.Fragment>
                                    <img src={qrCode} className='block w-full h-full scale-110 mb-4' alt="Student QR Code" />
                                    <div className='flex justify-center items-center absolute inset-0 bg-black/70 opacity-0 hover:opacity-100 transition-opacity duration-300'>
                                        <a href={qrCode} download={`qrcode-for-student-${profile?.firstName}-${profile?.otherNames}-${profile?.lastName}.png`} className='bg-blue font-medium rounded-lg text-sm py-2.5 px-4'>
                                            Download
                                        </a>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div> */}
                </div>
            </div>


            <div className='lg:col-span-4 xl:col-span-2 pt-6 pb-6'>
                <h2 className='font-medium text-xl mb-2'>School Details</h2>

                <div className='grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-8'>
                    <div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>School</p>
                            <p className='whitespace-pre-line'>{profile?.school?.name}</p>
                            <span className='text-gray-500 dark:text-gray-400'>({profile?.school?.alias})</span>
                        </div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Campus</p>
                            <p>{profile?.campus?.name} <span className='text-gray-500 dark:text-gray-400'>({profile?.campus?.alias})</span></p>
                        </div>
                    </div>

                    <div>
                        <div className='py-3.5'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Class</p>
                            <p>{profile?.grade?.name}</p>
                            <span className='text-gray-500 dark:text-gray-400'>({profile?.grade?.code})</span>
                        </div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Student Number</p>
                            <p>{profile?.studentNumber}</p>
                        </div>
                    </div>

                    <div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Admission Date</p>
                            <p>{profile?.dateOfAdmission ? format(new Date(profile?.dateOfAdmission), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className='lg:col-span-4 xl:col-span-2 pt-6 pb-3'>
                <h2 className='font-medium text-xl mb-2'>Other Details</h2>

                <div className='grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-8'>
                    <div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last DropOff Date/Time</p>
                            <p>{profile?.lastDropOffDatetime ? format(new Date(profile?.lastDropOffDatetime), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                        </div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Drop Off Person</p>
                            <p>{profile?.lastDropOffDelegate?.fullName || 'N/A'}</p>
                        </div>
                    </div>

                    <div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Pickup Date/Time</p>
                            <p>{profile?.lastPickupDatetime ? format(new Date(profile?.lastPickupDatetime), 'do LLLL yyyy h:mm a') : 'N/A'}</p>
                        </div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Pickup Person</p>
                            <p>{profile?.lastPickupDelegate?.fullName || 'N/A'}</p>
                        </div>
                    </div>

                    <div>
                        <div className='py-4'>
                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Allergies</p>
                            <p>{profile?.allergies?.length > 0 ? profile?.allergies[0].name : 'N/A'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentDetailsPage;