import { configureStore, } from "@reduxjs/toolkit";

import rootReducer from "./reducers/rootReducer";
//import api from "./middleware/apiMiddleware";


const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })//.concat(api)
});

export type RootState = ReturnType<typeof store.getState>
export type DispatchType = typeof store.dispatch;


export const state = store.getState();


export default store;