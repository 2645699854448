import { paroApi } from '../httpService';


const getCategories = async (query: any) => {
    try {
        const result = await paroApi.get('feed-categories', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getCategories
}