import React, { useContext, useLayoutEffect } from 'react';
import { useDispatch, ReactReduxContext } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import routing from './AppRouting';
import { setInitialTheme, setTheme, setUITheme } from './state/store/reducers/ui/themeSlice';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


// Create a client
const queryClient = new QueryClient();

function App() {
    const { store } = useContext<any>(ReactReduxContext);
    const dispatch = useDispatch();


    useLayoutEffect(() => {
        // Setting initial app theme
        dispatch(setTheme({ mode: setInitialTheme() }));

        // listening to store changes
        const unsubscribeFromStore = store.subscribe(() => {
            const theme = store.getState().ui.theme.mode;
            setUITheme(theme);
        });


        return () => {
            // unsubscribing from store
            unsubscribeFromStore();
        };
    });


    return (
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={routing} />
        </QueryClientProvider>
    );
}

export default App;
