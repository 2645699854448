import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import SmartSappLogo from '../../../components/svgs/SmartSappLogo';
import { Button } from '../../../components/common';
import { FormInput } from '../../../components/form';
import userAccountsService from '../../../services/api/user-accounts/userAccountsService';


function ForgotPasswordPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        const result = await userAccountsService.requestAccountReset({ ...formData, action: "FORGOT_PASSWORD" });

        if (result.success) {
            navigate('/verify-account', { replace: true, state: { email: formData.email, ...result.data } });
        } else {
            setSubmitStatus({
                loading: false,
                failedMessage: result.message
            });
        }
    }

    return (
        <main className='login-page bg-blue/5 dark:bg-gray-900 bg-no-repeat min-h-screen px-8 py-12 sm:py-14 md:py-16 lg:py-20 xl:py-36'>
            <div className='sm:max-w-lg bg-white dark:bg-gray-950 rounded-3xl mx-auto py-16 px-8 sm:px-10'>
                <div className='text-center mb-8'>
                    <span className='inline-block w-8 text-blue-500 '>
                        <SmartSappLogo />
                    </span>
                    {/*  */}
                    <p className='text-lg font-medium text-gray-500 dark:text-gray-400 mb-6'>SmartSapp</p>
                    <h1 className='font-medium text-2xl mb-1'>Forgot Password</h1>
                    <p className='max-w-xs text-gray-500 dark:text-gray-400 mx-auto'>Enter your registered email below to receive reset code</p>
                </div>

                <div className='mb-7'>
                    <Formik
                        initialValues={{
                            email: location.state && location.state.username ? location.state.username : '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ errors, touched, setErrors, submitCount, values, isValid }) => (
                            <Form className="" autoComplete="off">
                                {
                                    (submitStatus.failedMessage) &&
                                    <p className='text-center text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                }

                                <div className="mb-8">
                                    {/* <label htmlFor="input-email" className='block text-sm mb-1.5'>Email</label> */}
                                    <input autoComplete="false" name="hidden" type="email" style={{ display: 'none' }} />
                                    <FormInput type="email" id="input-email" name="email" className="w-full" placeholder="Enter your email" autoComplete='off' />
                                    {touched['email'] && errors['email'] && <p className='text-sm text-red-500 mt-2'>A valid email is required</p>}
                                </div>

                                <div className='mb-10'>
                                    <Button type='submit' loading={submitStatus.loading} className='w-full' disabled={submitStatus.loading}>
                                        Send
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>


                <div className='text-center'>
                    <Link to="/login" className='inline-flex justify-center items-center gap-2 text-gray-500 dark:text-gray-400 hover:text-blue dark:hover:text-blue'>
                        <span className='text-lg'><i className="ri-arrow-left-line"></i></span>
                        <span>Back to Login</span>
                    </Link>
                </div>
            </div>
        </main>
    );
}


const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email().required().label(""),
});


export default ForgotPasswordPage;