import React, { useEffect, useState } from 'react';

function RatingStarsComponent({ rating = 0, maximumRating = 5, className = '' }: Props) {
    const [starList, setStarList] = useState<number[]>([]);


    useEffect(() => {
        let compare = rating;
        const list = Array.from({ length: maximumRating }, (_, i) => {
            if (compare >= 1) {
                compare--;
                return 1;
            }

            if (compare >= 0.5) {
                compare = 0;
                return 0.5
            }

            return 0;
        });

        setStarList(list);

    }, [rating, maximumRating])


    return (
        <div className={className}>
            {
                starList.map((starItem, starItemIndex) =>
                    <React.Fragment key={`${starItem}${starItemIndex}`}>
                        {
                            starItem === 1 &&
                            <i className="ri-star-fill"></i>
                        }
                        {
                            starItem === 0.5 &&
                            <i className="ri-star-half-fill"></i>
                        }
                        {
                            starItem === 0 &&
                            <i className="ri-star-line"></i>
                        }
                    </React.Fragment>
                )
            }
        </div>
    );
}


interface Props {
    rating?: number;
    maximumRating?: number;
    className?: string;
}


export default RatingStarsComponent;