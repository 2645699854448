import { paymentsApi } from '../httpService';


const getThirdParties = async (query: any) => {
    try {
        const result = await paymentsApi.get('/api/v1/third-parties', {
            params: query
        });

        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        return {
            success: false,
            message: "An unexpected Error"
        };
    }
}



const getThirdParty = async (thirdPartyId: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/third-parties/${thirdPartyId}`, {});
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


const createThirdParty = async (data: any) => {
    try {
        const result = await paymentsApi.post(`/api/v1/third-parties`, data, {});
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {
        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem creating feed. Please try again later."
        };
    }
}


const updateThirdPartyStatus = async (thirdPartyId: number, data: any) => {
    try {
        const result = await paymentsApi.patch(`/api/v1/third-parties/${thirdPartyId}/statuses`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating feed. Please try again later."
        };
    }
}



export default {
    getThirdParties,
    getThirdParty,
    createThirdParty,
    updateThirdPartyStatus
}