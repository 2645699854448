import { paymentsApi } from '../httpService';


const getClientByReference = async (schoolId: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/clients/references/${schoolId}`, {});
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        }

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}


export default {
    getClientByReference
}