import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../../../components/modals/Modal';
import { SelectInput } from '../../../../../components/form';
import { Button, ErrorLoading } from '../../../../../components/common';
import { ListLoader } from '../../../../../components/loaders';
import schoolService from '../../../../../services/api/unified-api/schoolService';
import employeeService from '../../../../../services/api/unified-api/employeeService';
import useAlert from '../../../../../state/hooks/useAlert';


function ModalMoveUserToDIfferentSchoolForm({ isOpen, user, onClose, onUserMoved }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, failedMessage: '' });
    const [currentSchoolStatus, setCurrentSchoolStatus] = useState({ loading: false, error: false, notFound: false });
    const [currentSchool, setCurrentSchool] = useState<any>(null);

    const [schoolListData, setSchoolListData] = useState({ loading: false, list: [] as any[] });
    //const [schoolList, setSchoolList] = useState<any[]>([]);

    const { openAlertConfirm } = useAlert();



    useEffect(() => {
        if (isOpen && user && user.schoolId !== currentSchool?.id) {
            getCurrentSchool();
        }
    }, [isOpen, user]);


    const getCurrentSchool = async () => {
        setCurrentSchoolStatus({ loading: true, error: false, notFound: false });
        const result = await schoolService.getSchoolProfile(user.schoolId);
        if (result?.success) {
            setCurrentSchool(result.data);
            setCurrentSchoolStatus({ loading: false, error: false, notFound: false });
        } else {
            if (result.notFound) {
                setCurrentSchoolStatus({ loading: false, error: true, notFound: true });
            } else {
                setCurrentSchoolStatus({ loading: false, error: true, notFound: false });
            }
        }
    }


    const getSchools = debounce(async (searchQuery: string) => {
        setSchoolListData({ loading: true, list: [] });
        const result = await schoolService.getSchools({ name: searchQuery });
        if (result.success) {
            setSchoolListData({ loading: false, list: [...result.data] });
        } else {
            setSchoolListData({ loading: false, list: [] });
        }
    }, 500);


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        openAlertConfirm({
            title: 'Move User',
            message: `Are you sure you want to move ${user.username} to ${formData['destinationSchool']['name']}?`,
            onConfirmed: () => handleMoveUser(formData, resetForm),
            positiveButtonText: "Move",
            negativeButtonText: 'Cancel',
        });
    }


    const handleMoveUser = async (formData: any, resetForm: () => void) => {
        setSubmitStatus({ loading: true, failedMessage: '' });
        const payload = {
            destinationSchoolId: formData.destinationId,
            sourceSchoolId: currentSchool.id,
            employeeIds: [user.employeeId]
        }

        const result = await employeeService.moveToDifferentSchool(payload);
        if (result.success) {
            if (onUserMoved) {
                onUserMoved(result.data);
            }
            resetForm();
            onClose();
            setSubmitStatus({ loading: false, failedMessage: '' });
        } else {
            setSubmitStatus({ loading: false, failedMessage: result.message });
        }
    }



    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[32rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        Move User to School
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>
                {
                    !currentSchoolStatus.loading && !currentSchoolStatus.error &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <Formik
                            initialValues={{
                                destinationId: '',
                                destinationSchool: null,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                                <Form className="">
                                    {
                                        submitStatus.failedMessage && !submitStatus.loading &&
                                        <p className='text-red-500 mb-4' >{submitStatus.failedMessage}</p>
                                    }

                                    <div className='mb-6'>
                                        <p className='text-gray-500 dark:text-gray-400 mb-2'>Move user from:</p>
                                        <p className='font-medium text-lg'>{currentSchool?.name}</p>
                                    </div>

                                    <div className="mb-10">
                                        <label htmlFor="input-move-to" className='block text-sm mb-2'>To</label>

                                        <SelectInput
                                            items={schoolListData.list}
                                            labelKey='name'
                                            valueKey='id'
                                            searchable={true}
                                            placeholder="Select school"
                                            value={values["destinationId"]}
                                            onChange={(school: any) => {
                                                setFieldValue("destinationId", school.id);
                                                setFieldValue("destinationSchool", school);
                                            }}
                                            onSearch={(query) => getSchools(query)}
                                            searching={schoolListData.loading}
                                        // usePortal

                                        />
                                        {/*  <SelectInput
                                            items={schoolList}
                                            placeholder="Select School"
                                            labelKey='name'
                                            valueKey='id'
                                            value={values["destinationId"]}
                                            onChange={(value: any) => {
                                                setFieldValue("destinationId", value.id);
                                                setFieldValue("destinationSchool", value);
                                            }}
                                        /> */}
                                        {touched['destinationId'] && errors['destinationId'] && <p className='text-sm text-red-500 mt-2'>Destination school is required</p>}
                                    </div>


                                    <div className='flex justify-end items-center'>
                                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                            Cancel
                                        </Button>

                                        <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                            Move User
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                }

                {
                    (currentSchoolStatus.loading || currentSchoolStatus.error) &&
                    <div className='pb-10'>
                        {
                            currentSchoolStatus.loading &&
                            <ListLoader loadingText='Loading Current School' className='py-10' loaderSize={2} />
                        }
                        {
                            currentSchoolStatus.error &&
                            <ErrorLoading title='Error Loading School' message='An unexpected error occurred while loading school' className='px-8 py-16' onTryAgain={getCurrentSchool} />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


const validationSchema = Yup.object().shape({
    destinationId: Yup.string().trim().required().label(""),
});


interface Props {
    isOpen: boolean;
    user: any;
    onClose: () => void;
    onUserMoved?: (data: any) => void;
}


export default ModalMoveUserToDIfferentSchoolForm;