import { useDispatch } from 'react-redux';

import { AlertConfirmActionProps, AlertOkActionProps, alertConfirm, alertOk } from '../store/reducers/ui/alertSlice';


function useAlert() {
    const dispatch = useDispatch();

    const openAlertOk = (data: AlertOkActionProps) => {
        dispatch(alertOk(data));
    }

    const openAlertConfirm = (data: AlertConfirmActionProps) => {
        dispatch(alertConfirm(data))
    }

    return { openAlertOk, openAlertConfirm };
}

export default useAlert;



